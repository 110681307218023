import { Component, OnInit, ViewChild } from '@angular/core';
import { BioSearchData, HuellaSearch, RespuestaBioSearch, TravelDocumentData } from 'projects/siepbio-comu/src/lib/model/traveler';
import { IntegrationService } from 'projects/siepbio-comu/src/lib/serveis/integration.service';
import { AnsiNistData, AuditoriaFrontera, ConfiguracioService, DactilarService, DocumentoFrontera, ErrorRespuestaFrontera, Excepcio, FacialService, Fingerprint, FSMT, ImatgeTipus, ImpressioTipus, Modal, MotiuAbsencia, PasoFrontera, PersonaFrontera, Posat, RespuestaPasoFrontera, Sexo, SistemaFrontera } from 'projects/siepbio-comu/src/public-api';
import { DecisionTomada, Etapa, TipoDecision } from '../../model/documento';
import { CategoriaMensaje, MensajeProceso, ResultadoSearchEES, TipoMensajeProceso } from '../../model/EntryExit';
import { AutenticacionService } from 'projects/siepbio-comu/src/lib/serveis/autenticacion.service';
import { EnrolamentService } from '../../serveis/enrolament.service';
import { DatosService } from 'projects/siepbio-comu/src/lib/serveis/datos.service';
import { faCheck, faExclamationTriangle, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { isJSDocThisTag } from 'typescript';

@Component({
  selector: 'app-consultas',
  templateUrl: './consultas.component.html',
  styleUrls: ['./consultas.component.css']
})
export class ConsultasComponent implements OnInit {
  @ViewChild('missatgeModal') missatgeModal?: MissatgeModalComponent;
 
  resultadoBiometria: string = "success";
  mensajePolicial1: string;
  mensajePolicial2: string;
  mensajePolicial3: string;
  mensajePolicial4: string;
  mensajePolicial5: string;
  mensajePolicial6: string;
  mensajePolicial7: string;
  mensajePolicial8: string;

  mensajeBiometria: string;

  Etapa: typeof Etapa = Etapa;
  iconValido = faCheck;
  iconNoValido = faExclamationTriangle;
  iconBuscar = faSearch;
  iconActualizar = faRefresh;

  constructor(
    private dactilarServei: DactilarService,
    private configuracioServei: ConfiguracioService,
    private consultesService: AutenticacionService, 
    private integracionService: IntegrationService,
    private facialService: FacialService, 
    private enrolmentService: EnrolamentService,
    public datosService: DatosService) { }
  
  resultadoPolicial() {

    const resp = this.datosService.datosVerificacion?.respuestaPasoFrontera

    if (!resp.posiblePositivoSpecified && !resp.entrevistarTCNSpecified && !resp.permitirPasoCNPSpecified)
      return "alert-secondary";

    if (resp.posiblePositivo) {
      if (!resp.entrevistarTCNSpecified && !resp.permitirPasoCNPSpecified)
        return "alert-danger";
      else
        return "bg-orange";
    }

    if (resp.posiblePositivoSpecified && !resp.posiblePositivo) {
      if (resp.entrevistarTCNSpecified && resp.permitirPasoCNP) 
        return "alert-success";
      else
        return "alert-primary"; // Azul
    }

    if (!resp.posiblePositivoSpecified && resp.entrevistarTCNSpecified && resp.permitirPasoCNPSpecified)
      return "alert-warning";

    return "alert-success";
  }

  ngOnInit(): void {
    if (this.datosService.datosVerificacion?.respuestaPasoFrontera != undefined){
      this.validacionesConsultasPoliciales(this.datosService.datosVerificacion?.respuestaPasoFrontera);
    }
    if (this.datosService.datosVerificacion?.respuestaBioSearch != undefined){
      this.validacionesTextosBioSearch(this.getBioSearchData());
    }
  }
  
  obtenerURLSICF() {
    const idCnp = this.datosService.datosVerificacion.respuestaPasoFrontera.identificadorCNP;
    const keyAcceso = this.datosService.usuarioAutenticado.keyAcceso;
    const usuario = this.datosService.usuarioAutenticado.idn;
    const plantilla = this.datosService.usuarioAutenticado.plantilla;

    const ip = this.datosService.usuarioAutenticado.direccionIp??'10.200.26.101';
    const sentido = this.datosService.sentido;
    const literalSistema = this.datosService.usuarioAutenticado.literalSistema;

    return `http://171.26.12.236:39181/SICF/fronteraVerificador?idCnp=${idCnp}&keyAcceso=${keyAcceso}&usuario=${usuario}&plantilla=${plantilla}&direccionIp=${ip}&sistema=${literalSistema}&sentido=${sentido}&ubicacionDispositivo=${ip}`;
  }

  async consulta(): Promise<void>{
    let datos = await this.getPasoForntera();
    this.missatgeModal.tanca();
    this.missatgeModal.obre(
      new Modal({
        titol: "Procesando consulta",
        missatge:"Obteniendo información de la consulta policial",
        indicacions: "Espere un momento, por favor",
        esProgres: true,
        accions: [],
      })
    );
    try{
      this.datosService.datosVerificacion.respuestaPasoFrontera = await this.consultesService.pasoFrontera(datos);
      this.missatgeModal.tanca();
      this.validacionesConsultasPoliciales(this.datosService.datosVerificacion.respuestaPasoFrontera);
    } catch (ex) {
      this.datosService.datosVerificacion.respuestaPasoFrontera = new RespuestaPasoFrontera();
      
      if (ex instanceof Excepcio) {
        this.mensajePolicial1 = ex.missatge;
        this.mensajePolicial2 = null;
        this.mensajePolicial3 = null;
        this.mensajePolicial4 = null;
        this.mensajePolicial5 = null;
      } else {
        if(ex.error){
          this.mensajePolicial1 = ex.error.message;
          this.mensajePolicial2 = null;
          this.mensajePolicial3 = null;
          this.mensajePolicial4 = null;
          this.mensajePolicial5 = null;
        } else {
          this.mensajePolicial1 = ex.message;
          this.mensajePolicial2 = null;
          this.mensajePolicial3 = null;
          this.mensajePolicial4 = null;
          this.mensajePolicial5 = null;
        }
        this.datosService.datosVerificacion.resultadoConsultaPolicia = false;
      }
      this.missatgeModal.tanca();
    }
    console.log("Inicia Consulta biométrica");
    this.busqueda();
  }

  private colorError(err: ErrorRespuestaFrontera): string {
    return err.tipo == "E"? "danger": "warning";
  }

  private textoError(err: ErrorRespuestaFrontera): string {
    return err?.tipo + ":(" + err?.codigo + ") " + err?.descripcion;
  }

  private validacionesConsultasPoliciales(respuestaPasoFrontera: RespuestaPasoFrontera): void{
    console.log("Inicio validaciones de consultas policiales. Respuesta paso frontera", respuestaPasoFrontera);

    if (!respuestaPasoFrontera.permitirPasoCNPSpecified || (respuestaPasoFrontera.permitirPasoCNPSpecified && !respuestaPasoFrontera.permitirPasoCNP))    
      this.datosService.datosVerificacion.resultadoConsultaPolicia = false;
    else
      this.datosService.datosVerificacion.resultadoConsultaPolicia = true;

    this.mensajePolicial1 = "Identificador CNP: " + respuestaPasoFrontera.identificadorCNP;
    this.mensajePolicial2 = "Identificador Petición: " + respuestaPasoFrontera.identificadorPeticion;
    if(respuestaPasoFrontera.permitirPasoCNPSpecified)
      this.mensajePolicial3 = "Permitir paso CNP: " + this.toTexto(respuestaPasoFrontera.permitirPasoCNP);
    if(respuestaPasoFrontera.posiblePositivoSpecified)
      this.mensajePolicial4 = "Posible positivo: " + this.toTexto(respuestaPasoFrontera.posiblePositivo);
    if(respuestaPasoFrontera.entrevistarTCNSpecified)
      this.mensajePolicial5 = "Entrevistar TCN: " + this.toTexto(respuestaPasoFrontera.entrevistarTCN);
    if(respuestaPasoFrontera.tomarDecisionSpecified)
      this.mensajePolicial6 = "Tomar Decisión: " + this.toTexto(respuestaPasoFrontera.tomarDecision);
    if(respuestaPasoFrontera.abrirPuertaSpecified)
      this.mensajePolicial7 = "Abrir puerta: " + this.toTexto(respuestaPasoFrontera.abrirPuerta);
    this.mensajePolicial8 = null;

    if (respuestaPasoFrontera?.errores?.length > 0){
      this.mensajePolicial8 = "";
      respuestaPasoFrontera?.errores.forEach(element => {
        this.mensajePolicial8 += element?.tipo + ":(" + element?.codigo + ") " + element?.descripcion + ". ";
      });
    }

    this.datosService.datosVerificacion.listaMensajes = this.datosService.datosVerificacion.listaMensajes.filter(m => m.categoria != CategoriaMensaje.ConsultaPolicial);

    respuestaPasoFrontera?.errores?.forEach(e => {
      const msg = new MensajeProceso();
      msg.categoria = CategoriaMensaje.ConsultaPolicial;
      msg.texto = e?.tipo + ":(" + e?.codigo + ") " + e?.descripcion;
      msg.tipo = TipoMensajeProceso.Error;
      this.datosService.datosVerificacion.listaMensajes.push(msg);
    });

    if (this.datosService.datosVerificacion.resultadoConsultaPolicia)
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Consultas].tipo = TipoDecision.ACEPTADO;
      else
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Consultas].tipo = TipoDecision.RECHAZADO;
  }

  private toTexto(valor: boolean) : string {
    return valor?"Verdadero":"Falso";
  }

  private async getPasoForntera(): Promise<PasoFrontera> {
    let auditoria = new AuditoriaFrontera();
    auditoria.plantilla = this.datosService.usuarioAutenticado.plantilla;
    auditoria.idn = this.datosService.usuarioAutenticado.idn;
   
    let documento = new DocumentoFrontera();
    documento.numeroIdentificacion = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.idPersonal;
    documento.fechaCaducidad = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.fechaCaducidad;
    documento.fechaExpedicion = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.fechaExpedicion;
    documento.nacionalidad = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.nacionalidad;
    documento.tipo = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.tipoDocumento;
    documento.soporte = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.numeroDocumento; 
    documento.lineaMRZ = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.line1;

    let persona = new PersonaFrontera();
    persona.apellidos =  this.datosService.datosVerificacion?.documentoLeido?.rfid 
      ? this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.apellidos 
      : this.datosService.datosVerificacion?.documentoLeido?.MRZData?.apellidos;
    persona.nombre =  this.datosService.datosVerificacion?.documentoLeido?.rfid 
      ? this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.nombre 
      : this.datosService.datosVerificacion?.documentoLeido?.MRZData?.nombre;
    persona.fechaNacimiento =  this.datosService.datosVerificacion?.documentoLeido?.MRZData?.fechaNacimiento;
    persona.nacionalidad =  this.datosService.datosVerificacion?.documentoLeido?.MRZData?.nacionalidad;
    persona.sexo =  this.datosService.datosVerificacion?.documentoLeido?.MRZData?.sexo == "M"? Sexo.M: 
      this.datosService.datosVerificacion?.documentoLeido?.MRZData?.sexo == "F"?Sexo.F: Sexo.U;

    let sistema = new SistemaFrontera();
    sistema.sentido = this.datosService.sentido;

    let datos = new PasoFrontera();

    datos.checkFacial = this.decisionToCheck(this.datosService.datosVerificacion.decisionesTomadas[Etapa.Facial]);
    //Como no se envian huellas a la consulta policial el check dactilar se deja undefined
    //datos.checkDactilar = this.decisionToCheck(this.datosService.datosVerificacion.decisionesTomadas[Etapa.Dactilar]);
    datos.checkDocumento = this.decisionToCheck(this.datosService.datosVerificacion.decisionesTomadas[Etapa.Documento]);

    datos.auditoria = auditoria;
    datos.persona = persona;
    datos.documento = documento;
    datos.sistema = sistema;

    datos.imagenChip = this.datosService.datosVerificacion.documentoLeido?.RFIDData?.foto;
    datos.imagenVivo = this.datosService.datosVerificacion.fotoCapturada;

    //const blobNIST = await this.dactilarServei.crearNIST(this.configuracioServei.getNISTData(), this.datosService.datosVerificacion.huellasCapturadas, null);
    //this.datosService.datosVerificacion.nistHuellas = await ConfiguracioService.fromBlob( blobNIST );
    //console.log("NIST Huellas", this.datosService.datosVerificacion.nistHuellas);
    //datos.nist = this.datosService.datosVerificacion.nistHuellas;

    datos.instanteInicioRegistro = this.datosService.datosVerificacion.tiempos.instanteInicioRegistro;
    return datos;
  }

  private decisionToCheck(decision: DecisionTomada) {
    switch(decision.tipo) {
      case TipoDecision.ACEPTADO: return false;
      case TipoDecision.RECHAZADO: return true;
      default: return null;
    }
  }

  async busqueda(): Promise<void>{
    let data = this.getBioSearchData();
    this.resultadoBiometria ="warning";
    this.mensajeBiometria = "Pendiente de respuesta.";
    this.missatgeModal.tanca();
    this.missatgeModal.obre(
      new Modal({
        titol: "Procesando consulta biométrica",
        missatge:"Obteniendo información de la consulta biométrica al EES",
        indicacions: "Espere un momento, por favor",
        esProgres: true,
        accions: [],
      })
    );
    try {
      this.datosService.datosVerificacion.respuestaBioSearch = await this.integracionService.bioSearch(data);
      this.missatgeModal.tanca();
    } catch (ex) {
      this.datosService.datosVerificacion.respuestaBioSearch = new RespuestaBioSearch();
      this.datosService.datosVerificacion.respuestaBioSearch.error = true;
      if (ex instanceof Excepcio) {
        this.mensajeBiometria = ex.missatge;
      } else {
        if(ex.error){
          this.mensajeBiometria = ex.error?.message;
        } else {
          this.mensajeBiometria = ex.message;
        }
        this.datosService.datosVerificacion.resultadoConsultaBiometrica = false;
      }
      this.missatgeModal.tanca();
    }
    this.validacionesTextosBioSearch(data);
  }

  private getBioSearchData(): BioSearchData{
    let data = new BioSearchData();
      data.face =  this.facialService.netejarBase64(this.datosService.datosVerificacion?.fotoCapturada);
      if (this.datosService.datosVerificacion?.huellasCapturadas != undefined){
        let huellas = new Array<HuellaSearch>();
        this.datosService.datosVerificacion?.huellasCapturadas?.forEach(element => {
          if (element != undefined && element?.rawImatge != undefined){
            let huella = new HuellaSearch();
            huella.height = element?.rawImatge?.height;
            huella.width =  element?.rawImatge?.width;
            huella.position = element?.numeroDit;
            huella.resolution = 500; //TODO variable de configuración??
            huella.pixels = element?.rawImatge.data;
            huella.colorspace = 1; //TODo variable de coniguración?
            huellas.push(huella);
          }
        });
        data.fingerprints = huellas;
      }
      data.transactionId = !this.datosService.datosVerificacion.respuestaBioSearch?.error &&
                          this.datosService.datosVerificacion.respuestaBioSearch?.enviada ? this.datosService.datosVerificacion.respuestaBioSearch?.transactionId : undefined;
      //
      data.transactionId = "SIMULACION";

      let documentoViaje = new TravelDocumentData();
      documentoViaje.documentNumber = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.numeroDocumento;
      documentoViaje.documentType = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.tipoDocumento;
      documentoViaje.issuingCountry = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.paisExpedidor;
      data.travelDocumentData = documentoViaje;
    
      console.log("Datos enviado para la consulta biometrica", data);
      return data;
  }

  private validacionesTextosBioSearch(data: BioSearchData): void{
    console.log("Validaciones consulta biométrica", data);
    
    if (this.datosService.datosVerificacion.respuestaBioSearch?.error){
      this.resultadoBiometria ="danger";
      this.mensajeBiometria = !this.mensajeBiometria?"Se ha producido un error. Vuelva a ejecutar la consulta.": this.mensajeBiometria;
      
      this.datosService.datosVerificacion.resultadoConsultaBiometrica = false;
    }
    else if (
      data.transactionId == undefined &&
        (this.datosService.datosVerificacion.respuestaBioSearch?.enviada == undefined ||
          !this.datosService.datosVerificacion.respuestaBioSearch?.enviada) &&
        !this.datosService.datosVerificacion.respuestaBioSearch?.disponible){
      this.resultadoBiometria ="warning";
      this.mensajeBiometria = "La consulta no se ha enviado. Repita la consulta";
      this.datosService.datosVerificacion.resultadoConsultaBiometrica = false;
    }
    else{
      // si se ha enviado la consulta search y se tiene TransaccionId
        if (this.datosService.datosVerificacion.respuestaBioSearch?.disponible == undefined ||
          !this.datosService.datosVerificacion.respuestaBioSearch?.disponible){
          this.resultadoBiometria ="warning";
          this.mensajeBiometria = "No se han encontrado registros.";
          this.datosService.datosVerificacion.resultadoConsultaBiometrica = false;
        }
        else{
          // la consulta está disponible
          if (!this.datosService.datosVerificacion.respuestaBioSearch?.resultadoBusqueda?.encontrado){
            this.resultadoBiometria ="success";
            this.mensajeBiometria = "Consulta realizada correctamente. No se ha encontrado registros.";
            this.datosService.datosVerificacion.resultadoConsultaBiometrica = true;
          }
          else if (this.datosService.datosVerificacion.respuestaBioSearch?.resultadoBusqueda?.encontrado == undefined){
            this.resultadoBiometria ="warning";
            this.mensajeBiometria = "Se ha producido un error y no se ha podido comprobar si se ha encontrado o no.";
            this.datosService.datosVerificacion.resultadoConsultaBiometrica = false;
          }
          else {
              //encontrado
            if (this.documentoCoincide(this.datosService.datosVerificacion.respuestaBioSearch?.resultadoBusqueda?.registros,this.datosService.datosVerificacion?.documentoLeido?.MRZData?.numeroDocumento)){
              this.resultadoBiometria ="success";
              this.mensajeBiometria = "Consulta realizada correctamente. El número de documento coincide con el obtenido en la consulta.";
              this.datosService.datosVerificacion.resultadoConsultaBiometrica = true;
            }
            else{
              this.resultadoBiometria ="danger";
              this.mensajeBiometria = "El número de documento no coincide con el obtenido en la consulta biométrica.";
              this.datosService.datosVerificacion.resultadoConsultaBiometrica = false;
            }
          }
        }
    }
    if (!this.datosService.datosVerificacion.listaMensajes?.some(e=> e.categoria == CategoriaMensaje.ConsultaIntegracion)){
      this.añadirError(this.mensajeBiometria,this.resultadoBiometria !="success", CategoriaMensaje.ConsultaIntegracion)
    }
    
    if (!this.datosService.datosVerificacion.decisionesTomadas[Etapa.Consultas].esDecisionManual){
      if (this.datosService.datosVerificacion.resultadoConsultaPolicia && this.datosService.datosVerificacion.resultadoConsultaBiometrica)
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Consultas].tipo = TipoDecision.ACEPTADO;
      else
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Consultas].tipo = TipoDecision.RECHAZADO;
    }
  }

  documentoCoincide(records: Array<ResultadoSearchEES>, documentoMrz: string): boolean{
    return records?.some(element => element?.documento?.numeroDocumento == documentoMrz);
  }

  //TODO añadir los errores y validaciones que sea necesario
  private añadirError(texto: string, error: boolean, categoria: CategoriaMensaje){
    let mensaje = new MensajeProceso();
    mensaje.tipo = error ? TipoMensajeProceso.Error : TipoMensajeProceso.Validacion;
    mensaje.categoria = categoria;
    mensaje.texto = texto;
    this.datosService.datosVerificacion = this.enrolmentService.añadirError(this.datosService.datosVerificacion, mensaje);
  }
 
  cancelarProceso(): void{
    this.datosService.reiniciarProceso();
  }
}
