<div *ngIf="hayMensajes()">
    <h4 class="mt-2">{{nombreCategoria()}}</h4>
    <div class="row" *ngFor="let mensaje of mensajes()">
    <div class="col-sm-1">
        <fa-icon *ngIf="mensaje.tipo == TipoMensajeProceso.Validacion" [icon]="iconValido" class="text-success"></fa-icon>
        <fa-icon *ngIf="mensaje.tipo == TipoMensajeProceso.Error" [icon]="iconAlerta" class="text-danger"></fa-icon>
        <fa-icon *ngIf="mensaje.tipo == TipoMensajeProceso.Warning" [icon]="iconWarning" class="text-warning"></fa-icon>
    </div>
    <div class="col-sm-11">
        {{mensaje.texto}}
    </div>
    </div>
</div>