import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfiguracioService, DatosService } from 'projects/siepbio-comu/src/public-api';
import { CategoriaMensaje, MensajeProceso, TipoMensajeProceso } from '../../model/EntryExit';
import { faUser, faBars, faCheck, faTimes, faBan } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-validaciones',
  templateUrl: './validaciones.component.html',
  styleUrls: ['./validaciones.component.css']
})
export class ValidacionesComponent implements OnInit {
  @Input() categorias: Array<CategoriaMensaje>;
  @ViewChild('modal') modal?: ElementRef;
  
  CategoriaMensaje = CategoriaMensaje;
  TipoMensajeProceso  = TipoMensajeProceso;
  iconValido = faCheck;
  iconAlerta = faBan;

  constructor( private modalService: NgbModal, public datosService: DatosService) { }

  ngOnInit(): void {
  }

  obre(categorias:  Array<CategoriaMensaje>) {
    this.categorias = categorias;
    
    this.modalService.open(this.modal, {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      scrollable: true
    });
  }

}
