import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ContexteService {
  public software: string = 'Verificador Registro EES';
  public version: string = '01.00.01';
  public ultimaActualizacion: string = '13/10/2022';
  
  constructor() {}
}
