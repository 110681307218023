export class Modal {
  titol?: string;
  missatge?: string;
  detall?: string;
  llistaDetalls?: any;
  indicacions?: string;
  esProgres?: boolean = false;
  icona?: ModalIcon;
  esTancable?: boolean = false;
  accions?: ModalAccio[] = [
    new ModalAccio({ boto: ModalButton.Acceptar, principal: true }),
  ];

  public constructor(init: Partial<Modal>) {
    Object.assign(this, init);
  }
}

export enum ModalIcon {
  Cap,
  Informatiu,
  Atencio,
  Perill,
  Correcte,
}

export class ModalAccio {
  boto?: ModalButton;
  accio?: () => void;
  tancaModal?: boolean = true;
  text?: string;
  esAutofocus?: boolean = false;
  principal?: boolean = false;

  public constructor(init: Partial<ModalAccio>) {
    Object.assign(this, init);
  }
}

export enum ModalButton {
  Acceptar,
  Cancelar,
  Repetir,
}
