<div class="card">
  <h5 class="card-header bg-{{ colorTitol() }} text-white font-weight-bold">
    {{ titol() }}
  </h5>

  <ul class="list-group list-group-flush">
    <li
      *ngFor="let persona of resultatIdentificacio.personesIdentificades"
      class="list-group-item"
    >
      <div class="row">
        <div class="col-lg-4">
          <img
            class="img-fluid shadow rounded border border-secondary"
            *ngIf="persona.fotoFacialFrontal"
            alt="{{ 'comu.persona-identificada.foto' | translate }} {{
              persona.nom
            }}"
            src="{{ persona.fotoURL }}"
          />
        </div>
        <div class="col-lg-8">
          <div class="h5 card-title mt-0 mb-0">
            <strong>{{ persona.nom }}</strong>
          </div>
          <div class="row">
            <div class="col-sm-8">
              <div class="h5 card-title mt-0 mb-0 text-secondary">
                NIS <span #valorNIS> {{ persona.nis }}</span
                ><a
                  href="javascript:void(0)"
                  title="{{
                    'comu.persona-identificada.copiar-nis' | translate
                  }}"
                  class="mx-2"
                  (click)="copiarNIS(valorNIS)"
                >
                  <svg
                    width="1em"
                    height="1em"
                    viewBox="0 0 16 16"
                    class="bi bi-clipboard"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M9.5 1h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                    />
                  </svg>
                </a>
              </div>
              <div class="h5 card-title mt-0 mb-1 text-secondary">
                CIC {{ persona.cic }}
              </div>

              <div class="h4" *ngIf="persona.score">
                <span
                  class="badge badge-14 badge-pill badge-{{
                    colorScore(persona)
                  }}"
                  >{{ persona.score }} %</span
                >
              </div>

              <div class="mt-1 mb-1">
                <strong>{{ situacio(persona) }}</strong>
              </div>

              <div class="mt-1 mb-1" *ngIf="persona.darrerAcces">
                {{
                  "comu.persona-identificada.darrer-registre" | translate
                }}:<br />
                <strong
                  >{{ darrerRegistreTipus(persona) }} el
                  {{ darrerRegistreData(persona) }}</strong
                >
              </div>

              <button
                class="btn btn-warning pl-4 pr-4"
                (click)="inspeccionar(persona)"
              >
                {{ "comu.accio.inspeccionar" | translate }}
              </button>
            </div>
            <div
              class="col-sm-4 text-right p-0"
              *ngIf="resultatIdentificacio.personesIdentificades.length > 1"
            >
              <button
                type="button"
                class="btn btn-link"
                (click)="selecciona(persona)"
                aria-label="Seleccionar"
              >
                <div class="h1">
                  <svg
                    *ngIf="persona == resultatIdentificacio.personaIdentificada"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-check-square text-success"
                    viewBox="0 0 16 16"
                    width="2em"
                    height="2em"
                  >
                    <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                    />
                    <path
                      d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"
                    />
                  </svg>

                  <svg
                    *ngIf="persona != resultatIdentificacio.personaIdentificada"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="bi bi-square text-muted"
                    viewBox="0 0 16 16"
                    width="2em"
                    height="2em"
                  >
                    <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                    />
                  </svg>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
