<div class="container-fluid">
  <div class="row">
      <!-- sidebar-->
      <div class="col-sm-2"><app-sidebar></app-sidebar></div>
      <div class="col-sm-10">
        <app-inicialitzacio
        [hidden]="!(paginaActual == Pagina.Inicialitzacio)"
      ></app-inicialitzacio>
        <app-login [hidden]="!(paginaActual == Pagina.Login)" ></app-login>
        <app-enrolament [hidden]="!(paginaActual == Pagina.Enrolament)" ></app-enrolament>
      </div>
  </div>
</div>



<router-outlet></router-outlet>
