import { Injectable } from '@angular/core';
import { config } from 'process';
import { AnsiNistData } from '../model/nist';

export class NIST_Enrolament {
  
  id: string = 'LP0000000000000000000000000000';
  
  minuciesPlanas: boolean = false;
  minuciesRodadas: boolean = false;
  minuciesTipus: string = 'NB';
  minutaeDataReadMethod: string = 'A';
  
  originatingFingerprintReadingSystem: string = 'NIST_LFS_VER2';
  photoCompressionAlgorithm: string = 'JPEGB';
  reasonToTakePicture: string = 'CR';
  scaleUnits: string = '1';
  transactionReference: string = ""
  
  /* JVP Valores ya revisados */
  /* JVP: Valores que creemos que son fijos */
  version: string = '0502';
  typeTransaction: string = 'EES';
  priority: string = '9';
  destinationAgencyIdentifier: string = 'sBMS';
  originatingAgencyIdentifier: string = 'BELNOISE' //DUDA_VALOR?
  tcn: string = '0';
  nativeScanningResolution: string = '00.00';
  nominalTransmittingResolution: string = '00.00';
  version_2: string = '1.00'; //domain version number
  impressionType: string = '0';
  bitsPerPixel: string = '8';
  compressionAlgorithm: string = 'WSQ20';
  hps: string = '500';
  vps: string = '500';
  
}


export class ParametrosConfiguracion {
  public habilitarLectorHuellas: boolean = true;
  public intentsMinimCaptura: number = 0;
  public indexComparacioRepetit: number = 35;
  public indexComparacioCoincident: number = 20;
  public llindarMalaQualitat: number = 30;
  public qualitatMinimaPosades: number[] = [40, 40, 30, 30, 20, 40, 40, 30, 30, 20];
 
  public qualitatChecker: number[] = [40, 40, 40, 40, 30, 40, 40, 40, 40, 30];
  public incluirControlDuplicadosMeñique: boolean = false;

  public umbralFingerprintLiveness: number = 350; 

  public fotoFrontalObligatoria: boolean = false;
  public umbralCoincidenciaFotos: number = 2907;
  public encendidoLucesAutomatico: boolean = true;
  public midaMaximaKBFoto: number = 64;
  public reintentsMalaQualitat: number = 3;
  public permetContinuarSiMalaQualitat: boolean = true;
  public biometricQualityMetricThresholdFP: number = 123;
  public biometricQualityMetricThresholdFace: number = 456;
  public nist: NIST_Enrolament = new NIST_Enrolament();
  public utilizarUSKFace: boolean = true;
  public utilizarUSKDactilar: boolean = true;
  public edadMinimaCapturaHuellas: number = 12;

  public loginOperacion?: string = "VERIFICA"; 

  public autenticacionLiteralSistema?: string = "literal"; //TODO qué valor?
  public autenticacionDireccionIP?: string = "192.160.1.60"; //TODO qué valor?
  public autenticacionAplicacionCNP?: string = "aplicacionCNP"; //TODO qué valor?
  public fronteraUbicacionDispositivo?: string = "BCN"; 
  public fronteraSentido?: string = "Salida";

  public hostServiciosDocumentReader?: string = "https://localhost:3001";
  public hostServiciosFacialRecognition?: string = "https://localhost:3002";
  public hostServiciosSuprema?: string = "https://localhost:3003";
  public hostServiciosFPSDK?: string = "https://localhost:3004";
  public hostServiciosLaxton?: string = "https://localhost:3005";
  public hostServiciosIntegracion?: string = "https://localhost:3006";
  public hostServiciosPoliciales?: string = "https://localhost:3007";
  public hostServiciosUSK?: string = "https://localhost:3008";
  public hostServiciosIDV?: string = "https://localhost:3009";
  public hostServiciosIU?: string = "https://localhost:3010";
  
  public modoOscuro: boolean = true;
}

@Injectable({
  providedIn: 'root',
})
export class ConfiguracioService {

  private _parametrosConfiguracion = new ParametrosConfiguracion();

  constructor() {

    
  }

  public setConfiguracio(
    desti: { [key: string]: any },
    config?: { [key: string]: any }
  ): void {
    if (!config) return;

    Object.keys(config).forEach((k) => {
      if (desti.hasOwnProperty(k)) {
        if (Array.isArray(desti[k])) {
          desti[k] = JSON.parse(config[k]);
        } else if (desti[k] instanceof Object) {
          this.setConfiguracio(desti[k], config[k]);
        } else {
          switch (typeof desti[k]) {
            case 'boolean':
              desti[k] = Boolean(JSON.parse(config[k]));
              break;
            case 'number':
              desti[k] = Number(config[k]);
              break;
            case 'object':
              desti[k] = JSON.parse(config[k]);
              break;
            default:
              desti[k] = config[k];
              break;
          }
        }
      } else console.log('Propiedad de configuración no encontrada: ' + k);
    });
  }

  get parametrosConfiguracion(): ParametrosConfiguracion {
    if (localStorage.getItem('configuracion') != null){
      this._parametrosConfiguracion  =  JSON.parse(localStorage.getItem('configuracion'));
    }
    return  this._parametrosConfiguracion;
  }
  
  public static fromBlob(blob: Blob): Promise<string> {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        const bUrl = <string>reader.result;
        const result = bUrl.substr(bUrl.indexOf(',') + 1);

        resolve(result);
      };
      reader.readAsDataURL(blob);
    });
  }

  public getNISTData(): AnsiNistData {
    const data = new AnsiNistData();
    data.BitsPerPixel = this.parametrosConfiguracion.nist.bitsPerPixel;
    data.CaptureDate = new Date().getTime();
    data.CompressionAlgorithm = this.parametrosConfiguracion.nist.compressionAlgorithm;
    data.DestinationAgencyIdentifier = this.parametrosConfiguracion.nist.destinationAgencyIdentifier;
    data.OriginatingFingerprintReadingSystem = this.parametrosConfiguracion.nist.originatingFingerprintReadingSystem;
    data.hps = this.parametrosConfiguracion.nist.hps;
    data.Id = this.parametrosConfiguracion.nist.id;
    data.ImpressionType = this.parametrosConfiguracion.nist.impressionType;
    data.NativeScanningResolution = this.parametrosConfiguracion.nist.nativeScanningResolution;
    data.NominalTransmittingResolution = this.parametrosConfiguracion.nist.nominalTransmittingResolution;
    data.PhotoCompressionAlgorithm = this.parametrosConfiguracion.nist.photoCompressionAlgorithm;
    data.scaleUnits = this.parametrosConfiguracion.nist.scaleUnits;
    data.Tcn = this.parametrosConfiguracion.nist.tcn;
    data.TypeTransaction = this.parametrosConfiguracion.nist.typeTransaction;
    data.Version = this.parametrosConfiguracion.nist.version;
    data.Version_2 = this.parametrosConfiguracion.nist.version_2;
    data.vps = this.parametrosConfiguracion.nist.vps;
    data.ReasonToTakePicture = this.parametrosConfiguracion.nist.reasonToTakePicture;
    data.Priority = this.parametrosConfiguracion.nist.priority;
    data.transactionReference = this.parametrosConfiguracion.nist.transactionReference;
    data.OriginatingAgencyIdentifier = this.parametrosConfiguracion.nist.originatingAgencyIdentifier;
    data.Minucias = {
      Planas: this.parametrosConfiguracion.nist
        .minuciesPlanas,
      Rodadas: this.parametrosConfiguracion.nist
        .minuciesRodadas,
      Tipo: this.parametrosConfiguracion.nist.minuciesTipus,
    };
    data.MinutaeDataReadMethod = this.parametrosConfiguracion.nist.minutaeDataReadMethod;
   //TODO qué centro y estación?
    //data.OriginatingAgencyIdentifier =   this.contexteServei.centre + '/' + this.contexteServei.estacio;

    return data;
  }
  
}
