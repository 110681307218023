import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import {Observable, Subject, Subscription} from 'rxjs';
import { WebcamImage, WebcamInitError } from 'ngx-webcam';
import {faBoltLightning, faCamera, faCameraRotate, faTimesCircle, faUser} from '@fortawesome/free-solid-svg-icons';

import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { Modal, ModalIcon } from 'projects/siepbio-comu/src/lib/model/modal';
import { IcaoRequest, IcaoType, ResultadoIcao } from 'projects/siepbio-comu/src/lib/model/facial-info';
import { ConfiguracioService } from 'projects/siepbio-comu/src/lib/serveis/configuracio.service';
import { LaxtonService } from 'projects/siepbio-enrolament-dactilar/src/app/serveis/laxton.service';
import { FacialService } from 'projects/siepbio-comu/src/lib/serveis/facial.service';
import {
  FaceDetect,
  getBase64FromDataUrl,
  getBlurryVariance,
  getCroppedImage,
  isImageGoodQuality,
  isOutlier,
  removeLowestVariance
} from '../../helpers';

declare var cv: any;

@Component({
  selector: 'app-passport-scanner',
  templateUrl: './passport-scanner.component.html',
  styleUrls: ['./passport-scanner.component.css']
})
export class PassportScannerComponent implements OnInit, OnDestroy, AfterViewInit {

  @Output() capture = new EventEmitter<string>();
  @Output() closed = new EventEmitter<void>();

  @ViewChild('messageModal') messageModal?: MissatgeModalComponent;

  showCanvas = false;
  isValid = false;
  capturing = false;
  remainSeconds = 0;
  framePhoto?: string;
  capturedImage?: string;
  facePhoto?: string;
  timer?: number;
  captureTimer?: number;
  helperText = '';
  isCountdownStarted = false;
  maskPath = '';
  lightStatus = false;

  private trigger = new Subject<void>();
  triggerObservable = this.trigger.asObservable();
  private subscriptions = new Subscription();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();

  // Blurry check
  private variances: number[] = [];
  // After minFrameAnylize it will start the countdown
  private readonly minFrameAnylize = 10;
  // It will store maxFrames frames
  private readonly maxFrames = 60;

  videoOptions: MediaTrackConstraints = {
    width: {min: 640, ideal: 1920},
    height: {min: 480, ideal: 1080},
  };
  cameraSize = {
    width: 640,
    height: 480,
  };
  maskSize = {
    width: 640,
    height: 480,
    aspectRatio: 1250 / 880,
  };

  icons = {
    close: faTimesCircle,
    lightBulb: faBoltLightning,
    camera: faCamera,
    switchCamera: faCameraRotate,
  };

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  constructor(
    private translate: TranslateService,
    private configuracio: ConfiguracioService,
    private laxtonService: LaxtonService,
    private facialService: FacialService,
  ) {
    this.helperText = 'passport-scanner.helper-text';
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.captureTimer) {
      clearTimeout(this.captureTimer);
    }
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.onResize();

    // Activar luces
    if (this.configuracio.parametrosConfiguracion.encendidoLucesAutomatico) {
      this.toggleLight(true);
    }

    this.captureTimer = setTimeout(() => {
      this.startCardDetection();
    }, 500);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.cameraSize.width = window.innerWidth;
    this.cameraSize.height = window.innerHeight - 64;
    this.videoOptions.width = this.cameraSize.width;
    this.videoOptions.height = this.cameraSize.height;

    const maxWidth = this.cameraSize.width - 80;
    const maxHeight = this.cameraSize.height - 200;
    let w = maxWidth;
    let h = w / this.maskSize.aspectRatio;
    if (h > maxHeight) {
      h = maxHeight;
      w = h * this.maskSize.aspectRatio;
    }
    this.maskSize.width = w;
    this.maskSize.height = h;

    this.calculateMaskPath();
  }

  calculateMaskPath(): void {
    const w = this.maskSize.width / this.cameraSize.width;
    const h = this.maskSize.height / this.cameraSize.height;
    const l = (1 - w) / 2;
    const r = l + w;
    const t = (1 - h) / 2;
    const b = t + h;
    const rx = 10 / this.cameraSize.width;
    const ry = 10 / this.cameraSize.height;
    this.maskPath = `<svg class="absolute w-0 h-0">
      <defs>
        <clipPath id="mask-path" clipPathUnits="objectBoundingBox">
          <path d="M0,0 L0,1 L1,1 L1,0 L0,0
            L${l + rx},${t} L${r - rx},${t} C${r - rx},${t} ${r},${t} ${r}${t + ry}
            L${r},${t + ry} L${r},${b - ry} C${r},${b - ry} ${r},${b} ${r - rx}${b}
            L${r - rx},${b} L${l + rx},${b} C${l + rx},${b} ${l},${b} ${l}${b - ry}
            L${l}${b - ry} L${l},${t + ry} C${l},${t + ry} ${l},${t} ${l + rx}${t}
            z"
          />
        </clipPath>
      </defs>
    </svg>`;
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public onClose(): void {
    this.toggleLight(false);
    this.closed.emit();
  }

  startCardDetection(): void {
    this.timer = setInterval(() => {
      if (!this.capturedImage) {
        this.triggerSnapshot();
      }
    }, 100);
  }

  onCaptureTimeout(): void {
    if (!this.capturing) {
      return;
    }

    this.remainSeconds --;
    if (this.remainSeconds <= 0) {
      this.capturing = false;
      this.messageModal.tanca();
      this.messageModal.obre(
        new Modal({
          titol: this.translate.instant('passport-scanner.read-document'),
          missatge: this.translate.instant('passport-scanner.unable-detect-passport'),
          indicacions: 'Espere un momento, por favor',
          esProgres: true,
          accions: [],
        })
      );
    } else {
      this.captureTimer = setTimeout(this.onCaptureTimeout.bind(this), 1000);
    }
  }

  startAutoCapture(): void {
    this.variances = [];
    this.isCountdownStarted = false;
    this.capturing = true;
    this.remainSeconds = 5;
    this.capturedImage = undefined;
  }

  startCountdown(): void{
    this.isCountdownStarted = true;
    this.captureTimer = setTimeout(this.onCaptureTimeout.bind(this), 1000);
  }

  async processCapturedImage(webcamImage: WebcamImage): Promise<void> {
    if (!this.capturing) { return; }
    if (!this.isCountdownStarted) {
      this.startCountdown();
    }

    const image = webcamImage.imageData;
    const maskX = (image.width - this.maskSize.width) / 2;
    const maskY = (image.height - this.maskSize.height) / 2;
    const croppedImage = getCroppedImage(image, maskX, maskY, this.maskSize.width, this.maskSize.height);
    if (!croppedImage) {
      this.isValid = false;
      return;
    }

    this.framePhoto = croppedImage.dataURL;

    try {
      this.isValid = await this.detectDocumentCard(croppedImage.imageData);
    } catch (e) {
      this.isValid = false;
    }

    if (this.isValid) {
      this.capturedImage = getBase64FromDataUrl(this.framePhoto);
      this.capturing = false;

      const valid = await this.validatePhoto();
      if (!valid) {
        this.capturedImage = undefined;
      }
    }
  }

  async detectDocumentCard(imageData: ImageData): Promise<boolean> {
    this.facePhoto = undefined;
    this.helperText = 'passport-scanner.helper-text';

    const frame = cv.matFromImageData(imageData);
    if (!this.checkImageBrightness(frame)) {
      frame.delete();
      return false;
    }

    if (!await this.detectFace(imageData)) {
      this.helperText = 'passport-scanner.no-face';
      frame.delete();
      return false;
    }

    if (!this.checkBlurr(imageData)) {
      frame.delete();
      return false;
    }

    if (this.showCanvas) {
      cv.imshow('canvasOutput', frame);
    }

    frame.delete();
    return true;
  }

  checkBlurr(imageData: ImageData): boolean{
    const variance = getBlurryVariance(imageData);
    if (this.variances.length >= this.minFrameAnylize) {
      if (!isOutlier(this.variances, variance)){
        this.variances.push(variance);
      }
      // // Remove the oldest variance and add the current one
      // if (this.variances.length > this.maxFrames){
      //   this.variances.shift();
      // }
      // Remove the lowest variance and add the current one
      if (this.variances.length > this.maxFrames){
        removeLowestVariance(this.variances, variance);
      }

      if (!isImageGoodQuality(this.variances, variance)){
        this.helperText = 'passport-scanner.blurred-image';
        return false;
      }
    }
    else {
      this.variances.push(variance);
      return false;
    }
    return true;
  }

  checkImageBrightness(image: any): boolean {
    const darkThreshold = 128;
    const brightThreshold = 128;

    const src = image.clone();
    const grayImg = new cv.Mat();
    cv.cvtColor(src, grayImg, cv.COLOR_RGB2GRAY, 0);

    // Find the minimum and maximum pixel values in the image
    const { minVal, maxVal } = cv.minMaxLoc(grayImg);
    grayImg.delete();
    src.delete();

    if (maxVal < darkThreshold) {
      this.helperText = 'passport-scanner.too-dark';
      return false;
    }
    if (minVal > brightThreshold) {
      this.helperText = 'passport-scanner.too-bright';
      return false;
    }

    return true;
  }

  async detectFace(image: ImageData): Promise<boolean> {
    try {
      const faceRect = await FaceDetect.detectFace(image, {
        withEyes: true,
      });
      if (!faceRect) {
        return false;
      }

      // checking size & position
      const offset = 20;
      let isValid = faceRect.width > this.maskSize.width / 5
        && faceRect.width < this.maskSize.width / 2
        && faceRect.height > this.maskSize.height / 4
        && faceRect.height < this.maskSize.height
        && faceRect.x >= offset
        && faceRect.x + faceRect.width <= this.maskSize.width - offset
        && faceRect.y >= offset
        && faceRect.y + faceRect.height <= this.maskSize.height - offset;
      if (isValid) {
        const croppedImage = getCroppedImage(image, faceRect.x, faceRect.y, faceRect.width, faceRect.height);

        if (croppedImage) {
          this.facePhoto = getBase64FromDataUrl(croppedImage.dataURL);
        } else {
          isValid = false;
        }
      }
      return isValid;
    } catch {}

    return false;
  }

  public async toggleLight(active: boolean): Promise<void>{
    await this.laxtonService.luces(active);
    this.lightStatus = active;
  }

  async validatePhoto(): Promise<boolean> {
    let imatgeResultado: string | undefined;

    const icaoRequest = new IcaoRequest();
    icaoRequest.tipoIcao = IcaoType.DOCUMENT_PHOTO;
    icaoRequest.imagenB64SinCabecera = this.capturedImage;
    this.messageModal.tanca();
    this.messageModal.obre(
      new Modal({
        titol: 'Analizando foto',
        missatge: 'Obteniendo foto del frontal del documento',
        indicacions: 'Espere un momento, por favor',
        esProgres: true,
        accions: [],
      })
    );

    let resultado: ResultadoIcao;
    try {
      resultado = await this.facialService.icao(icaoRequest);
      this.messageModal.tanca();

      if (!resultado.error) {
        imatgeResultado = resultado.image;
      }
    } catch (ex) {
      this.messageModal.tanca();
      this.messageModal.obre(
        new Modal({
          titol: 'Error',
          missatge: ex.missatge,
          indicacions: ex.suggeriment,
          detall: ex.intern,
          icona: ModalIcon.Atencio,
        })
      );
    }

    if (imatgeResultado != null) {
      this.capturedImage = imatgeResultado;
      return true;
    }

    return false;
  }

  public handleInitError(error: WebcamInitError): void {
    let texteError = error.message;
    let indicacions = this.translate.instant(
      'live-view.modal-error-camera.indicacions-no-endollada'
    );

    if (error.mediaStreamError) {
      if (error.mediaStreamError.name === 'NotAllowedError') {
        texteError = this.translate.instant(
          'live-view.modal-error-camera.missatge-permis'
        );
        indicacions = this.translate.instant(
          'live-view.modal-error-camera.indicacions-permis'
        );
      } else if (
        error.mediaStreamError.name === 'NotFoundError' ||
        error.mediaStreamError.name === 'NotReadableError'
      ) {
        texteError = this.translate.instant(
          'live-view.modal-error-camera.missatge-no-trobada'
        );
        indicacions = this.translate.instant(
          'live-view.modal-error-camera.indicacions-no-trobada'
        );
      }
    } else {
      console.warn(error.message);
    }

    // this.modalActual?.dismiss();

    this.messageModal?.obre(
      new Modal({
        titol: this.translate.instant('live-view.titol'),
        missatge: texteError,
        indicacions,
        esTancable: true,
        icona: ModalIcon.Perill,
      })
    );
  }

  onRepeat(): void {
    this.capturedImage = undefined;
  }

  onConfirm(): void {
    this.capture.emit(this.capturedImage);
    this.onClose();
  }
}
