import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EscanerEmpremtesService } from '../../serveis/escaner-empremtes.service';

@Component({
  selector: 'lib-calibrar-escaner-empremtes',
  templateUrl: './calibrar-escaner-empremtes.component.html',
})
export class CalibrarEscanerEmpremtesComponent implements OnInit {
  @ViewChild('confirmarMissatge') confirmarMissatge: ElementRef;
  @ViewChild('calibrantMissatge') calibrantMissatge: ElementRef;
  @ViewChild('calibratMissatge') calibratMissatge: ElementRef;

  private activeModal: NgbActiveModal;
  private resolve: (value?: void | PromiseLike<void>) => void;
  private reject: (reason?: any) => void;
  private potFinalitzar: boolean;

  constructor(
    private modalService: NgbModal,
    private escanerServei: EscanerEmpremtesService
  ) {}

  ngOnInit(): void {}

  async calibrar(): Promise<void> {
    const promise = new Promise<void>((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;

      this.potFinalitzar = true;

      this.activeModal = this.modalService.open(this.confirmarMissatge, {
        centered: true,
        backdrop: 'static',

        beforeDismiss: () => {
          if (this.potFinalitzar) this.resolve();
          return true;
        },
      });
    });

    return promise;
  }

  async confirmarCalibrar() {
    this.potFinalitzar = false;
    this.activeModal.close();

    this.activeModal = this.modalService.open(this.calibrantMissatge, {
      centered: true,
      backdrop: 'static',
    });

    try {
      await this.escanerServei.calibrar();

      this.activeModal.close();

      this.activeModal = this.modalService.open(this.calibratMissatge, {
        centered: true,
        backdrop: 'static',
        beforeDismiss: () => {
          this.resolve(); // sempre, independentment de potFinalitzar. Només hi ha botó ok
          return true;
        },
      });
    } catch (ex) {
      this.reject(ex);
    }
  }
}
