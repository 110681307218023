<ng-template #confirmarMissatge let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "comu.calibrar.titol" | translate }}
    </h4>
    <button
      type="button"
      class="btn bt-danger"
      aria-label="Cerrar"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ "comu.calibrar.missatge-netejar-vidre" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="confirmarCalibrar()">
      {{ "comu.accio.calibrar" | translate }}
    </button>
    <button type="button" class="btn btn-warning" (click)="modal.dismiss()">
      {{ "comu.accio.cancelar" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #calibrantMissatge let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "comu.calibrar.modal-calibrant.titol" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ "comu.calibrar.modal-calibrant.missatge" | translate }}</p>

    <div class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only"
          >{{ "comu.calibrar.modal-calibrant.calibrant" | translate }}...</span
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #calibratMissatge let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "comu.calibrar.modal-calibrant.titol" | translate }}
    </h4>
    <button
      type="button"
      class="btn btn-danger"
      aria-label="Cerrar"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <p>
      {{
        "comu.calibrar.modal-calibrant.escaner-calibrat" | translate
      }}.<br /><br />
      {{ "comu.calibrar.modal-calibrant.recordatori" | translate }}
    </p>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      ngbAutofocus
      class="btn btn-warning"
      (click)="modal.dismiss()"
    >
      {{ "comu.accio.finalitzar" | translate }}
    </button>
  </div>
</ng-template>
