import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core/';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';

import { Empremta, Modal, ModalIcon } from 'projects/siepbio-comu/src/public-api';
import { Documento, Etapa, TipoDecision } from '../../model/documento';
import { CategoriaMensaje, DatosVerificacion, MensajeProceso, TipoMensajeProceso } from '../../model/EntryExit';
import { ApartatService } from '../../serveis/apartat.service';
import { DocumentReaderService } from '../../serveis/document-reader.service';
import { EnrolamentService } from '../../serveis/enrolament.service';

@Component({
  selector: 'app-doc-viz',
  templateUrl: './doc-viz.component.html',
  styleUrls: ['./doc-viz.component.css']
})

//CLASE OBSOLETA
export class DocVizComponent implements OnInit {
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;
  @Input() soloLectura: boolean;

  
  resultadoMRZ?: string;
  colorResultadoMRZ?: string;
  outline: string = "";
  procesandoMRZ: boolean = false;
  
  constructor( private modalService: NgbModal, 
    private documentReaderService: DocumentReaderService,
    private apartatServei: ApartatService,
    private translate: TranslateService,
    private enrolmentService: EnrolamentService) { }

  ngOnInit(): void {
    //if (this.datosVerificacion.validacionChip != undefined)
    //  this.resultadoMRZ = this.datosVerificacion.validacionChip;
   
  }

  getFecha(fecha?: Date): string{
    return fecha?.toLocaleDateString().toString();
  
  }

  limpiarDatos(): void{
    //this.datosVerificacion.documentoLeido.RFIDData = undefined;
    this.resultadoMRZ = undefined;
    this.colorResultadoMRZ = undefined;
    this.outline = "";
  }

  controlFechaValida(fecha: string, meses: number): boolean{
    //TODO preguntar si este valor de meses es configurable
    if (fecha != undefined){
      let [day, month, year] = fecha.split('/');
      if (year.length == 2)
        year = "20"+year;
      const feccad = new Date(+year, +month - 1, +day);
      const fechaActual = new Date();
      const fecchaValidacion =  new Date(fechaActual.getFullYear(), fechaActual.getMonth() + meses, fechaActual.getDate());
      
      if (feccad < fecchaValidacion ){
        this.añadirError("El documento va a caducar en menos de "+ meses + " meses", true);
      }
      return (feccad >= fecchaValidacion);
    }
    return false;
  }

  private añadirError(texto: string, error: boolean){
    let mensaje = new MensajeProceso();
    mensaje.tipo = error ? TipoMensajeProceso.Error : TipoMensajeProceso.Validacion;
    mensaje.categoria = CategoriaMensaje.Documento;
    mensaje.texto = texto;
    //this.datosVerificacion = this.enrolmentService.añadirError(this.datosVerificacion, mensaje);
  }

  async leerDatos(): Promise<void>{
    console.log("empieza lectura datos RFID");

    this.procesandoMRZ = true;
    try{
      //this.datosVerificacion.documentoLeido = await this.documentReaderService.leer(true);
      this.procesandoMRZ = false;
    }
    catch(ex){
      this.procesandoMRZ = false;
      this.missatgeModal.obre(
        new Modal({
          titol: "Error",
          missatge: ex.missatge,
          indicacions: ex.suggeriment,
          detall: ex.intern,
          icona: ModalIcon.Atencio,
        })
      );
    }


      this.outline = "outline-";
  }

 getFoto():any{
     return 'assets/img/sin-fotografia.png';
 }

 ampliarFoto(contentE: any): void {
 }


}
