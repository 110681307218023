import { SafeResourceUrl } from '@angular/platform-browser';
import { RawImage } from './raw-image';
import { Enrolament } from './siepbio-model';

export class ResultatIdentificacio {
  NISbuscat?: string;
  CICbuscat?: string;
}

export class EmpremtaCaptura {
  numeroMatch: number;
  scoresRegistre: Map<string, number> = new Map<string, number>();

  constructor(
    public numeroDit: number,
    public imatge: SafeResourceUrl,
    public rawImatge: RawImage
  ) {}

  nomDit(): string {
    switch (this.numeroDit) {
      case 1:
        return 'Polze dret';
      case 6:
        return 'Polze esquerre';
      case 2:
        return 'Índex dret';
      case 7:
        return 'Índex esquerre';
      case 3:
        return 'Mig dret';
      case 8:
        return 'Mig esquerre';
      case 4:
        return 'Anular dret';
      case 9:
        return 'Anular esquerre';
      case 5:
        return 'Auricular dret';
      case 10:
        return 'Auricular esquerre';
    }
    return null;
  }
}

export class ResultatIdentificacioExitosa extends ResultatIdentificacio {
  personaIdentificada: PersonaIdentificada;
  dubtos: boolean;

  constructor(public personesIdentificades: PersonaIdentificada[]) {
    super();
    if (personesIdentificades.length > 0)
      this.personaIdentificada = personesIdentificades[0];
  }
}

export class ResultatIdentificacioFallida extends ResultatIdentificacio {
  identificat: boolean = false;

  constructor(public motiuFallida: MotiuIdentificacioFallida) {
    super();
  }
}

export enum MotiuIdentificacioFallida {
  PersonaNoTrobada,
  PoquesEmpremtes,
  MalaQualitatEmpremtes,
  MesDunaPersonaTrobada,
}

export class PersonaIdentificada {
  referencia: string;
  nis: string;
  cic: string;
  nom: string;
  fotoFacialFrontal: string;
  darrerAcces: DarrerAcces;
  enrolament?: Enrolament;
  score: number;
  centre: string;
  situacio: string;
  fotoURL: string;
}

export class DarrerAcces {
  acces: CentreAcces;
  sortida: boolean;
  data: Date;
}

class CentreAcces {
  centre: string;
  porta: string;
}
