<div class="container-fluid">
    <div class="row my-1">
        <div class="col-sm-12 ">
            
            <app-barra-acciones [accion]="Etapa.Consultas">
                <button class="btn btn-primary btn-lg mr-2" type="button" (click)="consulta()">
                    <fa-icon [icon]="iconBuscar" *ngIf="this.datosService.datosVerificacion.respuestaPasoFrontera == undefined"></fa-icon><fa-icon *ngIf="this.datosService.datosVerificacion.respuestaPasoFrontera != undefined" [icon]="iconActualizar"></fa-icon> {{this.datosService.datosVerificacion.respuestaPasoFrontera == undefined ? 'Buscar': 'Actualizar' }}
                </button> 
            </app-barra-acciones>
          
          
        </div>
      </div>
    
    <div class="row">
        
            <div class="card w-100" *ngIf="datosService.datosVerificacion.respuestaPasoFrontera">
                <div class="card-header">
            
                <h5 class="card-title">Resultado consultas policiales</h5>
                </div> 
                <div class="card-body">
                    <div *ngIf="mensajePolicial1" class="row">
                          <div class="col-sm-12 alert {{resultadoPolicial()}}">
                            <div>{{mensajePolicial1}}</div>
                            <div>{{mensajePolicial2}}</div>
                            <div>{{mensajePolicial3}}</div>
                            <div>{{mensajePolicial4}}</div>
                            <div>{{mensajePolicial5}}</div>
                            <div>{{mensajePolicial6}}</div>
                            <div>{{mensajePolicial7}}</div>
                          </div>
                    </div>

                    <div *ngFor="let err of datosService.datosVerificacion.respuestaPasoFrontera?.errores" class="row">
                        <div class="alert alert-{{colorError(err)}}" class="col-sm-12">
                            {{textoError(err)}}
                        </div>
                    </div>
                </div>   
            </div>
        
            <div class="card w-100" *ngIf="datosService.datosVerificacion.respuestaPasoFrontera">
                <div class="card-header">            
                    <h5 class="card-title">SICF</h5>
                </div> 

                <div class="card-body">
                    <a target="_blank" href="{{obtenerURLSICF()}}">Abrir detalle control en SICF</a>
                </div>   
            </div>

            <div class="card w-100" *ngIf="datosService.datosVerificacion.respuestaBioSearch">
                <div class="card-header">
                    <h5 class="card-title">Resultado consulta biométrica al EES</h5>
                </div>
                <div class="card-body">
                    
                    <div *ngIf="mensajeBiometria" class="row mb-3">
                        <div class="col-sm-12">
                          <div class="alert alert-{{resultadoBiometria}}">
                            {{mensajeBiometria}}
                            
                          </div>
                        </div>
                    </div>
                </div>   
            </div>
        
    </div>
</div>

<lib-missatge-modal #missatgeModal></lib-missatge-modal>