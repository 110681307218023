import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Disponibilitat } from "projects/siepbio-enrolament-dactilar/src/app/model/disponibilitat";
import { Documento } from "projects/siepbio-enrolament-dactilar/src/app/model/documento";
import { EmpremtaCaptura } from "projects/siepbio-enrolament-dactilar/src/app/model/empremta-captura";
import {
  DatosVerificacion,
  ResultadoAutorizacion,
  ResultadoEES,
  ResultadoSearchEES,
} from "projects/siepbio-enrolament-dactilar/src/app/model/EntryExit";
import { Observable } from "rxjs";
import { Excepcio } from "../model/excepcio";
import { Formulario, TipoFormulario } from "../model/formulario";
import { Empremta } from "../model/siepbio-model";

import {
  BioSearchData,
  EESResultado,
  RespuestaBioSearch,
  Traveler,
  UmbralCalidadBiometrica,
} from "../model/traveler";
import { ConfiguracioService } from "./configuracio.service";
import { DatosService } from "./datos.service";

@Injectable({
  providedIn: "root",
})
export class IntegrationService {
  //esta variable serà global i per configuració
  public host: string = "https://localhost:3006";
  constructor(
    private http: HttpClient,
    private configuracionService: ConfiguracioService,
    private datosService: DatosService
  ) {
    if (
      configuracionService.parametrosConfiguracion.hostServiciosIntegracion !=
      undefined
    )
      this.host =
        configuracionService.parametrosConfiguracion.hostServiciosIntegracion;
  }

  private async doCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    console.log("docall host", this.host);
    var headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    if (isGet)
      return await this.http
        .get(this.host + url, {
          headers: headers,
          params: params,
          observe: "body",
          responseType: "json",
        })
        .toPromise();
    else {
      return await this.http
        .post(this.host + url, json ? json : undefined, {
          headers: headers,
          observe: "body",
          params: params,
          responseType: "json",
        })
        .toPromise();
    }
  }

  private async manageCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(isGet, url, params, json);

    if (obj && obj.rc && obj.rc === "ERR00-004") {
      obj = await this.doCall(isGet, url, params, json);
      if (obj && obj.rc) throw "session-expired";
    }

    return obj;
  }

  public async inicializaCalidad(
    umbralCalidad: UmbralCalidadBiometrica,
    tipo: string ////tipo faceQA / fingerQA
  ): Promise<EESResultado> {
    try{
      console.log("Inicializa calidad. Request:", umbralCalidad);
    let response = await this.manageCall(
      false,
      "/api/ees/" + tipo,
      null,
      JSON.stringify(umbralCalidad)
    );
    console.log("Inicializa calidad. Response:", response);
    let result = new EESResultado();
    let json = JSON.parse(JSON.stringify(response));
    result.resultado = json["Response"];
    return result;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Inicialización ["+ tipo+ "]. " , "Pruebe a reintentar", ex.message
      );
    }
  }

  public async bioSearch(data: BioSearchData): Promise<RespuestaBioSearch> {
    try{
      let response = await this.manageCall(
        false,
        "/api/ees/bioSearch",
        null,
        JSON.stringify(data)
      );
      console.log("BioSearch. Response:", response);
      let result = new RespuestaBioSearch();
      let json = JSON.parse(JSON.stringify(response));
      if (json != undefined) {
        if (json.enviada != undefined) result.enviada = Boolean(json.enviada);
        if (json.disponible != undefined)
          result.disponible = Boolean(json.disponible);
        if (json.error != undefined) result.error = Boolean(json.error);
        result.transactionId = json.transactionId;
        if (result.disponible == true)
          result.resultadoBusqueda = <ResultadoEES>json.resultadoBusqueda;
      }
      console.log("Resultat bioSearch", result);
      return result;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Integración [BioSearch]. " , "Pruebe a reintentar", ex.message
      );
    }
  }

  public async grabar(datosVerificacion: DatosVerificacion): Promise<EESResultado> {
    try{
      this.datosService.datosVerificacion.tiempos.instanteDecisionFinal = new Date();
      this.datosService.datosVerificacion.tiempos.tiempoTotalRegistro = 
        Math.round((this.datosService.datosVerificacion.tiempos.instanteDecisionFinal.getTime() -
        this.datosService.datosVerificacion.tiempos.instanteInicioRegistro.getTime()) / 1000);
      let response = await this.manageCall(
        false,
        "/api/ees/saveDatosVerificacion",
        null,
        JSON.stringify(datosVerificacion)
      );
      let result = new EESResultado();
      if(response) {
        let json = JSON.parse(JSON.stringify(response));
        result.resultado = json["Response"];
      } else {
        result.resultado = "Saved";
      }      
      return result;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Integración [Grabar]. " , "Pruebe a reintentar", ex.message
      );
    }
  }

  public async search(
    numeroDocumento?: string,
    tipoDocumento?: string,
    paisExpedidor?: string
  ): Promise<ResultadoEES> {
    try{
      let response = await this.manageCall(true, "/api/ees/search", {
        DocumentNumber: numeroDocumento,
        DocumentType: tipoDocumento,
        IssuingCountry: paisExpedidor,
      });
      console.log("Retorno del Search", response);
      let result = new ResultadoEES();
      let json = JSON.parse(JSON.stringify(response));
      result.encontrado = Boolean(json["encontrado"]);
      let records = new Array<ResultadoSearchEES>();

      console.log("Json Integracion", json);
      json.registros?.forEach((item) => {
        let record = new ResultadoSearchEES();
        record.foto = item?.foto;
        let huellas = new Array<EmpremtaCaptura>();
        item?.huellas?.forEach((element) => {
          let huella = new EmpremtaCaptura();
          huella.numeroDit = Number(element.posicion);
          let disponible = Boolean(element.disponible);
          let motivo = element.motivoNoDisponible;
          //TODO revisar si els valors son aquests
          huella.disponibilitat = disponible
            ? Disponibilitat.NORMAL
            : motivo == "AP"
            ? Disponibilitat.AMPUTAT
            : Disponibilitat.NO_DISPONIBLE;
          huella.puntuacio = Number(element.calidadIso);
          huella.imatge = element.imagen;
          huellas.push(huella);
        });
        record.huellas = huellas;
        let documento = new Documento();
        documento.foto = item?.documento?.foto;
        let huella = new Empremta();
        huella.posicio = item?.documento?.huella?.posicion;
        huella.nfiq = item?.documento?.huella?.nfiq;
        huella.motiuNoDisponible = item?.documento?.huella?.motivoNoDisponible;
        huella.disponible = Boolean(item?.documento?.huella?.disponible);
        huella.qualitat_iso = Number(item?.documento?.huella?.calidadIso);
        huella.imatge = item?.documento?.huella?.imagen;
        documento.huella1 = huella;
        documento.apellidos = item?.documento?.apellidos;
        documento.nombre = item?.documento?.nombre;
        documento.numeroDocumento = item?.documento?.numeroDocumento;
        documento.tipoDocumento = item?.documento?.tipoDocumento;
        documento.paisExpedidor = item?.documento?.paisExpedidor;
        documento.fechaCaducidad = item?.documento?.fechaCaducidad;
        documento.fechaNacimiento = item?.documento?.fechaNacimiento;
        documento.nacionalidad = item?.documento?.nacionalidad;
        documento.idPersonal = item?.documento?.numeroPersonal;
        documento.fechaExpedicion = item?.documento?.fechaExpedicion;
        documento.sexo = item?.documento?.sexo;
        record.documento = documento;
        let autorizacion = new ResultadoAutorizacion();
        autorizacion.autorizado = Boolean(
          item?.resultadoAutorizacion?.autorizado
        );
        autorizacion.motivoNoAutorizado =
          item?.resultadoAutorizacion?.motivoNoAutorizado;
        record.resultado = autorizacion;

        records.push(record);
      });

      result.registros = records;
      console.log("Retorno del Search. ResultadoSearch", result);
      return result;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Integración [Search]. " , "Pruebe a reintentar", ex.message
      );
    }
  }

  public async searchDatos(
    numeroDocumento?: string,
    tipoDocumento?: string,
    paisExpedidor?: string
  ): Promise<DatosVerificacion> {
    try{
      let response = await this.manageCall(true, "/api/ees/searchDatosVerificacion", {
        DocumentNumber: numeroDocumento,
        DocumentType: tipoDocumento,
        IssuingCountry: paisExpedidor,
      });
      console.log("Retorno del Search", response);
      
      return <DatosVerificacion> JSON.parse(JSON.stringify(response));
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Integración [Search Datos Verificacion]. " , "Pruebe a reintentar", ex.message
      );
    }
  }


  public obtenerFormulario(
    tipo: TipoFormulario,
    idioma: string
  ): Observable<Formulario> {
    return this.http.get<Formulario>(`${this.host}/api/ees/obtenerFormulario`, {
      params: new HttpParams({
        fromObject: { tipo: tipo.toString(), idioma: idioma },
      }),
    });
  }
}
