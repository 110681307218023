import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';

import { CodiExcepcioCara, Error, IcaoRequest, IcaoType, ResultadoIcao } from 'projects/siepbio-comu/src/lib/model/facial-info';
import {
  Modal,
  ModalAccio,
  ModalButton,
  ModalIcon,
} from 'projects/siepbio-comu/src/lib/model/modal';
import { Foto, TipusFoto } from 'projects/siepbio-comu/src/lib/model/siepbio-model';
import { ConfiguracioService } from 'projects/siepbio-comu/src/lib/serveis/configuracio.service';

import { FacialService } from 'projects/siepbio-comu/src/lib/serveis/facial.service';
import { DatosService } from 'projects/siepbio-comu/src/public-api';
import { Observable, Subject } from 'rxjs';

import { DatosVerificacion, DecisionResultadoFaceUSK, ResultadoFaceUSK, WarningResultadoFaceUSK } from '../../model/EntryExit';

import { MissatgeFotoCapturada } from '../../model/missatge-foto-capturada';
import { ProcessRequest } from '../../model/processResponse';
import { ResultatCapturaFoto } from '../../model/resultat-captura';
import { EnrolamentService } from '../../serveis/enrolament.service';
import { IdvService } from '../../serveis/idv.service';

import { LaxtonService } from '../../serveis/laxton.service';
import { UskService } from '../../serveis/usk.service';


@Component({
  selector: 'app-live-view-foto',
  templateUrl: './live-view-foto.component.html',
  styleUrls: ['./live-view-foto.component.css']
})
export class LiveViewFotoComponent implements OnInit, AfterViewInit {
  @Output() onFoto = new EventEmitter<string>();

  @ViewChild('missatgeModal') missatgeModal?: MissatgeModalComponent;
  @ViewChild('liveView') liveView?: ElementRef;
  @Input() activarControlesCalidadFoto: boolean;

  public videoOptions: MediaTrackConstraints = {
    width: {min: 640, ideal: 1280},
    height: {min: 480, ideal: 720},
    advanced: [{ zoom: 200 }],
  } as any;

  private trigger: Subject<void> = new Subject<void>();

  webcamImage?: WebcamImage;
  multipleWebcamsAvailable = false;
  track?: MediaStreamTrack;
  exposureTimeRange = 500; // valor per defecte temps d'exposicio
  brightnessRange = 100; // valor per defecte de brillantor

  valorExposicio = 500;
  valorBrillantor = 100;

  fotoIncorrecta = false;
  intentFoto = 1;

  modalActual?: NgbModalRef;
  foto?: Foto;

  estadoLuces = false;
  textoAccionLuces = 'Encender luces';
  calculandoIDV = false;


  constructor(
    private modalService: NgbModal,
    // public activeModal: NgbActiveModal, --PROVAR CON ESTO A VEURE SI ARA VA
    private enrolamentServei: EnrolamentService,
    private translate: TranslateService,
    private configuracio: ConfiguracioService,
    private laxtonService: LaxtonService,
    private uskService: UskService,
    private facialService: FacialService,
    private idvService: IdvService,
    public datosService: DatosService
  ) {}

  async ngAfterViewInit(): Promise<void> {
    // await this.crearTrack();
    // this.carregarValorConstraints();
  }

  carregarValorConstraints(): void {
    const settings = this.track!.getSettings();
    const json = JSON.parse(JSON.stringify(settings));
    this.exposureTimeRange = json.exposureTime;
    this.brightnessRange = json.brightness;
    this.valorBrillantor = json.brightness;
    this.valorExposicio = json.exposureTime;
  }

  async crearTrack(): Promise<void> {
    if (navigator.mediaDevices.getUserMedia) {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: { ideal: 500 } },
      });
      this.track = stream.getVideoTracks()[0];
      console.log('track', this.track);
    }
  }

  async ngOnInit(): Promise<void> {
    await WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        console.log('camara devices', mediaDevices);
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      }
    );
  }

  obre(): void {
    // Activar luces
    if (this.configuracio.parametrosConfiguracion.encendidoLucesAutomatico && this.activarControlesCalidadFoto) {
      this.luces(true);
    }
    this.modalActual = this.modalService.open(this.liveView, {
      windowClass: 'mobile-fullscreen',
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      centered: true
    });
  }

  public async cerrar(): Promise<void>{
    this.modalActual?.dismiss();
    this.luces(false);
  }

  public async luces(activar: boolean): Promise<void>{
    const resultado = await this.laxtonService.luces(activar);
    console.log('Resultado llamada a luces', resultado);
    this.estadoLuces = activar;
    this.textoAccionLuces = activar ? 'Apagar luces' : 'Encender luces';
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public async handleImage(webcamImage: WebcamImage): Promise<void> {
    const imatgeCapturada = webcamImage.imageAsBase64;
    let imatgeResultado: string | undefined;
    console.log('imagen capturada', webcamImage);
    this.luces(false);
    if (this.activarControlesCalidadFoto){
      try{
        imatgeResultado = await this.comprovarQualitatFoto(imatgeCapturada, this.activarControlesCalidadFoto);
        console.log('resultado comprobacion imagen', imatgeResultado);
      }
      catch (ex){
        this.missatgeModal.tanca();
        this.missatgeModal.obre(
          new Modal({
            titol: 'Error',
            missatge: ex.missatge,
            indicacions: ex.suggeriment,
            detall: ex.intern,
            icona: ModalIcon.Atencio,
          })
        );
      }
    }
    else{
      console.log('Foto impresa en live view', imatgeCapturada);
      // Llenar los campos para la verificació del documento (IDV)
      // let documento = new DocumentoIDV();
      // documento.doCropping = true;
      // documento.documentTypeSize = "TD3";
      // documento.returnImages = true;
      // let foto = new ImagenesDocumento();
      // foto.white = imatgeCapturada;
      // let pagina = new DocumentoPagina();
      // pagina.images = foto;
      // documento.pages = new Array<DocumentoPagina>();
      // documento.pages.push(pagina);

      const icaoRequest = new IcaoRequest();
      icaoRequest.tipoIcao = IcaoType.DOCUMENT_PHOTO;
      icaoRequest.imagenB64SinCabecera = imatgeCapturada;
      this.missatgeModal.tanca();
      this.missatgeModal.obre(
        new Modal({
          titol: 'Analizando foto',
          missatge: 'Obteniendo foto del frontal del documento',
          indicacions: 'Espere un momento, por favor',
          esProgres: true,
          accions: [],
        })
      );
      let resultado: ResultadoIcao;
      try{

      resultado = await this.facialService.icao(icaoRequest);
      this.missatgeModal.tanca();
    }
    catch (ex){
      this.missatgeModal.tanca();
      this.missatgeModal.obre(
        new Modal({
          titol: 'Error',
          missatge: ex.missatge,
          indicacions: ex.suggeriment,
          detall: ex.intern,
          icona: ModalIcon.Atencio,
        })
      );
    }

      this.calculandoIDV = false;

      // IDJONI: Llamada IDV ubicada temporalmente aquí, hasta que se decida dónde ponerla.
      // let processRequest = new ProcessRequest();
      // processRequest.Pages = new Array<string>();
      // processRequest.Pages.push(imatgeCapturada);
      // let resultadoIDV = await this.idvService.verificar(processRequest);

      // if(resultadoIDV.Error) {
      //  console.log("La llamada a processImage ha devuelto error" + resultadoIDV.Error.Message);
      // } else {
        // Ejemplo de obtención de información relevante para mostrar
        // lista de campos obtenidos del documento, incluyendo sus validaciones y comparaciones si proceden
        // 0: KO, 1: OK, 2: No procede
      //  let text = resultadoIDV.ContainerList.List.find(c => c.Text)?.Text;
        // El tipo de documento y el pais que lo emite
      //  let oneCandidate = resultadoIDV.ContainerList.List.find(c => c.OneCandidate)?.OneCandidate;
        // La foto facial y la firma
      //  let docGraphicsInfo = resultadoIDV.ContainerList.List.find(c => c.DocGraphicsInfo)?.DocGraphicsInfo;
      // }
      // Fin llamada temporal
      if (resultado.error) {
        this.mensajeIDV(imatgeCapturada, resultado.error.Message);
        imatgeResultado = imatgeCapturada;
      } else {
        console.log('Imagen impresa documento', resultado.image);
        imatgeResultado = resultado.image;
      }


      // if (resultado?.result == undefined){
      //   console.log("Fallo al obtener el resultado del IDV", resultado);
      // }
      // else if (resultado?.error != undefined){
      //   console.log("Error al obtener el resultado del IDV", resultado?.error);
      //   let fotoidv = this.mensajeIDV(resultado?.result?.portrait,resultado?.error?.Message);
      //   if (fotoidv != undefined)
      //     imatgeResultado = "data:image/Jpeg;base64," + fotoidv;
      // }
      // else if (!resultado?.result?.cropResult){
      //   console.log("Fallo al hacer el crop de IDV", resultado);
      //   this.mensajeIDV(resultado?.result?.portrait,"Fallo al hacer el crop de IDV");

      // }
      // else if (resultado?.result?.errorMessage != undefined){
      //   console.log("Fallo al realizar IDV verificación", resultado?.result?.errorMessage);
      //   this.mensajeIDV(resultado?.result?.portrait,"Fallo al realizar IDV");
      // }
      // else{
      // imatgeResultado = "data:image/Jpeg;base64," + resultado?.result?.portrait;
      // }

    }
    if (imatgeResultado != null) {
      this.onFoto.emit(imatgeResultado);
      this.modalActual?.dismiss();
    }
    else{
      this.luces(true);
    }
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  setBrillantor(value: number): void {
    const constraints: MediaTrackConstraints = {
      advanced: [{ brightness: value }],
    } as any;

    this.track?.applyConstraints(constraints);
    this.valorBrillantor = value;
  }

  setExposicio(value: number): void {
    const constraints: MediaTrackConstraints = {
      advanced: [{ exposureTime: value, exposureMode: 'manual' }],
    } as any;
    this.track?.applyConstraints(constraints);
    this.valorExposicio = value;
  }

  public handleInitError(error: WebcamInitError): void {
    let texteError = error.message;
    let indicacions = this.translate.instant(
      'live-view.modal-error-camera.indicacions-no-endollada'
    );

    if (error.mediaStreamError) {
      if (error.mediaStreamError.name === 'NotAllowedError') {
        texteError = this.translate.instant(
          'live-view.modal-error-camera.missatge-permis'
        );
        indicacions = this.translate.instant(
          'live-view.modal-error-camera.indicacions-permis'
        );
      } else if (
        error.mediaStreamError.name === 'NotFoundError' ||
        error.mediaStreamError.name === 'NotReadableError'
      ) {
        texteError = this.translate.instant(
          'live-view.modal-error-camera.missatge-no-trobada'
        );
        indicacions = this.translate.instant(
          'live-view.modal-error-camera.indicacions-no-trobada'
        );
      }
    } else { console.warn(error.message); }

    this.modalActual?.dismiss();

    this.missatgeModal?.obre(
      new Modal({
        titol: this.translate.instant('live-view.titol'),
        missatge: texteError,
        indicacions,
        esTancable: true,
        icona: ModalIcon.Perill,
      })
    );
  }

  async comprovarQualitatFoto(imatge: string, comprobar: boolean): Promise<string | undefined> {
    this.missatgeModal.tanca();
    this.missatgeModal?.obre(
      new Modal({
        titol: this.translate.instant('live-view.modal-analitzant.titol'),
        missatge: this.translate.instant('live-view.modal-analitzant.missatge'),
        indicacions: this.translate.instant(
          'live-view.modal-analitzant.indicacions'
        ),
        esProgres: true,
        accions: [],
      })
    );

    return new Promise(async (resolve) => {
      let resultadoUsk: ResultadoFaceUSK | undefined;
      let fotoUSKIncorrecta: boolean | undefined;
      let resultat: ResultatCapturaFoto;
      if (this.configuracio.parametrosConfiguracion.utilizarUSKFace){
        try{
          resultadoUsk = await this.uskService.face(imatge);
          fotoUSKIncorrecta = resultadoUsk?.decision != DecisionResultadoFaceUSK.ACCEPTABLE;
          console.log('mensaje USK', fotoUSKIncorrecta, resultadoUsk);
          this.datosService.datosVerificacion.resultadoFaceUSK = resultadoUsk;
          this.missatgeModal.tanca();
        }
        catch (ex){
          this.missatgeModal.tanca();
          this.missatgeModal.obre(
            new Modal({
              titol: 'Error',
              missatge: ex.missatge,
              indicacions: ex.suggeriment,
              detall: ex.intern,
              icona: ModalIcon.Atencio,
            })
          );
          return undefined;
        }
      }

      try{
         resultat = await this.enrolamentServei.getResultatCapturaFotoRest( imatge );
      }
      catch (ex){
        /*
        this.missatgeModal.tanca();
        this.missatgeModal.obre(
          new Modal({
            titol: "Error",
            missatge: ex.missatge,
            indicacions: ex.suggeriment,
            detall: ex.intern,
            icona: ModalIcon.Atencio,
          })
        );
        resolve(undefined);*/
        this.missatgeModal.tanca();
        console.log('ERROR COMPROBAR QUALITAT IMATGE', ex);
        throw ex;
      }
      console.log('LiveView: Resultado', resultat);
      this.datosService.datosVerificacion.resultadoCapturaFoto = resultat;
      this.missatgeModal?.tanca();
      let titolMissatgeFoto = await this.translate.get('live-view.modal-foto-millorable.titol').toPromise();
      let missatgeCaptura: MissatgeFotoCapturada = new MissatgeFotoCapturada();

      if (!resultat?.fotoCorrecta || fotoUSKIncorrecta) {
        if (fotoUSKIncorrecta) {
          this.fotoIncorrecta = false;
          missatgeCaptura.missatge = 'Control de calidad';
          let warning = '';
          if (resultadoUsk?.warning > 0){
            warning = this.getWarningUSK(resultadoUsk?.warning) + '.';
            this.datosService.datosVerificacion.resultadoFacial = false;
          }
          missatgeCaptura.indicacio = 'El control de calidad USK ha determinado:  (' + this.getDesicionUSK(resultadoUsk?.decision) +  ').' + warning + ' Se recomienda repetir la foto';
        }
        else if (resultat?.fotoMillorable) {
          this.fotoIncorrecta = false;
          missatgeCaptura = this.getMissatgeFotoMillorable(resultat);
        }
        else if (resultat?.repetirFoto == undefined) {
          this.fotoIncorrecta = true;
          missatgeCaptura.missatge = this.translate.instant(
            'live-view.modal-foto-millorable.error-generic'
          );
          missatgeCaptura.indicacio = this.translate.instant(
            'live-view.modal-foto-millorable.error-generic-indicacions'
          );
        }
        else {
          titolMissatgeFoto = this.translate.instant(
            'live-view.modal-foto-incorrecta.titol'
          );
          this.fotoIncorrecta = true;
          missatgeCaptura = this.getMissatgeFotoIncorrecta(
            resultat?.repetirFoto
          );
        }
        // Control de missatge d'error
        const textBotoRepetir =
          (await this.translate.get('comu.accio.repetir').toPromise()) +
          ' (' +
          this.intentFoto +
          '/' +
          this.configuracio.parametrosConfiguracion.reintentsMalaQualitat +
          ')';
        const accioRepetir = new ModalAccio({
          text: this.calRepetir()
            ? textBotoRepetir
            : await this.translate.get('comu.accio.acceptar').toPromise(),
          esAutofocus: true,
          accio: () => {
            if (this.calRepetir()) {
              this.repetir();
              resolve(undefined);
            } else {
              if (resultat?.fotoMillorable) {
                resolve(resultat?.foto);
              } else { resolve(undefined); }
            }
          },
          tancaModal: true,
          principal: true,
        });
        const accioCancel = new ModalAccio({
          tancaModal: true,
          principal: false,
          boto: ModalButton.Cancelar,
          esAutofocus: false,
          accio: () => {
            resolve(undefined);
          },
        });
        const accionsModals = new Array<ModalAccio>();
        accionsModals.push(accioRepetir);
        if (!this.calRepetir()) {
          accionsModals.push(accioCancel);
        }

        this.missatgeModal?.obre(
          new Modal({
            titol: titolMissatgeFoto,
            missatge: missatgeCaptura.missatge,
            indicacions: missatgeCaptura.indicacio,
            llistaDetalls: missatgeCaptura.detalls,
            esTancable: true,
            icona: this.fotoIncorrecta ? ModalIcon.Perill : ModalIcon.Atencio,
            accions: accionsModals,
          })
        );

        return;
      }
      console.log('LiveView: Resultado foto', resultat?.foto);

      if (resultat == undefined && (!resultat?.fotoCorrecta && this.calRepetir())) {
        resolve(undefined);
      }
      else { resolve(resultat?.foto); }
    });
  }

  calRepetir(): boolean {
    // TODO: De cara a la demo, no repetimos si mala calidad
    return false;
    // return (
    //   !this.fotoIncorrecta &&
    //   this.configuracio.parametrosConfiguracion.reintentsMalaQualitat >
    //     0 &&
    //   this.intentFoto <
    //     this.configuracio.parametrosConfiguracion.reintentsMalaQualitat
    // );
  }

  repetir(): void {
    this.intentFoto++;
  }

  getWarningUSK(error: WarningResultadoFaceUSK): string | undefined{
    if (error == undefined) { return undefined; }
    switch (error){
      case WarningResultadoFaceUSK.TOO_FAR_CAPTURE:
        return 'Demasiado alejado de la cámara';
      case WarningResultadoFaceUSK.TOO_LARGE_IMAGE:
        return 'Foto demasiado grande';
      case WarningResultadoFaceUSK.TOO_SMALL_IMAGE:
        return 'Foto demasiado pequeña';
    }
    return undefined;
  }

  getDesicionUSK(decision: DecisionResultadoFaceUSK): string | undefined{
    if (decision == undefined) { return undefined; }
    switch (decision){
      case DecisionResultadoFaceUSK.UNDETERMINED:
        return 'Error indeterminado';
      case DecisionResultadoFaceUSK.ACCEPTABLE:
        return 'Calidad aceptable';
      case DecisionResultadoFaceUSK.LOWQUALITY:
        return 'Baja calidad';
      case DecisionResultadoFaceUSK.LOWQUALITY_NON_FRONTAL_FACE_ORIENTATION:
        return 'Foto no frontal';
      case DecisionResultadoFaceUSK.LOWQUALITY_OPEN_MOUTH_DETECTED:
        return 'Boca abierta';
      case DecisionResultadoFaceUSK.LOWQUALITY_WEARING_MASK_DETECTED:
        return 'Lleva mascarilla';
    }
  }

  getMissatgeFotoMillorable(detalls: ResultatCapturaFoto): MissatgeFotoCapturada {
    const m = new MissatgeFotoCapturada();
    m.missatge = this.translate.instant(
      'live-view.modal-foto-millorable.error-mejorable'
    );
    m.indicacio = this.translate.instant(
      'live-view.modal-foto-millorable.error-mejorable-indicaciones'
    );
    m.detalls = detalls?.warningInfo;

    return m;
  }

  getMissatgeFotoIncorrecta(error: CodiExcepcioCara): MissatgeFotoCapturada {
    const m = new MissatgeFotoCapturada();
    m.missatge = this.translate.instant(
      'live-view.modal-foto-incorrecta.error-generic'
    );
    m.indicacio = this.translate.instant(
      'live-view.modal-foto-incorrecta.error-generic-indicacions'
    );
    if (error == undefined) { return m; }
    switch (error) {
      case CodiExcepcioCara.FaceNotFound:
        m.missatge = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-no-detecta-cara'
        );
        m.indicacio = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-no-detecta-cara-indicacions'
        );
        break;
      case CodiExcepcioCara.TooManyFaces:
        m.missatge = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-masses-cares'
        );
        m.indicacio = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-masses-cares-indicacions'
        );
        break;
      case CodiExcepcioCara.TooClose:
        m.missatge = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-massa-aprop'
        );
        m.indicacio = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-massa-aprop-indicacions'
        );
        break;
      case CodiExcepcioCara.TooFar:
        m.missatge = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-massa-lluny'
        );
        m.indicacio = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-massa-lluny-indicacions'
        );
        break;
      case CodiExcepcioCara.EyesNotCentered:
        m.missatge = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-ulls-no-centrats'
        );
        m.indicacio = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-ulls-no-centrats-indicacions'
        );
        break;
      case CodiExcepcioCara.FaceNotCentered:
        m.missatge = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-cara-no-centrada'
        );
        m.indicacio = this.translate.instant(
          'live-view.modal-foto-incorrecta.error-cara-no-centrada-indicacions'
        );
        break;
    }
    return m;
  }

  getPesImatgeCaptura(imatgeb64: string): number {
    const stringLength = imatgeb64.length - 'data:image/png;base64,'.length;
    const sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    return sizeInBytes / 1000;
  }

  async mensajeIDV(foto: string, error: string): Promise<string | undefined> {
    return new Promise(async (resolve) => {
      const missatgeCaptura: MissatgeFotoCapturada = new MissatgeFotoCapturada();
      if (this.missatgeModal != null) { this.missatgeModal.tanca(); }
      missatgeCaptura.titol = 'Análisis de la foto';
      missatgeCaptura.missatge = 'Error en la identificación (IDV)';
      missatgeCaptura.indicacio = error;
        // Control de missatge d'error
      const textBotoRepetir =
          (await this.translate.get('comu.accio.repetir').toPromise()) +
          ' (' +
          this.intentFoto +
          '/' +
          this.configuracio.parametrosConfiguracion.reintentsMalaQualitat +
          ')';
      const accioRepetir = new ModalAccio({
          text: this.calRepetir()
            ? textBotoRepetir
            : await this.translate.get('comu.accio.acceptar').toPromise(),
          esAutofocus: true,
          accio: () => {
            if (this.calRepetir()) {
              this.repetir();
              resolve(undefined);
            } else {
               resolve(foto);
            }
          },
          tancaModal: true,
          principal: true,
        });
      const accioCancel = new ModalAccio({
          tancaModal: true,
          principal: false,
          boto: ModalButton.Cancelar,
          esAutofocus: false,
          accio: () => {
            resolve(undefined);
          },
        });
      const accionsModals = new Array<ModalAccio>();
      accionsModals.push(accioRepetir);
      if (!this.calRepetir()) {
          accionsModals.push(accioCancel);
        }

      this.missatgeModal?.obre(
          new Modal({
            titol: missatgeCaptura.titol,
            missatge: missatgeCaptura.missatge,
            indicacions: missatgeCaptura.indicacio,
            esTancable: true,
            icona: ModalIcon.Atencio,
            accions: accionsModals,
          })
        );
    });
  }

}
