<div class="card">
  <div class="card-header">
    <div class="btn-group">
      <button
        (click)="onSeleccioDits.emit()"
        class="btn btn-link btn-sm dropdown-toggle"
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span class="h5">{{ nomDitsCaptura() }}</span>
      </button>
      <div class="dropdown-menu"></div>
    </div>
  </div>

  <div class="card-body pb-0 pl-2">
    <div class="text-center">
      <lib-captura-dactilar-en-viu #enviu></lib-captura-dactilar-en-viu>
    </div>
    <div class="font-weight-bold text-ajuda text-secondary">
      {{ texteAjuda }}
    </div>
  </div>

  <div class="card-footer">
    <button
      *ngIf="permetRepetir()"
      (click)="repetir()"
      class="btn btn-info px-4 mr-3"
    >
      {{ "comu.accio.repetir" | translate }}
    </button>

    <button
      *ngIf="escanejant"
      (click)="cancelar()"
      class="btn btn-warning px-4 mr-3"
    >
      {{ "comu.accio.cancelar" | translate }}
    </button>

    <button
      *ngIf="!escanejant"
      (click)="calibrarEscaner()"
      class="btn btn-outline-secondary float-right px-4"
    >
      {{ "comu.accio.calibrar-escaner" | translate }}
    </button>
  </div>
</div>

<lib-calibrar-escaner-empremtes #calibrar></lib-calibrar-escaner-empremtes>
