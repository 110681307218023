import { ResultadoEES } from 'projects/siepbio-enrolament-dactilar/src/app/model/EntryExit';
import { RawImage } from './raw-image';
import { Errorable } from './siepbio-model';

export class Traveler {
  public travelerRecordId?: string;
  public Version_2?: string;
  public familyName?: string;
  public firstName?: string;
  public gender?: string;
  public nationality?: string;
  public dateOfBirth?: string;
  public imageData?: string;
  public imageSource?: string;
  public imageQualityValue?: number;
  public fingerprints?: Huella[];
  public documentNumber?: string;
  public documentType?: string;
  public issuingCountry?: string;
  public validUntil?: string;
  public dateOfLastUpdate?: string;
  public lastInspectionId?: string;
}

export class Huella {
  public fingerprintImage?: string;
  public fingerPosition?: number;
  public fingerprintQualityMetric?: number;
}

export class UmbralCalidadBiometrica {
  constructor(umbral: number){
    this.biometricQualityMetricThreshold = umbral;
  }
  public biometricQualityMetricThreshold : number;
}

export class EESResultado extends Errorable {
  resultado?: string;
}

export class LogOut{
   Respuesta?: RespuestaLogOut;
}

export class RespuestaLogOut{
  Respuesta?: ReturnLogOut;
}

export class ReturnLogOut{
  return?: ErrorLogOut;
}

export class ErrorLogOut{
  codigoError?: string;
  literalError?: string;
}

export class BioSearchData{
  public travelDocumentData: TravelDocumentData;
  public face: string;
  public fingerprints: Array<HuellaSearch>;
  public transactionId?: string;
}

export class TravelDocumentData{
  public documentNumber: string;
  public documentType: string;
  public issuingCountry: string;
}

export class HuellaSearch{
  public position: number;
  public width: number;
  public height: number;
  public colorspace: number;
  public resolution: number;
  public pixels: string;
}

export class RespuestaBioSearch{
  public disponible?: boolean;
  public enviada? : boolean;
  public error?: boolean;
  public transactionId?: string;
  public resultadoBusqueda?: ResultadoEES;
}