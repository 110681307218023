import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';

// Using this practice: https://medium.com/most-wanted-experts/angular-application-configurations-best-practices-2e28514b9b4e
export const ENVIRONMENT = new InjectionToken<{ [key: string]: IEnvironment }>(
  'environment'
);

export interface IEnvironment {
  production: boolean;
  urlSiepbio: string;
}

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private readonly environment: IEnvironment;

  // We need @Optional to be able start app without providing environment file
  constructor(@Optional() @Inject(ENVIRONMENT) environment: IEnvironment) {
    this.environment = environment;
  }

  getEnvironment(): IEnvironment {
    return this.environment;
  }
}
