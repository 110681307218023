import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Disponibilitat } from 'projects/siepbio-enrolament-dactilar/src/app/model/disponibilitat';
import { EmpremtaCaptura } from 'projects/siepbio-enrolament-dactilar/src/app/model/empremta-captura';
import { Excepcio } from '../model/excepcio';

import { MatchResultat } from '../model/MatchResultat';
import { AnsiNistData, Fingerprint, FSMT, ImatgeTipus, ImpressioTipus, MotiuAbsencia, Posat } from '../model/nist';
import { ResultatEmparellament } from '../model/PairInfo';
import { RawImage } from '../model/raw-image';
import { ContenidoNist, EmparejarHuellas, HuellasMatch, ImagenesMatch, SegmentFingerprints, SegmentQualityScore } from '../model/SegmentQualityScore';
import { ConfiguracioService } from './configuracio.service';


@Injectable({
  providedIn: 'root',
})
export class DactilarService {
  
  public host: string = "https://localhost:3004";
  constructor(private http: HttpClient, private configuracionService: ConfiguracioService) {
    if (configuracionService.parametrosConfiguracion.hostServiciosFPSDK != undefined)
      this.host = configuracionService.parametrosConfiguracion.hostServiciosFPSDK;
  }

  private async doCall(
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    console.log("docall host facial", this.host);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return await this.http
      .post(
        this.host + url,
        json ? json : undefined,
        {
          headers: headers,
          observe: 'body',
          params: params,
          responseType: 'json',
        }
      )
      .toPromise();
  }

  private async manageCall(
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(url, params, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      obj = await this.doCall(url, params, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  public netejarBase64(b64: string | undefined): string | undefined {
    let result: string;
    if (b64 == undefined || b64.length == 0) return undefined;
    const i = b64.indexOf(',');
    if (i > -1) {
      return b64.substring(i + 1);
    }
    return undefined;
  }

  public async segmentar(
    raw: RawImage,
    fingers: number[]
  ): Promise<SegmentQualityScore[]> {
    let segmento = new SegmentFingerprints();
    segmento.fingers = fingers;
    segmento.imatge = raw;
    try{
    let response = await this.manageCall(
      '/api/fingerprint/segmentar', null, JSON.stringify(segmento)
    );
    console.log("Segmentar. Response:", response);
    
    let json = JSON.parse(JSON.stringify(response));
    let resultado = new Array<SegmentQualityScore>();  

    json?.forEach(element => {
      let result = new SegmentQualityScore();
      result.embedImage =  element.embedImage;
      let rawI = new RawImage();
      rawI.data = element.rawImage.data;
      rawI.width = element.rawImage.width;
      rawI.height = element.rawImage.height;
      rawI.pixelFormat = Number(element.rawImage.pixelFormat);
      result.rawImage = rawI;

      result.quality = Number(element.quality);
      result.fingerNumber = Number(element.fingerNumber);
      result.nfiq = Number(element.nfiq);
      result.wrongHand = Boolean(element.wrongHand);
      resultado.push(result);
    });
    
    console.log("segment. result:", resultado);
    return resultado;
  }
  catch(ex){
    throw new Excepcio(
      "Error en el servicio Dactilar [Segmentar]. " , "Pruebe a reintentar", ex.message
    );
  }
  }

  async matchGaleria(
    segments: SegmentQualityScore[]
  ): Promise<MatchResultat> {
    try{
       /* TODO. si se le tiene que pasar como parámetro el array de números de dedos, utilizar esto. Sino, quitar este código
      let dedos = new Array<number>();
      if (segments?.length > 0){
        segments.forEach(element => {
          dedos.push(element.fingerNumber);
        });
      }
     
      let response = await this.manageCall(
        '/api/fingerprint/matchgaleria',{dits: String(dedos)},  JSON.stringify(segments)
      );*/
      let response = await this.manageCall(
        '/api/fingerprint/matchgaleria',null,  JSON.stringify(segments)
      );
      console.log("MatchGaleria. Response:", response);
      let json = JSON.parse(JSON.stringify(response));
      let resultado = new MatchResultat();
      if (json != undefined){
        resultado.PersonaId = json.personaId;
        resultado.Scores = json.scores;
        resultado.ResultatCodi = json.resultatCodi;
      }
      return resultado;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Dactilar [MatchGaleria]. ", "Pruebe a reintentar", ex.message
      );
    }
  }

  async matchImatges(probe: RawImage, galeria: string[]): Promise<number[]> {
    let match = new ImagenesMatch();
    match.probe = probe;
    match.galeria = galeria;
    try{
      let response = await this.manageCall(
        '/api/fingerprint/matchimatges', null, JSON.stringify(match)
      );
      console.log("Match Imagenes. Response:", response);
      let json = JSON.parse(JSON.stringify(response));
      let resultado = new Array<number>();
      if (json != undefined){
        json.forEach(element => {
          resultado.push(Number(element));
        });
      }
      return resultado;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Dactilar [MatchImatges]. " , "Pruebe a reintentar", ex.message
      );
    }
  }
 
  async convertirWSQ(imatge_dit: RawImage): Promise<Blob> {
    try{
    let response = await this.manageCall(
      '/api/fingerprint/wsq', null, JSON.stringify(imatge_dit)
    );
    console.log("Convertir a WSQ. Response:", response);
    return await this.toBlob(response);
    }
    catch(ex){
      console.log("Error al convertir a wsq", ex);
    }
  }

  async match(probe: RawImage, gallery: Array<RawImage>): Promise<number[]> {
    let huellas = new HuellasMatch();
    huellas.probe = probe;
    huellas.galeria = gallery;
   
    try{
      console.log("Huellas MATCH", huellas);
    let response = await this.manageCall(
      '/api/fingerprint/match', null, JSON.stringify(huellas)
    );
    console.log("Match. Response:", response);
    let json = JSON.parse(JSON.stringify(response));
    let resultado = new Array<number>();  

    json?.forEach(element => {
      resultado.push(element);
    });
    return resultado;
  }
  catch(ex){
    throw new Excepcio(
      "Error en el servicio Dactilar [Match]. " , "Pruebe a reintentar", ex.message
    );
  }
  }

  private async _crearNIST(
    config: AnsiNistData,
    empremtes: Array<Fingerprint>,
    fotos: Array<FSMT>
  ): Promise<Blob> {
    let nist = new ContenidoNist();
    nist.data = config;
    nist.empremtes = empremtes;
    nist.facials = fotos;
    
    console.log("nist info", nist);

    try{
    let response = await this.manageCall(
      '/api/fingerprint/nist', null, JSON.stringify(nist)
    );
    console.log("NIST. Response:", response);
    return await this.toBlob(response);
  }
  catch(ex){
    throw new Excepcio(
      "Error en el servicio Dactilar [Crear NIST]. " , "Pruebe a reintentar", ex.message
    );
  }
  }
  
  public async crearNIST(
    data: AnsiNistData,
    empremtesPosades?: Array<EmpremtaCaptura>,
    fotos?: Array<string>
  ): Promise<Blob> {

    const empremtesP = empremtesPosades?.filter(
        (e) => e.rawImatge != null || e.disponibilitat != Disponibilitat.NORMAL
      )
      .map(
        (s) =>
          <Fingerprint>{
            NumeroDit: s.numeroDit,
            Imatge:
              s.disponibilitat == Disponibilitat.NORMAL ? s.rawImatge : null,
            MotiuAbsencia:
              s.disponibilitat == Disponibilitat.AMPUTAT
                ? MotiuAbsencia.Amputatat
                : s.disponibilitat == Disponibilitat.NO_DISPONIBLE
                ? MotiuAbsencia.NoDisponible
                : null,
            ImpressioTipus: ImpressioTipus.CapturaEnViuPosada,
          }
      );
 
      const fotosP = fotos?.map(
        (f) => 
          <FSMT> {
              Imatge: f,
              ImatgeTipus: ImatgeTipus.Face,
              Posat: Posat.Frontal
          }
      );
      
    return await this._crearNIST(data, empremtesP, fotosP);
  }
  
  async emparellaments(
    probe: RawImage,
    candidat: string,
    quantitat: number
  ): Promise<ResultatEmparellament> {
    let emparejo = new EmparejarHuellas();
    emparejo.numeroMinucies = quantitat;
    emparejo.probe = probe;
    emparejo.template = candidat;    
    try{
      let response = await this.manageCall(
        '/api/fingerprint/emparellaments', null, JSON.stringify(emparejo)
      );
      console.log("Emparejamientos. Response:", response);
      let json = JSON.parse(JSON.stringify(response));
      let resultado = new ResultatEmparellament();
      if (json != undefined){
        resultado.CandidateHeight = Number(json.candidateHeight);
        resultado.CandidateWidth = Number(json.candidateWidth);
        resultado.ProbeHeight = Number(json.probeHeight);
        resultado.ProbeWidth = Number(json.probeWidth);
        resultado.Parelles = json.parelles;
      }
      return resultado;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio Dactilar [Emparejamientos]. " , "Pruebe a reintentar", ex.message
      );
    }
  }

  private async toBlob(b64: string): Promise<Blob> {
    const f = await fetch('data:application/octet-stream;base64,' + b64);
    return await f.blob();
  }

  
}
