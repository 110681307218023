import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Etapa, TipoDecision } from '../../model/documento';
import { faChevronRight, faCheck, faTimes, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { ApartatService } from '../../serveis/apartat.service';
import { DatosService } from 'projects/siepbio-comu/src/public-api';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-barra-acciones',
  templateUrl: './barra-acciones.component.html',
  styleUrls: ['./barra-acciones.component.css']
})
export class BarraAccionesComponent implements OnInit {
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;
  @ViewChild('contentMotivo') contentMotivo: ElementRef;
  @Input() accion: Etapa;
  modalActual?: NgbModalRef;
  Etapa: typeof Etapa = Etapa;
  iconValido = faCheck;
  iconNoValido = faTimes;
  iconNext = faChevronRight;
  iconPendiente = faHourglassHalf;

  TipoDecision: typeof TipoDecision = TipoDecision;
  resolucionProvisional: TipoDecision;
  
  public motivoForm: FormGroup;

  submitted = false;
  constructor(
    private apartatServei: ApartatService,  
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public datosService: DatosService) { 
      this.motivoForm = formBuilder.group({
        motivoManual: ["", [Validators.required]]
      });
    }

  ngOnInit(): void {
   
      
  }
  get f(): { [key: string]: AbstractControl } {
    return this.motivoForm.controls;
  }
  setMotivo(): void {
    this.modalActual = this.modalService.open(this.contentMotivo, {ariaLabelledBy: 'modal-basic-title', size:'lg', backdrop: 'static',centered: true});
  }

  private irA(decision: TipoDecision, pagina: number): void{
    if (decision == TipoDecision.ACEPTADO)
      this.apartatServei.canviarPestanyaEvent.emit(pagina);
    else
      this.apartatServei.canviarPestanyaEvent.emit(5);
  }

  siguiente(): void{
    let decision = this.datosService.datosVerificacion?.decisionesTomadas[this.accion].tipo; 
    if (decision != undefined && decision != TipoDecision.PENDIENTE)
    {
      this.irA(decision,this.accion + 2); 
    }
  }

  cambiarResolucion(resolucion: TipoDecision): void{
    this.resolucionProvisional = resolucion;
    this.setMotivo();
  }

  onSubmit(): void{
    this.submitted = true;
    if (this.motivoForm.invalid) {
      console.log("Valor inválido", this.motivoForm);
      return;
    }
    else if (this.motivoForm.valid){
      console.log("form valido", this.motivoForm);
      this.grabarMotivo();
    }
  }

  grabarMotivo(): void{
    this.datosService.datosVerificacion.decisionesTomadas[this.accion].motivoManual = this.motivoForm.get("motivoManual")?.value;
    this.datosService.datosVerificacion.decisionesTomadas[this.accion].esDecisionManual = true;
    console.log("Motivo manual grabado",this.datosService.datosVerificacion.decisionesTomadas);
    this.modalActual.close();
    this.datosService.datosVerificacion.decisionesTomadas[this.accion].tipo = this.resolucionProvisional; 
  }

  cancelar():void{
    this.modalActual.close();
  }
}