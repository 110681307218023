export class Excepcio {
  constructor(
    public missatge: string,
    public suggeriment?: string,
    public intern?: string,
    public reintentar?: () => void
  ) {}

  static excepcioCancelatPerUsuari(): Excepcio {
    return new Excepcio(
      "Se ha cancelado la ejecución del proceso",
      'Puede cerrar esta ventana'
    );
  }

 
}
