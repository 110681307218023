import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
  NgbModalModule,
  NgbNavModule,
  NgbTooltipModule,
  NgbDropdownModule,
  NgbPopoverModule,
  NgbModule,
} from '@ng-bootstrap/ng-bootstrap';
import { WebcamModule } from 'ngx-webcam';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GrupDitsComponent } from './components/grup-dits/grup-dits.component';
import { DitComponent } from './components/dit/dit.component';
import { FinalitzacioComponent } from './pagines/finalitzacio/finalitzacio.component';

import { EnrolamentEmpremtesComponent } from './pestanyes/enrolament-empremtes/enrolament-empremtes.component';
import { EnviamentDadesComponent } from './pestanyes/enviament-dades/enviament-dades.component';
import { InicialitzacioComponent } from './pagines/inicialitzacio/inicialitzacio.component';
import { EnrolamentComponent } from './pagines/enrolament/enrolament.component';
import { DocumentoComponent } from './pestanyes/documento/documento.component';
import { FacialComponent } from './pestanyes/facial/facial.component';
import { FormularioComponent } from './pestanyes/formulario/formulario.component';

import {
  SiepbioComuModule,
  TraduccioModule,
} from 'projects/siepbio-comu/src/public-api';

import { DocBarcodeComponent } from './pestanyes/doc-barcode/doc-barcode.component';
import { DocMrzComponent } from './pestanyes/doc-mrz/doc-mrz.component';
import { DocVizComponent } from './pestanyes/doc-viz/doc-viz.component';
import { DocFotoImpresaComponent } from './pestanyes/doc-foto-impresa/doc-foto-impresa.component';
import { LiveViewFotoComponent } from './pagines/live-view-foto/live-view-foto.component';

import { DatePipe } from '@angular/common';
import {ImageUrlPipe} from './pipes/image-url.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {SafeStylePipe} from './pipes/safe-style.pipe';
import {SafeUrlPipe} from './pipes/safe-url.pipe';

import { ConsultasComponent } from './pestanyes/consultas/consultas.component';
import { LoginComponent } from './pagines/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BarraAccionesComponent } from './components/barra-acciones/barra-acciones.component';
import { ValidacionesComponent } from './components/validaciones/validaciones.component';
import { ValidacionGrupoComponent } from './components/validacion-grupo/validacion-grupo.component';
import { PassportScannerComponent } from './components/passport-scanner/passport-scanner.component';

@NgModule({
  declarations: [
    AppComponent,

    ImageUrlPipe,
    SafeHtmlPipe,
    SafeStylePipe,
    SafeUrlPipe,

    GrupDitsComponent,
    DitComponent,
    FinalitzacioComponent,
    // ConfirmacioPersonaComponent,
    EnrolamentEmpremtesComponent,
    EnviamentDadesComponent,
    InicialitzacioComponent,
    EnrolamentComponent,
    DocumentoComponent,
    FacialComponent,
    FormularioComponent,

    DocBarcodeComponent,
    DocMrzComponent,
    DocVizComponent,

    LiveViewFotoComponent,
    DocFotoImpresaComponent,
    ConsultasComponent,
    LoginComponent,
    SidebarComponent,
    BarraAccionesComponent,
    ValidacionesComponent,
    ValidacionGrupoComponent,
    PassportScannerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModalModule,
    FormsModule,
    ReactiveFormsModule,
    NgbNavModule,
    NgbTooltipModule,
    NgbDropdownModule,
    HttpClientModule,
    NgbPopoverModule,
    SiepbioComuModule,
    TraduccioModule,
    WebcamModule,
    NgbModule,
    FontAwesomeModule
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
