import { Disponibilitat } from './disponibilitat';
import { GrupEmpremtes } from './grup-empremtes';

export class DisponibilitatMa {
  constructor(
    public disponibilitat: Disponibilitat,
    public ma: GrupEmpremtes,
    public esRodades: boolean
  ) {}
}
