<div class="card bg-light p-1">
  <div class="mb-0">
    <div class="text-left mb-4">
      <div class="float-right mr-2 ">
        <button
          *ngIf="!esRodada"
          type="button"
          (click)="capturar(content)"
          class="btn btn-primary btn-lg mx-1 py-2 px-4"
          [hidden]="amagarBotonsGrup()"
        >
          {{ "comu.accio.capturar" | translate }}
        </button>
        <button
          *ngIf="!esRodada"
          type="button"
          title="{{ 'grup-dits.netejar' | translate }}"
          aria-label="Netejar grup d'empremtes"
          class="btn btn-secondary btn-lg  py-2"
          (click)="netejarDits()"
        >
        <fa-icon [icon]="iconDelete" ></fa-icon>
        </button>
        <div
          *ngIf="grupEmpremtes != GrupEmpremtes.POLZES"
          ngbDropdown
          class="d-inline-block ml-3"
        >
          <a class="btn btn-link p-0 fontGran" id="dpdgrup" ngbDropdownToggle></a>
          <div ngbDropdownMenu class="h4" aria-labelledby="dpdgrup">
            <button
              ngbDropdownItem class="h4"
              (click)="seleccionarDisponibilitat(Disponibilitat.NORMAL)"
            >
              {{ "comu.disponibilitat.normal" | translate }}
            </button>
            <button
              ngbDropdownItem class="h4"
              (click)="seleccionarDisponibilitat(Disponibilitat.AMPUTAT)"
            >
              {{ "comu.disponibilitat.amputat" | translate }}
            </button>
            <button
              ngbDropdownItem class="h4"
              (click)="seleccionarDisponibilitat(Disponibilitat.NO_DISPONIBLE)"
            >
              {{ "comu.disponibilitat.no-disponible" | translate }}
            </button>
          </div>
        </div>
      </div>
      <div class="h6 ml-2 pt-1">
        <span class="h4">{{ obtenirNomGrup442() }}</span>
        <span
          class="badge badge-secondary"
          *ngIf="
            !esRodada &&
            numeroMinimIntents > 0 &&
            numeroIntent <= numeroMinimIntents
          "
          ># {{ numeroIntent }} / {{ numeroMinimIntents }}</span
        >
      </div>
    </div>

    <div class="card-deck mx-auto">
      <app-dit
        *ngFor="let d of dits"
        (onAnalitzaEmpremta)="onAnalitzaEmpremta($event)"
        (onSeleccionatDisponibilitatDit)="
          onSeleccionatDisponibilitatDit($event)
        "
        (onNetejarEmpremta)="onNetejarEmpremta($event)"
        [dit]="d"
        [esRodada]="esRodada"
      ></app-dit>
    </div>
    <div class="mt-2"></div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "comu.accio.capturar" | translate }} {{ obtenirNomGrup442() }}
    </h4>
    <button
      type="button"
      class="btn btn-danger"
      aria-label="Cerrar"
      (click)="cancelar()"
      title="{{ 'comu.accio.tancar' | translate }}"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row text-center">
      <div class="col-sm-12">
        <div class="h3 mx-auto">
          {{ obtenirTitol() }}
        </div>
        <div class="row">
          <div *ngIf="grupEmpremtes === GrupEmpremtes.MA_DRETA" class="col-sm-5 mx-auto my-auto">
            <video  autoplay loop width="280" height="210" class="border shadow">
              <source src="assets/img/HuellasDerechaG.mp4" type="video/mp4">
              No soporta video
            </video>`
          </div>
          <div class="col-sm-7">
            <div class="caixaCaptura mx-auto "> <lib-captura-dactilar-en-viu></lib-captura-dactilar-en-viu></div>
          </div>
          <div *ngIf="grupEmpremtes !== GrupEmpremtes.MA_DRETA" class="col-sm-5 mx-auto my-auto">
            <video  autoplay loop width="280" height="210" class="border shadow">
              <source *ngIf="grupEmpremtes === GrupEmpremtes.MA_ESQUERRA" src="assets/img/HuellasIzquierdaG.mp4" type="video/mp4">
              <source *ngIf="grupEmpremtes === GrupEmpremtes.POLZES" src="assets/img/HuellasPulgaresG.mp4" type="video/mp4">
              No soporta video
            </video>`
          </div>
        </div>
      </div>
    
    </div>
  </div>

  <div class="modal-footer" style="display: inline-block">
    <!--button
      type="button"
      (click)="calibrarEscaner()"
      class="float-left btn btn-outline-secondary btn-lg"
    >
      {{ "comu.accio.calibrar" | translate }}
    </button-->
    <button
      type="button"
      (click)="cancelar()"
      class="btn btn-warning float-right btn-lg"
    >
      {{ "comu.accio.cancelar" | translate }}
    </button>
  </div>
</ng-template>

<lib-calibrar-escaner-empremtes #calibrar></lib-calibrar-escaner-empremtes>
