/*
 * Public API Surface of siepbio-comu
 */

export * from './lib/serveis/centre.service';
export * from './lib/serveis/dactilar.service';
export * from './lib/serveis/escaner-empremtes.service';
export * from './lib/serveis/facial.service';
export * from './lib/serveis/siepbio-services.service';
export * from './lib/serveis/sistema.service';
export * from './lib/serveis/autenticacion.service';
export * from './lib/serveis/datos.service';

export * from './lib/serveis/configuracio.service';

export * from './lib/model/autoritzacio';
export * from './lib/model/facial-info';
export * from './lib/model/modal';
export * from './lib/model/excepcio';
export * from './lib/model/nist';
export * from './lib/model/raw-image';
export * from './lib/model/SegmentQualityScore';
export * from './lib/model/PairInfo';
export * from './lib/model/siepbio-model';
export * from './lib/model/resultat-identificacio';

export * from './lib/siepbio-comu.module';
export * from './lib/moduls/traduccio/traduccio.module';
