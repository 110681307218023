import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-peu',
  templateUrl: './peu.component.html',
  styleUrls: ['./peu.component.css'],
})
export class PeuComponent implements OnInit {
  @Input() versio?: string;

  constructor() {}

  ngOnInit(): void {}
}
