import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MissatgeModalComponent } from '../components/missatge-modal/missatge-modal.component';
import { EnvironmentService } from '../serveis/environment.service';
import { ModalAccio, ModalButton, ModalIcon } from '../model/modal';
import { PeticioVerificacioFacial } from '../model/peticio-verificacio-facial';
import { RespostaVerificacioFacial } from '../model/resposta-verificacio-facial';
import {
  Consulta,
  DecisioCoincidenciaConsulta,
  DesplegamentInfo,
  Enrolament,
  Errorable,
  EstacioInfo,
  InicialitzarSoftwareResult,
  Login,
  PersonaInfo,
  PersonaTemplates,
  RegistreAcces,
  CentresDTO,
  AccesInfo,
  PersonaAcces,
  TemplateFacialPersona,
  RegistreAccesFacial,
} from '../model/siepbio-model';
import pako from 'pako';

export class ServeiCancelat {}

@Injectable({
  providedIn: 'root',
})
export class SiepbioServicesService {
  private token: string = null;
  private securityToken: string = null;

  public host: string =
    //'https://preproduccio.siep.justicia.intranet.gencat.cat/SIEPBIO-web';
    // 'https://siep.justicia.intranet.gencat.cat/SIEPBIO-web';
    'http://siepbio.northeurope.cloudapp.azure.com:7001/SIEPBIO-web-cors';
  //'https://localhost:44343/SIEPBIO-web';

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private environtment: EnvironmentService //private datePipe: DatePipe
  ) {}

  public init(securityToken: string) {
    this.securityToken = securityToken;
  }

  private async login() {
    if (this.token != null) return Promise.resolve();

    var resp = await this.http
      .post<Login>(
        this.host + '/api/login',
        new HttpParams().set('param1', this.securityToken),
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          }),
          observe: 'response',
        }
      )
      .toPromise();

    if (resp.body.infojwt) {
      this.token = resp.body.infojwt;
      return Promise.resolve();
    } else throw 'session-expired';
  }

  public async intentaCrida<T>(
    fn: () => Promise<T>,
    modalComponent: MissatgeModalComponent
  ): Promise<T | ServeiCancelat> {
    try {
      return await fn();
    } catch (ex) {
      if (ex instanceof HttpErrorResponse) {
        const repetir = new ModalAccio({
          boto: ModalButton.Repetir,
          principal: true,
          esAutofocus: true,
        });
        const cancelar = new ModalAccio({ boto: ModalButton.Cancelar });

        modalComponent.tanca();

        const result = await modalComponent.obreAsync({
          missatge: await this.translate
            .get('comu.serveis.modal-intenta-crida.missatge')
            .toPromise(),
          titol: await this.translate
            .get('comu.serveis.modal-intenta-crida.titol')
            .toPromise(),
          indicacions: await this.translate
            .get('comu.serveis.modal-intenta-crida.indicacions')
            .toPromise(),
          detall:
            (await this.translate
              .get('comu.serveis.modal-intenta-crida.detall')
              .toPromise()) + ex.message,
          icona: ModalIcon.Atencio,
          accions: [repetir, cancelar],
        });

        return result === repetir
          ? this.intentaCrida(fn, modalComponent)
          : new ServeiCancelat();
      }
    }
  }

  private async intenta<T extends Errorable>(
    accio: () => Promise<T>
  ): Promise<T> {
    const result = await accio();
    if (result && result.rc === 'ERR00-004') {
      this.token = null;
      await this.login();
      return await accio();
    } else return result;
  }

  private async doCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    formParams?: FormData,
    json?: string
  ): Promise<any> {
    var headers = new HttpHeaders({
      Authorization: `Bearer ${this.token}`,
    });

    if (isGet)
      return await this.http
        .get(this.host + url, {
          headers: headers,
          params: params,
          observe: 'body',
          responseType: 'json',
        })
        .toPromise();
    else if (json) {
      headers = new HttpHeaders({
        Authorization: `Bearer ${this.token}`,
        'Content-Type': 'application/json',
      });

      await this.http
        .post(this.host + url, json, {
          headers,
        })
        .toPromise();
    } else {
      return await this.http
        .post(
          this.host + url,
          formParams ? formParams : json ? json : undefined,
          {
            headers: headers,
            params: params,
            observe: 'body',
            responseType: 'json',
          }
        )
        .toPromise();
    }
  }

  private async manageCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    formParams?: FormData,
    json?: string
  ): Promise<any> {
    if (this.token == null) await this.login();

    var obj = await this.doCall(isGet, url, params, formParams, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      this.token = null;
      await this.login();
      obj = await this.doCall(isGet, url, params, formParams, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  public async inicialitzaSoftware(
    estacio: string,
    software: string,
    versio: string
  ): Promise<InicialitzarSoftwareResult> {
    return (await this.manageCall(
      true,
      '/api/estacio/inicialitzarSoftware/' + estacio,
      {
        software: software,
        versio: versio,
      }
    )) as InicialitzarSoftwareResult;
  }

  public async persones(
    desplegamentId: number,
    operador: string,
    dits: number[]
  ): Promise<PersonaTemplates[]> {
    return (await this.manageCall(
      true,
      '/api/desplegament/persones/' + desplegamentId,
      {
        operador: operador,
        dit: dits.map((x) => String(x)),
      }
    )) as PersonaTemplates[];
  }

  public async personesFacial(
    centre: string,
    operador: string,
    fotos: boolean
  ): Promise<TemplateFacialPersona[]> {
    return (await this.manageCall(
      true,
      '/api/centre/personesFacial/' + centre,
      {
        operador: operador,
        fotos: String(fotos),
      }
    )) as TemplateFacialPersona[];
  }

  public async personesAmbFoto(
    centre: string,
    desplegamentId: number,
    operador: string,
    dits: number[],
    fotos: boolean
  ): Promise<PersonaTemplates[]> {
    return (await this.manageCall(true, '/api/centre/persones/' + centre, {
      desplegament: String(desplegamentId),
      operador: operador,
      dit: dits.join(','),
      fotos: String(fotos),
      alsada: String(0),
    })) as PersonaTemplates[];
  }

  public async personaInfo(referencia: string): Promise<PersonaInfo> {
    return (await this.manageCall(
      true,
      '/api/personaEnrolada/info/' + referencia
    )) as PersonaInfo;
  }

  public async imatgeEmpremta(imatge: string): Promise<ArrayBuffer> {
    const result = await this.http
      .get(this.host + '/api/empremta/imatge/' + imatge, {
        responseType: 'arraybuffer',
      })
      .toPromise();

    return result;
  }

  public async personaEmpremtes(referencia: string): Promise<Enrolament> {
    return (await this.manageCall(
      true,
      '/api/personaEnrolada/empremtes/' + referencia
    )) as Enrolament;
  }

  public async registrarAcces(
    desplegamentId: number,
    operador: string,

    registreAcces: RegistreAcces
  ): Promise<void> {
    await this.login();

    const params = new FormData();
    params.append('operador', operador);

    if (registreAcces.motiu !== undefined)
      params.append('motiu', registreAcces.motiu);

    if (
      registreAcces.is_sortida !== undefined &&
      registreAcces.is_sortida !== null
    )
      params.append('is_sortida', String(registreAcces.is_sortida));

    if (registreAcces.ndit_esquerre !== undefined)
      params.append('ndit_esquerre', String(registreAcces.ndit_esquerre));

    if (registreAcces.imatge_dit_esquerre_wsq !== undefined)
      params.append(
        'imatge_dit_esquerre',
        registreAcces.imatge_dit_esquerre_wsq
      );

    if (registreAcces.ndit_dret !== undefined)
      params.append('ndit_dret', String(registreAcces.ndit_dret));

    if (registreAcces.imatge_dit_dret_wsq !== undefined)
      params.append('imatge_dit_dret', registreAcces.imatge_dit_dret_wsq);

    if (registreAcces.persona_trobada_ref !== undefined)
      params.append('persona_trobada_ref', registreAcces.persona_trobada_ref);

    params.append('is_error', String(registreAcces.is_error));

    if (registreAcces.observacio !== undefined)
      params.append('observacio', registreAcces.observacio);

    if (registreAcces.persona_esperada_ref !== undefined)
      params.append('persona_esperada_ref', registreAcces.persona_esperada_ref);

    if (registreAcces.acces_concedit !== undefined)
      params.append('acces_concedit', String(registreAcces.acces_concedit));

    if (registreAcces.acces_id !== undefined)
      params.append('acces_id', registreAcces.acces_id);

    if (registreAcces.data_control !== undefined)
      params.append('data_control', registreAcces.data_control);

    await this.manageCall(
      false,
      '/api/desplegament/registrarAcces/' + desplegamentId,
      undefined,
      params
    );
  }

  public async cercar(
    desplegamentId: number,
    nist: Blob,
    numero: number,
    limit: number,
    operador: string,
    referencia: string
  ): Promise<Consulta> {
    const params = new FormData();

    params.append('desplegamentId', String(desplegamentId));
    params.append('nist', nist, 'nist.NIST');
    params.append('operador', operador);
    params.append('limit', String(limit));
    params.append('numero', String(numero));
    params.append('referencia', referencia);

    return (await this.manageCall(
      false,
      '/api/consulta/cercar',
      undefined,
      params
    )) as Consulta;
  }

  public async enrolarPerNIST(
    id: string,
    desplegamentId: number,
    nist: Blob,
    operador: string,
    referenciaSessio?: string
  ): Promise<void> {
    const params = new FormData();

    params.append('desplegamentId', String(desplegamentId));
    params.append('nist', nist, 'nist.NIST');
    params.append('operador', operador);
    if (referenciaSessio != null)
      params.append('referenciaSessio', referenciaSessio);

    await this.manageCall(
      false,
      '/api/personaEnrolada/enrolar/' + id,
      undefined,
      params
    );
  }

  public async verificar(
    idCerca: number,
    decisions: DecisioCoincidenciaConsulta[]
  ) {
    var json = JSON.stringify(decisions);

    await this.manageCall(
      false,
      '/api/consulta/verificar/' + idCerca,
      undefined,
      undefined,
      json
    );
  }

  public async cercaPerNIS(NIS: string): Promise<PersonaInfo> {
    return (await this.manageCall(
      true,
      '/api/personaEnrolada/infoByNis/' + NIS
    )) as PersonaInfo;
  }

  public async cercaPerCIC(centre: string, CIC: string): Promise<PersonaInfo> {
    return (await this.manageCall(
      true,
      '/api/personaEnrolada/infoByCic/' + CIC,
      { centre: centre }
    )) as PersonaInfo;
  }

  public async verificaFacialment(
    peticio: PeticioVerificacioFacial
  ): Promise<RespostaVerificacioFacial> {
    var json = JSON.stringify(peticio);

    return (await this.manageCall(
      false,
      '/api/personaEnrolada/verificaFacialment',
      undefined,
      undefined,
      json
    )) as RespostaVerificacioFacial;
  }

  public async desplegamentInfo(
    desplegamentId: number
  ): Promise<DesplegamentInfo> {
    return (await this.manageCall(
      true,
      '/api/desplegament/' + desplegamentId
    )) as DesplegamentInfo;
  }

  public async estacioInfo(estacio: string): Promise<EstacioInfo> {
    return (await this.manageCall(
      true,
      '/api/estacio/' + estacio
    )) as EstacioInfo;
  }

  public async nomAcces(desplegamentId: number): Promise<string> {
    const desplegamentInfo = await this.desplegamentInfo(desplegamentId);

    if (desplegamentInfo && desplegamentInfo.estacioBiometrica) {
      const accesInfo = await this.estacioInfo(
        desplegamentInfo.estacioBiometrica
      );

      if (accesInfo && accesInfo.acces) return accesInfo.acces.porta;
    }

    return null;
  }

  public async accesID(desplegamentId: number): Promise<number> {
    const desplegamentInfo = await this.desplegamentInfo(desplegamentId);

    if (desplegamentInfo && desplegamentInfo.estacioBiometrica) {
      const accesInfo = await this.estacioInfo(
        desplegamentInfo.estacioBiometrica
      );

      if (accesInfo && accesInfo.acces) return accesInfo.acces.id;
    }

    return null;
  }

  public async llistaCentres(): Promise<CentresDTO[]> {
    return (await this.manageCall(true, '/api/centre/')) as CentresDTO[];
  }

  public async llistaAccessos(): Promise<AccesInfo[]> {
    return (await this.manageCall(true, '/api/acces')) as AccesInfo[];
  }

  public async obtenirFoto(id: string) {
    await this.manageCall(
      true,
      '/api/foto/obtenirRegistre/' + id,
      undefined,
      undefined
    );
  }

  public async obtenirFotoUrl(id: string): Promise<string> {
    let blob = (await this.manageCall(
      true,
      '/api/foto/obtenirRegistre/' + id,
      undefined,
      undefined
    )) as Blob;
    if (blob == null) {
      return '/assets/img/sin-fotografia.png';
    }

    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onloadend = function () {
        const bUrl = <string>reader.result;
        const result = bUrl.substr(bUrl.indexOf(',') + 1);
        resolve(result);
      };
      reader.readAsDataURL(blob);
    });
  }

  public async obtenirPerAcces(
    accesId: number,
    dataInici: string,
    dataFi?: string
  ): Promise<Array<PersonaAcces>> {
    return (await this.manageCall(
      true,
      '/api/personaAccesHistoria/obtenirPerAcces',
      { id: String(accesId), dataInici: dataInici, dataFi: dataFi }
    )) as Array<PersonaAcces>;
    //.filter((x) => x.sortida == esSortida);
  }

  public async obtenirPersona(
    personaReferencia: string,
    accesId: number,
    dataInici?: string,
    dataFi?: string
  ): Promise<PersonaInfo> {
    return (await this.manageCall(
      true,
      '/api/personaAccesHistoria/obtenirPerPersona',
      {
        referencia: personaReferencia,
        accesId: String(accesId),
        dataInici: dataInici,
        dataFi: dataFi,
      }
    )) as PersonaInfo;
  }

  public async registrarAccesFacial(
    desplegamentId: number,
    accesId: number,
    registreAcces: RegistreAccesFacial,
    operador: string
  ): Promise<void> {
    await this.login();
    console.log('Registre Acces Facial', registreAcces);
    const params = new FormData();
    params.append('acces_id', String(accesId));
    params.append('operador', operador);
    params.append('motiu', 'IDENTIFICAR-FACIAL');
    params.append('is_sortida', String(registreAcces.is_sortida));
    if (registreAcces.persona_trobada_ref !== undefined)
      params.append('persona_trobada_ref', registreAcces.persona_trobada_ref);
    if (registreAcces.persona_esperada_ref !== undefined)
      params.append('persona_esperada_ref', registreAcces.persona_esperada_ref);
    if (registreAcces.data_control != undefined) {
      params.append('data_control', registreAcces.data_control);
    }
    if (registreAcces.foto !== null) {
      params.append('imatge_foto', registreAcces.foto);
    }
    params.append('acces_concedit', String(registreAcces.acces_concedit));
    if (registreAcces.is_error !== undefined)
      params.append('is_error', String(registreAcces.is_error));
    if (registreAcces.observacio !== undefined)
      params.append('observacio', registreAcces.observacio);

    await this.manageCall(
      false,
      '/api/desplegament/registrarAccesFacial/' + String(desplegamentId),
      undefined,
      params
    );
  }

  public async toBlob(db64: string): Promise<Blob> {
    const f = await fetch(db64);
    return await f.blob();
  }

  public fromSiepTemplateFingerprint(b64: string): string {
    const decod = atob(b64);
    const template = this.decompress(decod);

    const ppmString = template.substring(0, 5);
    const heightString = template.substring(5, 9);

    const ppm = Number.parseFloat(ppmString);
    const height = Number.parseFloat(heightString);

    var minucies = [...Array((template.length - 9) / 17).keys()].map((i) => {
      const text = template.substring(i * 17 + 9, i * 17 + 9 + 17);

      const x_units = Number.parseInt(text.substring(3, 3 + 4));
      const y_units = Number.parseInt(text.substring(7, 7 + 4));
      const std_deg = Number.parseInt(text.substring(11, 11 + 3));
      const q_nist = Number.parseInt(text.substring(14, 14 + 2));

      const x = Math.floor((x_units / 100.0) * ppm);
      const h_units = Math.ceil((height / ppm) * 100.0);

      const y = Math.floor(((h_units - y_units - 1) / 100) * ppm);

      var direction = (270 - std_deg) % 360;

      if (direction < 0) direction += 360;

      var quality = Math.ceil((100.0 * (63.0 - q_nist)) / 61.0);

      const type = text.substring(16, 17) === 'A' ? 1 : 0;

      return { x, y, direction, quality, type };
    });

    if (minucies[0].quality) minucies = minucies.filter((m) => m.quality >= 10);

    const iso = this.ISOFormat(minucies, 400, height);

    const isoB64 = btoa(iso);

    return isoB64;
  }

  private to256(direction) {
    return Math.floor((direction * 256) / 360);
  }

  private decompress(buffer: string) {
    const array = Uint8Array.from(buffer, (c) => c.charCodeAt(0));

    return pako.ungzip(array, { to: 'string' });
  }

  private writeString(bytes: Array<number>, txt: string): void {
    for (var i = 0; i < txt.length; i++) bytes.push(txt.charCodeAt(i));
    bytes.push(0);
  }

  private writeByte(bytes: Array<number>, byte: number): void {
    bytes.push(byte);
  }

  private writeShort(bytes: Array<number>, short: number): void {
    bytes.push((short & 0xff00) >> 8);
    bytes.push(short & 0xff);
  }

  private writeInt(bytes: Array<number>, int: number): void {
    bytes.push((int & 0xff000000) >> 32);
    bytes.push((int & 0xff0000) >> 16);
    bytes.push((int & 0xff00) >> 8);
    bytes.push(int & 0xff);
  }

  private updateInt(bytes: Array<number>, int: number, pos: number): void {
    bytes[pos++] = (int & 0xff000000) >> 32;
    bytes[pos++] = (int & 0xff0000) >> 16;
    bytes[pos++] = (int & 0xff00) >> 8;
    bytes[pos] = int & 0xff;
  }

  private flipAngle(angle: number): number {
    return angle < 128 ? angle + 128 : angle - 128;
  }

  private ISOFormat(minucies, width, height): string {
    var iso: Array<number> = [];

    // 4B magic "FMR\0"
    this.writeString(iso, 'FMR');

    // 4B version (ignored, set to " 20\0"
    this.writeString(iso, ' 20');

    // 4B total length (including header, will be updated later)
    this.writeInt(iso, 0);

    // 2B rubbish (zeroed)
    this.writeShort(iso, 0);

    // 2B image size in pixels X
    this.writeShort(iso, width);

    // 2B image size in pixels Y
    this.writeShort(iso, height);

    // 2B rubbish (pixels per cm X, set to 196 = 500dpi)
    this.writeShort(iso, 196);

    // 2B rubbish (pixels per cm Y, set to 196 = 500dpi)
    this.writeShort(iso, 196);

    // 1B rubbish (number of fingerprints, set to 1)
    this.writeByte(iso, 1);

    // 1B rubbish (zeroed)
    this.writeByte(iso, 0);

    // 1B rubbish (finger position, zeroed)
    this.writeByte(iso, 0);

    // 1B rubbish (zeroed)
    this.writeByte(iso, 0);

    // 1B rubbish (fingerprint quality, set to 100)
    this.writeByte(iso, 100);

    // 1B minutia count
    this.writeByte(iso, Math.min(minucies.length, 255));

    // N*6B minutiae
    minucies.slice(0, 255).forEach((minucia) => {
      const type = minucia.type == 1 ? 0x4000 : 0x8000;

      const angle = this.flipAngle(this.to256(minucia.direction));

      this.writeShort(iso, minucia.x | type);
      this.writeShort(iso, height - minucia.y - 1);
      this.writeByte(iso, angle);
      this.writeByte(iso, 0);
    });

    this.writeShort(iso, 0);

    this.updateInt(iso, iso.length, 8);

    return String.fromCharCode.apply(null, new Uint8Array(iso));
  }
}
