import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, AbstractControl, FormControl, Validators } from '@angular/forms';
import { ConfiguracioService, ParametrosConfiguracion } from '../../serveis/configuracio.service';
//import { AutenticacionService } from 'projects/siepbio-enrolament-dactilar/src/app/serveis/autenticacion.service';
import { MissatgeModalComponent } from '../missatge-modal/missatge-modal.component';
import { Modal, ModalIcon } from '../../model/modal';
import { AutenticacionService } from '../../serveis/autenticacion.service';
import { ApartatService } from 'projects/siepbio-enrolament-dactilar/src/app/serveis/apartat.service';

import { DatosService } from '../../serveis/datos.service';
import { DatosVerificacion } from 'projects/siepbio-enrolament-dactilar/src/app/model/EntryExit';

@Component({
  selector: 'lib-capsalera',
  templateUrl: './capsalera.component.html',
  styleUrls: ['./capsalera.component.css'],
})

//CLASE OBSOLETA
export class CapsaleraComponent implements OnInit {
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;
  
  //@ViewChild('configPanel') configPanel?: ElementRef;
    
  public configForm: FormGroup;
  submitted = false;
  usuario?: string;
  calidadMinimaArrayError = false;
  calidadCheckerArrayError = false;
  modalActual?: NgbModalRef;
  fechaUltimaActualizacion?: string = new Date().toDateString();
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private configService: ConfiguracioService,
    private autenticacionService: AutenticacionService,
    private apartatService: ApartatService,
    public datosService: DatosService
    ) {
      try{
        this.cargarConfiguración(configService.parametrosConfiguracion);
      }
      catch(ex){
        console.log("Error al carregar la configuració", ex);
      }
      
  }

  cargarConfiguración(configParams: ParametrosConfiguracion):void{
    
    this.configForm = this.formBuilder.group({
      habilitarLectorHuellas: configParams.habilitarLectorHuellas,
      reintentsMalaQualitat: [configParams.reintentsMalaQualitat,[Validators.required,Validators.min(0)]],
      indexComparacioCoincident: [configParams.indexComparacioCoincident, [Validators.required]],
      indexComparacioRepetit: [configParams.indexComparacioRepetit,[Validators.required]],
      intentsMinimCaptura: [configParams.intentsMinimCaptura, [Validators.required,Validators.min(0), Validators.max(4)]],
      llindarMalaQualitat: [configParams.llindarMalaQualitat,[Validators.required]],
      midaMaximaKBFoto: [configParams.midaMaximaKBFoto, [Validators.required]],
      umbralCoincidenciaFotos: [configParams.umbralCoincidenciaFotos, [Validators.required]],
      permetContinuarSiMalaQualitat:  configParams.permetContinuarSiMalaQualitat, 
      encendidoLucesAutomatico:  configParams.encendidoLucesAutomatico, 
      fotoFrontalObligatoria: configParams.fotoFrontalObligatoria,
      utilizarUSKDactilar: configParams.utilizarUSKDactilar,
      utilizarUSKFace: configParams.utilizarUSKFace,

      qualitatMinimaPosades: [this.GetValoresArray(configParams.qualitatMinimaPosades,5), [Validators.required]],
      qualitatChecker: [this.GetValoresArray(configParams.qualitatChecker,5),[Validators.required]],
      biometricQualityMetricThresholdFP: [configParams.biometricQualityMetricThresholdFP,[Validators.required,Validators.maxLength(6)]],
      biometricQualityMetricThresholdFace: [configParams.biometricQualityMetricThresholdFace,[Validators.required,Validators.maxLength(6)]],
      loginOperacion: [configParams.loginOperacion,[Validators.required]],
      edadMinimaCapturaHuellas: [configParams.edadMinimaCapturaHuellas,[Validators.required]],
      //autenticacionUsuario: [configParams.autenticacionUsuario,[Validators.required]],
      //autenticacionClave: [configParams.autenticacionClave,[Validators.required]],
      //autenticacionPass: [configParams.autenticacionPass,[Validators.required]],
      autenticacionLiteralSistema: [configParams.autenticacionLiteralSistema,[Validators.required]],
      autenticacionDireccionIP: [configParams.autenticacionDireccionIP,[Validators.required]],
      autenticacionAplicacionCNP: [configParams.autenticacionAplicacionCNP,[Validators.required]],
      fronteraUbicacionDispositivo: [configParams.fronteraUbicacionDispositivo,[Validators.required]],
      //fronteraSentido: [configParams.fronteraSentido,[Validators.required]],
      hostServiciosDocumentReader: [configParams.hostServiciosDocumentReader,[Validators.required]],
      hostServiciosFacialRecognition: [configParams.hostServiciosFacialRecognition,[Validators.required]],
      hostServiciosSuprema: [configParams.hostServiciosSuprema,[Validators.required]],
      hostServiciosFPSDK: [configParams.hostServiciosFPSDK,[Validators.required]],
      hostServiciosLaxton: [configParams.hostServiciosLaxton,[Validators.required]],
      hostServiciosIntegracion: [configParams.hostServiciosIntegracion,[Validators.required]],
      hostServiciosPoliciales: [configParams.hostServiciosPoliciales,[Validators.required]],
      hostServiciosUSK: [configParams.hostServiciosUSK,[Validators.required]],
      hostServiciosIDV: [configParams.hostServiciosIDV,[Validators.required]],
      hostServiciosIU: [configParams.hostServiciosIU,[Validators.required]]
    });
  }

  ngOnInit(): void {  
    
  }

  abrir(content: any, tamaño: string, scroll: boolean): void {
    
    this.modalActual = this.modalService.open(content, {
      size: tamaño,
      ariaLabelledBy: 'modal-basic-title',
      backdrop: false,
      centered: true,
      scrollable: scroll
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.configForm.controls;
  }

  onSubmit(): void{
    
    console.log("on submit");
    this.submitted = true;
    if (this.ExistenErroresValoresArray(this.configForm.get("qualitatMinimaPosades")?.value)){
      console.log("hay errores array qualitat minima", this.configForm.get("qualitatMinimaPosades")?.value);
      this.calidadMinimaArrayError = true;
      return;
    }
    if(this.ExistenErroresValoresArray(this.configForm.get("qualitatChecker")?.value)){
      console.log("hay errores array qualitat checker", this.configForm.get("qualitatChecker")?.value);
      this.calidadCheckerArrayError = true;
      return;
    }
    
    if (this.configForm.invalid) {
      console.log("Configuración inválida", this.configForm);
      return;
    }
    else if (this.configForm.valid){
      console.log("form valido", this.configForm);
      this.guardar();
    }
  }

  onReset(): void {
    this.submitted = false;
    console.log("reset abans clear", this.configService.parametrosConfiguracion);
    localStorage.clear();
    console.log("reset despres clear", this.configService.parametrosConfiguracion);
    let configNuevo: ParametrosConfiguracion = new ParametrosConfiguracion();
    localStorage.setItem('configuracion', JSON.stringify(configNuevo));
    this.cargarConfiguración(configNuevo);
  }

  guardar(): void{
    this.calidadCheckerArrayError = false;
    var config: ParametrosConfiguracion = new ParametrosConfiguracion();
    config.qualitatMinimaPosades =  this.grabarValoresHuellas(this.configForm.get("qualitatMinimaPosades")?.value);  // [60, 65, 60, 60, 40, 60, 65, 60, 60, 40];
    config.qualitatChecker = this.grabarValoresHuellas(this.configForm.get("qualitatChecker")?.value); // [40, 40, 40, 40, 30, 40, 40, 40, 40, 30];
    config.reintentsMalaQualitat = Number(this.configForm.get("reintentsMalaQualitat")?.value);
    config.indexComparacioCoincident = Number(this.configForm.get("indexComparacioCoincident")?.value);
    config.indexComparacioRepetit = Number(this.configForm.get("indexComparacioRepetit")?.value);
    config.intentsMinimCaptura = Number(this.configForm.get("intentsMinimCaptura")?.value);
    config.llindarMalaQualitat = Number(this.configForm.get("llindarMalaQualitat")?.value);
    config.umbralCoincidenciaFotos = Number(this.configForm.get("umbralCoincidenciaFotos")?.value);
    config.midaMaximaKBFoto = Number(this.configForm.get("midaMaximaKBFoto")?.value);
    config.permetContinuarSiMalaQualitat = Boolean(this.configForm.get("permetContinuarSiMalaQualitat")?.value);
    config.encendidoLucesAutomatico = Boolean(this.configForm.get("encendidoLucesAutomatico")?.value);
    config.edadMinimaCapturaHuellas = Number(this.configForm.get("edadMinimaCapturaHuellas")?.value);


    config.utilizarUSKDactilar = Boolean(this.configForm.get("utilizarUSKDactilar")?.value);
    config.utilizarUSKFace = Boolean(this.configForm.get("utilizarUSKFace")?.value);
    config.habilitarLectorHuellas = Boolean(this.configForm.get("habilitarLectorHuellas")?.value);
    config.fotoFrontalObligatoria =Boolean(this.configForm.get("fotoFrontalObligatoria")?.value);
    config.biometricQualityMetricThresholdFP =  Number(this.configForm.get("biometricQualityMetricThresholdFP")?.value);
    config.biometricQualityMetricThresholdFace =  Number(this.configForm.get("biometricQualityMetricThresholdFace")?.value);

    config.loginOperacion =  this.configForm.get("loginOperacion")?.value;
    //config.autenticacionUsuario =  this.configForm.get("autenticacionUsuario")?.value;
    //config.autenticacionClave =  this.configForm.get("autenticacionClave")?.value;
    //config.autenticacionPass =  this.configForm.get("autenticacionPass")?.value;
    config.autenticacionLiteralSistema =  this.configForm.get("autenticacionLiteralSistema")?.value;
    config.autenticacionDireccionIP =  this.configForm.get("autenticacionDireccionIP")?.value;
    config.autenticacionAplicacionCNP =  this.configForm.get("autenticacionAplicacionCNP")?.value;
    config.fronteraUbicacionDispositivo =  this.configForm.get("fronteraUbicacionDispositivo")?.value;
    //config.fronteraSentido =  this.configForm.get("fronteraSentido")?.value;
    config.hostServiciosDocumentReader =  this.configForm.get("hostServiciosDocumentReader")?.value;
    config.hostServiciosFacialRecognition =  this.configForm.get("hostServiciosFacialRecognition")?.value;
    config.hostServiciosSuprema =  this.configForm.get("hostServiciosSuprema")?.value;
    config.hostServiciosFPSDK =  this.configForm.get("hostServiciosFPSDK")?.value;
    config.hostServiciosLaxton =  this.configForm.get("hostServiciosLaxton")?.value;
    config.hostServiciosIntegracion =  this.configForm.get("hostServiciosIntegracion")?.value;
    config.hostServiciosPoliciales =  this.configForm.get("hostServiciosPoliciales")?.value;
    config.hostServiciosUSK =  this.configForm.get("hostServiciosUSK")?.value;
    config.hostServiciosIDV =  this.configForm.get("hostServiciosIDV")?.value;
    config.hostServiciosIU =  this.configForm.get("hostServiciosIU")?.value;
    
    localStorage.setItem('configuracion', JSON.stringify(config));

    console.log("storage guardat", localStorage.getItem('configuracion'))

    this.modalActual.close();
  }

  private GetValoresArray(valores: number[], numvalores: number): string{
    if (valores == null) return null;
    if (valores.length > numvalores){
      return valores.slice(0,numvalores).toString();
    }
    return valores.toString();
  }

  private ExistenErroresValoresArray(valores: string): boolean{
    let arr = Array.from(valores.split(','), Number);
    return (valores == '' || arr == undefined || arr.length < 5);
  }

  private grabarValoresHuellas(valores: string): number[]{
   let arr = Array.from(valores.split(','), Number);
   return arr.concat(arr); // repetimos los valores del array para la otra mano
  }

  //TODO los parámetros que hay que pasar hay que determinarlos. se guardan en sessión?
  async cerrarSesion(): Promise<void>{
    try{
      console.log("Se cierra la sessión para el usuario");
      let resultado = await this.autenticacionService.logOut();
      this.datosService.usuario = undefined;
      this.datosService.literal = undefined;
      this.datosService.usuarioAutenticado = undefined;
      this.apartatService.loginEvent.emit();
      this.datosService.datosVerificacion = new DatosVerificacion();
    }
    catch(ex){
      this.missatgeModal.obre(
        new Modal({
          titol: "Error",
          missatge: ex.missatge,
          indicacions: ex.suggeriment,
          detall: ex.intern,
          icona: ModalIcon.Atencio,
        })
      );
    }
  }

}
