<ng-template #liveView let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ "live-view.titol" | translate }}
      </h4>
      <button
        type="button"
        class="btn  btn-danger"
        aria-label="Cerrar"
        (click)="cerrar()"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="p-2" id="cameraviu">
        <div class="text-center">
          <webcam
            #videoCam
            id="webCamControl"
            [height]="400"
            [width]="500"
            [allowCameraSwitch]="true"
            (initError)="handleInitError($event)"
            [captureImageData]="true"
            [videoOptions]="videoOptions"
            (imageCapture)="handleImage($event)"
            [trigger]="triggerObservable"
            [imageQuality]="1"
          ></webcam>
        </div>
        <div *ngIf="calculandoIDV" class="d-flex justify-content-center mt-2">
          <span class="spinner-grow spinner-grow-sm text-info" role="status" aria-hidden="true"></span>
          <span class="ml-2 text-info">Procesando ...</span>
        </div>
        <div class="form-row" style="display:none">
          <div class="form-group col-sm-6">
            <label for="exposureTimeRange"
              >Exposición:
              <span class="text-info">{{ valorExposicio }} </span></label
            >
            <input
              type="range"
              class="form-control-range"
              id="exposureTimeRange"
              [(ngModel)]="exposureTimeRange"
              (ngModelChange)="setExposicio($event)"
              min="4.8828125"
              max="2500"
              step="4.8828125"
            />
          </div>
          <div class="form-group col-sm-6">
            <label for="brightnessRange"
              >Brillo:
              <span class="text-info">{{ valorBrillantor }}</span></label
            >
            <input
              type="range"
              class="form-control-range"
              id="brightnessRange"
              [(ngModel)]="brightnessRange"
              (ngModelChange)="setBrillantor($event)"
              min="0"
              max="255"
              step="1"
            />
          </div>
        </div>
      </div>
    </div>
  
    <div class="modal-footer inline-footer">
      <button
        ngbAutofocus
        type="button"
        [disabled] ="calculandoIDV"
        (click)="triggerSnapshot()"
        class="btn btn-info btn-lg mr-2 my-2 float-right"
      >
        {{ "comu.accio.capturar" | translate }}
      </button>
      <button
        type="button"
        [disabled] ="calculandoIDV"
        (click)="cerrar()"
        class="btn btn-warning btn-lg mx-1 my-2 float-right"
      >
        {{ "comu.accio.cancelar" | translate }}
      </button>
      <button
      type="button"
      (click)="luces(!estadoLuces)"
      class="btn btn-outline-secondary btn-lg float-left mx-2 my-2"
    >
      {{ textoAccionLuces }}
    </button>
    </div>

  </ng-template>
  
  <lib-missatge-modal #missatgeModal></lib-missatge-modal>
  
