import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Excepcio } from 'projects/siepbio-comu/src/public-api';

@Component({
  selector: 'app-finalitzacio',
  templateUrl: './finalitzacio.component.html',
})
export class FinalitzacioComponent implements OnInit {
  excepcio: Excepcio;
  missatge: string;
  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    this.missatge = this.translate.instant('finalitzacio.missatge');
  }
}
