import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DecisionTomada, Etapa } from 'projects/siepbio-enrolament-dactilar/src/app/model/documento';
import { DatosVerificacion, ResumenDatosSideBar } from 'projects/siepbio-enrolament-dactilar/src/app/model/EntryExit';
import { ApartatService } from 'projects/siepbio-enrolament-dactilar/src/app/serveis/apartat.service';
import { UsuarioAutenticar } from '../model/autoritzacio';

@Injectable({
  providedIn: 'root',
})
export class DatosService {
  public datosVerificacion?: DatosVerificacion;
  public usuario?: string;
  public literal?: string;
  public usuarioAutenticado?: UsuarioAutenticar;
  public resumenDatosResolucion?: ResumenDatosSideBar;
  public sentido?: string;

  constructor(
    private translate: TranslateService,
    private apartatService: ApartatService
  ) {}

  reiniciarProceso(){
    this.datosVerificacion = new DatosVerificacion();
    this.resumenDatosResolucion = new ResumenDatosSideBar();
    this.apartatService.enrolamentEvent.emit();
    this.apartatService.canviarPestanyaEvent.emit(1);
    
  }



}
