import { Component, OnInit, ViewChild } from '@angular/core';

import { DactilarService } from 'projects/siepbio-comu/src/lib/serveis/dactilar.service';
import {
  ConfiguracioService,
  DatosService,
  EscanerEmpremtesService,
  RawImage,
  TipusPitu,
} from 'projects/siepbio-comu/src/public-api';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { Modal, ModalAccio, ModalButton, ModalIcon } from 'projects/siepbio-comu/src/lib/model/modal';
import { Pagina, Pestanya } from '../../model/pagina';
import { ApartatService } from '../../serveis/apartat.service';
import {
  EmpremtaAmbTipus,
  EmpremtaCaptura,
  EmpremtesAnalitzades,
} from '../../model/empremta-captura';
import { DisponibilitatMa } from '../../model/disponibilitat-ma';
import { Disponibilitat } from '../../model/disponibilitat';
import { GrupEmpremtes } from '../../model/grup-empremtes';
import { ResultatCaptura } from '../../model/resultat-captura';
import { EnrolamentService } from '../../serveis/enrolament.service';

import {
  ResultatCapturaEmpremta,
  ResultatCapturaFallida,
} from '../../model/resultat-captura-empremta';
import { AplicacioService } from '../../serveis/aplicacio.service';
import { TranslateService } from '@ngx-translate/core';
import { CategoriaMensaje, DatosVerificacion, FingerPosition, FingerQualityRequest, FingerScore, MensajeProceso, SlapQuality, TipoMensajeProceso } from '../../model/EntryExit';
import { DecisionTomada, Etapa, TipoDecision } from '../../model/documento';

import { faIdCard, faCamera, faHandPaper, faSearch, faFileAlt, faCheckSquare ,faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UskService } from '../../serveis/usk.service';

type Zip<T extends unknown[][]> = {
  [I in keyof T]: T[I] extends (infer U)[] ? U : never;
}[];
function zip<T extends unknown[][]>(...args: T): Zip<T> {
  return <Zip<T>>(<unknown>args[0].map((_, c) => args.map((row) => row[c])));
}

@Component({
  selector: 'app-enrolament',
  templateUrl: './enrolament.component.html',
})
export class EnrolamentComponent implements OnInit {

  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;
  @ViewChild('missatgeModalConfirm') missatgeModalConfirm: MissatgeModalComponent;

  Pagina: typeof Pagina = Pagina;
  Pestanya: typeof Pestanya = Pestanya;
  title: string;
  paginaActual: Pagina;
  pestanyaActual: Pestanya = Pestanya.DocumentacionMrz;
  active = 1;

  intentsEsquerra: number = 0;
  intentsDreta: number = 0;
  intentsPolzes: number = 0;

  iconDocumento = faIdCard;
  iconFacial =  faCamera;
  iconDactilar = faHandPaper;
  iconConsultas = faSearch;
  iconFormulario = faFileAlt;
  iconEnviar = faCheckSquare;

  deshabilitar: boolean = false;

  constructor(
    private apartatServei: ApartatService,
    private aplicacioServei: AplicacioService,
    private dactilarServei: DactilarService,
    private enrolamentServei: EnrolamentService,
    private escanerEmpremtesServei: EscanerEmpremtesService,
    private translate: TranslateService,
    private configuracio: ConfiguracioService,
    private uskService: UskService,
    private datosService: DatosService
    
  ) {
    this.deshabilitar = !configuracio.parametrosConfiguracion.habilitarLectorHuellas;
    this.apartatServei.canviarPestanyaEvent.subscribe((num) => {
      this.active = num;
      this.pestanyaActual = this.active - 1;
    });

  }
  
  ngOnInit(): void {
    console.log("NUEVO REGISTRO DE DOCUMENTO");
    this.datosService.datosVerificacion = new DatosVerificacion();
  }

  setDisponibilitatDit(
    index: number,
    disponibilitat: Disponibilitat,
    esRodada: boolean
  ) {
    const empremtesPosades = this.datosService.datosVerificacion.huellasCapturadas;

    if (disponibilitat !== Disponibilitat.NORMAL) {
        empremtesPosades[index] = new EmpremtaCaptura(index + 1);
        empremtesPosades[index].noEsMatched = null;
    }
    empremtesPosades[index].disponibilitat = disponibilitat;
    this.comprobarDecisionHuellas();
  
  }

  onSeleccionatDisponibilitatDit(empremta: EmpremtaAmbTipus) {
    this.setDisponibilitatDit(
      empremta.empremtaCapturada.numeroDit - 1,
      empremta.empremtaCapturada.disponibilitat,
      empremta.esRodada
    );
  }

  onSeleccionatDisponibilitat(disponibilitatMa: DisponibilitatMa) {
    if (disponibilitatMa.ma === GrupEmpremtes.MA_ESQUERRA) {
      for (let i = 5; i < 10; i++) {
        this.setDisponibilitatDit(
          i,
          disponibilitatMa.disponibilitat,
          disponibilitatMa.esRodades
        );
      }
    } else if (disponibilitatMa.ma == GrupEmpremtes.MA_DRETA) {
      for (let i = 0; i < 5; i++) {
        this.setDisponibilitatDit(
          i,
          disponibilitatMa.disponibilitat,
          disponibilitatMa.esRodades
        );
      }
    }
  }

  //la neteja d'empremtes és per grup de posades
  onNetejarEmpremtes(empremtes: Array<EmpremtaCaptura>) {
    const empremtesPosades = this.datosService.datosVerificacion.huellasCapturadas;

    empremtes
      .filter((x) => x.disponibilitat === Disponibilitat.NORMAL)
      .forEach((element) => {
        /*
        if (element.noEsMatched == true)
          this.empremtaMirall(element.numeroDit, false).noEsMatched = null;
        */
        empremtesPosades[element.numeroDit - 1] = new EmpremtaCaptura(
          element.numeroDit
        );
      });
      this.comprobarDecisionHuellas(); 
  }

  onNetejarEmpremta(empremta: EmpremtaAmbTipus) {
    const empremtesPosades = this.datosService.datosVerificacion.huellasCapturadas;

    if (empremta.empremtaCapturada.disponibilitat === Disponibilitat.NORMAL) {
      empremta.empremtaCapturada.rawImatge = null;
      empremta.empremtaCapturada.imatge = null;
      empremta.empremtaCapturada.esMillor = null;
      empremta.empremtaCapturada.intents = 0;
      empremta.empremtaCapturada.noEsMatched = null;
      empremta.empremtaCapturada.puntuacio = null;
      empremta.empremtaCapturada.resultatCaptura = null;
      empremtesPosades[empremta.empremtaCapturada.numeroDit - 1] = new EmpremtaCaptura(
        empremta.empremtaCapturada.numeroDit
      );
    }
    this.comprobarDecisionHuellas();
  }

  getResultatCapturaQualitat(
    puntuacio: number,
    numeroDit: number,
    esRodada: boolean
  ): ResultatCaptura {
    if (
      puntuacio <
      this.configuracio.parametrosConfiguracion.llindarMalaQualitat
    ) {
      return ResultatCaptura.MALA_QUALITAT;
    } else if (
      (!esRodada &&
        puntuacio <
          this.configuracio.parametrosConfiguracion
            .qualitatMinimaPosades[numeroDit - 1]) 
    ) {
      return ResultatCaptura.BAIXA_QUALITAT;
    } else return ResultatCaptura.CORRECTA;
  }

  // Rep l'array d'empremtes capturades i si són posades o una rodada


  async onAnalitzaEmpremtes(empremtes: EmpremtesAnalitzades) {
    this.missatgeModal.tanca();
    this.missatgeModal.obre(
      new Modal({
        titol: await this.translate.get('modal-analitzar.titol').toPromise(),
        missatge: await this.translate
          .get('modal-analitzar.missatge')
          .toPromise(),
        indicacions: await this.translate
          .get('modal-analitzar.indicacions')
          .toPromise(),
        esProgres: true,
        accions: [],
      })
    );
    const segmentsCaptura: ResultatCapturaEmpremta = await this.enrolamentServei.getSegmentsCaptura(
      empremtes.fpi?.rawImage,
      empremtes.empremtesCapturades,
      empremtes.esRodada
    );


    if (!segmentsCaptura?.segments) {
        this.escanerEmpremtesServei.pita(TipusPitu.Malament);
        this.missatgeModal.tanca();
        this.missatgeModal.obre(
          new Modal({
            titol: await this.translate
              .get('modal-mala-qualitat.titol')
              .toPromise(),
            missatge: await this.translate
              .get('modal-mala-qualitat.missatge')
              .toPromise(),
            indicacions: await this.translate
              .get('modal-mala-qualitat.indicacions')
              .toPromise(),
            esTancable: true,
            icona: ModalIcon.Atencio,
          })
        );
        return;
    }

    segmentsCaptura.segments.forEach(segment => {
      empremtes.empremtesCapturades.filter(e => e.numeroDit == segment.fingerNumber).forEach(r => {
        r.rawImatge = segment.rawImage;
      });
    });

    console.log("Valor LIVENESS", empremtes?.fpi?.liveness);
    console.log("Segment captura", segmentsCaptura);
    if (segmentsCaptura instanceof ResultatCapturaFallida && segmentsCaptura?.motiuFallida == ResultatCaptura.SEGMENTACION_ERRONEA) {
      this.escanerEmpremtesServei.pita(TipusPitu.Malament);
      this.mostraMissatgeErrorCaptura(segmentsCaptura.motiuFallida);
      return;  
    } 
    
    //Control mano incorrecta
    let manoErronea = segmentsCaptura instanceof ResultatCapturaFallida && segmentsCaptura?.motiuFallida == ResultatCaptura.MA_ERRONIA;
    if (manoErronea){
      if (this.missatgeModal != null) this.missatgeModal.tanca();
      if (this.missatgeModalConfirm != null) this.missatgeModalConfirm.tanca();
      let mano = empremtes.empremtesCapturades[0].numeroDit < 6 ? "DERECHA" : "IZQUIERDA";
      if (await this.mostraMissatgeConfirmacio("¡Atención!", "Posible mano incorrecta","Compruebe que el ciudadano ha colocado la mano "+mano+" correctamente. Pulse Aceptar para ignorar el aviso o Repetir para volver a hacer la captura.")){
        //repetir la captura
        this.missatgeModalConfirm.tanca();  
        
        return;
      }
      this.missatgeModalConfirm.tanca();
    }
    //Control liveness
    if (empremtes.fpi.liveness < this.configuracio.parametrosConfiguracion.umbralFingerprintLiveness){
      if (this.missatgeModal != null) this.missatgeModal.tanca();
      if (this.missatgeModalConfirm != null) this.missatgeModalConfirm.tanca();

      if (await this.mostraMissatgeConfirmacio("¡Atención!", "Posible huella falsa","Revise que las huellas que se están utilizando no contengan elementos como pegamento o pintura que dificulten su lectura.","Valor Liveness: " + empremtes?.fpi?.liveness)){
        //repetir la captura
        this.missatgeModalConfirm.tanca();  
        
        return;
      }
      this.missatgeModalConfirm.tanca();
    }
    //Control USK
    if (this.configuracio.parametrosConfiguracion.utilizarUSKDactilar){
      try{
        let fingersQR = new Array<FingerQualityRequest>();
        
        empremtes?.empremtesCapturades?.forEach(empremta => {
          let finger = new FingerQualityRequest();
          finger.colorSpace = 1;
          finger.fingerPosition =  empremta?.numeroDit;
          finger.resolution = 500;
          finger.pixels = empremta?.rawImatge?.data;
          finger.height = empremta?.rawImatge?.height;
          finger.width = empremta?.rawImatge?.width;
          
          fingersQR.push(finger);
        });
        console.log("USK request:", fingersQR);
        let resultadoUsk = await this.uskService.slap(fingersQR);
        
        console.log("Resultado USK dactilar", resultadoUsk);
        let mano = empremtes?.empremtesCapturades?.length > 1 ? ( empremtes?.empremtesCapturades?.some(e=> e.numeroDit == 1 || e.numeroDit == 6) ? " los PULGARES" :
            empremtes?.empremtesCapturades?.some(e=> e.numeroDit < 6) ? " la mano DERECHA" : " la mano IZQUIERDA" ) :
             " " + this.aplicacioServei.obtenirNomDit(empremtes?.empremtesCapturades[0]?.numeroDit, true) ;
        if (resultadoUsk.slapQuality ==="GOOD"){
          let mensaje = new MensajeProceso();
          mensaje.texto = "Se ha superado el control de calidad USK de las huellas de " + mano;
          mensaje.tipo = TipoMensajeProceso.Validacion;
          mensaje.categoria = CategoriaMensaje.USK;
          this.enrolamentServei.añadirError(this.datosService.datosVerificacion, mensaje);
        }
        else{
          if (this.missatgeModal != null) this.missatgeModal.tanca();
          let detall = "Resultado: " + (resultadoUsk.slapQuality === "UNDETERMINED" ? 'Indeterminado' : 'Mala calidad') +
                   ". Puntuación slap: " + resultadoUsk.slapScore + ". Puntuación dedos: " + this.getFingersScore(resultadoUsk.fingersScore);
          this.missatgeModal.tanca();
          this.missatgeModal.obre(
            new Modal({
              titol: this.translate.instant('modal-error-captura.titol'),
              missatge: "Mala calidad USK",
              indicacions: "Repita la captura de "+mano+", ya que no ha superado el control de calidad USK",
              detall: detall,
              esTancable: true,
              icona: ModalIcon.Perill,
            })
          );
          return;
        }
      }
      catch(ex){
        this.missatgeModal.tanca();
        this.missatgeModal.obre(
          new Modal({
            titol: "Error llamada al servicio USK",
              missatge: ex.missatge,
              indicacions: ex.suggeriment,
              detall: ex.intern,
              icona: ModalIcon.Atencio
          })
        );
        return;
      }
    }
    if (segmentsCaptura instanceof ResultatCapturaFallida && segmentsCaptura?.motiuFallida != ResultatCaptura.MA_ERRONIA) {
      this.escanerEmpremtesServei.pita(TipusPitu.Malament);
      this.mostraMissatgeErrorCaptura(segmentsCaptura.motiuFallida);
      
    } 
    else {
     
      var repetides: Array<EmpremtaCaptura>;
      var empremtaRepetida: EmpremtaCaptura = null;

      var segmentsCapturaQualitats = zip(
        segmentsCaptura.segments,
        empremtes.empremtesCapturades
      ).map((segmentCaptura) => {
        const resultatCaptura = this.getResultatCapturaQualitat(
          segmentCaptura[0].nfiq,
          segmentCaptura[1].numeroDit,
          empremtes.esRodada
        );

        return {
          segment: segmentCaptura[0],
          captura: segmentCaptura[1],
          resultatCaptura,
        };
      });

      for (let i = 0; i < segmentsCapturaQualitats.length; i++) {
        const segment = segmentsCapturaQualitats[i].segment;
        const captura = segmentsCapturaQualitats[i].captura;
        const resultatCaptura = segmentsCapturaQualitats[i].resultatCaptura;
        console.log("Resultado Captura huella " + i, resultatCaptura.toString());
        if (resultatCaptura === ResultatCaptura.MALA_QUALITAT) continue;
        /*
        const mirall = this.empremtaMirall(
          captura.numeroDit,
          empremtes.esRodada
        );
        */
        // els dits 5 i 10 no es mira si coincideixen
        
        /*
        if (
          //mirall.disponibilitat === Disponibilitat.NORMAL &&
          //mirall.rawImatge != null &&
          captura.numeroDit != 5 &&
          captura.numeroDit != 10 &&
          this.datosService.datosVerificacion?.huellasCapturadas != undefined &&
          this.datosService.datosVerificacion?.huellasCapturadas?.length > 0
        ) {
          //Comprobar si la huella registrada hace match con la huella capturada
          
          let huellasConImagen = this.datosService.datosVerificacion?.huellasCapturadas.filter(x=> x != undefined && x.rawImatge != undefined); 
          console.log("huellas con imagen", huellasConImagen);
          if (huellasConImagen?.length > 0){
            let huellas = huellasConImagen?.map((em) => em.rawImatge);
            console.log("huellas raw", huellas);
            const [score] = await this.dactilarServei.match(segment.rawImage, huellas);

            const coincideixen =
              score >=
              this.configuracio.parametrosConfiguracion
                .indexComparacioCoincident;

            segmentsCapturaQualitats[i].captura.noEsMatched = !coincideixen;
            if (coincideixen) {
              continue; // No cal que miri si coincideixen altres
            }
          }
        }
        */
        if ( this.configuracio.parametrosConfiguracion.incluirControlDuplicadosMeñique || (captura.numeroDit != 5 && captura.numeroDit != 10)) {
          //si se ha capturado una huella, se debe comparar con todas las demás huellas
          //si se ha capturado un slap, se debe comparar con las huellas que no pertenecen al slap
          const empremtesPerComparar = this.getEmpremtesPerComparar(
            captura.numeroDit, empremtes?.empremtesCapturades
          );
          console.log("empremtes per comparar", empremtesPerComparar);
          const scores = await this.dactilarServei.match(
            segment.rawImage,
            empremtesPerComparar.map((e) => e.rawImatge)
          );
          console.log("scores comparació dit "+ segment.fingerNumber, scores)  
          repetides = zip(empremtesPerComparar, scores)
            .filter(
              (empremtaScore) =>
                empremtaScore[1] >=
                this.configuracio.parametrosConfiguracion
                  .indexComparacioRepetit
            )
            .map((empremtaScore) => empremtaScore[0]);
          console.log("Repetides", repetides);
          if (repetides.length) {
            captura.noEsMatched = null;
            

            empremtaRepetida = captura;
      
            break; // Sortir per la primera repetida
          }
        }
        


      } //fi del bucle

      if (this.missatgeModal != null) this.missatgeModal.tanca();
      if (empremtaRepetida != null) {
        this.escanerEmpremtesServei.pita(TipusPitu.Malament);
        this.mostraMissatgeRepeticio(empremtes, repetides);
        return;
      } else {
        segmentsCapturaQualitats.forEach((scq) => {
          if (
            scq.captura.puntuacio == null ||
            scq.captura.noEsMatched === true ||
            (scq.captura.puntuacio != null &&
              scq.segment.nfiq > scq.captura.puntuacio)
          ) {
            scq.captura.rawImatge = scq.segment.rawImage;
            scq.captura.imatge = scq.segment.embedImage;
            scq.captura.esMillor = scq.captura.puntuacio != null;
            scq.captura.puntuacio = scq.segment.nfiq;
            scq.captura.resultatCaptura = scq.resultatCaptura;
          } else {
            scq.captura.esMillor = false;
          }
          scq.captura.intents++;
          console.log("EMPREMTA CAPTURADA", scq.captura);
          //this.datosService.datosVerificacion.huellasCapturadas.push(scq.captura);
        });
        
        if (
          segmentsCapturaQualitats.some(
            (x) => x.resultatCaptura == ResultatCaptura.MALA_QUALITAT
          )
        ) {

          this.escanerEmpremtesServei.pita(TipusPitu.Malament);
          this.missatgeModal.tanca();
          this.missatgeModal.obre(
            new Modal({
              titol: await this.translate
                .get('modal-mala-qualitat.titol')
                .toPromise(),
              missatge: await this.translate
                .get('modal-mala-qualitat.missatge')
                .toPromise(),
              indicacions: await this.translate
                .get('modal-mala-qualitat.indicacions')
                .toPromise(),
              esTancable: true,
              icona: ModalIcon.Atencio,
            })
          );
        }
       
      }
    }
    this.comprobarDecisionHuellas();

    
  }

  private getFingersScore(fingers: Array<FingerScore>): string{
    let resultado = "[ ";  
    let coma ="";
    fingers.forEach(element => {
      resultado += coma + this.aplicacioServei.obtenirNomDit(element.position, true) + ": " + element.score;   
      coma = ", ";
    });
    return resultado + " ]";
  }

  private comprobarDecisionHuellas(){
    if (!this.datosService.datosVerificacion.decisionesTomadas[Etapa.Dactilar].esDecisionManual){
      if (this.datosService.datosVerificacion.mensajeNoEsNecesarioTomarHuellas != undefined){
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Dactilar].tipo = TipoDecision.ACEPTADO;
      }
      else{
        if (this.haySuficientesHuellasCapturadas(this.datosService.datosVerificacion.huellasCapturadas)){
          if (!this.hayHuellasMalas(this.datosService.datosVerificacion.huellasCapturadas))
            this.datosService.datosVerificacion.decisionesTomadas[Etapa.Dactilar].tipo = TipoDecision.ACEPTADO;
          else
            this.datosService.datosVerificacion.decisionesTomadas[Etapa.Dactilar].tipo = TipoDecision.RECHAZADO;
        }
        else 
          this.datosService.datosVerificacion.decisionesTomadas[Etapa.Dactilar].tipo = TipoDecision.PENDIENTE;
      }
    }
  }

  private haySuficientesHuellasCapturadas(empremtes: Array<EmpremtaCaptura>): boolean{
    console.log("HAY HUELLAS CAPTURADAS", empremtes);
    let huellas =  empremtes?.filter(element => element.rawImatge != undefined && element.disponibilitat == Disponibilitat.NORMAL);
    let huellasNoDisponibles = empremtes?.filter(element => element.disponibilitat != Disponibilitat.NORMAL);
    return huellas?.length + huellasNoDisponibles?.length >= 4;
  }

  private hayHuellasMalas(empremtes: Array<EmpremtaCaptura>): boolean{
    return  empremtes?.some(element => element.resultatCaptura != ResultatCaptura.CORRECTA &&
                                     element.resultatCaptura != ResultatCaptura.BAIXA_QUALITAT &&
                                     element.rawImatge != undefined &&
                                     element.disponibilitat == Disponibilitat.NORMAL);
  }


  mostraMissatgeRepeticio(
    empremtes: EmpremtesAnalitzades,
    repetides: Array<EmpremtaCaptura>
    
  ) {
    const ditRepetit = this.aplicacioServei.obtenirNomDit(
      repetides[0].numeroDit,
      true
    );

    if (empremtes.empremtesCapturades.length == 1) {
      this.missatgeModal.tanca();
      this.missatgeModal.obre(
        new Modal({
          titol: this.translate.instant('modal-repetida.titol'),
          missatge: this.translate.instant('modal-repetida.missatge', {
            dit: ditRepetit.toUpperCase(),
          }),
          indicacions:
            this.translate.instant('modal-repetida.indicacions') +
            ditRepetit.toUpperCase() +
            '.',
          esTancable: true,
          icona: ModalIcon.Perill,
        })
      );
    } else if (empremtes.empremtesCapturades.length > 1) {
      this.missatgeModal.tanca();
      this.missatgeModal.obre(
        new Modal({
          titol: this.translate.instant('modal-repetides.titol'),
          missatge: this.translate.instant('modal-repetides.missatge'),
          indicacions: this.translate.instant('modal-repetides.indicacions'),
          detall:
            this.translate.instant('modal-repetides.detall') +
            ditRepetit.toUpperCase() +
            '.',
          esTancable: true,
          icona: ModalIcon.Perill,
        })
      );
    }
  }

  mostraMissatgeErrorCaptura(motiu: ResultatCaptura, detall?: string) {
    let indicacions = '';
    let missatgeDeError = '';
    if (motiu == ResultatCaptura.POQUES_EMPREMTES) {
      missatgeDeError = this.translate.instant(
        'modal-error-captura.missatge-poques'
      );
      indicacions = this.translate.instant(
        'modal-error-captura.indicacions-poques'
      );
    } else if (motiu == ResultatCaptura.ERRONI) {
      missatgeDeError = this.translate.instant(
        'modal-error-captura.missatge-erroni'
      );
      indicacions = this.translate.instant(
        'modal-error-captura.indicacions-erroni'
      );
    } else if (motiu == ResultatCaptura.MA_ERRONIA) {
      //TODO poner mensaje en recursos idioma
      missatgeDeError = "Mano equivocada"; 
      indicacions = "Revise que la mano utilizada es la correcta";
    }  
    else if (motiu == ResultatCaptura.HUELLA_FALSA) {
        //TODO poner mensaje en recursos idioma
      missatgeDeError = "Alguna de las huellas es falsa"; 
      indicacions = "Revise que las huellas que se están utilizando no contengan elementos como pegamento o pintura que dificulten su lectura";
    }  
    else if (motiu == ResultatCaptura.SEGMENTACION_ERRONEA) {
      //TODO poner mensaje en recursos idioma
      missatgeDeError = "Error a tratar de segmentar las huellas"; 
      indicacions = "Revise que los dedos no están demasiado juntos ni demasiado inclinados y repita la captura";
    }  
    else {
      missatgeDeError = this.translate.instant(
        'modal-error-captura.missatge-altre'
      );
      indicacions = this.translate.instant(
        'modal-error-captura.indicacions-altre'
      );
    }
    this.missatgeModal.tanca();
    this.missatgeModal.obre(
      new Modal({
        titol: this.translate.instant('modal-error-captura.titol'),
        missatge: missatgeDeError,
        indicacions: indicacions,
        detall: detall,
        esTancable: true,
        icona: ModalIcon.Perill,
      })
    );
  }

  //Si retorna true, hay que repetir la acción que ha provocado el mensaje. Si false, acepta y
  async mostraMissatgeConfirmacio(titulo: string, mensaje: string, indicaciones: string, detalle?: string): Promise<boolean> {
    const repetir = new ModalAccio({
      boto: ModalButton.Repetir,
      principal: true,
      esAutofocus: true,
    });
    const aceptar = new ModalAccio({ boto: ModalButton.Acceptar });  
    console.log("Mensaje de confirmación", mensaje);
    let resultado = await this.missatgeModalConfirm.obreAsync(
        new Modal({
          titol: titulo,
          missatge: mensaje,
          indicacions: indicaciones,
          detall: detalle,
          esTancable: false,
          icona: ModalIcon.Atencio,
          accions: [repetir, aceptar], 
          esProgres: false
        })
      );
      console.log("resultado confirmación", resultado);
      return resultado === repetir;
    }

  /*
  empremtaMirall(dit: number, esRodada: boolean): EmpremtaCaptura {
    if (esRodada) return this.empremtesPosades.find((x) => x.numeroDit == dit);
    else return this.empremtesRodades.find((x) => x.numeroDit == dit);
  }
*/
  esCapturada(empremta: EmpremtaCaptura): boolean {
    return (
      empremta.disponibilitat === Disponibilitat.NORMAL &&
      empremta.rawImatge != null
    );
  }

  
  getEmpremtesPerComparar(dit: number, empremtes: EmpremtaCaptura[]): Array<EmpremtaCaptura> {
    const empremtesPosades = this.datosService.datosVerificacion.huellasCapturadas;

      if (empremtes?.length > 1){ //si se ha capturado un slap
        return empremtesPosades
        .filter((x) => x != null) // Que la posada o rodada s'hagi capturat prèviament
        .filter((x) => x.rawImatge != undefined) // Que la posada o rodada s'hagi capturat prèviament
        .filter((x) => x.numeroDit != 5 && x.numeroDit != 10) // No siguin dits petits
        .filter((x) => !empremtes.map((e) => e.numeroDit).includes(x.numeroDit)); // el número de dedo no esté entre los dedos capturados en el slap

      }
      else{
        return empremtesPosades
        .filter((x) => x != null) // Que la posada o rodada s'hagi capturat prèviament
        .filter((x) => x.rawImatge != undefined) // Que la posada o rodada s'hagi capturat prèviament
        .filter((x) => x.numeroDit != 5 && x.numeroDit != 10) // No siguin dits petits
        .filter((x) => x.numeroDit != dit); // No sigui la mirall o la mateixa
      }
  }

  onIncrementarIntents(grup442: GrupEmpremtes) {
    switch (grup442) {
      case GrupEmpremtes.MA_DRETA:
        this.intentsDreta = this.intentsDreta + 1;
        break;
      case GrupEmpremtes.MA_ESQUERRA:
        this.intentsEsquerra = this.intentsEsquerra + 1;
        break;
      case GrupEmpremtes.POLZES:
        this.intentsPolzes = this.intentsPolzes + 1;
    }
  }

  cancelarProceso(): void{
    this.datosService.reiniciarProceso();
  }
}
