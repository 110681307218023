import {
  AfterContentInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { ConfiguracioService, DactilarService, DatosService, Modal, ModalIcon } from 'projects/siepbio-comu/src/public-api';
import { ValidacionesComponent } from '../../components/validaciones/validaciones.component';
import { DecisionTomada, Etapa, TipoDecision } from '../../model/documento';
import { CategoriaMensaje, TipoMensajeProceso } from '../../model/EntryExit';
import { ApartatService } from '../../serveis/apartat.service';
import { IntegrationService } from 'projects/siepbio-comu/src/lib/serveis/integration.service';



@Component({
  selector: 'app-enviament-dades',
  templateUrl: './enviament-dades.component.html',
})
export class EnviamentDadesComponent implements AfterContentInit {
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;
  @ViewChild('validaciones') validacionesModal: ValidacionesComponent;

  modalActual?: NgbModalRef;

  avis: SafeHtml;
  Etapa: typeof Etapa = Etapa;
  TipoDecision: typeof TipoDecision = TipoDecision;
  decisiones:  Array<DecisionTomada>;
  autorizarActivo: boolean = false;
  TipoMensajeProceso: typeof TipoMensajeProceso = TipoMensajeProceso;
  CategoriaMensaje: typeof CategoriaMensaje = CategoriaMensaje;
  constructor(
    private dactilarService: DactilarService,
    private apartatService: ApartatService,
    private translate: TranslateService,
    private configuracio: ConfiguracioService,
    private modalService: NgbModal,
    public datosService: DatosService,
    private integracionService: IntegrationService
  ) {}

  ngAfterContentInit(): void {
    
    if (this.datosService.datosVerificacion?.decisionesTomadas != undefined){
      this.decisiones = new Array<DecisionTomada>();
      this.autorizarActivo = true;
      this.decisiones.push(this.datosService.datosVerificacion?.decisionesTomadas[Etapa.Documento]);
      this.decisiones.push(this.datosService.datosVerificacion?.decisionesTomadas[Etapa.Facial]);
      this.decisiones.push(this.datosService.datosVerificacion?.decisionesTomadas[Etapa.Dactilar]);
      this.decisiones.push(this.datosService.datosVerificacion?.decisionesTomadas[Etapa.Consultas]);
      
      this.autorizarActivo = !this.decisiones.some(element => element.tipo == TipoDecision.RECHAZADO || element.tipo == TipoDecision.PENDIENTE);
    }
  }

  textoAutorizar(): string {
    return "AUTORIZAR " + (this.datosService.sentido == "E"?"ENTRADA":"SALIDA");
  }

  textoRechazar(): string {
    return "RECHAZAR " + (this.datosService.sentido == "E"?"ENTRADA":"SALIDA");
  }

  async generarNists(): Promise<void> {
    
    const dataNist = this.configuracio.getNISTData();
    
    if(!this.datosService.datosVerificacion.nistHuellas && this.datosService.datosVerificacion.huellasCapturadas?.length > 0) {
      const blobNIST = await this.dactilarService.crearNIST(dataNist, this.datosService.datosVerificacion.huellasCapturadas,null);
      this.datosService.datosVerificacion.nistHuellas = await ConfiguracioService.fromBlob( blobNIST );
    }
    
    if(!this.datosService.datosVerificacion.nistFacial && this.datosService.datosVerificacion.fotoCapturada) {
      //eliminamos los campos que sobran del record 1

      //fin eliminamos los campos que sobran del record 1
      const fotoArray = new Array<string>();
      fotoArray.push(this.datosService.datosVerificacion.fotoCapturada);
      const blobNIST = await this.dactilarService.crearNIST(dataNist, null, fotoArray);
      this.datosService.datosVerificacion.nistFacial = await ConfiguracioService.fromBlob( blobNIST );
    }
  }

  async autorizar(): Promise<void> {
    //TODO hay que llamar al servicio de integración de SAVE passándole el datosVerificación
    this.datosService.datosVerificacion.decisionFinal = TipoDecision.ACEPTADO;
    //generamos los ficheros nist, si no estaban ya generados
    await this.generarNists();
    //llamada al guardar
    var res = this.integracionService.grabar(this.datosService.datosVerificacion);
    this.missatgeModal.obre(
      new Modal({
        titol: "Finalización",
        icona: ModalIcon.Informatiu,
        missatge: "La autorización se ha completado correctamente",
        indicacions: "Cierre esta ventana para continuar",
        esTancable: true,
      })
    );
    
    this.datosService.reiniciarProceso();
  }
  async rechazar(): Promise<void>{
    //TODO hay que llamar al servicio de integración de SAVE passándole el datosVerificación
    this.datosService.datosVerificacion.decisionFinal = TipoDecision.RECHAZADO;
    //llamada al guardar
    var res = this.integracionService.grabar(this.datosService.datosVerificacion);
    
    this.missatgeModal.obre(
      new Modal({
        titol: "Finalización",
        icona: ModalIcon.Informatiu,
        missatge: "El rechazo se ha completado correctamente",
        indicacions: "Cierre esta ventana para continuar",
        esTancable: true,
      })
    );

    this.datosService.reiniciarProceso();
  }

  async redirigir(): Promise<void>{
    //TODO hay que llamar al servicio de integración de SAVE passándole el datosVerificación
    this.datosService.datosVerificacion.decisionFinal = TipoDecision.REDIRIGIDO;
    //llamada al guardar
    var res = this.integracionService.grabar(this.datosService.datosVerificacion);
    
    this.missatgeModal.obre(
      new Modal({
        titol: "Finalización",
        icona: ModalIcon.Informatiu,
        missatge: "La redirección se ha completado correctamente",
        indicacions: "Cierre esta ventana para continuar",
        esTancable: true,
      })
    );
    
    this.datosService.reiniciarProceso();
  }

  abrirValidaciones(): void {
    this.validacionesModal.obre([
      CategoriaMensaje.Documento,
      CategoriaMensaje.ResultadoEES,
      CategoriaMensaje.Dactilar,
      CategoriaMensaje.Facial,
      CategoriaMensaje.USK,
      CategoriaMensaje.ConsultaIntegracion,
      CategoriaMensaje.ConsultaPolicial,
      CategoriaMensaje.Envio,
    ]);
  }

  
  abrir(content: any): void {
    this.modalActual = this.modalService.open(content, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
      centered: true,
      scrollable: true
    });
  }

}
