import { Injectable, EventEmitter, Output } from '@angular/core';

// Per canviar-ho a websockets enlloc de SignalR
// import { webSocket, WebSocketSubject } from 'rxjs/webSocket';

import * as signalR from "@microsoft/signalr"

import { Observer, Subscription } from 'rxjs';
import { Fingerprint } from '../model/nist';
import { FingerprintImage, RawImage } from '../model/raw-image';
import { ResultSegment } from '../model/SegmentQualityScore';
import { ConfiguracioService } from './configuracio.service';

@Injectable({
  providedIn: 'root',
})
export class EscanerEmpremtesService {
  @Output() imatgeEnViuEvent = new EventEmitter<RawImage>();
  @Output() imatgeCapturadaEvent = new EventEmitter<FingerprintImage>();
  @Output() dispositiuConnectatEvent = new EventEmitter<boolean>();

  private _connection: signalR.HubConnection
  private subscription: Subscription;
  host: string = "https://localhost:3003";
  constructor(private configuracion: ConfiguracioService) {
    if (configuracion.parametrosConfiguracion.hostServiciosSuprema != undefined)
      this.host = configuracion.parametrosConfiguracion.hostServiciosSuprema;
  }

  async lock(): Promise<boolean> {
    return await this._connection.invoke('lock');
  }

  async connectar(): Promise<boolean> {
    //let options: IConnectionOptions = { hubName: 'EscannerDactilarHub' };
    //let options: IConnectionOptions = { hubName: 'FingerprintScannerHub' };
   
    //this._connection = await this.signalr.connect(options);

      this._connection = new signalR.HubConnectionBuilder()
                              .withUrl(this.host + "/signalr/fingerprint_scanner" )
                              .build();

      try {                              
        this._connection.on("OnPreviewCapture", (dto) => {
          console.log(dto);
        });
        this.escolta('OnPreviewImage', this.imatgeEnViuEvent);
        this.escolta('OnResultImage', this.imatgeCapturadaEvent);
        this.escolta('dispositiuConnectat', this.dispositiuConnectatEvent);

        await this._connection.start();
        console.log('Connection started');
        const result = await this.lock(); 
        
        
        return result;
      }
      catch(err) {
        console.log('Error while starting connection: ' + err);
        return false;
      }
  }

  private escolta<T>(event: string, observer: Observer<T>) {
    this._connection.on(event, (data: T) => {
      observer.next(data);
    });
  }

  async capturar(
    dits: Array<number>,
    esRodada: boolean = false
  ): Promise<void> {
    return await this._connection.invoke('capture', dits, true, esRodada);
  }

  // TODO: si s'aborta la captura, queda la suscripció oberta.
  // Cal fer que abortar captura també envii una resolve(null) i una cancelació
  // de la suscripció
  async capturarAsincron(
    dits: Array<number>,
    esRodada: boolean = false
  ): Promise<FingerprintImage> {
    this.clearSubscription();
    
    return new Promise<FingerprintImage>(async (resolve, reject) => {
      this.clearSubscription();

      this.subscription = this.imatgeCapturadaEvent.subscribe(
        (fpi: FingerprintImage) => {
          this.clearSubscription();
          resolve(fpi);
        }
      );

      await this._connection.invoke('capture', dits, true, esRodada);

    });
  }

  private clearSubscription() {
    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe();
    }
  }

  async calibrar(): Promise<void> {
    await this._connection.invoke('calibrate');
  }

  async abortarCaptura(): Promise<void> {
    this.clearSubscription();

    await this._connection.invoke('abortcapture');
  }

  async acceptarCaptura(): Promise<void> {
    try {
      await this._connection.invoke('accept_capture');
    } catch (ex) {
      if (!this.subscription.closed) this.imatgeCapturadaEvent.emit(null);
      throw ex;
    }
  }

  async unlock(): Promise<void> {
    await this._connection.invoke('unlock');
  }

  async pita(mode: TipusPitu): Promise<void> {
    await this._connection.invoke('beep', mode);
  }

  async segmentar(
    captura: RawImage,
    fingers: Array<number>
  ): Promise<ResultSegment> {
    try{
      return await this._connection.invoke('Segmentar', captura, fingers);
    }
    catch(ex){
      console.log("Error segmentar", ex);
    }
  }

}

export enum TipusPitu {
  Ok,
  Malament,
}
