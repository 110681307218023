import { Component, ViewChild, ViewChildren, QueryList } from "@angular/core";

import { EnviamentDadesComponent } from "./pestanyes/enviament-dades/enviament-dades.component";
import { InicialitzacioComponent } from "./pagines/inicialitzacio/inicialitzacio.component";
import { ApartatService } from "./serveis/apartat.service";
import { ContexteService } from "./serveis/contexte.service";
import { Pagina, Pestanya } from "./model/pagina";
import { EnrolamentComponent } from "./pagines/enrolament/enrolament.component";
import { Excepcio } from "projects/siepbio-comu/src/public-api";
import { TranslateService } from "@ngx-translate/core";
import { EstadoToken } from "./model/documento";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  Pagina: typeof Pagina = Pagina;
  Pestanya: typeof Pestanya = Pestanya;
  title: string;
  pagina: Pagina;
  active = 1;

  estadoToken: EstadoToken = EstadoToken.INEXISTENTE;

  @ViewChildren(InicialitzacioComponent)
  inicialitzacio: QueryList<InicialitzacioComponent>;
  @ViewChildren(EnrolamentComponent)
  enrolamentPosades: QueryList<EnrolamentComponent>;

  paginaActual: Pagina = Pagina.Inicialitzacio;

  constructor(
    private apartatServei: ApartatService,
    private contexteServei: ContexteService,
    translate: TranslateService
  ) {
    translate.setDefaultLang("es");
    translate.use("es");
    this.apartatServei.inicialitzacioEvent.subscribe(() => {
      this.paginaActual = Pagina.Inicialitzacio;
    });

    this.apartatServei.enrolamentEvent.subscribe(() => {
      this.paginaActual = Pagina.Enrolament;
    });

    this.apartatServei.loginEvent.subscribe(() => {
      this.paginaActual = Pagina.Login;
    });

  }

}
