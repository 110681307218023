<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ model.titol }}</h4>
    <button
      *ngIf="model.esTancable"
      type="button"
      class="btn  btn-danger"
      aria-label="Cerrar"
      (click)="modal.dismiss()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-{{ model.icona || model.esProgres ? 2 : 0 }}">
        <div *ngIf="model.icona == ModalIcon.Informatiu" class="text-info">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="currentColor"
            class="bi bi-info-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
            />
          </svg>
        </div>
        <div *ngIf="model.icona == ModalIcon.Correcte" class="text-success">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="currentColor"
            class="bi bi-check-circle"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            />
            <path
              d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
            />
          </svg>
        </div>
        <div *ngIf="model.icona == ModalIcon.Atencio" class="text-warning">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="currentColor"
            class="bi bi-exclamation-triangle"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
            />
            <path
              d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
            />
          </svg>
        </div>
        <div *ngIf="model.icona == ModalIcon.Perill" class="text-danger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="64"
            fill="currentColor"
            class="bi bi-exclamation-triangle"
            viewBox="0 0 16 16"
          >
            <path
              d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"
            />
            <path
              d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995z"
            />
          </svg>
        </div>
        <div *ngIf="model.esProgres">
          <div class="spinner-border" role="status">
            <span class="sr-only">Treballant</span>
          </div>
        </div>
      </div>
      <div class="col-md-{{ model.icona || model.esProgres ? 10 : 12 }}">
        <h5>{{ model.missatge }}</h5>

        <p>{{ model.indicacions }}</p>

        <a
          href="#"
          *ngIf="model.detall || model.llistaDetalls"
          data-toggle="collapse"
          data-target="#missatgeDetall"
        >
          {{ "comu.detalls" | translate }}
        </a>

        <div class="collapse" *ngIf="model.detall || model.llistaDetalls" id="missatgeDetall">
          <div class="card mt-3">
            <div *ngIf="model.detall" class="card-body">
              {{ model.detall }}
            </div>
            <div *ngIf="model.llistaDetalls" class="card-body">
              <div *ngFor="let item of model.llistaDetalls | keyvalue">
                 <b>{{ item.key }}</b>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  <div *ngIf="model.accions && model.accions.length" class="modal-footer">
    <button
      *ngFor="let accio of model.accions"
      [attr.ngbAutofocus]="accio.esAutofocus"
      type="button"
      class="btn btn-{{ accio.principal ? 'info' : 'warning' }}"
      (click)="onClick(accio)"
    >
      {{ texteBoto(accio) }}
    </button>
  </div>
</ng-template>
