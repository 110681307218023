import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApartatService {
  @Output() inicialitzacioEvent = new EventEmitter();
  @Output() enrolamentEvent = new EventEmitter();
  @Output() finalitzacioEvent = new EventEmitter();
  @Output() loginEvent = new EventEmitter();
  @Output() logoutEvent = new EventEmitter();

  @Output() canviarPestanyaEvent = new EventEmitter<number>();
  @Output() canviarPestanyaDocEvent = new EventEmitter<number>();
  constructor() {}
}
