<div class="container-fluid p-4">
  
  

</div>

<ng-template #contentFoto let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      Foto ampliada
    </h4>
    <button
      type="button"
      class="btn  btn-danger"
      aria-label="Cerrar"
      (click)="modal.dismiss('')"
      title="{{ 'comu.accio.tancar' | translate }}"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row text-center">
      <div class="mx-auto">
        <img
          style="object-fit: contain"
          [src]="getFoto()"
        />
      </div>
    </div>
  </div>
</ng-template>

<lib-missatge-modal #missatgeModal></lib-missatge-modal>