<ng-template #modal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
       Validaciones
      </h4>
      <button
        type="button"
        class="btn  btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
        <div class="p-2">
            <app-validacion-grupo *ngFor="let categoria of categorias" [categoria]="categoria"></app-validacion-grupo>
            <!--
            <app-validacion-grupo titulo="Documento" [mensajes]="mensajes(CategoriaMensaje.Documento)"></app-validacion-grupo>
            <app-validacion-grupo titulo="EES" [mensajes]="mensajes(CategoriaMensage.ResultadoEES)"></app-validacion-grupo>
            -->
        </div>
    </div>
</ng-template>