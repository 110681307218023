import { SegmentQualityScore } from 'projects/siepbio-comu/src/public-api';
import { ResultatCaptura } from './resultat-captura';

export class ResultatCapturaEmpremta {
  constructor(
    public segments: SegmentQualityScore[],
    public esRodada: boolean
  ) {}
}

export class ResultatCapturaExitosa extends ResultatCapturaEmpremta {
  constructor(
    public segments: SegmentQualityScore[],
    esRodada: boolean,
    public scores: number[]
  ) {
    super(segments, esRodada);
  }
}

export class ResultatCapturaFallida extends ResultatCapturaEmpremta {
  constructor(
    public segments: SegmentQualityScore[],
    esRodada: boolean,
    public motiuFallida: ResultatCaptura
  ) {
    super(segments, esRodada);
  }
}
