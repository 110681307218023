<div class="container-fluid mt-4">
  <div class="row">
    <div class="col-sm-12">
      <div class="card card-secondary card-tabs">
        <div class="card-header p-0 pt-1">
          <span class="float-right mx-1 mt-2"><button class="btn btn-danger btn-lg" (click)="cancelarProceso()" role="button" type="button" >Cancelar</button></span>
          <ul ngbNav #nav1="ngbNav" [(activeId)]="active" class="nav nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink class="text-center"
                ><fa-icon [icon]="iconDocumento" size="2x"  ></fa-icon><div>Documento</div></a
              > 
              <ng-template ngbNavContent>
                <app-doc-mrz 
        
                ></app-doc-mrz>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink class="text-center"
                ><fa-icon [icon]="iconFacial" size="2x" ></fa-icon> <div>Facial</div></a
              >
              <ng-template ngbNavContent>
                <app-facial
                
                ></app-facial>
              </ng-template>
            </li>
            <li [ngbNavItem]="3" [disabled]="deshabilitar">
              <a ngbNavLink class="text-center {{deshabilitar? 'linkDisabled' : ''}}"
                ><fa-icon [icon]="iconDactilar" size="2x" ></fa-icon>
                <div>Dactilar</div></a
              >
              <ng-template ngbNavContent>
                <app-enrolament-empremtes
                  (onSeleccionatDisponibilitat)="onSeleccionatDisponibilitat($event)"
                  (onSeleccionatDisponibilitatDit)="onSeleccionatDisponibilitatDit($event)"
                  (onIncrementarIntents)="onIncrementarIntents($event)"
                  (onNetejarEmpremtes)="onNetejarEmpremtes($event)"
                  (onNetejarEmpremta)="onNetejarEmpremta($event)"
                  (onAnalitzaEmpremtes)="onAnalitzaEmpremtes($event)"
                
                  [intentsDreta]="intentsDreta"
                  [intentsEsquerra]="intentsEsquerra"
                  [intentsPolzes]="intentsPolzes"
                  [esRodada]="false"
                ></app-enrolament-empremtes>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink class="text-center"
                ><fa-icon [icon]="iconConsultas" size="2x" ></fa-icon>
                <div>Consultas</div></a
              >
              <ng-template ngbNavContent>
                <app-consultas
                
                ></app-consultas>
              </ng-template>
            </li>
            <li [ngbNavItem]="5">
              <a ngbNavLink class="text-center"
                ><fa-icon [icon]="iconEnviar" size="2x" ></fa-icon>
                <div>Validar</div></a
              >
              <ng-template ngbNavContent>
                <app-enviament-dades
                  [empremtesPosades]="empremtesPosades"
                  [intentsDreta]="intentsDreta"
                  [intentsEsquerra]="intentsEsquerra"
                  [intentsPolzes]="intentsPolzes"
                
                  (onEnviarDades)="onEnviarDades($event)"
                
                ></app-enviament-dades>
              </ng-template>
            </li>
          </ul>
          
        </div>
        <div class="card-body">
          <div [ngbNavOutlet]="nav1"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<lib-missatge-modal #missatgeModal></lib-missatge-modal>
<lib-missatge-modal #missatgeModalConfirm></lib-missatge-modal>
