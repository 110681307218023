<div class="container-fluid">
    <div class="row my-1">
        <div class="col-sm-12 ">
            <app-barra-acciones [accion]="Etapa.Documento">
                <button class="btn btn-primary btn-lg mr-1" type="button" (click)="leerDatos($event)"> Leer Documento</button> 
                <button class="btn btn-secondary btn-lg " type="button" (click)="openVizModal()"> VIZ</button>
            </app-barra-acciones>
        </div>
    </div>

    <div>
        <div class="row">
            <div *ngIf="this.datosService.datosVerificacion?.documentoLeido != undefined" class="col-sm-7">
                <div class="card card-default">
                    <div class="card-header">
                        <h4 class="card-title">Documento</h4>
                        <div class="card-tools">
                            <a href="#" (click)="abrirValidaciones([CategoriaMensaje.Documento])">
                                <fa-icon *ngIf="datosService.datosVerificacion.resultadoDocumento == true" [icon]="iconValido" size="2x" class="text-success" ></fa-icon><fa-icon *ngIf="datosService.datosVerificacion.resultadoDocumento == false" [icon]="iconNoValido" size="2x" class="text-danger" ></fa-icon>
                            </a>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-4 h6">Nombre</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.nombre}} {{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.apellidos}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Personal ID </div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.idPersonal}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Número de documento</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.numeroDocumento}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Tipo documento</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.tipoDocumento}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">País de expedición</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.paisExpedidor}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Fecha caducidad</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.fechaCaducidad}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Fecha Expedición</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.fechaExpedicion}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Sexo</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.sexo}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Nacionalidad</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.nacionalidad}}</div>
                        </div>
                        <div class="row">
                            <div class="col-sm-4 h6">Fecha de nacimiento</div>
                            <div class="col-sm-8">{{this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.fechaNacimiento}}</div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-5">
                <div *ngIf="datosService.datosVerificacion.resultadoSearch != undefined" class="card card-default">
                    <div class="card-header">
                        <h5 class="card-title">Registros en EES</h5>
                        <div class="card-tools">
                            <a href="#" (click)="abrirValidaciones([CategoriaMensaje.ResultadoEES])">
                                <fa-icon *ngIf="datosService.datosVerificacion?.resultadoConsultaEES == true" [icon]="iconValido" size="2x" class="text-success" ></fa-icon><fa-icon *ngIf="datosService.datosVerificacion?.resultadoConsultaEES == false" [icon]="iconNoValido" size="2x" class="text-danger" ></fa-icon>
                            </a>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul *ngIf="datosService.datosVerificacion.resultadoSearch?.encontrado" class="nav nav-pills flex-column">
                            <li class="nav-item" *ngFor="let record of datosService.datosVerificacion?.resultadoSearch?.registros; index as i">
                                <div class="row">
                                    <div class="col-sm-3 "><fa-icon *ngIf="record?.resultado?.autorizado" [icon]="iconValido" size="3x" [style]="{'stroke':'green', 'color':'green'}"></fa-icon><fa-icon *ngIf="!record?.resultado?.autorizado" [icon]="iconAlerta" size="3x" [style]="{'stroke':'red', 'color':'red'}"></fa-icon></div>
                                    <div class="col-sm-6">
                                        <div class="h6">{{ record?.documento?.nombre }} {{ record?.documento?.apellidos }}</div>
                                        <div class="h6">{{ record?.documento?.idPersonal }}</div>
                                        <div class="h6">{{ record?.documento?.fechaCaducidad  }}</div>
                                        <div *ngIf="!record?.resultado?.autorizado" class="alert alert-danger py-1">{{record?.resultado?.motivoNoAutorizado}}</div>
                                    </div>
                                    <div class="col-sm-3">
                                        <button type="button" class="btn btn-primary w-100 mb-1" title="Aplicar" (click)="aplicarDatos(record)"><fa-icon [icon]="iconAplicar"></fa-icon></button>
                                        <button class="btn btn-info w-100" title="Ver más" type="button" (click)="onClickRegistro(record, contentRegistro)"><fa-icon [icon]="iconMas"></fa-icon></button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div *ngIf="datosService.datosVerificacion.resultadoSearch?.encontrado == false" class="alert alert-success">No encontrado en el EES</div>
                    </div>

                </div>
                <div  *ngIf="datosService.datosVerificacion.fotoImpresa != undefined" class="card card-default">
                    <div class="card-header">
                        <h5 class="card-title">VIZ</h5>
                        
                    </div>
                    <div class="card-body">
                        <div class="row justify-content-center">
                            <div class="col-sm-6 h5">
                                <a  href="javascript:void(0)" (click)="ampliarFoto(contentFoto,datosService.datosVerificacion?.fotoImpresa)"
                                    title="ampliar foto">
                                    <img class="img-fluid shadow border border-secondary rounded" style="object-fit: contain"
                                        [src]="getFoto(datosService.datosVerificacion?.fotoImpresa)" alt="Foto Viz">
                                    <div class="ribbon-wrapper ribbon-lg" *ngIf="datosService.datosVerificacion?.fotoImpresa != undefined">
                                        <div class="ribbon bg-{{datosService.datosVerificacion?.coincideVizVsChip ? 'success': 'danger'}}">
                                            {{datosService.datosVerificacion?.scoreImpresaVsChip}}
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #contentFoto let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Foto ampliada
      </h4>
      <button
        type="button"
        class="btn  btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="row text-center">
        <div class="mx-auto">
          <img
            style="object-fit: contain"
            [src]="fotoAmpliada"
          />
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #contentRegistro let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Registro de {{resultadoEESSeleccionado?.documento?.nombre}} {{resultadoEESSeleccionado?.documento?.apellidos}}
      </h4>
      <button
        type="button"
        class="btn btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
        <div class="row {{autorizado(resultadoEESSeleccionado)}}">
            <div class="col-sm-2">
                <a
                href="javascript:void(0)"
                (click)="ampliarFoto(contentFoto, resultadoEESSeleccionado?.foto)"
                title="ampliar foto"
              >
                <img class="img-fluid shadow border border-secondary rounded" style="object-fit: contain"
                [src]="getFoto(resultadoEESSeleccionado?.foto)"
                alt="Foto">
                </a>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <strong>Tipo de documento</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.tipoDocumento}}</div>
                    
                </div>
                <div class="form-group">
                    <strong>País de expedición</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.paisExpedidor}}</div>
                </div>
                <div *ngIf="!resultadoEESSeleccionado?.resultado?.autorizado" class="form-group ">
                    <div class="h4">NO AUTORIZADO</div>
                </div>
    
            </div>
            <div class="col-sm-4">
                <div class="form-group">
                    <strong>Número de documento</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.numeroDocumento}}</div>
                </div>
                <div class="form-group">
                    <strong>Fecha caducidad</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.fechaCaducidad}}</div>
                </div>
                <div *ngIf="!resultadoEESSeleccionado?.resultado?.autorizado" class="form-group">
                    <strong>Motivo</strong>
                    <div>Motivo{{resultadoEESSeleccionado?.resultado?.motivoNoAutorizado}}</div>
                </div>
                
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <strong>Personal ID</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.idPersonal}}</div>
                    
                </div>
                <div class="form-group">
                    <strong>Fecha de expedición</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.fechaExpedicion}}</div>
                </div>
            </div>

        </div>
        <div class ="row mt-4">
            <div class="col-sm-4">
                <div class="form-group">
                    <strong>Nombre completo</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.nombre}} {{resultadoEESSeleccionado?.documento?.apellidos}}</div>
                </div>
            </div>
            <div class="col-sm-1">
                <div class="form-group">
                    <strong>Sexo</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.sexo}}</div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="form-group">
                    <strong>Nacionalidad</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.nacionalidad}}</div>
                </div>
            </div>
            <div class="col-sm-2">
                <div class="form-group">
                    <strong>F. nacimiento</strong>
                    <div>{{resultadoEESSeleccionado?.documento?.fechaNacimiento}}</div>
                </div>
            </div>
            <div class="col-sm-2">
                <button class="btn btn-secondary" type="button" data-toggle="collapse" data-target="#masDatos" aria-expanded="false" aria-controls="masDatos">Más datos</button>
            </div>
        </div>
        <div class="row collapse" id="masDatos">
            <div class="card card-body">
                Más datos del registro a mostrar. Aún no se han especificado
            </div>
        </div>
    </div>
  </ng-template>

    
  <app-passport-scanner
    *ngIf="showVizModal"
    (capture)="onFotoCapturada($event)"
    (closed)="closeVizModal()"
  ></app-passport-scanner>

  <app-validaciones #validaciones></app-validaciones>

  <lib-missatge-modal #missatgeModal></lib-missatge-modal>
