import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, FormControl, Validators } from '@angular/forms';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';


import { Autenticacion, AutenticacionService, AutenticacionSP, ConfiguracioService, DatosService, Frontera, Modal, ParametrosConfiguracion } from 'projects/siepbio-comu/src/public-api';
import { ApartatService } from '../../serveis/apartat.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;

  public loginForm: FormGroup;
  submitted = false;
  errorLogin?: string;
  constructor(
    private formBuilder: FormBuilder,
    private configService: ConfiguracioService,
    private autenticacionService: AutenticacionService,
    private apartatServei: ApartatService,
    private renderer: Renderer2,
    public datosService: DatosService
  ) {
    this.cargarConfiguración();

    this.apartatServei.logoutEvent.subscribe(() => {
      this.loginForm.reset();
    });
   }

   ngAfterViewInit(): void {
    this.renderer.selectRootElement('#usuario').focus();
    console.log('after view init login');
  }

  ngOnInit(): void {
  }

  cargarConfiguración():void{
    
    this.loginForm = this.formBuilder.group({
      usuario: [null,[Validators.required]],
      clave: [null,[Validators.required]],
      password: [null, [Validators.required]],
      sentidoMarcha: this.configService.parametrosConfiguracion.fronteraSentido,
     });
     
  }

  get f(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }

  onSubmit(): void{
    this.submitted = true;
    if (this.loginForm.invalid) {
      this.errorLogin = this.loginForm.errors[0];
      return;
    }
    else if (this.loginForm.valid){
      this.entrar();
    }
  }

  async entrar(): Promise<void> {
    this.missatgeModal.tanca();
    this.missatgeModal.obre(
      new Modal({
        titol: "Autorización",
        missatge:"Validando petición de acceso",
        indicacions: "Espere un momento, por favor",
        esProgres: true,
        accions: [],
      })
    );


    let usuario =  this.loginForm.get("usuario")?.value;
  
    let contraseña =  this.loginForm.get("password")?.value;
    let clave =  this.loginForm.get("clave")?.value;
    let sentido =  this.loginForm.get("sentidoMarcha")?.value;
    try{
      let autenticacionSP = new AutenticacionSP();
      let parametros =this.configService.parametrosConfiguracion;
      autenticacionSP.Operacion = parametros.loginOperacion;
      let autenticacion = new Autenticacion();
      autenticacion.Clave = clave;
      autenticacion.AplicacionCNP = parametros.autenticacionAplicacionCNP;
      autenticacion.DireccionIp = parametros.autenticacionDireccionIP;
      autenticacion.LiteralSistema = parametros.autenticacionLiteralSistema;
      autenticacion.Usuario = usuario;
      autenticacion.Pass = contraseña;
      autenticacionSP.Autenticacion = autenticacion;
      let frontera = new Frontera();
      frontera.IdUbicacionDispositivo = parametros.fronteraUbicacionDispositivo;
      frontera.Sentido = sentido=='Entrada'?'E':'S';
      autenticacionSP.Frontera = frontera;
      this.autenticacionService.host = parametros.hostServiciosPoliciales;
      console.log("Parametros para el login", autenticacionSP);
      let resultado = await this.autenticacionService.login(autenticacionSP);
      console.log("resultado login", resultado);
      this.errorLogin = null;

      this.datosService.sentido = frontera.Sentido;
      this.datosService.usuario = usuario;
      this.datosService.literal = autenticacion.LiteralSistema;
      this.datosService.usuarioAutenticado = resultado;
      this.apartatServei.enrolamentEvent.emit();
      this.missatgeModal.tanca();
    }
    catch(ex){
      this.missatgeModal.tanca();
      this.errorLogin = ex.intern?? ex.missatge;
    }
  }

  //TODO quietar este método. Es solo para hacer pruebas
  cargarDatosPruebas(): void{
    this.loginForm = this.formBuilder.group({
      usuario: ["77799906Z",[Validators.required]],
      clave: ["1234.Abc",[Validators.required]],
      password: ["1234.Abc", [Validators.required]],
      sentidoMarcha: this.configService.parametrosConfiguracion.fronteraSentido,
     });
  }
}
