import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  ConfiguracioService,
  EscanerEmpremtesService,
} from 'projects/siepbio-comu/src/public-api';
import { CalibrarEscanerEmpremtesComponent } from 'projects/siepbio-comu/src/lib/components/calibrar-escaner-empremtes/calibrar-escaner-empremtes.component';
import { GrupEmpremtes } from '../../model/grup-empremtes';
import { Disponibilitat } from '../../model/disponibilitat';
import {
  EmpremtaAmbTipus,
  EmpremtaCaptura,
  EmpremtesAnalitzades,
} from '../../model/empremta-captura';

import { DisponibilitatMa } from '../../model/disponibilitat-ma';
import { TranslateService } from '@ngx-translate/core';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-grup-dits',
  templateUrl: './grup-dits.component.html',
  styleUrls: ['./grup-dits.component.css'],
  providers: [NgbModal],
})
export class GrupDitsComponent {
  @ViewChild('calibrar') calibrarComponent: CalibrarEscanerEmpremtesComponent;
  @Output('onSeleccionatDisponibilitat')
  onSeleccionatDisponibilitat = new EventEmitter<DisponibilitatMa>();
  @Output('onSeleccionatDisponibilitatDit')
  onSeleccionatDisponibilitatDitPare = new EventEmitter<EmpremtaAmbTipus>();

  @Output('onNetejarEmpremtes')
  onNetejarEmpremtes = new EventEmitter<Array<EmpremtaCaptura>>();
  @Output('onNetejarEmpremta')
  onNetejarEmpremtaPare = new EventEmitter<EmpremtaAmbTipus>();
  @Output('onIncrementarIntents')
  onIncrementarIntents = new EventEmitter<GrupEmpremtes>();
  @Output('onAnalitzaEmpremtes')
  onAnalitzaEmpremtesGrup = new EventEmitter<EmpremtesAnalitzades>();

  @Input() numeroIntent: number;
  @Input() esRodada: boolean;
  @Input() dits: Array<EmpremtaCaptura>;
  GrupEmpremtes: typeof GrupEmpremtes = GrupEmpremtes;
  @Input() grupEmpremtes: GrupEmpremtes;

  numeroMinimIntents: number;
  Disponibilitat: typeof Disponibilitat = Disponibilitat;
  escanejant: boolean = false;
  iconDelete = faTrash;
  constructor(
    private modalService: NgbModal,
    private escanerServei: EscanerEmpremtesService,
    private translate: TranslateService,
    private configuracio: ConfiguracioService
  ) {
    this.numeroMinimIntents = this.configuracio.parametrosConfiguracion.intentsMinimCaptura;
  }

  teAlgunaPuntuacio() {
    for (let c = 0; c < this.dits.length; c++) {
      if (this.dits[c].puntuacio != null) return true;
    }
    return false;
  }

  getDitsNum(): Array<number> {
    return this.dits
      .filter((x) => x.disponibilitat == Disponibilitat.NORMAL)
      .map((i) => i.numeroDit);
  }

  obtenirDits(n): EmpremtaCaptura {
    if (this.dits) return this.dits[n];
    else return null;
  }

  seleccionarDisponibilitat(disponibilitat: Disponibilitat) {
    let disponibilitatMa = new DisponibilitatMa(
      disponibilitat,
      this.grupEmpremtes,
      this.esRodada
    );
    this.onSeleccionatDisponibilitat.emit(disponibilitatMa);
  }

  amagarBotonsGrup(): boolean {
    let ditsDisponibles = 0;
    this.dits.forEach((element) => {
      if (element.disponibilitat == Disponibilitat.NORMAL) ditsDisponibles++;
    });
    return ditsDisponibles < 2;
  }

  onSeleccionatDisponibilitatDit(empremta: EmpremtaAmbTipus) {
    this.onSeleccionatDisponibilitatDitPare.emit(empremta);
  }

  onNetejarEmpremta(empremta: EmpremtaAmbTipus) {
    this.onNetejarEmpremtaPare.emit(empremta);
  }

  netejarDits() {
    this.onNetejarEmpremtes.emit(this.dits);
  }

  async capturar(content) {
    this.modalService
      .open(content, { size: 'xl', backdrop: 'static' })
      .result.then(
        (result) => {},
        (reason) => {
          if (reason != undefined) this.cancelar();
        }
      );

    this.escanejant = true;
    const fingerprintImage = await this.escanerServei.capturarAsincron(this.getDitsNum());

    this.escanejant = false;

    if (this.modalService.hasOpenModals) {
      this.modalService.dismissAll();
    }

    if (fingerprintImage?.rawImage) {
      this.onAnalitzaEmpremtesGrup.emit(
        new EmpremtesAnalitzades(
          this.dits.filter((x) => x.disponibilitat == Disponibilitat.NORMAL),
          false,
          fingerprintImage
        )
      );
      this.onIncrementarIntents.emit(this.grupEmpremtes);
    }
  }

  obtenirNomGrup442() {
    switch (this.grupEmpremtes) {
      case GrupEmpremtes.MA_DRETA:
        return this.translate.instant('grup-dits.ma-dreta');
      case GrupEmpremtes.MA_ESQUERRA:
        return this.translate.instant('grup-dits.ma-esquerra');
      case GrupEmpremtes.POLZES:
        return this.translate.instant('grup-dits.polzes');
    }
  }

  obtenirTitol() {
    let tpart1 = this.translate.instant('grup-dits.titol-modal.poseu-dits');
    let tpart2 = this.translate.instant('grup-dits.titol-modal.de-la-ma');
    let tma = this.translate.instant('grup-dits.titol-modal.esquerra');
    if (this.grupEmpremtes == GrupEmpremtes.POLZES) {
      tpart2 = this.translate.instant('grup-dits.titol-modal.polzes');
      tma = '';
    }
    let tpart4 = this.translate.instant('grup-dits.titol-modal.sobre-lector');
    let tpartfin = '';
    if (this.grupEmpremtes == GrupEmpremtes.MA_DRETA)
      tma = this.translate.instant('grup-dits.titol-modal.dreta');
    return tpart1 + tpart2 + tma + tpart4 + tpartfin;
  }

  cancelar() {
    this.escanejant = false;
    this.escanerServei.abortarCaptura();
    this.modalService.dismissAll();
  }

  async calibrarEscaner() {
    this.escanejant = false;
    await this.escanerServei.abortarCaptura();

    await this.calibrarComponent.calibrar();

    await this.escanerServei.capturar(this.getDitsNum());
    this.escanejant = true;
  }

  async onAnalitzaEmpremta(empremta: EmpremtesAnalitzades) {
    this.onAnalitzaEmpremtesGrup.emit(empremta);
  }
}
