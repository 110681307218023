<div>
  <img  alt="Dirección General de Policía" class="img-fluid w-100"  src="assets/img/logopolicia.png" />
    <nav class="navbar navbar-expand-md px-0">
        <ul class="navbar-nav navbar-no-expand ">
          <li class="nav-item dropdown">
            <a class="nav-link p-1 pr-2" href="#" role="button" data-toggle="dropdown" aria-expanded="false" >
              <fa-icon [icon]="faBars" size="2x" ></fa-icon> 
            </a>  
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-left" >
              <span *ngIf="datosService.usuario != undefined" class="dropdown-item">
                <fa-icon [icon]="faUser" ></fa-icon> {{datosService.usuario}}
              </span>
              
              <div *ngIf="datosService.usuario != undefined" class="dropdown-divider"></div>
              
              <a 
                *ngIf="datosService.usuarioAutenticado" 
                class="dropdown-item" 
                href="javascript:void(0)"
                (click)="abrirValidaciones()"
                title="abrir configuración">Validaciones</a>

              <a 
                class="dropdown-item" 
                href="javascript:void(0)"
                (click)="abrir(configPanel,'lg',true)"
                title="abrir configuración">Configuración</a>

              <a 
                *ngIf="datosService.usuarioAutenticado" 
                class="dropdown-item" 
                href="javascript:void(0)"
                (click)="cerrarSesion()"
                title="abrir configuración">Cerrar sesión</a>

              <div class="dropdown-divider"></div>
            
              <a 
                class="dropdown-item" 
                href="javascript:void(0)"
                (click)="abrir(aboutPanel,'md',false)"
                title="abrir configuración">Acerca de</a>
            </div>  
          </li>
          <span class="navbar-text h5"> Registro EES </span>
        </ul>
    </nav>
    <hr  *ngIf="datosService.resumenDatosResolucion?.foto">
    <div *ngIf="datosService.resumenDatosResolucion?.foto" class="row justify-content-center">
      <div class="col-sm-8">
        <a
        href="javascript:void(0)"
        (click)="ampliarFoto(contentFoto,datosService.resumenDatosResolucion?.foto)"
        title="ampliar foto"
      >
        <img class="img-fluid shadow border border-secondary rounded" style="object-fit: contain"
            [src]="getFoto(datosService.resumenDatosResolucion?.foto)"  alt="Foto">
        </a>
        </div>
    </div>
    <hr *ngIf="datosService.resumenDatosResolucion?.nombre">
    <div  *ngIf="datosService.resumenDatosResolucion?.nombre" class="row mt-2">
      <div class="card col-sm-12">
        <div class="card-body">
          <div class="h6">{{datosService.resumenDatosResolucion?.nombre}} {{datosService.resumenDatosResolucion?.apellidos}}</div>
          <div class="h6">{{datosService.resumenDatosResolucion?.numeroDocumento}}</div>
          <div class="h6">{{datosService.resumenDatosResolucion?.fechaNacimiento}} {{datosService.resumenDatosResolucion?.edad}}</div>
          <div class="h6">{{datosService.resumenDatosResolucion?.nacionalidad}}</div>
        </div>
      </div>
    </div>
    <div  *ngIf="datosService.resumenDatosResolucion?.estadoResolucion != undefined" class="row">
      <div class="card  col-sm-12">
        <div class="card-body">
          <div class="h6">{{datosService.resumenDatosResolucion?.estadoResolucion?.fechaMovimiento}} {{datosService.resumenDatosResolucion?.estadoResolucion?.codigoPais}} {{datosService.resumenDatosResolucion?.estadoResolucion?.sentido}} </div>
          <div *ngIf="autorizado != ''">
            <div class="h6 alert alert-{{colorAutorizado}}"><fa-icon [icon]="faResultado"  ></fa-icon> {{getAutorizado()}}</div>  
            <div *ngIf="!datosService.resumenDatosResolucion?.estadoResolucion?.autorizado" class="h6">{{datosService.resumenDatosResolucion?.estadoResolucion?.motivo}}</div>
          </div>
          <div class="h6">{{datosService.resumenDatosResolucion?.estadoResolucion?.diasEstancia}}</div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #configPanel let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
       Parámetros de Configuración
      </h4>
      <button
        type="button"
        class="btn  btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="p-1">
        <form [formGroup]="configForm" id="formConfig" (ngSubmit)="onSubmit()">
          <div class="row justify-content-center">
              <div class="col-sm-12 mx-auto">
                  <nav class="my-2">
                    <ul class="nav nav-tabs nav-fills" id="myTab" role="tablist">
                      <li class="nav-item">
                          <a class="nav-link active" id="dactilar-tab" data-toggle="tab" href="#dactilar" role="tab" aria-controls="dactilar" aria-selected="true">Dactilar</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" id="facial-tab" data-toggle="tab" href="#facial" role="tab" aria-controls="facial" aria-selected="false">Facial</a>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" id="document-tab" data-toggle="tab" href="#document" role="tab" aria-controls="document" aria-selected="false">Autenticación</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="host-tab" data-toggle="tab" href="#host" role="tab" aria-controls="host" aria-selected="false">Host</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="general-tab" data-toggle="tab" href="#general" role="tab" aria-controls="general" aria-selected="false">General</a>
                      </li>
                    </ul>
                  </nav>
                  <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="dactilar" role="tabpanel" aria-labelledby="dactilar-tab">
                      <strong class="mb-0">Enrolamiento dactilar</strong>
                      <p>Parámetros para el registro de la huellas dactilares.</p>
                      <div class="list-group mb-5 shadow">
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Habilitar Lector Huellas</strong>
                                  <p class="text-muted mb-0">Si se activa, se inicializa el lector y se puede llevar a cabo el enrolamiento dactilar.</p>
                              </div>
                              <div class="col-auto">
                                  <div class="custom-control custom-switch">
                                      <input type="checkbox" class="custom-control-input" id="habilitarLectorHuellas" name="habilitarLectorHuellas" formControlName="habilitarLectorHuellas"  />
                                      <label class="custom-control-label" for="habilitarLectorHuellas"></label>
                                    </div>
                              </div>
                          </div>
                        </div>
                        <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <strong class="mb-0">Intentos mínimo captura</strong>
                                    <p class="text-muted mb-0">Número de intentos que se deben hacer para cada captura de la huella dactilar.</p>
                                </div>
                                <div class="col-auto">
                                    <div class="form-group">
                                      <input type="text" class="form-control" id="intentsMinimCaptura" size="1"  name="intentsMinimCaptura" formControlName="intentsMinimCaptura"
                                      [ngClass]="{ 'is-invalid': submitted && f.intentsMinimCaptura.errors }"
                                      >
                                      <div *ngIf="submitted && f.intentsMinimCaptura.errors" class="invalid-feedback">
                                        <div *ngIf="f.intentsMinimCaptura.errors.required">Campo obligatorio</div>
                                        <div *ngIf="f.intentsMinimCaptura.errors.min">Valor mínimo es 0</div>
                                        <div *ngIf="f.intentsMinimCaptura.errors.max">Valor máximo es 4</div>
                                      </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <strong class="mb-0">Índice de comparación huellas repetidas</strong>
                                    <p class="text-muted mb-0">Índice de comparación para determinar que dos huellas pertenecen al mismo dedo y, por tanto, para identificar si se ha repetido la huella.</p>
                                </div>
                                <div class="col-auto">
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="indexComparacioRepetit" size="1"  name="indexComparacioRepetit" formControlName="indexComparacioRepetit"
                                    [ngClass]="{ 'is-invalid': submitted && f.indexComparacioRepetit.errors }"
                                    >
                                    <div *ngIf="submitted && f.indexComparacioRepetit.errors" class="invalid-feedback">
                                      <div *ngIf="f.indexComparacioRepetit.errors.required">Campo obligatorio</div>
                                    </div>

                                  </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Índice de comparación huellas coincidentes</strong>
                                  <p class="text-muted mb-0">Índice de comparación para determinar si dos huellas coinciden (son la misma).</p>
                              </div>
                              <div class="col-auto">
                                <div class="form-group">
                                  <input type="text" class="form-control" id="indexComparacioCoincident" size="1"  name="indexComparacioCoincident" formControlName="indexComparacioCoincident"
                                  [ngClass]="{ 'is-invalid': submitted && f.indexComparacioCoincident.errors }"
                                  >
                                  <div *ngIf="submitted && f.indexComparacioCoincident.errors" class="invalid-feedback">
                                    <div *ngIf="f.indexComparacioCoincident.errors.required">Campo obligatorio</div>
                                  </div>

                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Umbral mala calidad</strong>
                                  <p class="text-muted mb-0">Valor que índica a partir de que score se considera que una huella es de mala calidad.</p>
                              </div>
                              <div class="col-auto">
                                <div class="form-group">
                                  <input type="text" class="form-control" id="llindarMalaQualitat" size="1"  name="llindarMalaQualitat" formControlName="llindarMalaQualitat"
                                  [ngClass]="{ 'is-invalid': submitted && f.llindarMalaQualitat.errors }"
                                  >
                                  <div *ngIf="submitted && f.llindarMalaQualitat.errors" class="invalid-feedback">
                                    <div *ngIf="f.llindarMalaQualitat.errors.required">Campo obligatorio</div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>

                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Umbral Liveness</strong>
                                  <p class="text-muted mb-0">Valor de referencia para determinar si una huella es falsa o no. Si el valor obtenido es superior al umbral, se considera que es una huella falsa.</p>
                              </div>
                              <div class="col-auto">
                                <div class="form-group">
                                  <input type="text" class="form-control" id="umbralFingerprintLiveness" size="1"  name="umbralFingerprintLiveness" formControlName="umbralFingerprintLiveness"
                                  [ngClass]="{ 'is-invalid': submitted && f.umbralFingerprintLiveness.errors }"
                                  >
                                  <div *ngIf="submitted && f.umbralFingerprintLiveness.errors" class="invalid-feedback">
                                    <div *ngIf="f.umbralFingerprintLiveness.errors.required">Campo obligatorio</div>
                                  </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Utilizar USK</strong>
                                  <p class="text-muted mb-0">Si se activa, será obligatorio que el slap de huellas pase por el filtro de calidad USK.</p>
                              </div>
                              <div class="col-auto">
                                  <div class="custom-control custom-switch">
                                      <input type="checkbox" class="custom-control-input" id="utilizarUSKDactilar" name="utilizarUSKDactilar" formControlName="utilizarUSKDactilar"  />
                                      <label class="custom-control-label" for="utilizarUSKDactilar"></label>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Incluir control duplicados de meñiques</strong>
                                  <p class="text-muted mb-0">Si se activa, los meñiques se utilizarán para comparar si hay duplicados.</p>
                              </div>
                              <div class="col-auto">
                                  <div class="custom-control custom-switch">
                                      <input type="checkbox" class="custom-control-input" id="incluirControlDuplicadosMeñique" name="incluirControlDuplicadosMeñique" formControlName="incluirControlDuplicadosMeñique"  />
                                      <label class="custom-control-label" for="incluirControlDuplicadosMeñique"></label>
                                  </div>
                              </div>
                          </div>
                        </div>
                      </div>
                      <hr class="my-4">
                      <strong class="mb-0">Calidad de las huellas</strong>
                      <p>Valores de calidad para cada una de las huellas dactilares. Introducir cada valor, separados por comas; el primer valor es para el pulgar y el quinto es para el meñique.</p>
                      <div class="list-group mb-5 shadow">
                          <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <strong class="mb-0">Calidad mínima huella</strong>
                                    <p class="text-muted mb-0">Calidad mínima de la huella.</p>
                                </div>
                                <div class="col-auto">
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="qualitatMinimaPosades" size="15"  name="qualitatMinimaPosades" formControlName="qualitatMinimaPosades"
                                    [ngClass]="{ 'is-invalid': submitted && (f.qualitatMinimaPosades.errors || calidadMinimaArrayError)  }"
                                        >
                                        <div *ngIf="submitted && f.qualitatMinimaPosades.errors" class="invalid-feedback">
                                          <div *ngIf="f.qualitatMinimaPosades.errors.required">Campo obligatorio</div>
                                        </div>
                                        <div *ngIf="submitted && calidadMinimaArrayError" class="invalid-feedback">
                                          <div *ngIf="calidadMinimaArrayError">Debe haber 5 elementos</div>
                                        </div>
  
                                  </div>
                                </div>
                            </div>
                          </div>
                          <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <strong class="mb-0">Calidad del checker</strong>
                                    <p class="text-muted mb-0">Calidad del sistema de control Checker.</p>
                                </div>
                                <div class="col-auto">
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="qualitatChecker" size="15"  name="qualitatChecker" formControlName="qualitatChecker"
                                    [ngClass]="{ 'is-invalid': submitted && (f.qualitatChecker.errors || calidadCheckerArrayError) }"
                                        >
                                        <div *ngIf="submitted && f.qualitatChecker.errors" class="invalid-feedback">
                                          <div *ngIf="f.qualitatChecker.errors.required">Campo obligatorio</div>
                                        </div>
                                        <div *ngIf="submitted && calidadCheckerArrayError" class="invalid-feedback">
                                          <div *ngIf="calidadCheckerArrayError">Debe haber 5 elementos</div>
                                        </div>
  
                                  </div>
                                </div>
                            </div>
                          </div>
  
                      </div>
                    </div>
                    <div class="tab-pane fade " id="facial" role="tabpanel" aria-labelledby="facial-tab">
                        <strong class="mb-0">Enrolamiento facial</strong>
                        <p>Registro de la foto frontal facial de la persona en formato ICAO.</p>
                        <div class="list-group mb-5 shadow">
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Permite continuar si mala calidad</strong>
                                        <p class="text-muted mb-0">Si la calidad de la foto es insuficiente, permitir que se continúe con el registro.</p>
                                    </div>
                                    <div class="col-auto">
                                        <div class="form-group">
                                          <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="permetContinuarSiMalaQualitat" name="permetContinuarSiMalaQualitat" formControlName="permetContinuarSiMalaQualitat"    />
                                            <label class="custom-control-label" for="permetContinuarSiMalaQualitat"></label>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                              <div class="row align-items-center">
                                  <div class="col">
                                      <strong class="mb-0">Umbral de coincidencia</strong>
                                      <p class="text-muted mb-0">Cuando se comparan dos fotos, si el score >= a este valor, indica que se ha hecho Match.</p>
                                  </div>
                                  <div class="col-auto">
                                      <div class="form-group">
                                        <input type="text" class="form-control" id="umbralCoincidenciaFotos" size="3"  name="umbralCoincidenciaFotos" formControlName="umbralCoincidenciaFotos"
                                        [ngClass]="{ 'is-invalid': submitted && f.umbralCoincidenciaFotos.errors }"
                                        >
                                        <div *ngIf="submitted && f.umbralCoincidenciaFotos.errors" class="invalid-feedback">
                                          <div *ngIf="f.umbralCoincidenciaFotos.errors.required">Campo obligatorio</div>
                                        </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                          <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <strong class="mb-0">Utilizar USK</strong>
                                    <p class="text-muted mb-0">Si se activa, será obligatorio que la foto pase por el filtro de calidad USK.</p>
                                </div>
                                <div class="col-auto">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="utilizarUSKFace" name="utilizarUSKFace" formControlName="utilizarUSKFace"  />
                                        <label class="custom-control-label" for="utilizarUSKFace"></label>
                                      </div>
                                </div>
                            </div>
                        </div>
                            <div class="list-group-item">
                              <div class="row align-items-center">
                                  <div class="col">
                                      <strong class="mb-0">Foto obligatoria</strong>
                                      <p class="text-muted mb-0">Si se activa, será obligatorio tomar la foto frontal facial para concluir el registro EES.</p>
                                  </div>
                                  <div class="col-auto">
                                      <div class="custom-control custom-switch">
                                          <input type="checkbox" class="custom-control-input" id="fotoFrontalObligatoria" name="fotoFrontalObligatoria" formControlName="fotoFrontalObligatoria"  />
                                          <label class="custom-control-label" for="fotoFrontalObligatoria"></label>
                                        </div>
                                  </div>
                              </div>
                          </div>
                          <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <strong class="mb-0">Encender luces automáticamente</strong>
                                    <p class="text-muted mb-0">Al realizar foto, que se activen las luces automáticamente.</p>
                                </div>
                                <div class="col-auto">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="encendidoLucesAutomatico" name="encendidoLucesAutomatico" formControlName="encendidoLucesAutomatico"  />
                                        <label class="custom-control-label" for="encendidoLucesAutomatico"></label>
                                      </div>
                                </div>
                            </div>
                        </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Peso máximo de la foto</strong>
                                        <p class="text-muted mb-0">Peso máximo en kilobytes de la foto.</p>
                                    </div>
                                    <div class="col-auto">
                                      <div class="form-group">
                                        <input type="text" class="form-control" id="midaMaximaKBFoto" size="1"  name="midaMaximaKBFoto" formControlName="midaMaximaKBFoto"
                                        [ngClass]="{ 'is-invalid': submitted && f.midaMaximaKBFoto.errors }"
                                        >
                                        <div *ngIf="submitted && f.midaMaximaKBFoto.errors" class="invalid-feedback">
                                          <div *ngIf="f.midaMaximaKBFoto.errors.required">Campo obligatorio</div>
                                        </div>
  
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Reintentos mala calidad </strong>
                                        <p class="text-muted mb-0">Número de reintentos que se deberán realizar en el caso de que la calidad de la foto sea insuficiente.</p>
                                    </div>
                                    <div class="col-auto">
                                      <div class="form-group">
                                        <input type="text" class="form-control" id="reintentsMalaQualitat" size="1"  name="reintentsMalaQualitat" formControlName="reintentsMalaQualitat"
                                        [ngClass]="{ 'is-invalid': submitted && f.reintentsMalaQualitat.errors }"
                                        >
                                        <div *ngIf="submitted && f.reintentsMalaQualitat.errors" class="invalid-feedback">
                                          <div *ngIf="f.reintentsMalaQualitat.errors.required">Campo obligatorio</div>
                                          <div *ngIf="f.reintentsMalaQualitat.errors.min">Valor mínimo es el 0</div>
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                    </div>
                    <div class="tab-pane fade " id="document" role="tabpanel" aria-labelledby="document-tab">
                        <strong class="mb-0">Autenticación</strong>
                        <p>Parámetros de configuración para el login.</p>
                        <div class="list-group mb-5 shadow">
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Operación</strong>
                                        <p class="text-muted mb-0">Operación que se lleva a cabo.</p>
                                    </div>
                                    <div class="col-auto">
                                      <div class="form-group">
                                        <input type="text" class="form-control" id="loginOperacion" size="10"  name="loginOperacion" formControlName="loginOperacion"
                                        [ngClass]="{ 'is-invalid': submitted && f.loginOperacion.errors }"
                                        >
                                        <div *ngIf="submitted && f.loginOperacion.errors" class="invalid-feedback">
                                          <div *ngIf="f.loginOperacion.errors.required">Campo obligatorio</div>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group-item">
                              <div class="row align-items-center">
                                  <div class="col">
                                      <strong class="mb-0">Literal sistema</strong>
                                      <p class="text-muted mb-0">Literal del sistema para la autenticación.</p>
                                  </div>
                                  <div class="col-auto">
                                    <div class="form-group">
                                      <input type="text" class="form-control" id="autenticacionLiteralSistema" size="10"  name="autenticacionLiteralSistema" formControlName="autenticacionLiteralSistema"
                                      [ngClass]="{ 'is-invalid': submitted && f.autenticacionLiteralSistema.errors }"
                                      >
                                      <div *ngIf="submitted && f.autenticacionLiteralSistema.errors" class="invalid-feedback">
                                        <div *ngIf="f.autenticacionLiteralSistema.errors.required">Campo obligatorio</div>
                                      </div>
                                  </div>
                                  </div>
                              </div>
                          </div>
                          <div class="list-group-item">
                            <div class="row align-items-center">
                                <div class="col">
                                    <strong class="mb-0">Dirección IP</strong>
                                    <p class="text-muted mb-0">Dirección IP para la autenticación.</p>
                                </div>
                                <div class="col-auto">
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="autenticacionDireccionIP" size="10"  name="autenticacionDireccionIP" formControlName="autenticacionDireccionIP"
                                    [ngClass]="{ 'is-invalid': submitted && f.autenticacionDireccionIP.errors }"
                                    >
                                    <div *ngIf="submitted && f.autenticacionDireccionIP.errors" class="invalid-feedback">
                                      <div *ngIf="f.autenticacionDireccionIP.errors.required">Campo obligatorio</div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Aplicación CNP</strong>
                                  <p class="text-muted mb-0">Aplicación que utiliza el Cuerpo Nacional de Policía.</p>
                              </div>
                              <div class="col-auto">
                                <div class="form-group">
                                  <input type="text" class="form-control" id="autenticacionAplicacionCNP" size="10"  name="autenticacionAplicacionCNP" formControlName="autenticacionAplicacionCNP"
                                  [ngClass]="{ 'is-invalid': submitted && f.autenticacionAplicacionCNP.errors }"
                                  >
                                  <div *ngIf="submitted && f.autenticacionAplicacionCNP.errors" class="invalid-feedback">
                                    <div *ngIf="f.autenticacionAplicacionCNP.errors.required">Campo obligatorio</div>
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>
                      <div class="list-group-item">
                        <div class="row align-items-center">
                            <div class="col">
                                <strong class="mb-0">Ubicación en frontera</strong>
                                <p class="text-muted mb-0">Puesto en Frontera donde se ubica el dispositivo.</p>
                            </div>
                            <div class="col-auto">
                              <div class="form-group">
                                <input type="text" class="form-control" id="fronteraUbicacionDispositivo" size="10"  name="fronteraUbicacionDispositivo" formControlName="fronteraUbicacionDispositivo"
                                [ngClass]="{ 'is-invalid': submitted && f.fronteraUbicacionDispositivo.errors }"
                                >
                                <div *ngIf="submitted && f.fronteraUbicacionDispositivo.errors" class="invalid-feedback">
                                  <div *ngIf="f.fronteraUbicacionDispositivo.errors.required">Campo obligatorio</div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                   </div>
    
                    </div>
                    <div class="tab-pane fade " id="host" role="tabpanel" aria-labelledby="host-tab">
                        <strong class="mb-0">Host</strong>
                        <p>Parámetros relativos a los Host utilizados.</p>
                        <div class="list-group mb-5 shadow">
                       
                          <div class="list-group-item">
                            <div class="row align-items-center">
                              <div class="col">
                                <strong class="mb-0">Host Servicios Lector Documentos </strong>
                                <p class="text-muted mb-0">Host y puerto de los servicios para la lectura del documento (MRZ y RFID).</p>
                              </div>
                              <div class="col-auto">
                                <div class="form-group">
                                  <input type="text" class="form-control" id="hostServiciosDocumentReader" name="hostServiciosDocumentReader" formControlName="hostServiciosDocumentReader" 
                                      [ngClass]="{ 'is-invalid': submitted && f.hostServiciosDocumentReader.errors }"
                                      >
                                      <div *ngIf="submitted && f.hostServiciosDocumentReader.errors" class="invalid-feedback">
                                        <div *ngIf="f.hostServiciosDocumentReader.errors.required">Campo obligatorio</div>
                                      </div>
                                </div>
                              </div>
                          </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                            <div class="col">
                              <strong class="mb-0">Host Servicios Facial Recognition </strong>
                              <p class="text-muted mb-0">Host y puerto de los servicios de reconocimiento facial.</p>
                            </div>
                            <div class="col-auto">
                              <div class="form-group">
                                <input type="text" class="form-control" id="hostServiciosFacialRecognition" name="hostServiciosFacialRecognition" formControlName="hostServiciosFacialRecognition" 
                                    [ngClass]="{ 'is-invalid': submitted && f.hostServiciosFacialRecognition.errors }"
                                    >
                                    <div *ngIf="submitted && f.hostServiciosFacialRecognition.errors" class="invalid-feedback">
                                      <div *ngIf="f.hostServiciosFacialRecognition.errors.required">Campo obligatorio</div>
                                    </div>
                              </div>
                            </div>
                        </div>
                      </div>
                      <div class="list-group-item">
                        <div class="row align-items-center">
                          <div class="col">
                            <strong class="mb-0">Host Servicios FP Suprema </strong>
                            <p class="text-muted mb-0">Host y puerto de los servicios para la utilización del lector de huellas Suprema.</p>
                          </div>
                          <div class="col-auto">
                            <div class="form-group">
                              <input type="text" class="form-control" id="hostServiciosSuprema" name="hostServiciosSuprema" formControlName="hostServiciosSuprema" 
                                  [ngClass]="{ 'is-invalid': submitted && f.hostServiciosSuprema.errors }"
                                  >
                                  <div *ngIf="submitted && f.hostServiciosSuprema.errors" class="invalid-feedback">
                                    <div *ngIf="f.hostServiciosSuprema.errors.required">Campo obligatorio</div>
                                  </div>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div class="list-group-item">
                      <div class="row align-items-center">
                        <div class="col">
                          <strong class="mb-0">Host Servicios FP SDK </strong>
                          <p class="text-muted mb-0">Host y puerto de los servicios del SDK de huellas.</p>
                        </div>
                        <div class="col-auto">
                          <div class="form-group">
                            <input type="text" class="form-control" id="hostServiciosFPSDK" name="hostServiciosFPSDK" formControlName="hostServiciosFPSDK" 
                                [ngClass]="{ 'is-invalid': submitted && f.hostServiciosFPSDK.errors }"
                                >
                                <div *ngIf="submitted && f.hostServiciosFPSDK.errors" class="invalid-feedback">
                                  <div *ngIf="f.hostServiciosFPSDK.errors.required">Campo obligatorio</div>
                                </div>
                          </div>
                        </div>
                    </div>
                  </div>
                  <div class="list-group-item">
                    <div class="row align-items-center">
                      <div class="col">
                        <strong class="mb-0">Host Servicios Laxton </strong>
                        <p class="text-muted mb-0">Host y puerto de los servicios para enceder/a pagar las luces y lectura del código de barras.</p>
                      </div>
                      <div class="col-auto">
                        <div class="form-group">
                          <input type="text" class="form-control" id="hostServiciosLaxton" name="hostServiciosLaxton" formControlName="hostServiciosLaxton" 
                              [ngClass]="{ 'is-invalid': submitted && f.hostServiciosLaxton.errors }"
                              >
                              <div *ngIf="submitted && f.hostServiciosLaxton.errors" class="invalid-feedback">
                                <div *ngIf="f.hostServiciosLaxton.errors.required">Campo obligatorio</div>
                              </div>
                        </div>
                      </div>
                  </div>
                </div>
                          <div class="list-group-item">
                            <div class="row align-items-center">
                              <div class="col">
                                <strong class="mb-0">Host Servicios Integración </strong>
                                <p class="text-muted mb-0">Host y puerto de los servicios de integración.</p>
                              </div>
                              <div class="col-auto">
                                <div class="form-group">
                                  <input type="text" class="form-control" id="hostServiciosIntegracion" name="hostServiciosIntegracion" formControlName="hostServiciosIntegracion" 
                                      [ngClass]="{ 'is-invalid': submitted && f.hostServiciosIntegracion.errors }"
                                      >
                                      <div *ngIf="submitted && f.hostServiciosIntegracion.errors" class="invalid-feedback">
                                        <div *ngIf="f.hostServiciosIntegracion.errors.required">Campo obligatorio</div>
                                      </div>
                                </div>
                              </div>
                          </div>
                        </div>
                            <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Host Servicios Policiales </strong>
                                        <p class="text-muted mb-0">Host y puerto de los servicios policiales.</p>
                                    </div>
                                    <div class="col-auto">
                                        <div class="form-group">
                                          <input type="text" class="form-control" id="hostServiciosPoliciales" name="hostServiciosPoliciales" formControlName="hostServiciosPoliciales" 
                                          [ngClass]="{ 'is-invalid': submitted && f.hostServiciosPoliciales.errors }"
                                          >
                                          <div *ngIf="submitted && f.hostServiciosPoliciales.errors" class="invalid-feedback">
                                            <div *ngIf="f.hostServiciosPoliciales.errors.required">Campo obligatorio</div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Host Servicios USK </strong>
                                        <p class="text-muted mb-0">Host y puerto de los servicios de calidad de huellas y reconocimiento facial.</p>
                                    </div>
                                    <div class="col-auto">
                                        <div class="form-group">
                                          <input type="text" class="form-control" id="hostServiciosUSK" name="hostServiciosUSK" formControlName="hostServiciosUSK" 
                                          [ngClass]="{ 'is-invalid': submitted && f.hostServiciosUSK.errors }"
                                          >
                                          <div *ngIf="submitted && f.hostServiciosUSK.errors" class="invalid-feedback">
                                            <div *ngIf="f.hostServiciosUSK.errors.required">Campo obligatorio</div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Host Servicios IDV </strong>
                                        <p class="text-muted mb-0">Host y puerto de los servicios IDV.</p>
                                    </div>
                                    <div class="col-auto">
                                        <div class="form-group">
                                          <input type="text" class="form-control" id="hostServiciosIDV" name="hostServiciosIDV" formControlName="hostServiciosIDV" 
                                          [ngClass]="{ 'is-invalid': submitted && f.hostServiciosIDV.errors }"
                                          >
                                          <div *ngIf="submitted && f.hostServiciosIDV.errors" class="invalid-feedback">
                                            <div *ngIf="f.hostServiciosIDV.errors.required">Campo obligatorio</div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                              <div class="list-group-item">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <strong class="mb-0">Host Servicios IU </strong>
                                        <p class="text-muted mb-0">Host y puerto de los servicios de la interfaz de usuario.</p>
                                    </div>
                                    <div class="col-auto">
                                        <div class="form-group">
                                          <input type="text" class="form-control" id="hostServiciosIU" name="hostServiciosIU" formControlName="hostServiciosIU" 
                                          [ngClass]="{ 'is-invalid': submitted && f.hostServiciosIU.errors }"
                                          >
                                          <div *ngIf="submitted && f.hostServiciosIU.errors" class="invalid-feedback">
                                            <div *ngIf="f.hostServiciosIU.errors.required">Campo obligatorio</div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                              </div>
                          
                         
                      
                        </div>
    
                    </div>
                    <div class="tab-pane fade " id="general" role="tabpanel" aria-labelledby="general-tab">
                      <strong class="mb-0">General</strong>
                      <p>Parámetros generales de la aplicación.</p>
                      <div class="list-group mb-5 shadow">
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">EES Umbral calidad Huellas </strong>
                                  <p class="text-muted mb-0">Umbral que se utiliza para determinar la calidad de las huellas enviadas.</p>
                              </div>
                              <div class="col-auto">
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="biometricQualityMetricThresholdFP" size="6"  name="biometricQualityMetricThresholdFP" formControlName="biometricQualityMetricThresholdFP"
                                    [ngClass]="{ 'is-invalid': submitted && f.biometricQualityMetricThresholdFP.errors }"
                                    >
                                    <div *ngIf="submitted && f.biometricQualityMetricThresholdFP.errors" class="invalid-feedback">
                                      <div *ngIf="f.biometricQualityMetricThresholdFP.errors.required">Campo obligatorio</div>
                                      <div *ngIf="f.biometricQualityMetricThresholdFP.errors.maxlength">Máximo 6 dígitos</div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">EES Umbral calidad Foto </strong>
                                  <p class="text-muted mb-0">Umbral que se utiliza para determinar la calidad de la foto facial enviada.</p>
                              </div>
                              <div class="col-auto">
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="biometricQualityMetricThresholdFace" size="6"  name="biometricQualityMetricThresholdFace" formControlName="biometricQualityMetricThresholdFace"
                                    [ngClass]="{ 'is-invalid': submitted && f.biometricQualityMetricThresholdFace.errors }"
                                    >
                                    <div *ngIf="submitted && f.biometricQualityMetricThresholdFace.errors" class="invalid-feedback">
                                      <div *ngIf="f.biometricQualityMetricThresholdFace.errors.required">Campo obligatorio</div>
                                      <div *ngIf="f.biometricQualityMetricThresholdFace.errors.maxlength">Máximo 6 dígitos</div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Edad mínima huellas </strong>
                                  <p class="text-muted mb-0">Edad mínima que es necesario capturar las huellas dactilares.</p>
                              </div>
                              <div class="col-auto">
                                  <div class="form-group">
                                    <input type="text" class="form-control" id="edadMinimaCapturaHuellas" size="6"  name="edadMinimaCapturaHuellas" formControlName="edadMinimaCapturaHuellas"
                                    [ngClass]="{ 'is-invalid': submitted && f.edadMinimaCapturaHuellas.errors }"
                                    >
                                    <div *ngIf="submitted && f.edadMinimaCapturaHuellas.errors" class="invalid-feedback">
                                      <div *ngIf="f.edadMinimaCapturaHuellas.errors.required">Campo obligatorio</div>
                                    </div>
                                  </div>
                              </div>
                          </div>
                        </div>
                        <div class="list-group-item">
                          <div class="row align-items-center">
                              <div class="col">
                                  <strong class="mb-0">Utilizar modo oscuro</strong>
                                  <p class="text-muted mb-0">Si se activa, el diseño de pantalla serà oscuro.</p>
                              </div>
                              <div class="col-auto">
                                  <div class="custom-control custom-switch">
                                      <input type="checkbox" class="custom-control-input" id="modoOscuro" name="modoOscuro" formControlName="modoOscuro"  />
                                      <label class="custom-control-label" for="modoOscuro"></label>
                                    </div>
                              </div>
                          </div>
                      </div>
                        
                       
                    
                      </div>
  
                  </div>
                  </div>
  
  
  
              </div>
          </div>
        </form> 
          
      </div>
    </div>
  
    <div class="modal-footer inline-footer">
      <button
        ngbAutofocus
        type="submit"
        
        form="formConfig"
        class="btn btn-info btn-lg mr-1 my-1 float-right"
      >
        {{ "comu.accio.guardar" | translate }}
      </button>
      <button
        type="button"
        (click)="modal.dismiss('')"
        class="btn btn-warning btn-lg mx-1 my-1 float-right"
      >
        {{ "comu.accio.cancelar" | translate }}
      </button>
      <button
      type="button"
      
      (click)="onReset()"
      class="btn btn-outline-secondary btn-lg float-left mx-1 my-1"
    >
      {{ "comu.accio.reset" | translate }}
    </button>
    </div>
  </ng-template>
  
  <ng-template #validacionesPanel let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
       Validaciones
      </h4>
      <button
        type="button"
        class="btn  btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="p-2">
        <h4>Tipo</h4>
        <div class="row" *ngFor="let mensaje of datosService.datosVerificacion.listaMensajes">
          <div class="col-sm-1">
            <fa-icon *ngIf="mensaje.tipo == TipoMensajeProceso.Validacion" [icon]="iconValido" [style]="{'stroke':'green', 'color':'green'}"></fa-icon>
            <fa-icon *ngIf="mensaje.tipo == TipoMensajeProceso.Error" [icon]="iconAlerta" [style]="{'stroke':'red', 'color':'red'}"></fa-icon>
          </div>
          <div class="col-sm-11">
            {{mensaje.texto}}
          </div>
        </div>
          
      </div>
    </div>
  
  </ng-template>
  
  <ng-template #aboutPanel let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
       Acerca de...
      </h4>
      <button
        type="button"
        class="btn btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="p-2">
        <div class="h3">{{software()}}</div>
        <hr>
        <div class="row mt-5">
          <div class="col-sm-5 font-weight-bold">Desarrollado por</div>
          <div class="col-sm-7">Bioidenti-Cell S.A.</div>
        </div> 
        <div class="row">
          <div class="col-sm-5 font-weight-bold">Provisto por</div>
          <div class="col-sm-7">Telefónica TIS</div>
        </div> 
        <!--div class="row">
          <div class="col-sm-5 font-weight-bold">SDK FRP</div>
          <div class="col-sm-7">Thales DIS</div>
        </div--> 
        <div class="row">
          <div class="col-sm-5 font-weight-bold">Versión</div>
          <div class="col-sm-7">{{version()}}</div>
        </div> 
        <div class="row">
          <div class="col-sm-5 font-weight-bold">Última actualización</div>
          <div class="col-sm-7">{{ultimaActualizacion()}}</div>
        </div> 
  
      </div>
    </div>
  
  </ng-template>
  
  <ng-template #contentFoto let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Foto ampliada
      </h4>
      <button
        type="button"
        class="btn btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="row text-center">
        <div class="mx-auto">
          <img
            style="object-fit: contain"
            [src]="fotoAmpliada"
          />
        </div>
      </div>
    </div>
  </ng-template>


  <lib-missatge-modal #missatgeModal></lib-missatge-modal>

  <app-validaciones #validaciones></app-validaciones>