import { Errorable } from "./siepbio-model";

export class ComparacionFotos{
  galleryImage?: string;
  probeImage?: string;
}

export class ResultadoComparacionFotos{
  errorGalleryImage?: string;
  errorProbeImage?: string;
  score?: number;
}

export class PeticioVerificacioFacial {
  token?: string;
  nis?: string;
  facialImages?: string[];
}

export class RespostaVerificacioFacial extends Errorable {
  token?: string;
  resultat?: string;
}

export class IdentificationResult {
  public Level?: IdentificationLevel;
  public Score?: number;
  public Name?: string;
  public ErrorCode?: ThalesErrorCode;
}

export class FacialInfo {
  public ICAO?: string; //base64
  public Info?: GeometriaFacial;
  public Error?: Error;
}

export class FacialConfiguration {
  public HitThreshold?: number;
  public PossibleThreshold?: number;
  public MaybeThreshold?: number;
}
export class GeometriaFacial {
  public Cara?: Cara;
  public CoordenadesGeometria?: Point[];
  public Probabilidades?: number[];
  public Caracteristicas?: string;
}

export class Cara {
  public requadre?: Requadre;
  public eyeLeft?: Ull;
  public eyeRight?: Ull;
  public probabilitat?: number;
}

export class Requadre {
  public left?: number;
  public top?: number;
  public width?: number;
  public height?: number;
}
export class Ull {
  public puntCentral?: Punt;
  public requadre?: Requadre;
  public probabilitat?: number;
}

export class Point {
  public x?: number;
  public y?: number;
}

export class Punt {
  public left?: number;
  public top?: number;
}

export class Error {
  public Code?: CodiExcepcioCara;
  public Message?: string;
}

export class IcaoRequest {
  public tipoIcao: IcaoType = IcaoType.FACE_PHOTO;
  public imagenB64SinCabecera?: string;
}

export class ResultadoIcao{
  public image?: string;
  public error?: Error;
}

export class QualityResponse{
  public isoQuality?: IsoQuality;

  public warnings?: WarningType;
  public error?: Error;
  public imagenIcao?: string;
}

export class IsoQuality{
  public frontal: boolean; // la posicón de la cara es frontal. 1, es frontal y es correcto
  public eyeOpen: boolean; // los oojos están abiertos o no. 0, cerrados. 1, es correcto
  public gaze?: boolean; // mirada. = -1 si frontal, eyeOpen y sharp son 0; 0 es que no mira al frente; 1, mira al frente, luego es correcto
  public mouthClose: boolean; // la boca está abierta o no; 0, boca cerrada
  public onlyOneFace: boolean; //una sola cara.  1, es correcto
  public light: boolean; //contraste/saturación correctos. 1, es correcto
  public noEyeShadow: boolean;// hay sombras en los ojos, 1; 0, si no hay sombras
  public noHotSpot: boolean; // reflejo de flash en la piel. 0, hay reflejo; 1, no hay reflejo y es correcto
  public glasses: boolean; // 0, no lleva gafas. 1, lleva
  public noTintedGlasses: boolean; // lleva lentes tintadas; 0, lleva lentes. 1, es correcto
  public exposure: boolean; // hay exposición. 1, está muy luminoso
  public sharp: boolean;// borroso. 1, está borroso
  public hCentred: boolean; // centrado horizontalmente.  1, es correcto
  public vPos: boolean; //posicion vertical. 1, es correcto
  public widthHead: boolean; //ancho de la cabeza correcto (debe ser entre el 50 y el 70% de la imagen). 1, es correcto
  public lengthHead: boolean; //longitud de la cabeza correcta (debe ser entre el 60 y 90% de la imagen). 1. es correcta
  public grayScaleProf: boolean;// histograma de gris normal. 1, es correcto
  public naturalSkinColor: boolean; //el color de la piel es natural; 1, es correcto
  public notRedEye: boolean; //no tiene los ojos rojos. 1, no tiene los ojos rojos y es correcto
  public background: boolean; // fondo uniforme. 1, es correcto
  public resolution: boolean; // resolucion de imagen w:240 , h: 320. Si es correcta, 1
  public whRatio: boolean; // pixel aspect ratio. 1, es correcto
  public veil: boolean; // si no hay velo sobre la cara; 1, es correcto
  public hat: boolean; // si lleva sombrero o no; 1, lleva sombrero
  public heavyFrames: boolean; // 1, si el marco de las gafas es demasiado grueso
  public score: number; //porcentaje de score
  public isoErrorType: IsoErrorType; // tipo de error
  
}

export enum IsoErrorType{
      NoError = 0x1,
      PoorQuality = 0x2,
      Small = 0x4,
      UnderExposure = 0x8,
      OverExposure = 0x10,
      Blur = 0x20,
      Pose = 0x40,
      MultiFace = 0x80,
      NotFrontal = 0x100,
      EyeClosed = 0x200,
      MouthOpened = 0x400,
      TintedGlasses = 0x800,
      Resolution = 0x1000,
      NaturalSkinColor = 0x2000,
      Glasses = 0x4000,
      EyeShadow = 0x8000,
      HotSpot = 0x10000,
      Sharp = 0x20000,
      Light = 0x40000,
      OutOfImage = 0x80000,
      EyeNotLive = 0x100000,
      MouthNotLive = 0x200000,
      PoseNotLive = 0x400000,
      DepthNotLive = 0x800000
}

export enum WarningType
{
    TOO_FAR_CAPTURE = 3,
    TOO_LARGE_IMAGE = 2,
    TOO_SMALL_IMAGE = 1,
    NoWarning = 0
}

export enum ThalesErrorCode
{
    None = 0,
    Password = 1,
    NoFace = 2,
    MultiFace = 3,
    Blur = 4,
    Pose = 5,
    Size = 6,
    Similar = 7,
    ImageTooLarge = 8,
    Config = 9,
    General = 10
}

export enum CodiExcepcioCara {
  //Error Desconocido
  UnknownError = 0,
  //No se han encontrado caras
  FaceNotFound = 1,
  //Demasiadas caras encontradas
  TooManyFaces = 2,
  //Cara fuera de encuadre
  TooClose = 3,
  //La distancia entre ojos es escasa. Acérquese a la cámara
  TooFar = 4,
  //Los ojos no estan verticalmente centrados
  EyesNotCentered = 5,
  //La cara no está centrada
  FaceNotCentered = 6
 
}

export enum IdentificationLevel {
  //Frame descartat per mala qualitat, veure CodiExcepcioCara
  Discarded = 0,
  //La recerca no ha retornat cap resultat fiable
  Unknown = 1,
  //La recerca ha retornat un candidat semblant, pero sense fiabilitat
  Maybe = 2,
  //La recerca ha retornat un candidat semblant, pero sense fiabilitat
  Possible = 3,
  //La recerca ha retornat un resultat fiable
  Hit = 4
}

export enum IcaoType {
  //Recorte Icao para fotos en vivo
  FACE_PHOTO,
  //Recorte Icao para fotos de documentos de viaje con foto facial
  DOCUMENT_PHOTO
}
