import { Component, OnInit,  ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EnrolamentService } from '../../serveis/enrolament.service';

import { TranslateService } from '@ngx-translate/core';
import { LiveViewFotoComponent } from '../../pagines/live-view-foto/live-view-foto.component';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';

import { CategoriaMensaje, DecisionResultadoFaceUSK, MensajeProceso, ResultadoColorFondo, ResumenDatosSideBar, TipoMensajeProceso, WarningResultadoFaceUSK } from '../../model/EntryExit';
import { ComparacionFotos, ConfiguracioService, FacialService, DatosService, Modal, ModalIcon } from 'projects/siepbio-comu/src/public-api';
import { Etapa, TipoDecision } from '../../model/documento';
import { ApartatService } from '../../serveis/apartat.service';
import { faCheck, faExclamationTriangle, faTrash, faCamera, faInfo } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-facial',
  templateUrl: './facial.component.html',
  styleUrls: ['./facial.component.css'],
  providers: [NgbModal]
})
export class FacialComponent implements OnInit {

  @ViewChild('liveView') liveView?: LiveViewFotoComponent;
  @ViewChild('missatgeModal') missatgeModal?: MissatgeModalComponent;
  closeResult: string = '';
  accio?: string;
  fotoZoom: string | undefined;

  Etapa: typeof Etapa = Etapa;

  iconDelete = faTrash;
  iconCamera = faCamera;
  iconInfo = faInfo;

  iconValido = faCheck;
  iconNoValido = faExclamationTriangle;

  constructor(
    private modalService: NgbModal,
    private configurationService: ConfiguracioService,
    private translate: TranslateService,
    private facialService: FacialService,
    private apartatServei: ApartatService,
    private enrolmentService: EnrolamentService,
    public datosService: DatosService) {
   }

   ngOnInit(): void {
    this.accio = this.translate.instant('comu.accio.fer-foto');
  }

  normalizaScore(score?: number): string {
    return FacialService.normalizaScore(score);
  }

  async validarScore(fotoCapturada: string, otraFoto: string): Promise<number>{
    if (fotoCapturada != undefined && otraFoto != undefined 
      && fotoCapturada != 'assets/img/sin-fotografia.png'
      && otraFoto != 'assets/img/sin-fotografia.png'){
      let comparacion = new ComparacionFotos();
      comparacion.galleryImage = otraFoto;
      comparacion.probeImage = fotoCapturada;
      this.missatgeModal.tanca();
      this.missatgeModal.obre(
        new Modal({
          titol: "Comparando fotos",
          missatge:"Obteniendo puntuación de comparación entre fotos del Chip, EES y VIZ",
          indicacions: "Espere un momento, por favor",
          esProgres: true,
          accions: [],
        })
      );
      try{
        let resultado = await this.facialService.match(comparacion);
        this.missatgeModal.tanca();
        if (resultado.errorGalleryImage != undefined){
          this.añadirError(resultado.errorGalleryImage, true);
        }
        if (resultado.errorProbeImage != undefined){
          this.añadirError(resultado.errorProbeImage, true);
        }
        if (resultado.score != undefined && resultado.score > 0){
          return resultado.score;
        }
        else {
          return  undefined;
        }
        
      }
      catch(ex){
        this.missatgeModal.tanca();
        this.missatgeModal.obre(
          new Modal({
            titol: "Error",
            missatge: ex.missatge,
            indicacions: ex.suggeriment,
            detall: ex.intern,
            icona: ModalIcon.Atencio,
          })
        );
      }
    }
  }

 
  private añadirError(texto: string, error: boolean){
    let mensaje = new MensajeProceso();
    mensaje.tipo = error ? TipoMensajeProceso.Error : TipoMensajeProceso.Validacion;
    mensaje.categoria = CategoriaMensaje.Facial;
    mensaje.texto = texto;
    this.datosService.datosVerificacion = this.enrolmentService.añadirError(this.datosService.datosVerificacion, mensaje);
  }
  
  afegirFoto(): void {
    this.liveView?.obre();
  }

  borrarFoto(): void{
  
    this.datosService.datosVerificacion.fotoCapturada = undefined;
    this.datosService.datosVerificacion.scoreFotoVsChip = undefined;
    this.datosService.datosVerificacion.scoreFotoVsEES = undefined;
    this.datosService.datosVerificacion.scoreFotoVsImpresa = undefined;
    this.datosService.datosVerificacion.coincideFotoVsChip = undefined;
    this.datosService.datosVerificacion.coincideFotoVsEES = undefined;
    this.datosService.datosVerificacion.coincideFotoVsImpresa = undefined;
    this.datosService.datosVerificacion.resultadoCapturaFoto = undefined;
    this.accio = this.translate.instant('comu.accio.fer-foto');
  }

  ampliarImatge(content: any, imatge: string): void {
    if (imatge != undefined && imatge != 'assets/img/sin-fotografia.png'){
      this.fotoZoom = this.getFoto(imatge);
      this.modalService.open(content, {
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        scrollable: true,
      });
    }
  }

  getFoto(foto?: string):string{
    if (foto != undefined)
      return  "data:image/Jpeg;base64," + foto;
    else
      return 'assets/img/sin-fotografia.png';
   }

 private matched(score?: number): boolean{
  if (score == undefined) return undefined;
  return score >= this.configurationService.parametrosConfiguracion.umbralCoincidenciaFotos;
 }


  async onFotoCapturada(foto: string): Promise<void> {
    console.log("on foto capturada", foto);
   
    if (foto){
      //foto = this.facialService.netejarBase64(foto);
      console.log("warning resultado captura foto", this.datosService.datosVerificacion?.resultadoCapturaFoto?.warningInfo);
      
      this.datosService.datosVerificacion.scoreFotoVsEES = await this.validarScore(foto, this.datosService.datosVerificacion?.resultadoSearch?.registros[0]?.foto);
      console.log("score EES", this.datosService.datosVerificacion.scoreFotoVsEES);
      this.datosService.datosVerificacion.coincideFotoVsEES = this.matched(this.datosService.datosVerificacion.scoreFotoVsEES); 
      if (!this.datosService.datosVerificacion.coincideFotoVsEES){
        this.añadirError("Foto EES no coincide con la foto tomada",true);
      }
      this.datosService.datosVerificacion.scoreFotoVsChip = await this.validarScore(foto, this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.foto);
      this.datosService.datosVerificacion.coincideFotoVsChip = this.matched(this.datosService.datosVerificacion.scoreFotoVsChip); 
      if (!this.datosService.datosVerificacion.coincideFotoVsChip){
        this.añadirError("Foto del chip no coincide con la foto tomada",true);
      }
      this.datosService.datosVerificacion.scoreFotoVsImpresa = await this.validarScore(foto, this.datosService.datosVerificacion?.fotoImpresa);
      this.datosService.datosVerificacion.coincideFotoVsImpresa = this.matched(this.datosService.datosVerificacion.scoreFotoVsImpresa);   
      if (!this.datosService.datosVerificacion.coincideFotoVsImpresa){
        this.añadirError("Foto del VIZ no coincide con la foto tomada",true);
      }

      this.accio = this.translate.instant('comu.accio.repetir');
      
      this.datosService.datosVerificacion.fotoCapturada = foto;
      if (this.datosService.resumenDatosResolucion?.foto == undefined){
        if (this.datosService.resumenDatosResolucion == undefined)
          this.datosService.resumenDatosResolucion = new ResumenDatosSideBar();
        this.datosService.resumenDatosResolucion.foto =  foto;
      }
      this.datosService.datosVerificacion.resultadoFacial = (this.datosService.datosVerificacion.resultadoFacial  != false && 
        (this.datosService.datosVerificacion.coincideFotoVsChip == undefined || this.datosService.datosVerificacion.coincideFotoVsChip == true) &&
        (this.datosService.datosVerificacion.coincideFotoVsEES == undefined || this.datosService.datosVerificacion.coincideFotoVsEES == true) &&
        (this.datosService.datosVerificacion.coincideFotoVsImpresa == undefined || this.datosService.datosVerificacion.coincideFotoVsImpresa == true));
    }
    else{
      //this.datosService.datosVerificacion.fotoCapturada = undefined;
      this.accio = this.translate.instant('comu.accio.fer-foto');
      this.datosService.datosVerificacion.resultadoFacial = false;
    }
   
    if (!this.datosService.datosVerificacion.decisionesTomadas[Etapa.Facial].esDecisionManual){
      if (this.datosService.datosVerificacion.resultadoFacial)
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Facial].tipo = TipoDecision.ACEPTADO;
      else
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Facial].tipo = TipoDecision.RECHAZADO;
    }
  }

  mensajeUSK():ResultadoColorFondo{
    let resultado = new ResultadoColorFondo();
    if (this.datosService.datosVerificacion?.resultadoFaceUSK?.decision == undefined){
      resultado.texto = "Pendiente de foto";
      resultado.color = "warning";
      this.datosService.datosVerificacion.resultadoFacial = false;
    }
    else if (this.datosService.datosVerificacion?.resultadoFaceUSK?.decision != undefined && this.datosService.datosVerificacion?.resultadoFaceUSK?.decision != DecisionResultadoFaceUSK.ACCEPTABLE){
      resultado.texto = this.getDesicionUSK(this.datosService.datosVerificacion?.resultadoFaceUSK?.decision) + ". " + this.getWarningUSK(this.datosService.datosVerificacion?.resultadoFaceUSK?.warning);
      resultado.color = "danger";
      this.datosService.datosVerificacion.resultadoFacial = false;
    }
    else{
      resultado.texto = "Foto correcta";
      resultado.color = "success";
    }
   
    return resultado;
  }

  getWarningUSK(error: WarningResultadoFaceUSK): string{ 
    switch(error){
      case WarningResultadoFaceUSK.TOO_FAR_CAPTURE:
        return "Demasiado alejado de la cámara";
      case WarningResultadoFaceUSK.TOO_LARGE_IMAGE:
        return "Foto demasiado grande";
      case WarningResultadoFaceUSK.TOO_SMALL_IMAGE:
        return "Foto demasiado pequeña";
      case WarningResultadoFaceUSK.NoWarning:
        return "";
    }
  }

  getDesicionUSK(decision: DecisionResultadoFaceUSK): string{
    switch(decision){
      case DecisionResultadoFaceUSK.UNDETERMINED:
        return "Error indeterminado";
      case DecisionResultadoFaceUSK.ACCEPTABLE:
        return "Calidad aceptable";
      case DecisionResultadoFaceUSK.LOWQUALITY:
        return "Baja calidad";
      case DecisionResultadoFaceUSK.LOWQUALITY_NON_FRONTAL_FACE_ORIENTATION:
        return "Foto no frontal";
      case DecisionResultadoFaceUSK.LOWQUALITY_OPEN_MOUTH_DETECTED:
        return "Boca abierta";
      case DecisionResultadoFaceUSK.LOWQUALITY_WEARING_MASK_DETECTED:
        return "Lleva mascarilla";
    }
   
  }


}
