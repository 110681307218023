import { Empremta } from "projects/siepbio-comu/src/public-api";
import { Error } from 'projects/siepbio-comu/src/lib/model/facial-info';

export class Documento {
  public foto?: string; 
  public huella1?: Empremta; 
  public huella2?: Empremta; 
  public idPersonal?: string;
  public tipoDocumento?: string;
  public paisExpedidor?: string;
  public numeroDocumento?: string;
  public fechaCaducidad?: string;
  public fechaExpedicion?: string;
  public nombre?: string;
  public apellidos?: string;
  public fechaNacimiento?: string;
  public nacionalidad?: string;
  public sexo?: string;
  public mrzCode?: string;
  public caducado?: boolean;
  public checksum?: boolean;
  public mrzCheck?: boolean;
  public line1?: string;
  public line2?: string;
  public line3?: string;
}

export enum SEXO {
  VARON = 0,
  MUJER = 1,
  NO_BINARIO = 2,
  OTRO = 3
}

export enum TIPODOCUMENTO {
  PASSPORT = 0,
  DNI = 1
}

export enum EstadoToken{
  INEXISTENTE = 0,
  NO_VERIFICADO = 1,
  VERIFICADO = 2
}

export class ImagenesDocumento{
  public white: string;
  public uv: string;
  public ir: string;
}

export class ImagenMetaDataDocumento{
  public additionalProp1: string;
  public additionalProp2: string;
  public additionalProp3: string;
}


export class DocumentoPagina{
  public part: string;
  public mrz: Array<string>;
  public images: ImagenesDocumento;
  public imageMetaData: ImagenMetaDataDocumento;
}

export class DocumentoIDV{
  public requesterId: string;
  public pdf: string;
  public tiff: string;
  public pages: Array<DocumentoPagina>;
  public doCropping: boolean = true;
  public doBlackening: boolean = true;
  public documentTypeSize: string;
  public publicdocumentDimensions: string;
  public transactionId: string;
  public channel: string;
  public correlationId: string;
  public returnImages: boolean = true;
  public ocrOverwrittenpublicues: ImagenMetaDataDocumento;
  public returnPDFReport: boolean;
  public chipData: ImagenMetaDataDocumento;
}

export class ResultadoDocumentoVerificado{
  public responseType: string;
  public transactionId: string;
  public documentQAResult: string;
  public verificationTime: string;
  public responseTime: string;
  public pages: Array<DocumentoPagina>;
  public chipResult: string;
  public workstation: string;
  public startTime: string;
  public endTime: string;
  public exception: string;
  public externalData: any;
  public templateRepositoryVersion: string;
  public minimumResolutionFailed: boolean;
  public subKey: string;
  public country: string;
  public documentType: string;
  public templateName: string;
  public templateKey: string;
  public actions: Array<AccionesVerificacionDocumento>;
  public missingPage: boolean;
  public cropResult: boolean;
  public surname: string;
  public firstName: string;
  public birthDate: string;
  public expiryDate: string;
  public gender: string;
  public documentNumber: string;
  public issuingDate: string;
  public portrait: string;
  public mrzExtractionTime: string;
  public croppingTime: string;
  public dvTime: string;
  public mrzTextFields: CamposMRZ;
  public ocrTextFields: CamposOCR;
  public captureTime: string;
  public identificationTime: string;
  public errorMessage: string;
}

export class AccionesVerificacionDocumento{
  public verificationCategory: string;
  public verificationName: string;
  public verificationType: string;
  public threshold: number;
  public score: number;
  public outputs: Array<OutputAccionDocumento>;
}

export class OutputAccionDocumento{
  public name: string;
  public base64Image: string;
  public text: string;
}

export class CamposMRZ{
  public mrzRawData: string;
  public documentType: string;
  public issuingState: string;
  public lastName: string;
  public firstName: string;
  public documentNumber: string;
  public nationality: string;
  public dateOfBirth: string;
  public sex: string;
  public dateOfExpiry: string;
  public optionalData: string;
}

export class CamposOCR{
public dateOfIssue: string;
public documentNumber: string;
public dateOfExpiry: string;
public lastName: string;
public firstName: string;
public dateOfBirth: string;
public nationality: string;
public sex_Gender: string;
public placeOfBirth: string;
public address: string;
public optionalData: string;
public none: string;
public fullName: string;
public documentType: string;
public personalNumber: string;
public profession: string;
public occupation: string;
public issuingState: string;
public personalSummary: string;
public otherDataidTdNumbers: string;
public custodyInformation: string;
public date_Of_Registration: string;
public age: string;
public folio_NumberData: string;
public voter_Key: string;
public address_Municipality: string;
public address_Location: string;
public section: string;
public ocrField: string;
public address_Jurisdiction_Code: string;
public street: string;
}

export class DocumentoVerificado{
  public result: ResultadoDocumentoVerificado;
  public pdf?: string;
  public subject?: string;
  public body?: string;
  public metadata?: string;
  public error?: Error;
}

export class DecisionTomada{
  
  public tipo: TipoDecision = TipoDecision.PENDIENTE;
  public motivo?: string;
  public motivoManual?: string;
  public esDecisionManual?: boolean;

}

export enum TipoDecision{
  PENDIENTE = 0,
  ACEPTADO = 1,
  RECHAZADO =2,
  REDIRIGIDO = 3
}

export enum Etapa{
 Documento, Facial, Dactilar, Consultas, Formulario
}