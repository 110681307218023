import { AnsiNistData, Fingerprint, FSMT } from '../../public-api';
import { RawImage } from './raw-image';

export class ResultSegment {
  public wrongHand: boolean;
  public segments: Array<SegmentQualityScore>;
}

export class SegmentQualityScore {
  public fingerNumber: number;
  public rawImage: RawImage;
  public embedImage: string;
  public quality: number;
  public score: number;
  public nfiq: number;
  public wrongHand: boolean;

}

export class SegmentFingerprints {
  public fingers: Array<number>;
  public imatge: RawImage;

}

export class ContenidoNist{
  public data: AnsiNistData;
  public empremtes : Array<Fingerprint>;
  public facials: Array<FSMT>;
}

export class HuellasMatch{
  public galeria: Array<RawImage>;
  public probe: RawImage;
}

export class EmparejarHuellas{
  public template: string;
  public probe: RawImage;
  public numeroMinucies: number;
}

export class ImagenesMatch {
  public galeria: Array<string>;
  public probe: RawImage;
}