import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfiguracioService } from 'projects/siepbio-comu/src/lib/serveis/configuracio.service';
import { DocumentoIDV, DocumentoVerificado, ResultadoDocumentoVerificado } from '../model/documento';

import { Error } from 'projects/siepbio-comu/src/lib/model/facial-info';
import { Excepcio } from 'projects/siepbio-comu/src/public-api';
import { ProcessRequest, ProcessResponse } from '../model/processResponse';

@Injectable({
  providedIn: 'root'
})
export class IdvService {

  public host: string = "https://localhost:3009";
  constructor(private http: HttpClient,private configuracionService: ConfiguracioService) {
    if (configuracionService.parametrosConfiguracion.hostServiciosIDV != undefined)
      this.host = configuracionService.parametrosConfiguracion.hostServiciosIDV;
  }

  private async doCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    console.log("docall host idv", this.host);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (isGet)
      return await this.http
        .get(this.host + url, {
          headers: headers,
          params: params,
          observe: 'body',
          responseType: 'json',
        })
        .toPromise();
    else {
      return await this.http
        .post(
          this.host + url,
          json ? json : undefined,
          {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'json',
          }
        )
        .toPromise();
    }
  }

  private async manageCall(
    isGet: boolean,
    url: string,
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(isGet, url,null, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      obj = await this.doCall(isGet, url, null, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  public async verificarOld(
    datos: DocumentoIDV
  ): Promise<DocumentoVerificado> {
    console.log("Datos IDV", datos);
    try{
    let response = await this.manageCall(
      false,
      '/api/idv/verifydoc',JSON.stringify(datos)
      );
      console.log("llamada a verificar doc", response);
      let documento = new DocumentoVerificado();
      let json = JSON.parse(JSON.stringify(response));
      if (json?.message != undefined){
        let error = new Error();
        error.Code = Number(json["code"]);
        error.Message = json["message"];
        console.log("Error en quality", error);
        documento.error = error;
      }
      else{
        let result = new ResultadoDocumentoVerificado();
        result.cropResult = Boolean(json.result?.cropResult);
        //result.cropResult = Boolean(json.documentVerifyResponse?.result?.cropResult);
        result.errorMessage = json.result?.errorMessage;
        //result.errorMessage = json.documentVerifyResponse?.result?.errorMessage;
        result.portrait = json.result?.portrait;
        //result.portrait = json.documentVerifyResponse?.result?.portrait;
        
        documento.result = result;
      }
    return documento;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio IDV. " , "Pruebe a reintentar", ex.message
      );
    }
  }

  public async verificar(
    datos: ProcessRequest
  ): Promise<ProcessResponse> {
    console.log("Datos IDV", datos);
    try {
      let response = await this.manageCall(
        false,
        '/api/documentReaderApi/process',JSON.stringify(datos)
      );
      console.log("llamada a documentReaderApi/process", response);
      var json = JSON.parse(JSON.stringify(response));
      return json as ProcessResponse;
    }
    catch(ex){
      
      throw new Excepcio(
        "Error en el servicio Document Reader API. " , "Pruebe a reintentar", ex.message
      );
    }
  }

}
