import { Component, Input, OnInit } from '@angular/core';
import { faBan, faCheck, faWarning } from '@fortawesome/free-solid-svg-icons';
import { DatosService } from 'projects/siepbio-comu/src/public-api';
import { CategoriaMensaje, MensajeProceso, TipoMensajeProceso } from '../../model/EntryExit';

@Component({
  selector: 'app-validacion-grupo',
  templateUrl: './validacion-grupo.component.html',
  styleUrls: ['./validacion-grupo.component.css']
})
export class ValidacionGrupoComponent implements OnInit {
  @Input() categoria: CategoriaMensaje;
  
  iconValido = faCheck;
  iconAlerta = faBan;
  iconWarning = faWarning

  TipoMensajeProceso  = TipoMensajeProceso;
  
  constructor(public datosService: DatosService) { }

  ngOnInit(): void {
  }

  hayMensajes() {
    const categoria = this.mensajes();

    return categoria != null && categoria.length > 0;
  }

  mensajes(): Array<MensajeProceso> {
    const todos = this.mensajesAgrupados();

    return todos.get(this.categoria);
  }

  private mensajesAgrupados(): Map<CategoriaMensaje, Array<MensajeProceso>> {
    var result = new Map<CategoriaMensaje, Array<MensajeProceso>>();

    this.datosService.datosVerificacion.listaMensajes?.forEach(m => {
      if (result.has(m.categoria))
        result.get(m.categoria).push(m);
      else
        result.set(m.categoria, [m]);
    });

    return result;
  }

  nombreCategoria() {
    switch(this.categoria) {
      case CategoriaMensaje.Documento: return "Documento";
      case CategoriaMensaje.ResultadoEES: return "EES";
      case CategoriaMensaje.ConsultaPolicial: return "Servicios Policiales";
      case CategoriaMensaje.Dactilar: return "Dactilar";
      case CategoriaMensaje.Facial: return "Facial";
      case CategoriaMensaje.USK: return "Calidad biometría";
      case CategoriaMensaje.ConsultaIntegracion: return "Consultas EES";
      case CategoriaMensaje.Formulario: return "Formulario";
      case CategoriaMensaje.Envio: return "Envío";
      case CategoriaMensaje.Luces: return "Luces";
    }
    return null;
  }
}
