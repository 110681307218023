import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { RawImage } from 'projects/siepbio-comu/src/lib/model/raw-image';
import { EscanerEmpremtesService } from 'projects/siepbio-comu/src/lib/serveis/escaner-empremtes.service';
import { CalibrarEscanerEmpremtesComponent } from '../calibrar-escaner-empremtes/calibrar-escaner-empremtes.component';
import { CapturaDactilarEnViuComponent } from './captura-dactilar-en-viu/captura-dactilar-en-viu.component';

@Component({
  selector: 'lib-captura-empremtes',
  templateUrl: './captura-empremtes.component.html',
  styleUrls: ['./captura-empremtes.component.css'],
})
export class CapturaEmpremtesComponent implements OnInit {
  @Output() onRepetir = new EventEmitter();
  @Output() onSeleccioDits = new EventEmitter();
  @Output() onCapturaResult = new EventEmitter<RawImage>();
  @Input() dits: number[];

  @ViewChild('calibrar') calibrarComponent: CalibrarEscanerEmpremtesComponent;
  @ViewChild('enviu') enViu: CapturaDactilarEnViuComponent;

  escanejant: boolean = false;
  texteAjuda: string;
  esRepetirActiu: boolean = true;

  constructor(
    private escanerServei: EscanerEmpremtesService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  nomDitsCaptura(): string {
    if (!this.dits) return null;

    if (this.dits.length === 2) {
      if (JSON.stringify(this.dits) === JSON.stringify([6, 1]))
        return this.translate.instant('comu.captura-empremtes.dos-polzes');
      else if (JSON.stringify(this.dits) === JSON.stringify([7, 2]))
        return this.translate.instant('comu.captura-empremtes.dos-indexs');
      else if (JSON.stringify(this.dits) === JSON.stringify([8, 3]))
        return this.translate.instant('comu.captura-empremtes.dos-mitjos');
      else if (JSON.stringify(this.dits) === JSON.stringify([2, 3]))
        return this.translate.instant('comu.captura-empremtes.index-mig-dret');
      else if (JSON.stringify(this.dits) === JSON.stringify([8, 7]))
        return this.translate.instant(
          'comu.captura-empremtes.index-mig-esquerre'
        );
    } else if (this.dits.length === 1) {
      switch (this.dits[0]) {
        case 1:
          return this.translate.instant('comu.dits-dreta.polze');
        case 2:
          return this.translate.instant('comu.dits-dreta.index');
        case 3:
          return this.translate.instant('comu.dits-dreta.mig');
        case 4:
          return this.translate.instant('comu.dits-dreta.anular');
        case 5:
          return this.translate.instant('comu.dits-dreta.auricular');
        case 6:
          return this.translate.instant('comu.dits-esquerra.polze');
        case 7:
          return this.translate.instant('comu.dits-esquerra.index');
        case 8:
          return this.translate.instant('comu.dits-esquerra.mig');
        case 9:
          return this.translate.instant('comu.dits-esquerra.anular');
        case 10:
          return this.translate.instant('comu.dits-esquerra.auricular');
      }
    }
  }

  repetir() {
    this.onRepetir.emit();
  }

  async capturar(dits: Array<number> = null) {
    this.escanejant = true;
    this.texteAjuda = await this.translate
      .get('comu.captura-empremtes.text-ajuda')
      .toPromise();
    this.enViu.neteja();
    const fpi = await this.escanerServei.capturarAsincron(
      dits ? dits : this.dits
    );
    this.escanejant = false;
    this.texteAjuda = '';

    this.onCapturaResult.emit(fpi.rawImage);
  }

  cancelar() {
    this.escanejant = false;
    this.texteAjuda = '';
    this.escanerServei.abortarCaptura();
    this.enViu.neteja();
  }

  calibrarEscaner() {
    this.calibrarComponent.calibrar();
  }

  permetRepetir(): boolean {
    return !this.escanejant && this.esRepetirActiu;
  }
}
