export class Autoritzacio {
  autoritzat: boolean;
  observacions: string;
  erronia: boolean;
  sortida?: boolean;
  test: boolean;

  public constructor(init: Partial<Autoritzacio>) {
    Object.assign(this, init);
  }
}

export class UsuarioAutenticar
{
    nivel: string;
    plantilla: string;
    idn: string;
    keyAcceso: string;
    aplicationCNP: string;
    direccionIp: string;
    literalSistema: string;
}

export class AutenticacionSP{
  Operacion: string;
  Autenticacion?: Autenticacion;
  Frontera?: Frontera;
}

export class Autenticacion{
  Usuario?: string;
  Clave?: string;
  Pass?: string;
  LiteralSistema?: string;
  DireccionIp?: string;
  AplicacionCNP?: string;
}

export class Frontera{
  IdUbicacionDispositivo?: string;
  Sentido?: string;
}

export class AuditoriaFrontera{
  public idn: string;
  public plantilla: string;
}

export class DocumentoFrontera{
  public nacionalidad: string;
  public soporte: string;
  public tipo: string;
  public fechaCaducidad?: string;
  public fechaExpedicion?: string;
  public numeroIdentificacion?: string;
  public lineaMRZ?: string;
}

export class PersonaFrontera{
  public apellidos: string;
  public fechaNacimiento: string;
  public nacionalidad: string;
  public nombre: string;
  public sexo?: Sexo;
  public paisNacimiento?: string;
  public descripcionLocalidadNacimiento: string;
}

export enum Sexo { M, F, U }

export class SistemaFrontera{
  public sentido: string;
}

export class PasoFrontera{
  public checkFacial?: boolean;
  public checkDactilar?: boolean;
  public checkDocumento?: boolean;
  public keyAcceso: string;
  public auditoria: AuditoriaFrontera;
  public documento: DocumentoFrontera;
  public persona: PersonaFrontera;
  public sistema: SistemaFrontera;

  public imagenChip?: string;
  public imagenVivo?: string;

  public nist: string;

  public instanteInicioRegistro?: Date;
}

export class RespuestaPasoFrontera{
  public errores?: Array<ErrorRespuestaFrontera>;
  public posiblePositivo?: boolean;
  public posiblePositivoSpecified: boolean;
  public tomarDecision?: boolean;
  public tomarDecisionSpecified: boolean;
  public abrirPuerta?: boolean;
  public abrirPuertaSpecified: boolean;
  public entrevistarTCN?: boolean;
  public entrevistarTCNSpecified: boolean;
  public permitirPasoCNP?: boolean;
  public permitirPasoCNPSpecified: boolean;
  public identificadorPeticion: string;
  public identificadorCNP: string;
}

export class ErrorRespuestaFrontera{
  public codigo: string;
  public descripcion: string;
  public tipo: string;
}

export class ConsultaPolicial{
  public usuario: UsuarioConsultaPolicial;
  public ip_dispositivo: string;
  public tipo_algoritmo_facial?: string;
  public tipo_algoritmo_dactilar?: string;
  public modo_funcionamiento?: string;
}

export class UsuarioConsultaPolicial{
  public usuario1: string;
  public literalSistema: string;
  public keyAcceso: string;
  public direccionIp: string;
  public sentido: string;
  public plantilla: string;
}

export class RespuestaConsultaPolicial{
  public controlPersonas: boolean;
  public controlDocumentos: boolean;
  public entrevistaTCN: boolean;
  public controlPersonasSpecified: boolean;
  public controlDocumentosSpecified: boolean;
  public entrevistaTCNSpecified: boolean;
  public fechaVigencia?: string;
  public codigoError?: string;
  public literalError?: string;
}

export class RespuestaParametrosAbc{
  public paramPatrones: boolean;
  public paramDocumentoLectura: boolean;
  public paramDocumentoVerificacion: boolean;
  public paramFacialReconocimiento: boolean;
  public paramDactilarVerificacionHuellaViva: boolean;
  public paramFacialVerificacionCaraViva: boolean;
  public paramDactilarReconocimiento: boolean;
  public paramPatronesSpecified: boolean;
  public paramDocumentoLecturaSpecified: boolean;
  public paramDocumentoVerificacionSpecified: boolean;
  public paramFacialReconocimientoSpecified: boolean;
  public paramDactilarVerificacionHuellaVivaSpecified: boolean;
  public paramFacialVerificacionCaraVivaSpecified: boolean;
  public paramDactilarReconocimientoSpecified: boolean;
  public paramFacialPrecisionReconocimiento?: number;
  public paramDactilarPrecisionReconocimiento?: number;
 
  public fechaVigencia?: string;
  public listaPaises?: PaisRespuestaParametrosAbc;
  public codigoError?: string;
  public literalError?: string;
}

export class PaisRespuestaParametrosAbc{
  public pais?: Array<CodigoICAO>;
}

export class CodigoICAO{
  public codigoICAO?: string;
}

export class DatosAbrirPuerta{
  public auditoria: AuditoriaFrontera;
  public identificadorCNP: string;
  public identificadorPeticion: string;
  public keyAcceso: string;
  public observaciones: string;
}

export class RespuestaAbrirPuerta{
  public errores?: ErrorRespuestaFrontera;
  public abrirPuerta: boolean;
}

export class InformeMovilidad{
  public identificadorIdentidad: IdentificadorIdentidad;
  public keyAcceso: string;
  public auditoria: AuditoriaFrontera;
}

export class IdentificadorIdentidad{
  public ordinalCNP: string;
  public identificadorBDSN: string;
  public identificadorSIS: string;
}