<div class="container-fluid p-2">
    <div  class="row mb-2 mt-3" >
      <div class="col-sm-3 p-5" >
        <img src="assets/img/cnp_logo.webp" title="logo CNP" class="img-fluid" (click)="cargarDatosPruebas()">
        </div>
        <div class="col-sm-6 p-5" >
            <div class="card card-body">
                <div *ngIf="errorLogin != undefined" class="alert alert-danger">{{errorLogin}}</div>
                <form [formGroup]="loginForm" id="formLogin" (ngSubmit)="onSubmit()">
                    <div class="row form-group">
                        <label for="usuario" class="col-sm-5 col-form-label font-weight-bold">Usuario</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" id="usuario"  name="usuario" formControlName="usuario"
                            [ngClass]="{ 'is-invalid': submitted && f.usuario.errors }"
                            >
                            <div *ngIf="submitted && f.usuario.errors" class="invalid-feedback">
                                <div *ngIf="f.usuario.errors.required">Campo obligatorio</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="password" class="col-sm-5 col-form-label font-weight-bold">Contraseña</label>
                        <div class="col-sm-7">
                            <input type="password" class="form-control" id="password"  name="password" formControlName="password"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                            >
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Campo obligatorio</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="clave" class="col-sm-5 col-form-label font-weight-bold">Identificación</label>
                        <div class="col-sm-7">
                            <input type="text" class="form-control" id="clave"  name="clave" formControlName="clave"
                            [ngClass]="{ 'is-invalid': submitted && f.clave.errors }"
                            >
                            <div *ngIf="submitted && f.clave.errors" class="invalid-feedback">
                                <div *ngIf="f.clave.errors.required">Campo obligatorio</div>
                            </div>
                        </div>
                    </div>
                    <div class="row form-group">
                        <label for="sentidoMarcha" class="col-sm-5 col-form-label font-weight-bold">Sentido</label>
                        <div class="col-sm-7">
                            <select class="form-control" id="sentidoMarcha"  name="sentidoMarcha" formControlName="sentidoMarcha">
                                <option name="none">Sin determinar</option>
                                <option name="entrada">Entrada</option>
                                <option name="salida">Salida</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col">
                            <button type="submit" class="btn btn-info btn-lg w-100">Aceptar</button>
                        </div>
                    </div>
                </form>
               
            </div>
        </div>
        <div class="col-sm-3 p-5" >
          
        </div>
    </div>
</div>

<lib-missatge-modal #missatgeModal></lib-missatge-modal>