<div class="container-fluid ">
  <div class="row my-1">
    <div class="col-sm-12 ">
        
        <app-barra-acciones [accion]="Etapa.Dactilar">
           <div *ngIf="datosService.datosVerificacion.mensajeNoEsNecesarioTomarHuellas != undefined" class="alert alert-info float-left">{{datosService.datosVerificacion.mensajeNoEsNecesarioTomarHuellas}}</div>
        </app-barra-acciones>
    
    </div>
  </div>
  
      <ul ngbNav #navhuellas="ngbNav"  class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink
            ><strong>MANO DERECHA</strong>
            </a
          >
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-sm-12 p-4 text-center">
                <app-grup-dits
                class="d-inline-block"
                (onSeleccionatDisponibilitat)="onSeleccionatDisponibilitat($event)"
                (onSeleccionatDisponibilitatDit)="
                  onSeleccionatDisponibilitatDit($event)
                "
                (onNetejarEmpremtes)="onNetejarEmpremtes($event)"
                (onNetejarEmpremta)="onNetejarEmpremta($event)"
                (onIncrementarIntents)="onIncrementarIntents($event)"
                (onAnalitzaEmpremtes)="onAnalitzaEmpremtes($event)"
                [numeroIntent]="intentsDreta"
                [grupEmpremtes]="GrupEmpremtes.MA_DRETA"
                [dits]="ditsDreta()"
                [esRodada]="esRodada"
              ></app-grup-dits>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink
            ><strong>MANO IZQUIERDA</strong>
            </a
          >
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-sm-12 p-4 text-center">
                <app-grup-dits
                  class="d-inline-block"
                  (onSeleccionatDisponibilitat)="onSeleccionatDisponibilitat($event)"
                  (onSeleccionatDisponibilitatDit)="
                    onSeleccionatDisponibilitatDit($event)
                  "
                  (onNetejarEmpremtes)="onNetejarEmpremtes($event)"
                  (onNetejarEmpremta)="onNetejarEmpremta($event)"
                  (onIncrementarIntents)="onIncrementarIntents($event)"
                  (onAnalitzaEmpremtes)="onAnalitzaEmpremtes($event)"
                  [numeroIntent]="intentsEsquerra"
                  [grupEmpremtes]="GrupEmpremtes.MA_ESQUERRA"
                  [dits]="ditsEsquerra()"
                  [esRodada]="esRodada"
                ></app-grup-dits>
              </div>
            </div>
          </ng-template>

          
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink
            ><strong>PULGARES</strong></a
          >
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-sm-12 p-4 text-center">
                <app-grup-dits
                class="d-inline-block"
                (onSeleccionatDisponibilitatDit)="
                  onSeleccionatDisponibilitatDit($event)
                "
                (onNetejarEmpremtes)="onNetejarEmpremtes($event)"
                (onNetejarEmpremta)="onNetejarEmpremta($event)"
                (onIncrementarIntents)="onIncrementarIntents($event)"
                (onAnalitzaEmpremtes)="onAnalitzaEmpremtes($event)"
                [numeroIntent]="intentsPolzes"
                [grupEmpremtes]="GrupEmpremtes.POLZES"
                [dits]="ditsPolzes()"
                [esRodada]="esRodada"
              ></app-grup-dits>
              </div>
            </div>
          </ng-template>
        </li>
      </ul>

      <div
        [ngbNavOutlet]="navhuellas"
      ></div>

      

</div>