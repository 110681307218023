import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { Modal, ModalIcon } from 'projects/siepbio-comu/src/lib/model/modal';
import {
  MotiuIdentificacioFallida,
  ResultatIdentificacioFallida,
} from '../../model/resultat-identificacio';
import { CentreService } from '../../serveis/centre.service';

@Component({
  selector: 'lib-identificacio-fallida',
  templateUrl: './identificacio-fallida.component.html',
})
export class IdentificacioFallidaComponent implements OnInit {
  @Output() onCercaPerNIS = new EventEmitter<string>();
  @Output() onCercaPerCIC = new EventEmitter<string>();

  @Input() resultatIdentificacio: ResultatIdentificacioFallida;
  @Input() intent: number = 0;
  @Input() intentsCaptura: number;
  @Input() permetCercaPerCIC: boolean = true;

  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;

  cercaNIS: string;
  cercaCIC: string;
  centre: string;

  constructor(
    public centreService: CentreService, // Ho requereix el HTML
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  cercaPerNIS() {
    if (
      this.cercaNIS === null ||
      typeof this.cercaNIS === 'undefined' ||
      this.cercaNIS.trim() === ''
    ) {
      this.missatgeModal.obre(
        new Modal({
          titol: this.translate.instant(
            'comu.identificacio-fallida.cerca-nis.titol'
          ),
          icona: ModalIcon.Atencio,
          missatge: this.translate.instant(
            'comu.identificacio-fallida.cerca-nis.missatge'
          ),
          indicacions: this.translate.instant(
            'comu.identificacio-fallida.cerca-nis.indicacions'
          ),
          esTancable: true,
        })
      );
      return;
    }

    this.onCercaPerNIS.emit(this.cercaNIS);
  }

  cercaPerCIC() {
    if (this.centre === null || typeof this.centre === 'undefined') {
      this.missatgeModal.obre(
        new Modal({
          titol: this.translate.instant(
            'comu.identificacio-fallida.cerca-cic.titol'
          ),
          icona: ModalIcon.Atencio,
          missatge: this.translate.instant(
            'comu.identificacio-fallida.cerca-cic.missatge'
          ),
          indicacions: this.translate.instant(
            'comu.identificacio-fallida.cerca-cic.indicacions-falta-centre'
          ),
          esTancable: true,
        })
      );
      return;
    }

    if (
      this.cercaCIC === null ||
      typeof this.cercaCIC === 'undefined' ||
      this.cercaCIC.trim() === ''
    ) {
      this.missatgeModal.obre(
        new Modal({
          titol: this.translate.instant(
            'comu.identificacio-fallida.cerca-cic.titol'
          ),
          icona: ModalIcon.Atencio,
          missatge: this.translate.instant(
            'comu.identificacio-fallida.cerca-cic.missatge'
          ),
          indicacions: this.translate.instant(
            'comu.identificacio-fallida.cerca-cic.indicacions-falta-cic'
          ),
          esTancable: true,
        })
      );
      return;
    }

    this.onCercaPerCIC.emit(this.centre + ':' + this.cercaCIC);
  }

  intentsEsgotats(): boolean {
    return this.intent >= this.intentsCaptura;
  }

  titolMotiu(): string {
    if (this.resultatIdentificacio)
      switch (this.resultatIdentificacio.motiuFallida) {
        case MotiuIdentificacioFallida.PersonaNoTrobada:
          return this.translate.instant(
            'comu.identificacio-fallida.titol-motiu.no-trobat'
          );
        case MotiuIdentificacioFallida.MalaQualitatEmpremtes:
          return this.translate.instant(
            'comu.identificacio-fallida.titol-motiu.baixa-qualitat'
          );
        case MotiuIdentificacioFallida.PoquesEmpremtes:
          return this.translate.instant(
            'comu.identificacio-fallida.titol-motiu.poques-empremtes'
          );
        case MotiuIdentificacioFallida.MesDunaPersonaTrobada:
          return this.translate.instant(
            'comu.identificacio-fallida.titol-motiu.mes-una-persona-trobada'
          );
      }
    else return '';
  }

  texteMotiu() {
    if (this.resultatIdentificacio)
      switch (this.resultatIdentificacio.motiuFallida) {
        case MotiuIdentificacioFallida.PersonaNoTrobada:
          return this.translate.instant(
            'comu.identificacio-fallida.text-motiu.no-trobat'
          );
        case MotiuIdentificacioFallida.MalaQualitatEmpremtes:
          return this.translate.instant(
            'comu.identificacio-fallida.text-motiu.baixa-qualitat'
          );
        case MotiuIdentificacioFallida.PoquesEmpremtes:
          return this.translate.instant(
            'comu.identificacio-fallida.text-motiu.poques-empremtes'
          );
        case MotiuIdentificacioFallida.MesDunaPersonaTrobada:
          return this.translate.instant(
            'comu.identificacio-fallida.text-motiu.mes-una-persona-trobada'
          );
      }
    else return '';
  }
}
