import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfiguracioService } from 'projects/siepbio-comu/src/lib/serveis/configuracio.service';

@Injectable({
  providedIn: 'root'
})
export class LaxtonService {

  public host: string = "https://localhost:3005";
  constructor(private http: HttpClient, private configuracionService: ConfiguracioService) {
    if (configuracionService.parametrosConfiguracion.hostServiciosLaxton != undefined)
      this.host = configuracionService.parametrosConfiguracion.hostServiciosLaxton;
  }

  private async doCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    console.log("docall host documentreader", this.host);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (isGet)
      return await this.http
        .get(this.host + url, {
          headers: headers,
          params: params,
          observe: 'body',
          responseType: 'json',
        })
        .toPromise();
    else {
      return await this.http
        .post(
          this.host + url,
          json ? json : undefined,
          {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'json',
          }
        )
        .toPromise();
    }
  }

  private async manageCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(isGet, url, params, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      obj = await this.doCall(isGet, url, params, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  public async luces(
    activar: boolean
  ): Promise<string> {
    try{
    let response = await this.manageCall(
      false,
      '/api/laxton/luces',{on: String(activar)}
      );
      console.log("llamada a encender/apagar luces", response);
      if (response == undefined){
        return "Error en la llamada al servicio Luces";
      }
      let json = JSON.parse(JSON.stringify(response));
      if (json.message != undefined){
        //Se ha producido un error
        return json.message?.Mensaje;
      }
    return response;
    }
    catch(ex){
      console.log("Error luces",ex);
      
      let json =  JSON.parse(JSON.stringify(ex));
      console.log("Error luces json",json);
      return  json["status"];
    }
  }

  public async cerrarLuces(
  ): Promise<string> {
    try{
      let response = await this.manageCall(
        false,
        '/api/laxton/cierra'
        );
        console.log("llamada a cerrar servicio luces", response);
        
        let json = JSON.parse(JSON.stringify(response));
        if (json.message != undefined){
          //Se ha producido un error
          return json.message?.Mensaje;
        }

      return response;
    }
    catch(ex){
      console.log("Error cerrar luces",ex);
      let json =  JSON.parse(JSON.stringify(ex));
      console.log("Error cerrar luces json",json);
      return  json["status"];
    }
  }

  /*TODO falta implementar la llamada a barcode para recibir los datos leídos mediante código de barras
  public async barCode(
    
  ): Promise<string> {
    let response = await this.manageCall(
      true,
      '/api/laxton/luces'
      );
      console.log("llamada a barcode", response);
      
      let json = JSON.parse(JSON.stringify(response));
      if (json.message != undefined){
        //Se ha producido un error
        return json.message?.Mensaje;
      }

    return response;
  }
*/

}
