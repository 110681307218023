import { RespuestaBioSearch } from "projects/siepbio-comu/src/lib/model/traveler";
import { RespuestaPasoFrontera } from "projects/siepbio-comu/src/public-api";
import { DecisionTomada, Documento, Etapa, TipoDecision } from "./documento";
import { EmpremtaCaptura } from "./empremta-captura";
import { ResultatCapturaFoto } from "./resultat-captura";

export class DatosVerificacion {
  constructor()
  {
    // Nou sistema
    this.decisionesTomadas = new Map<Etapa, DecisionTomada>();
    this.decisionesTomadas[Etapa.Documento] = new DecisionTomada();
    this.decisionesTomadas[Etapa.Facial] = new DecisionTomada();
    this.decisionesTomadas[Etapa.Dactilar] = new DecisionTomada();
    this.decisionesTomadas[Etapa.Consultas] = new DecisionTomada();
    this.decisionesTomadas[Etapa.Formulario] = new DecisionTomada();
    
    this.huellasCapturadas = new Array<EmpremtaCaptura>();

    for (let i = 1; i <= 10; i++) {
      this.huellasCapturadas.push(
        new EmpremtaCaptura(i, 'assets/img/empremta.png')
      );
    }
  }

  public fotoCapturada?: string;
  public fotoImpresa?: string;
  public huellasCapturadas?: Array<EmpremtaCaptura>;
  public nistHuellas: string;
  public nistFacial: string;
  public mensajeNoEsNecesarioTomarHuellas?: string;
  public documentoLeido?: DocumentoUtilizado;
  public verificador?: Verificador;
  public estado?: EstadoVerificacion;

  public resultadoSearch?: ResultadoEES;
  //public errores?: Array<string>;
  public listaMensajes?: Array<MensajeProceso> = [];
 
  public resultadoFaceUSK: ResultadoFaceUSK | undefined;
  public resultadoCapturaFoto: ResultatCapturaFoto | undefined;
  public respuestaPasoFrontera: RespuestaPasoFrontera;
  public respuestaBioSearch: RespuestaBioSearch;
  public respuestasFormularioSchengen: RespuestasFormularioSchengen = new RespuestasFormularioSchengen();

  public decisionesTomadas: Map<Etapa,DecisionTomada>;
  public validacionMrz?: string;
  public validacionChip?: string;
  public validacionEES: string;
  public scoreImpresaVsChip?: number;
  public scoreFotoVsEES?: number;
  public scoreFotoVsChip?: number;
  public scoreFotoVsImpresa?: number;
  public coincideVizVsChip?: boolean;
  public coincideFotoVsEES?: boolean;
  public coincideFotoVsChip?: boolean;
  public coincideFotoVsImpresa?: boolean;
  
  public decisionFinal?: TipoDecision;

  //Traveler fields
  public travellerFileId?: string;
  public Version_2?: string;
  public imageSource?: string;
  public imageQualityValue?: number;
  public dateOfLastUpdate?: string;
  public lastInspectionId?: string;

  //resultados según etapas
  public resultadoDocumento?: boolean;
  public resultadoConsultaEES?: boolean;
  public resultadoVIZ?: boolean;
  public resultadoFacial?: boolean;
  public resultadoDactilar?: boolean;
  public resultadoConsultaPolicia?: boolean;
  public resultadoConsultaBiometrica?: boolean;
  public tiempos: TiemposDTO = new TiemposDTO();
  //Entry Exit Record
  public tipoMovimiento: TipoMovimiento;
  public borderCrossingPoint: string;
  //Entry (campos que solo tienen sentido en un entry)
  public authorisedStayUntil: Date;
  ///Description: Code table value indicating third-country nationals for which calculator is not applicable - who:
  ///i) is a member of the family of a Union citizen to whom Directive 2004/38/EC applies or of a national of a third country enjoying the right of free movement equivalent to that of Union citizens under an agreement between the Union and its Member States, on the one hand, and a third country, on the other; and
  ///ii) does not hold a residence card pursuant to Directive 2004/38/EC or a residence permit pursuant to Regulation (EC) No 1030/2002.
  ///[Article 2(4), Article 16(2)(c)]
  ///For visa-exempt third-country nationals, points (a), (b) and (c) of Articles 16(2), points (a) and (b) of Article 16(3) and Article 16(4) shall apply mutatis mutandis.
  ///[Article 17(2)]
  public personStatus: string;
  //FinEntry
  //Refusal (campos que solo tienen sentido en un refusal)
  ///Tiene que contener almenos 1 elemento
  public refusalReason: Array<string>;
  //FinRefusal
}

export class TiemposDTO {
  public instanteLecturaDocumento?: Date;
  public tiempoConsumidoEDIS?: number; //en ms
  public tiempoConsumidoPasoFrontera?: number; //en ms
  public instanteDecisionFinal?: Date;
  public instanteInicioRegistro?: Date;
  public tiempoTotalRegistro?: number; //en s
}


export enum TipoMovimiento {
  Entry, Exit, Refusal
}

export class DocumentoUtilizado {
  public mrz?: boolean;
  public rfid?: boolean;
  public MRZData?: Documento;
  public RFIDData?: Documento;
  public sod?: boolean;
  public mensajeError?: string;
  public validacions?: ValidationData;
  public tiempoConsumidoEDIS: number;
}

export class ResultadoEES {
  public registros?: Array<ResultadoSearchEES>;
  public encontrado?: boolean;
}

export class ResultadoSearchEES {
  public foto?: string;
  public huellas?: Array<EmpremtaCaptura>;
  public documento?: Documento;
  public resultado?: ResultadoAutorizacion;
}

export class Verificador {
  public fecha?: Date;
  public estacion?: string;
  public usuario?: string;
}

export class ResultadoAutorizacion {
  public autorizado?: boolean;
  public motivoNoAutorizado?: string;
}
export enum EstadoVerificacion {
  NO_VERIFICADO = 0,
  VERIFICADO = 1,
}

export class ResultadoFaceUSK {
  public decision: DecisionResultadoFaceUSK;
  public warning: WarningResultadoFaceUSK;
  public consolidatedQuality: number;
  public bioQuality: number;
}

export enum DecisionResultadoFaceUSK {
  UNDETERMINED = 0,
  ACCEPTABLE = 1,
  LOWQUALITY = 2,
  LOWQUALITY_WEARING_MASK_DETECTED = 3,
  LOWQUALITY_NON_FRONTAL_FACE_ORIENTATION = 4,
  LOWQUALITY_OPEN_MOUTH_DETECTED = 5,
}

export enum WarningResultadoFaceUSK {
  NoWarning = 0,
  TOO_SMALL_IMAGE = 1,
  TOO_LARGE_IMAGE = 2,
  TOO_FAR_CAPTURE = 3,
}

export class FingerQualityRequest {
  public fingerPosition: FingerPosition;
  public colorSpace: ColorSpace;
  public resolution: number;
  public height: number;
  public width: number;
  public pixels: string;
}

export class SlapQualityResult {
  public slapScore: number;
  public slapQuality: string;
  public fingersScore: Array<FingerScore>;
}

export enum FingerPosition {
  UNKNOWN_FINGER = 0xFF,
  LEFT_LITTLE = 10,
  LEFT_RING = 9,
  LEFT_MIDDLE = 8,
  LEFT_INDEX = 7,
  LEFT_THUMB = 6,
  RIGHT_LITTLE = 5,
  RIGHT_RING = 4,
  RIGHT_MIDDLE = 3,
  RIGHT_INDEX = 2,
  RIGHT_THUMB = 1
}

export enum ColorSpace {
  UNKNOWN = -1,
  COLORSPACE_ENUM_MAXVALUE = 6,
  RGBA32 = 5,
  RGB24 = 4,
  BGR24 = 3,
  Y16LE = 2,
  Y8 = 1,
  COLORSPACE_ENUM_MINVALUE = 0  
}

export enum SlapQuality {
  UNDETERMINED = 0,
  POOR = 1,
  GOOD = 2,
}

export class FingerScore {
  public score: number;
  public position: FingerPosition;
}

export class ResultadoCalidadFoto {
  public code?: number;
  public message?: string;
}

export class ResultadoColorFondo {
  public color: string;
  public texto: string;
}

export class RespuestasFormularioSchengen {
  public values: Map<string, string> = new Map<string, string>();
}

export class MensajeProceso{
  public tipo: TipoMensajeProceso;
  public categoria: CategoriaMensaje;
  public texto: string;
}

export enum TipoMensajeProceso{
  Validacion, Error, Warning
}

export class ValidationData{
  public resultatValidacions?: Array<ResultatValidacio>;
  public dits?: Array<string>; //en base 64
}

export class ResultatValidacio
{
    public tipus?: Validacio;
    public correcte?: boolean;
    public resultat?: string;
}

export enum CategoriaMensaje{
  Documento, ResultadoEES, VIZ, Facial, USK, Luces, Dactilar, ConsultaPolicial, ConsultaIntegracion, Formulario, Envio
}

export enum Validacio{
  BAC, AA, PA, CHIP, TA 
}

export class ResumenDatosSideBar{
  public foto?:string;
  public nombre?: string;
  public apellidos?: string;
  public numeroDocumento?: string;
  public fechaNacimiento?: string;
  public edad?: string;
  public nacionalidad?: string;
  public estadoResolucion?: EstadoResolucion;
}

export class EstadoResolucion{
  public autorizado?: boolean;
  public fechaMovimiento?: string;
  public motivo?: string;
  public sentido?: string;
  public diasEstancia?: string;
  public codigoPais?: string;
}