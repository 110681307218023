import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  ElementRef,
} from '@angular/core';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CalibrarEscanerEmpremtesComponent } from 'projects/siepbio-comu/src/lib/components/calibrar-escaner-empremtes/calibrar-escaner-empremtes.component';
import {
  EscanerEmpremtesService,
  Modal,
  ModalIcon,
} from 'projects/siepbio-comu/src/public-api';
import { Disponibilitat } from '../../model/disponibilitat';
import { ResultatCaptura } from '../../model/resultat-captura';
import { AplicacioService } from '../../serveis/aplicacio.service';

import {
  EmpremtaAmbTipus,
  EmpremtaCaptura,
  EmpremtesAnalitzades,
} from '../../model/empremta-captura';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dit',
  templateUrl: './dit.component.html',
  styleUrls: ['./dit.component.css'],
  providers: [NgbModal],
})
export class DitComponent {
  @Input() dit: EmpremtaCaptura;
  @Input() esRodada: boolean;

  @Output() carregarEmpremtaEvent = new EventEmitter();
  @Output('onAnalitzaEmpremta')
  onAnalitzaEmpremta = new EventEmitter<EmpremtesAnalitzades>();
  @Output('onSeleccionatDisponibilitatDit')
  onSeleccionatDisponibilitatDit = new EventEmitter<EmpremtaAmbTipus>();
  @Output('onNetejarEmpremta')
  onNetejarEmpremta = new EventEmitter<EmpremtaAmbTipus>();
  @ViewChild('calibrar') calibrarComponent: CalibrarEscanerEmpremtesComponent;
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;
  @ViewChild('capturaTemplate') capturaTemplate: ElementRef;

  Disponibilitat: typeof Disponibilitat = Disponibilitat;
  disponibilitatDit: Disponibilitat;
  coincident: boolean;
  closeResult = '';
  escanejant: boolean = false;
  capturaModal: NgbModalRef;

  constructor(
    private aplicacioServei: AplicacioService,
    private modalService: NgbModal,
    private escanerServei: EscanerEmpremtesService,
    private translate: TranslateService
  ) {}

  seleccionarDisponibilitat(disponibilitat: Disponibilitat): void {
    const empremta = new EmpremtaCaptura();
    empremta.numeroDit = this.dit.numeroDit;
    empremta.disponibilitat = disponibilitat;
    const empremtaAmbTipus = new EmpremtaAmbTipus(empremta, this.esRodada);
    this.onSeleccionatDisponibilitatDit.emit(empremtaAmbTipus);
    this.dit.disponibilitat = disponibilitat;
  }

  getImatge(): any {
    switch (this.dit.disponibilitat) {
      case Disponibilitat.NO_DISPONIBLE:
        return 'assets/img/no_disponible.png';
      case Disponibilitat.AMPUTAT:
        return 'assets/img/amputat.png';
      case Disponibilitat.NORMAL: {
        if (this.dit.imatge != null) return this.dit.imatge;
        else return 'assets/img/empremta.png';
      }
    }
  }

  async escanejar() {
    this.escanejant = true;
    const fpi = await this.escanerServei.capturarAsincron(
      [this.dit.numeroDit],
      this.esRodada
    );

    this.escanejant = false;
    if (!this.esRodada) this.capturaModal.dismiss();

    if (fpi)
      this.onAnalitzaEmpremta.emit(
        new EmpremtesAnalitzades([this.dit], this.esRodada, fpi)
      );
  }

  async capturar() {
    this.capturaModal = this.modalService.open(this.capturaTemplate, {
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      backdrop: 'static',
    });

    this.capturaModal.result.then(
      (result) => {},
      (reason) => {
        if (reason != undefined) this.cancelar();
      }
    );

    await this.escanejar();
  }

  async acceptar(): Promise<void> {
    try {
      await this.escanerServei.acceptarCaptura();
      this.escanejant = false;
      this.capturaModal.dismiss();
    } catch (ex) {
      this.missatgeModal.obre(
        new Modal({
          titol: await this.translate
            .get('dit.missatge-error-rodada.titol')
            .toPromise(),
          missatge: await this.translate
            .get('dit.missatge-error-rodada.missatge')
            .toPromise(),
          indicacions: await this.translate
            .get('dit.missatge-error-rodada.indicacions')
            .toPromise(),
          icona: ModalIcon.Atencio,
        })
      );
      await this.escanejar();
    }
  }

  cancelar(): void {
    this.escanejant = false;
    this.escanerServei.abortarCaptura();
    this.modalService.dismissAll();
  }

  async calibrarEscaner() {
    this.escanejant = false;
    await this.escanerServei.abortarCaptura();
    await this.calibrarComponent.calibrar();
    await this.escanerServei.capturar([this.dit.numeroDit], this.esRodada);
    this.escanejant = true;
  }

  netejarEmpremta() {
    this.onNetejarEmpremta.emit(new EmpremtaAmbTipus(this.dit, this.esRodada));
  }

  ampliarImatge(contentE: any): void {
    if (
      this.dit.imatge != null &&
      this.dit.disponibilitat == Disponibilitat.NORMAL &&
      this.dit.imatge != 'assets/img/empremta.png'
    ) {
      this.modalService.open(contentE, { size: 'lg' });
    }
  }

  getNomDit(sencer: boolean, alt: boolean = false): string {
    if (this.dit != null) {
      if (alt) {
        if (this.dit.disponibilitat == Disponibilitat.AMPUTAT)
          return this.translate.instant('dit.dit-amputat');
        if (this.dit.disponibilitat == Disponibilitat.NO_DISPONIBLE)
          return this.translate.instant('dit.dit-no-disponible');
      }
      return this.aplicacioServei.obtenirNomDit(this.dit.numeroDit, sencer);
    } else return '';
  }

  esCorrecte(): boolean {
    return this.dit.resultatCaptura === ResultatCaptura.CORRECTA;
  }

  getColorFons(): string {
    if (
      this.dit.resultatCaptura != null &&
      this.dit.disponibilitat === Disponibilitat.NORMAL
    ) {
      if (this.dit.noEsMatched == true) return 'bgTaronja';
      switch (this.dit.resultatCaptura) {
        case ResultatCaptura.BAIXA_QUALITAT:
          return 'bgTaronja';
        case ResultatCaptura.ERRONI:
          return 'bgBlau';
        case ResultatCaptura.MALA_QUALITAT:
          return 'bgVermell';
      }
    }
    return '';
  }

  getResultatText(): string {
    if (
      this.dit.resultatCaptura != null &&
      this.dit.disponibilitat === Disponibilitat.NORMAL
    ) {
      let resultat = '';
      if (this.dit.noEsMatched == true) {
        resultat = 'no-coincideix';
      } else {
        switch (this.dit.resultatCaptura) {
          case ResultatCaptura.ERRONI:
            resultat = 'erroni';
            break;
          case ResultatCaptura.MALA_QUALITAT:
            resultat = 'mala-qualitat';
            break;
          case ResultatCaptura.BAIXA_QUALITAT:
            resultat = 'baixa-qualitat';
            break;
          default:
            return '';
        }
      }

      return this.translate.instant('dit.resultat-captura.' + resultat);
    }
    return '';
  }

  getBadge(aSota: boolean): string {
    if (
      this.dit.resultatCaptura != null &&
      this.dit.disponibilitat === Disponibilitat.NORMAL
    ) {
      if (aSota == true && this.dit.noEsMatched == true) return 'warning';
      switch (this.dit.resultatCaptura) {
        case ResultatCaptura.CORRECTA:
          return 'success';
        case ResultatCaptura.BAIXA_QUALITAT:
          return 'warning';
        case ResultatCaptura.ERRONI:
          return 'info';
        case ResultatCaptura.MALA_QUALITAT:
          return 'danger';
      }
    }
    return 'light';
  }

  getTitol(): string {
    if (this.dit != null) {
      let tpart1 =
        this.translate.instant('dit.titol-modal.poseu-dit') +
        this.aplicacioServei.obtenirNomDit(this.dit.numeroDit, true);
      let tpart2 = this.translate.instant('dit.titol-modal.sobre-lector');
      let tpartfin = '';
      if (this.esRodada) {
        tpartfin += this.translate.instant('dit.titol-modal.fer-rodolar');
      }
      return tpart1 + tpart2 + tpartfin;
    }
    return null;
  }

  getTexteRodada(): string {
    let ma = this.translate.instant('dit.tipus-ma.posada-upper');
    if (this.esRodada) {
      ma = this.translate.instant('dit.tipus-ma.rodada-upper');
    }
    return ma;
  }

  getPuntuacio(): string {
    if (
      this.dit.puntuacio != null &&
      this.dit.puntuacio > 0 &&
      this.dit.disponibilitat === Disponibilitat.NORMAL
    ) {
      return this.dit.puntuacio + '%';
    } else return '';
  }

  esMillorCaptura(): boolean {
    return (
      this.dit.esMillor && this.dit.disponibilitat === Disponibilitat.NORMAL
    );
  }

  fonsBotoCaptura(): string {
    if (this.esRodada && this.dit.rawImatge == null) {
      return 'btn-info';
    } else {
      return 'btn-outline-danger';
    }
  }
}
