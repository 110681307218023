export class ResultatEmparellament {
  ProbeWidth: number;
  ProbeHeight: number;
  CandidateWidth: number;
  CandidateHeight: number;

  Parelles: Parella[];
}

export class Parella {
  Pair: ParellaMinucia;
  Reference: ParellaMinucia;
}

export class ParellaMinucia {
  Probe: Minucia;
  Candidate: Minucia;
}

export class Minucia {
  Position: Posicio;
  Direction: number;
  Type: MinutiaType;
}

export class Posicio {
  X: number;
  Y: number;
}

export enum MinutiaType {
  Ending,
  Bifurcation,
  Other,
}
