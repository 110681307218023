export class Errorable {
  rc?: string;
}

export class PersonaTemplates extends Errorable {
  personaReferencia?: string;
  minucies?: PersonaDit[];
  nis?: number;
  cic?: string;
  nom?: string;
  foto?: string;
}

export class PersonaDit {
  dit?: number;
  minucies?: string;
}

export class InicialitzarSoftwareResult extends Errorable {
  desplegamentId?: number;
  deshabilitada?: boolean;
  parametres?: object;
  centre?: string;
  porta?: string;
}

export class Login {
  infojwt?: string;
}

export class PersonaInfo extends Errorable {
  referencia?: string;
  nomComplet?: string;
  fotoFacialFrontal?: string;
  detalls?: string;
  cic?: string;
  nis?: string;
  ultimAcces?: PersonaAcces;
  centre?: string;
  situacio?: string;
  fotos?: Foto[];
  accessos?: PersonaAcces[];
  enrolamentIdentificacio: any;
}

export class PersonaAcces extends Errorable {
  id?: number;
  sortida?: boolean;
  data?: number;
  isAFIS?: boolean;
  responsableAcces?: string;
  acces?: CentreAccesDTO;
  enrolamentTrobat: any;
  personaTrobat?: PersonaInfo;
  fotoRegistre?: string;
  autoritzat?: boolean;
  manual?: boolean;
}

export class Foto {
  public imatge?: string;
  public descripcio?: string;
  public tipus?: TipusFoto;
  public index?: number;

  constructor(imatge?: string, descripcio?: string, tipus?: TipusFoto) {
    this.imatge = imatge;
    this.descripcio = descripcio;
    this.tipus = tipus;
  }
}

export enum TipusFoto {
  FACIAL_FRONTAL = 0,
  PERFIL_DRET = 1,
  PERFIL_ESQUERRA = 2,
  CICATRIU = 3,
  MARCA = 4,
  TATUATGE = 5,
}

export class CentreAccesDTO {
  id?: number;
  centre?: string;
  porta?: string;
}

export class Enrolament extends Errorable {
  operador?: string;
  referencia?: string;
  porta?: string;
  dataEnrolat?: number;
  id?: number;
  empremptes?: Empremta[];
}

export class Empremta {
  posicio?: number;
  rodada?: boolean;

  disponible?: boolean;
  motiuNoDisponible?: string;
  imatge?: string;
  nfiq?: number;
  qualitat_iso?: number;

  // Calculades posteriorment
  numeroMatch?: number;
  png?: string;
}

export class Consulta extends Errorable {
  id?: number;
  nom?: string;
  estat?: number;
  detall?: PersonaDTO;
  coincidencies?: ResultatCerca[];
  lloc?: Lloc;
  tipus?: number;
}

export class Lloc {
  latitud?: number;
  longitud?: number;
  ciutat?: string;
}

export class ResultatCerca {
  id?: number;
  nomComplet?: string;
  centre?: string;
  fotoFacialFrontal?: string;
  detalls?: string;
  cic?: string;
  nis?: number;
  situacio?: string;
  millor?: number;
  puntuacio?: number;
  referencia?: string;
}

export class DecisioCoincidenciaConsulta {
  id?: number;
  candidat?: boolean;
  operador?: string;
  dataVerifica?: number;
}

export class PersonaDTO {
  nam?: string;
  dob?: string;
  pob?: string;
  sex?: string;
  dpr?: string;
  rfp?: string;
  poa?: string;
  mn1?: string;
}

export class DecisioCoincidenciaConsultaDTO {
  id?: number;
  candidat?: boolean;
  operador?: string;
  dataVerifica?: number;
}

export class RegistreAcces {
  motiu: string | undefined;
  is_sortida: boolean | undefined;
  ndit_esquerre: number = 0;
  imatge_dit_esquerre_wsq: Blob | undefined;
  ndit_dret: number = 0;
  imatge_dit_dret_wsq: Blob | undefined;
  persona_trobada_ref: string | undefined;
  is_error: boolean = false;
  observacio: string | undefined;
  persona_esperada_ref: string | undefined;
  acces_concedit: boolean = false;
  acces_id: string | undefined;
  data_control: string | undefined;
}

export class RegistreAccesFacial {
  motiu: string | undefined;
  is_sortida: boolean | undefined;
  foto: Blob | undefined;
  persona_trobada_ref: string | undefined;
  is_error: boolean = false;
  observacio: string | undefined;
  persona_esperada_ref: string | undefined;
  acces_concedit: boolean = false;
  acces_id: string | undefined;
  data_control: string | undefined;
}

export class DesplegamentInfo extends Errorable {
  id?: number;
  dataPrimerInici?: number;
  dataDarreraExecucio?: number;
  estacioBiometrica?: string;
  software?: string;
  deshabilitat?: boolean;
}

export class EstacioInfo extends Errorable {
  nom?: string;
  acces?: AccesInfo;
}

export class AccesInfo {
  id?: number;
  centre?: string;
  porta?: string;
}

export class CentresDTO {
  codi?: string;
  descripcio?: string;
}

export class TemplateFacialPersona {
  personaReferencia?: string;
  minucies: any; // A siepbio és un PersonaDit
  nom?: string;
  NIS?: number;
  CIC?: string;
  foto?: string;
  facial?: string;
}

export class TemplateFacial {
  //dit: number;
  minucies?: string;
}
