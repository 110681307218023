<div class="container-fluid p-2">
 
    <div class="row justify-content-center">
      <div class="col-sm-8">
        <div class="row justify-content-center">
          <div class="col col-sm-3 mx-auto mt-1" >
            <div class="card">
                  <img
                    class="card-img-top"
                    src="{{ fotoDocumento }}"
                    alt="{{ 'comu.accio.ampliar' | translate }}"
                    (click)="ampliarImatge(fotoAmpliada,fotoDocumento)"
                    title="{{ 'comu.accio.ampliar' | translate }}"
                    aria-label="Ampliar imatge"
                  />
              <div class="card-footer text-center p-3">
                <div class="font-weight-bold">Foto del chip</div>
              </div>
            </div>
          </div>
        <div class="col col-sm-2 mx-auto my-auto">
            <div class="alert alert-{{coincidente == undefined ? '' : coincidente === true ? 'success' : 'danger'}} text-center">
              {{(coincidente == undefined ? "NO COMPARADO" : coincidente === true ? "COINCIDE" : "NO COINCIDE")}}
              <hr class="my-2">
              <div *ngIf="calculandoScore" class="spinner-border  text-info" role="status">
                <span class="sr-only"
                  >Calculando...</span
                >
              </div>
              <div class="h5 text-center">{{coincidente == undefined ? "" :"Score"}}</div>
              <div class="h4 text-center">{{normalizaScore(score)}}</div>
             </div>
          </div>  
      <div class="col col-sm-3 mx-auto mt-1" >
        <div class="card">
              <img
                class="card-img-top"
                src="{{ fotoImpresa }}"
                alt="{{ 'comu.accio.ampliar' | translate }}"
                (click)="ampliarImatge(fotoAmpliada,fotoImpresa)"
                title="{{ 'comu.accio.ampliar' | translate }}"
                aria-label="Ampliar imatge"
              />
          <div class="card-footer text-center p-2">
            <button
              type="button"
              role="button"
              (click)="afegirFoto()"
              class="btn btn-info btn-lg full-width "
              title="{{accio }}"
              aria-label="tomar foto documento"
            >  {{ accio }}
            </button>
            <button
            *ngIf="datosService.datosVerificacion?.fotoImpresa"
            type="button"
            role="button"
            (click)="borrarFoto()"
            class="btn btn-warning btn-lg ml-2 px-2"
            title="Eliminar imagen"
            aria-label="eliminar foto"
          >  <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-trash"
          viewBox="0 0 16 16"
        >
          <path
            d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
          />
          <path
            fill-rule="evenodd"
            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
          />
        </svg>
          </button>
          </div>
        </div>
      </div>
        </div>
      </div>
        
      
      
      
      
    </div>
  
  
  
  </div>
  
  
  <app-live-view-foto
    #liveView
    (onFoto)="onFotoCapturada($event)"
    [activarControlesCalidadFoto]="false"
  ></app-live-view-foto>
  
  <ng-template #fotoAmpliada let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{ "comu.foto-ampliada" | translate }}
      </h4>
      <button
        type="button"
        class="btn  btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('tanca')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
      <div class="text-center">
        <img
          class="img-fluid"
          alt=" {{ 'comu.foto-ampliada' | translate }}"
          src="{{ fotoZoom }}"
        />
      </div>
    </div>
  </ng-template>
  <lib-missatge-modal #missatgeModal></lib-missatge-modal>


