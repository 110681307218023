import { RawImage } from './raw-image';

export class AnsiNistData {
  public Version?: string;
  public Version_2?: string;
  public Priority?: string;
  public TypeTransaction?: string;
  public DestinationAgencyIdentifier?: string;
  public OriginatingAgencyIdentifier?: string;
  public transactionReference?: string;
  public NativeScanningResolution?: string;
  public NominalTransmittingResolution?: string;
  public ImpressionType?: string;
  public scaleUnits?: string;
  public CompressionAlgorithm?: string;
  public BitsPerPixel?: string;
  public PhotoCompressionAlgorithm?: string;
  public ReasonToTakePicture?: string;
  public hps?: string;
  public vps?: string;
  public Tcn?: string;
  public CaptureDate?: number;
  public Id?: string;

  public OriginatingFingerprintReadingSystem?: string;
  public MinutaeDataReadMethod?: string;
  public Minucias?: ConfigMinucias;
}

class ConfigMinucias {
  public Planas?: boolean;
  public Rodadas?: boolean;
  public Tipo?: string;
}

export enum MotiuAbsencia {
  Amputatat = 0,
  NoDisponible = 1,
}

export class Fingerprint {
  public NumeroDit?: number;
  public Imatge?: RawImage;
  public MotiuAbsencia?: MotiuAbsencia;
  public ImpressioTipus?: ImpressioTipus;
}

export class FSMT {
  public ImatgeTipus?: ImatgeTipus;
  public Imatge?: string; //Base64 sense capçalera
  public Posat?: Posat;
  public Descripcio?: string;
}

export enum Posat {
  Frontal = 'F',
  Right = 'R',
  Left = 'L',
  Angle = 'A',
}

export enum ImatgeTipus {
  Face = 'FACE',
  Scar = 'SCAR',
  Mark = 'MARK',
  Tattoo = 'TATTOO',
}

export enum ImpressioTipus {
  CapturaEnViuPosada = 0,
  CapturaEnViuRodada = 1,
  CapturaNoEnViuPosada = 2,
  CapturaNoEnViuRodada = 3,
  LatentImpressio = 4,
  LatentTrassat = 5,
  LatentFoto = 6,
  LatentElevada = 7,
}
