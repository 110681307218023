import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
   DatosService
} from 'projects/siepbio-comu/src/public-api';
import { Pestanya } from '../../model/pagina';
import { ApartatService } from '../../serveis/apartat.service';
import { ContexteService } from '../../serveis/contexte.service';


@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.css']
})

//CLASE OBSOLETA
export class DocumentoComponent implements OnInit {
  
  missatgeCopiat: string;
  msgHideAndShow: boolean = false;

  disabledBarcode = true;
  disabledFotoImpresa = false;
  disabledChip = false;

  Pestanya: typeof Pestanya = Pestanya;
  
  pestanyaDocActual: Pestanya = Pestanya.DocumentacionMrz;
  activeDoc = 1;
  
  constructor(
    public contexteServei: ContexteService,
    public apartatServei: ApartatService,
    private translate: TranslateService,
    public datosService: DatosService
  ) {
  }

  ngOnInit(): void {
  
  }

  
  
}
