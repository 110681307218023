import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  PersonaIdentificada,
  ResultatIdentificacioExitosa,
} from '../../model/resultat-identificacio';

@Component({
  selector: 'lib-persona-identificada',
  templateUrl: './persona-identificada.component.html',
  styleUrls: ['./persona-identificada.component.css'],
})
export class PersonaIdentificadaComponent implements OnInit {
  @Output() onInspeccionar = new EventEmitter<PersonaIdentificada>();
  @Input() resultatIdentificacio: ResultatIdentificacioExitosa;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  titol() {
    if (this.resultatIdentificacio) {
      if (this.resultatIdentificacio.dubtos)
        return this.translate.instant('comu.persona-identificada.dubtos');
      else if (
        this.resultatIdentificacio.NISbuscat ||
        this.resultatIdentificacio.CICbuscat
      )
        return this.translate.instant(
          'comu.persona-identificada.identificacio-manual'
        );
      if (this.resultatIdentificacio.personesIdentificades.length === 1)
        return this.translate.instant(
          'comu.persona-identificada.persona-identificada'
        );
      else if (this.resultatIdentificacio.personesIdentificades.length > 1)
        return this.translate.instant(
          'comu.persona-identificada.persones-trobades'
        );
    }
  }

  colorTitol() {
    if (this.resultatIdentificacio) {
      if (this.resultatIdentificacio.dubtos) return 'warning';
      else if (
        this.resultatIdentificacio.NISbuscat ||
        this.resultatIdentificacio.CICbuscat
      )
        return 'info';
      else return 'success';
    }
  }

  inspeccionar(persona: PersonaIdentificada) {
    this.onInspeccionar.emit(persona);
  }

  situacio(persona: PersonaIdentificada): string {
    if (persona.situacio == 'D') {
      return (
        this.translate.instant('comu.persona-identificada.actiu') +
        (persona.centre
          ? this.translate.instant('comu.persona-identificada.a') +
            persona.centre
          : '')
      );
    } else return this.translate.instant('comu.persona-identificada.no-actiu');
  }

  darrerRegistreTipus(persona: PersonaIdentificada): string {
    return persona.darrerAcces.sortida
      ? this.translate.instant('comu.autoritzacio.sortida')
      : this.translate.instant('comu.autoritzacio.entrada');
  }

  darrerRegistreData(persona: PersonaIdentificada): string {
    return new Date(persona.darrerAcces.data).toLocaleString();
  }

  selecciona(persona: PersonaIdentificada) {
    this.resultatIdentificacio.personaIdentificada = persona;
  }

  colorScore(persona: PersonaIdentificada) {
    // Quan estigui implementada la configuració moure aquests valors
    if (persona.score < 40) return 'danger';
    else if (persona.score < 60) return 'warning';
    else return 'success';
  }

  copiarNIS(inputElement): void {
    var range = document.createRange();
    range.setStartBefore(inputElement.firstChild);
    range.setEndAfter(inputElement.lastChild);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
    document.execCommand('copy');
    sel.removeAllRanges();
  }
}
