<div class="card">
  <h5 class="card-header bg-warning">
    <span class="font-weight-bold">{{ titolMotiu() }}</span>
    <ul
      *ngIf="intentsEsgotats()"
      class="nav nav-tabs card-header-tabs"
      role="tablist"
    >
      <li class="nav-item" role="presentation">
        <a
          class="nav-link font-weight-bold active"
          id="nis-tab"
          href="#nis"
          data-toggle="tab"
          role="tab"
          aria-controls="nis"
          aria-selected="true"
          >{{ "comu.identificacio-fallida.cerca-nis.cerca" | translate }}</a
        >
      </li>
      <li *ngIf="permetCercaPerCIC" class="nav-item" role="presentation">
        <a
          class="nav-link font-weight-bold"
          id="cic-tab"
          href="#cic"
          data-toggle="tab"
          role="tab"
          aria-controls="cic"
          aria-selected="true"
          >{{ "comu.identificacio-fallida.cerca-cic.cerca" | translate }}</a
        >
      </li>
    </ul>
  </h5>

  <div class="card-body">
    <div *ngIf="!intentsEsgotats()">
      <p class="card-text">
        <strong>{{
          "comu.identificacio-fallida.panell.intenti-de-nou" | translate
        }}</strong>
      </p>

      <p class="card-text text-danger">
        <strong>{{
          "comu.identificacio-fallida.panell.intents"
            | translate: { intent: intent, intentsCaptura: intentsCaptura }
        }}</strong>
      </p>
    </div>

    <div *ngIf="intentsEsgotats()" class="tab-content">
      <div
        class="tab-pane fade show active"
        id="nis"
        role="tabpanel"
        aria-labelledby="nis-tab"
      >
        <p class="card-text">
          <strong>{{ texteMotiu() }}</strong>
        </p>
        <form>
          <div class="form-row align-items-center">
            <div class="col-sm-4 mr-1">
              <input
                type="text"
                [(ngModel)]="cercaNIS"
                class="form-control"
                placeholder="NIS"
                name="NISinput"
                id="NISinput"
                aria-label="NIS"
              />
            </div>

            <div class="col-sm-3">
              <button
                (click)="cercaPerNIS()"
                type="button"
                class="btn btn-danger"
              >
                {{ "comu.accio.cercar" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        *ngIf="permetCercaPerCIC"
        class="tab-pane fade"
        id="cic"
        role="tabpanel"
        aria-labelledby="cic-tab"
      >
        <p class="card-text">
          <strong>{{ texteMotiu() }}</strong>
        </p>

        <form>
          <div class="form-row align-items-center">
            <div class="col-sm-6 mr-1">
              <label class="sr-only" for="centreSelect">{{
                "comu.centre" | translate
              }}</label>
              <select
                class="form-control"
                id="centreSelect"
                [(ngModel)]="centre"
                name="centre"
              >
                <option
                  *ngFor="let c of centreService.centresDTO"
                  [value]="c.codi"
                >
                  {{ c.descripcio }}
                </option>
              </select>
            </div>

            <div class="col-sm-3 mr-1">
              <label class="sr-only" for="CICinput">CIC</label>
              <input
                id="CICinput"
                type="text"
                [(ngModel)]="cercaCIC"
                class="form-control"
                placeholder="CIC"
                name="CICinput"
              />
            </div>

            <div class="col-sm-2">
              <button
                (click)="cercaPerCIC()"
                type="button"
                class="btn btn-danger"
              >
                {{ "comu.accio.cercar" | translate }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<lib-missatge-modal #missatgeModal></lib-missatge-modal>
