import { EventEmitter, Injectable, Output, ViewChild } from '@angular/core';

import {
  ConfiguracioService,
  DactilarService,
  Error,
  EscanerEmpremtesService,
  Excepcio,
  IsoQuality,
  Modal,
  ModalIcon,
  RawImage,
} from 'projects/siepbio-comu/src/public-api';
import {
  ServeiCancelat,
  SiepbioServicesService,
} from 'projects/siepbio-comu/src/lib/serveis/siepbio-services.service';
import {
  AnsiNistData,
  Fingerprint,
  ImpressioTipus,
  MotiuAbsencia
} from 'projects/siepbio-comu/src/lib/model/nist';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { EmpremtaCaptura } from '../model/empremta-captura';
import { ContexteService } from './contexte.service';
import {
  ResultatCapturaFallida,
  ResultatCapturaExitosa,
  ResultatCapturaEmpremta,
} from '../model/resultat-captura-empremta';
import { ResultatCaptura, ResultatCapturaFoto } from '../model/resultat-captura';
import { Disponibilitat } from '../model/disponibilitat';

import { FacialService } from 'projects/siepbio-comu/src/lib/serveis/facial.service';
import { Foto } from 'projects/siepbio-comu/src/lib/model/siepbio-model';
import { DatosVerificacion, MensajeProceso } from '../model/EntryExit';
@Injectable({
  providedIn: 'root',
})
export class EnrolamentService {
  @ViewChild('missatgeModal') missatgeModal?: MissatgeModalComponent;
  @Output() afegirFotoEvent = new EventEmitter<Foto>();
  @Output() modificarFotoEvent = new EventEmitter<Foto>();
  fotosPersona?: Foto[];
  fotoPersona?: Foto;
  entradaDirectaCaptura?: boolean;
  public darreraCaptura: ResultatCapturaEmpremta | null = null;

  erroresFoto: Array<Error>;
  public datosVerificacion?: DatosVerificacion;

  constructor(
    private dactilarServei: DactilarService,
    
    private configuracio: ConfiguracioService,
    private facialServei: FacialService,
    private escanerEmpremtesServei: EscanerEmpremtesService
  ) {

    
  }

  
  /*  HUELLAS */
  async getSegmentsCaptura(
    raw: RawImage,
    grup442: Array<EmpremtaCaptura>,
    esRodada: boolean
  ): Promise<ResultatCapturaEmpremta> {
    let dedos = new Array<number>();
    grup442?.forEach(element => {
      dedos.push(element.numeroDit);
    });
  
    console.log("Dedos a segmentar", dedos);
    
    const segmentacio = await this.escanerEmpremtesServei.segmentar(
      raw,
      dedos
      
    );
    console.log("Segmentacion", segmentacio);
    const segments = segmentacio?.segments;

    if (segmentacio == undefined || segmentacio?.segments == undefined){  
      return new ResultatCapturaFallida(
        null,
        esRodada,
        ResultatCaptura.SEGMENTACION_ERRONEA
      );  
    }

    if (segmentacio?.wrongHand){  
      return new ResultatCapturaFallida(
        segments,
        esRodada,
        ResultatCaptura.MA_ERRONIA
      );  
    }
    
    if (segments != null && segments.length > 0) {

      if (segments.length < grup442.length) {
        return new ResultatCapturaFallida(
          segments,
          esRodada,
          ResultatCaptura.POQUES_EMPREMTES
        );
      }
      return new ResultatCapturaExitosa(segments, esRodada, null);
    } else {
      return new ResultatCapturaFallida(null, esRodada, ResultatCaptura.ERRONI);
    }
  }

 


async getResultatCapturaFotoRest(imatgeBase64: string): Promise<ResultatCapturaFoto> {
  console.log("captura Foto", imatgeBase64);
  let resultat: ResultatCapturaFoto = new ResultatCapturaFoto();
  //const b64 = this.netejarBase64(imatgeBase64);
  console.log("neteja Foto", imatgeBase64);
  try{
    const calidadFotoResponse = await this.facialServei.qualityIcao(imatgeBase64);
  
    if (calidadFotoResponse?.error == undefined) {
      //const resultadoIcao = await this.facialServei.icao(b64);
      if (calidadFotoResponse?.imagenIcao != undefined){
        resultat.foto = calidadFotoResponse?.imagenIcao;
        resultat.fotoCorrecta = true;
      } 
      
      var isoQuality: IsoQuality = calidadFotoResponse.isoQuality;
      var warnings = {};
      if(!isoQuality.frontal)
        warnings["Cara no frontal"] = true;
      if(!isoQuality.mouthClose)
        warnings["Boca abierta"] = true;
      if(!isoQuality.eyeOpen)
        warnings["Ojos cerrados"] = true;
      if(!isoQuality.eyeOpen)
        warnings["Ojos cerrados"] = true;
      if(!isoQuality.notRedEye)
        warnings["Ojos rojos"] = true; 
      if(!isoQuality.noTintedGlasses)
        warnings["Gafas oscuras"] = true;
      if(isoQuality.glasses)
        warnings["Gafas ocultan ojos"] = true;
      if(isoQuality.noEyeShadow)
        warnings["Sombras en los ojos"] = true;
      if(!isoQuality.naturalSkinColor)
        warnings["Color de piel no natural"] = true;
      if(!isoQuality.gaze)
        warnings["Mirada no frontal"] = true;
      if(!isoQuality.veil)
        warnings["Mascarilla o velo cubre la boca"] = true;



      if (Object.keys(warnings).length > 0){
          resultat.fotoMillorable = true;
          resultat.fotoCorrecta = false;
          resultat.warningInfo = warnings;
        } 
    }
    else{
      resultat.repetirFoto = calidadFotoResponse?.error?.Code;
      resultat.fotoCorrecta = false;
    }
  }
  catch(ex){
    console.log("ERROR QUALITY AND ICAO", ex);
    //this.missatgeModal.tanca();
    //TODO no tinc clar si s'hauria d'assignar foto en cas que peti el servei
    //resultat.foto = imatgeBase64;
/*
    this.missatgeModal.obre(
      new Modal({
        titol: "Error",
        missatge: ex.missatge,
        indicacions: ex.suggeriment,
        detall: ex.intern,
        icona: ModalIcon.Atencio,
      })
    );*/
    throw ex;
  }
  return resultat;
}


/** OTROS */

async toBlobImage(b64: string, tipus: string): Promise<Blob> {
  const f = await fetch('data:image/' + tipus + ';base64,' + b64);
  return await f.blob();
}

async toBlobImageB64(b64: string): Promise<Blob> {
  const f = await fetch(b64);
  return await f.blob();
}
private base64ToArrayBuffer(base64: string): Uint8Array {
  let binary_string = window.atob(base64);
  const len = binary_string.length;
  let bytes = new Uint8Array(new ArrayBuffer(len));
  for (let i = 0; i < len; i++) {
    bytes[i] = Number(binary_string.slice(i, i + 1));
  }
  return bytes;
}

netejarBase64(b64: string | undefined): string | undefined {
  let result: string;
  if (b64 == undefined || b64.length == 0) return undefined;
  const i = b64.indexOf(',');
  if (i > -1) {
    return b64.substring(i + 1);
  }
  return undefined;
}

saveToDisc(file: Blob, nom: string): void {
  var saveBlob = (function () {
    var a = document.createElement('a');
    document.body.appendChild(a);

    return function (blob: Blob, fileName: string) {
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();
  saveBlob(file, 'd:' + nom);
 }

añadirError(datosVerificacion: DatosVerificacion, mensaje: MensajeProceso): DatosVerificacion{
  if (datosVerificacion?.listaMensajes == undefined)
    datosVerificacion.listaMensajes = new Array<MensajeProceso>();
  datosVerificacion.listaMensajes.push(mensaje);
  return datosVerificacion;
}


}
