<div class="container-fluid p-2">
  
<ul ngbNav #nav="ngbNav" [(activeId)]="activeDoc"  class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink
        ><strong>MRZ + Chip</strong>
        </a
      >
      <ng-template ngbNavContent>
        <app-doc-mrz 
    
        ></app-doc-mrz>
      </ng-template>
    </li>
    <!--li [ngbNavItem]="2" [disabled]="disabledChip">
      <a ngbNavLink 
        ><strong>MRZ + Chip</strong>
        </a
      >
      <ng-template ngbNavContent>
        <app-doc-viz
     
        ></app-doc-viz>
      </ng-template>
    </li-->
    <li [ngbNavItem]="2" >
      <a ngbNavLink 
        ><strong>VIZ</strong>
        </a
      >
      <ng-template ngbNavContent>
        <app-doc-foto-impresa
  
        ></app-doc-foto-impresa>
      </ng-template>
    </li>
    <!--li [ngbNavItem]="4" [disabled]="disabledBarcode">
      <a ngbNavLink class="linkDisabled"
        ><strong>VISADO</strong>
        </a
      >
      <ng-template ngbNavContent>
        <app-doc-viz
      
        ></app-doc-viz>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" [disabled]="disabledBarcode">
      <a ngbNavLink class="linkDisabled"
        ><strong>BarCode</strong>
        <div>Lectura código de barras</div></a
      >
      <ng-template ngbNavContent>
        <app-doc-barcode
       
        ></app-doc-barcode>
      </ng-template>
    </li-->
    
  </ul>

  <div
    [ngbNavOutlet]="nav"
 
  ></div>
</div>
