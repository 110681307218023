export enum Pagina {
  Inicialitzacio = 0,
  Enrolament = 1,
  Finalitzacio = 2,
  Login = 3
}

export enum Pestanya {
  DocumentacionMrz = 0,
  DocumentacionChip = 1,
  DocumentacionVIZ = 2,
  DocumentacionVisa = 3,
  RegistreFotos = 4,
  EnrolmentDactilar = 5,
  ConsultaPolicial = 6,
  Formulario = 7,
  EnviamentDades = 8,
}

