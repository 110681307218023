import { Component, Input, OnInit } from "@angular/core";
import {
  Formulario,
  FormularioPregunta,
  TipoFormulario,
} from "projects/siepbio-comu/src/lib/model/formulario";
import { DatosService } from "projects/siepbio-comu/src/lib/serveis/datos.service";
import { IntegrationService } from "projects/siepbio-comu/src/lib/serveis/integration.service";
import { Etapa, TipoDecision } from "../../model/documento";
import { DatosVerificacion } from "../../model/EntryExit";
import { ApartatService } from "../../serveis/apartat.service";
import { faCheck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: "app-formulario",
  templateUrl: "./formulario.component.html",
  styleUrls: ["./formulario.component.css"],
})
export class FormularioComponent implements OnInit {
  Etapa: typeof Etapa = Etapa;
  iconValido = faCheck;
  iconNoValido = faExclamationTriangle;
  formulario?: Formulario = null;
  myVar = "plena";

  constructor(
    private integrationService: IntegrationService,
    private apartatServei: ApartatService,
    public datosService: DatosService
  ) {
    
    this.integrationService
      .obtenerFormulario(TipoFormulario.SCHENGEN, "es-ES")
      .subscribe(
        (value) => {
          this.formulario = value;
          this.actualizaDecisionTomada();
        },
        (error) => {
          // TODO: Gestió d'errors
        }
      );
  }

  siguiente() {
    this.apartatServei.canviarPestanyaEvent.emit(5);
  }

  getValorPregunta(name, value) {
    return (
      this.datosService.datosVerificacion?.respuestasFormularioSchengen?.values.get(name) ==
      value
    );
  }

  onRadioChangeValue(name, value) {
    this.datosService.datosVerificacion?.respuestasFormularioSchengen?.values.set(name, value);
    this.actualizaDecisionTomada();
  }

  private actualizaDecisionTomada() {
    if (!this.datosService.datosVerificacion.decisionesTomadas[Etapa.Formulario].esDecisionManual){
        //ha tomado la decisión manualmente
      if (this.esValido()) {
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Formulario].tipo = TipoDecision.ACEPTADO;
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Formulario].motivo = null;
      } else {
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Formulario].tipo = TipoDecision.PENDIENTE;
        this.datosService.datosVerificacion.decisionesTomadas[Etapa.Formulario].motivo = "Formulario Schengen incompleto";
      }
    }
     
       

  }

  esValido() {
    if (this.formulario == null || this.datosService.datosVerificacion == null) return false;

    return this.formulario.formularioPreguntas.every(
      (pregunta) =>
        !pregunta.obligatorio ||
        (this.datosService.datosVerificacion?.respuestasFormularioSchengen?.values.has(
          pregunta.identificador
        ) &&
          this.datosService.datosVerificacion?.respuestasFormularioSchengen?.values.get(
            pregunta.identificador
          ))
    );
  }

  async ngOnInit() {}


}
