import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MissatgeModalComponent } from './components/missatge-modal/missatge-modal.component';
import { AutoritzacioIdentificacioComponent } from './components/autoritzacio-identificacio/autoritzacio-identificacio.component';

import { CapturaEmpremtesComponent } from './components/captura-empremtes/captura-empremtes.component';
import { CalibrarEscanerEmpremtesComponent } from './components/calibrar-escaner-empremtes/calibrar-escaner-empremtes.component';
import { CapturaDactilarEnViuComponent } from './components/captura-empremtes/captura-dactilar-en-viu/captura-dactilar-en-viu.component';
import { SeleccioDitsComponent } from './components/seleccio-dits/seleccio-dits.component';
import { PersonaIdentificadaComponent } from './components/persona-identificada/persona-identificada.component';
import { IdentificacioFallidaComponent } from './components/identificacio-fallida/identificacio-fallida.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CapsaleraComponent } from './components/capsalera/capsalera.component';
import { PeuComponent } from './components/peu/peu.component';
import { TraduccioModule } from './moduls/traduccio/traduccio.module';

@NgModule({
  declarations: [
    MissatgeModalComponent,
    AutoritzacioIdentificacioComponent,
    CapturaEmpremtesComponent,
    CalibrarEscanerEmpremtesComponent,
    CapturaDactilarEnViuComponent,
    SeleccioDitsComponent,
    PersonaIdentificadaComponent,
    IdentificacioFallidaComponent,
    CapsaleraComponent,
    PeuComponent,
  ],
  imports: [HttpClientModule, BrowserModule, FormsModule, TraduccioModule, ReactiveFormsModule],
  exports: [
    MissatgeModalComponent,
    AutoritzacioIdentificacioComponent,
    CapturaEmpremtesComponent,
    CalibrarEscanerEmpremtesComponent,
    CapturaDactilarEnViuComponent,
    SeleccioDitsComponent,
    PersonaIdentificadaComponent,
    IdentificacioFallidaComponent,
    // CapsaleraComponent,
    PeuComponent,
  ],
})
export class SiepbioComuModule {}
