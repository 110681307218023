import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CentresDTO } from '../model/siepbio-model';
import { SiepbioServicesService } from './siepbio-services.service';

@Injectable({
  providedIn: 'root',
})
export class CentreService {
  public centresDTO?: CentresDTO[];

  nomCentre(centre: string): string {
    return (
      this.centresDTO?.filter((x) => x.codi).find((x) => centre == x.codi)
        ?.descripcio ?? ''
    );
  }

  constructor(
    private translate: TranslateService,
    private siepbioServei: SiepbioServicesService
  ) {}

  async inicialitzaLlistaCentres(): Promise<void> {
    this.centresDTO = await this.siepbioServei.llistaCentres();

    const centreSeleccio = new CentresDTO();

    centreSeleccio.descripcio = this.translate.instant(
      'comu.serveis.selecciona-centre'
    );

    this.centresDTO?.splice(0, 0, centreSeleccio);
  }
}
