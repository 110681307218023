<div class="container-fluid">
  <div class="row my-1">
    <div class="col-sm-12 ">
        
        <app-barra-acciones [accion]="Etapa.Formulario">
        </app-barra-acciones>
        <!--span class="text-info" *ngIf="procesandoMRZ"> Leyendo MRZ y chip ...  </span><span *ngIf="procesandoMRZ" class="spinner-border spinner-border-sm text-info" role="status"></span> 
        <span class="text-info" *ngIf="procesandoEES"> Obteniendo información EES... </span><span *ngIf="procesandoEES" class="spinner-border spinner-border-sm text-info" role="status"></span>
        <span *ngIf="datosService.datosVerificacion?.documentoLeido" class="ml-3 badge badge-pill badge-{{colorResultadoMRZ}}">{{resultadoMRZ}}</span-->
      
    </div>
  </div>
  <!--div class="row mb-2 ">
    <div class="col-sm-12">
     
      <button
        *ngIf="esValido()"
        class="btn btn-info btn-lg float-right"
        type="button"
        role="button"
        (click)="siguiente()"
      >
        Siguiente
      </button>
    
    </div>
  </div-->
  
    

  <div class="row mb-3 justify-content-md-center">
    <div class="col-sm-6">
      <form class="needs-validation" novalidate>
        <div *ngIf="formulario">
          <div *ngFor="let item of formulario.formularioPreguntas">
            <div *ngIf="item.tipoPregunta == 'SELECCION_UNICA'">
              <div class="form-group">
                <h4 mb-2>
                  <strong>{{ item.pregunta }}</strong>
                </h4>

                <div
                  *ngFor="
                    let opt of item.formularioPreguntaSeleccionGroup
                      ?.listaOpciones
                  "
                  class="icheck-primary"
                >
                  <input
                    type="radio"
                    id="id-{{ opt?.nombre }}"
                    name="{{ item.identificador }}"
                    [checked]="getValorPregunta(item.identificador, opt.nombre)"
                    (change)="
                      onRadioChangeValue(item.identificador, opt?.nombre)
                    "
                  />
                  <label for="id-{{ opt?.nombre }}">
                    {{ opt?.texto }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
     
  

</div>
