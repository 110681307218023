<footer class="bg-dark mt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-6 px-sm-0 px-lg-4">
<div class="float-left text-white">
              © Dirección General de la Policía 2022 
</div>
<div class="float-left">
  <ul class="list-inline infoRegistrada d-flex-nowrap">
    <li class="list-inline-item p-0"><span class="px-1 text-light" focusable="false">|</span><a href="https://www.policia.es/_es/proteccion_datos.php">Protección de datos</a></li>
    <li class="list-inline-item p-0"><span class="pr-1 text-light" focusable="false">|</span><a href="https://www.policia.es/_es/aviso_legal.php">Aviso Legal</a></li>
  </ul>
      </div>
      </div>
      <div class="col-md-6 px-sm-0 px-lg-4">
          <!--div class="social-list align-left align-md-right">
  <ul class="list-inline" aria-label="Redes sociales">
  <li class="list-inline-item p-0">
  <a href="#siguenos" data-toggle="modal" data-target="#twitter-modal_pie" data-placement="bottom" title="Pulse para seleccionar cuenta de twiter">
    twitter
  </a>
  </li>
  <li class="list-inline-item p-0">
  <a href="https://www.facebook.com/PoliciaNacional/" target="_blank" title="Ir a facebook en nueva ventana">
    facebook
  </a>
  </li>
  <li class="list-inline-item p-0">
  <a href="https://www.youtube.com/user/Policia" target="_blank" title="Ir a youtube en nueva ventana">
    youtube
  </a>
  </li>
  <li class="list-inline-item p-0">
  <a href="https://www.instagram.com/policianacional/?hl=es" target="_blank" title="Ir a instagram en nueva ventana">
    instagram
  </a>
  </li>
  <li class="list-inline-item p-0">
  <a href="https://www.pscp.tv/policia/" target="_blank" title="Ir a periscope en nueva ventana">
    periscope
  </a>
  </li>						
  <li class="list-inline-item p-0">
  <a href="https://telegram.im/@policianacional" target="_blank" title="Ir a telegram en nueva ventana">
    telegram
  </a>
  </li>
  <li class="list-inline-item p-0">
  <a href="https://www.tiktok.com/@policia" target="_blank" title="Ir a tik tok en nueva ventana">
    tik tok
  </a>
  </li>
  </ul>

          </div-->
      </div>
</div>
  </div>
</footer>
