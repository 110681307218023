// @ts-ignore
import pico from './pico.js';
// @ts-ignore
import lploc from './lploc.js';

const convertImageToGrayScale = (rgba: Uint8ClampedArray, nRows: number, nCols: number) => {
  const gray = new Uint8Array(nRows * nCols);
  for (let r = 0; r < nRows; ++r) {
    for (let c = 0; c < nCols; ++c) {
      gray[r * nCols + c] =
        (2 * rgba[r * 4 * nCols + 4 * c] +
          7 * rgba[r * 4 * nCols + 4 * c + 1] +
          1 * rgba[r * 4 * nCols + 4 * c + 2]) /
        10;
    }
  }
  return gray;
};

export interface DetectFaceOptions {
  onlyOneFace?: boolean;
  withEyes?: boolean;
  minEyeDistance?: number;
}

export class FaceDetect {
  static initialized = false;
  static updateMemory = pico.instantiate_detection_memory(5);
  static faceFinderClassifyRegion: Function;
  static doPuploc: Function;

  static async init() {
    if (FaceDetect.initialized) {
      return;
    }

    const cascadeUrl = '/assets/plugins/facefinder.bin';
    await fetch(cascadeUrl).then((response) => {
      response.arrayBuffer().then((buffer) => {
        const bytes = new Int8Array(buffer);
        FaceDetect.faceFinderClassifyRegion = pico.unpack_cascade(bytes);
      });
    }).catch((err) => {
      console.log('Loading facefinder.bin failed', err);
    });

    const puplocUrl = '/assets/plugins/puploc.bin';
    await fetch(puplocUrl).then(function(response) {
      response.arrayBuffer().then(function(buffer) {
        const bytes = new Int8Array(buffer);
        FaceDetect.doPuploc = lploc.unpack_localizer(bytes);
      });
    }).catch((err) => {
      console.log('Loading puploc.bin failed', err);
    });

    FaceDetect.initialized = true;
  }

  static getICAORect(eyes: any) {
    const eyeDiff = Math.abs(eyes.right.x - eyes.left.x);
    const x = eyes.left.x - eyeDiff * 1.75;
    const y = eyes.left.y - eyeDiff * 2.5;
    const width = eyeDiff * 4.5;
    const height = width * 5 / 4;

    return { x, y, width, height };
  }

  static async detectFace(data: ImageData, options: DetectFaceOptions = {}) {
    try {
      await FaceDetect.init();

      if (!FaceDetect.faceFinderClassifyRegion) {
        return undefined;
      }

      const { width, height } = data;

      // prepare input to `run_cascade`
      const image = {
        pixels: convertImageToGrayScale(data.data, height, width),
        nrows: height,
        ncols: width,
        ldim: width,
      };

      const params = {
        shiftfactor: 0.1, // move the detection window by 10% of its size
        minsize: 100, // minimum size of a face
        maxsize: 1000, // maximum size of a face
        scalefactor: 1.1, // for multiscale processing: resize the detection window by 10% when moving to the higher scale
      };

      // run the cascade over the frame and cluster the obtained detections
      // dets is an array that contains (r, c, s, q) quadruplets
      // (representing row, column, scale and detection score)
      let dets: number[][] = pico.run_cascade(
        image,
        FaceDetect.faceFinderClassifyRegion,
        params
      );

      dets = FaceDetect.updateMemory(dets);
      dets = pico.cluster_detections(dets, 0.2); // set IoU threshold to 0.2

      const faces = dets.filter((det) => det[3] > 50.0);
      if (options.onlyOneFace && faces.length !== 1) {
        return undefined;
      }

      if (!options.withEyes) {
        return faces
          .sort((a, b) => b[3] - a[3])
          .map((det) => ({
            confidence: det[3],
            x: det[1] - det[2] / 2 - det[2] / 6,
            y: det[0] - det[2] / 2 - det[2] / 4,
            width: det[2] + det[2] / 3,
            height: det[2] + det[2] / 2,
          }))[0];
      }

      const closest = faces.reduce((closest: number[] | undefined, face) => (closest && closest[2] >= face[2]) ? closest : face, undefined);
      if (!closest) {
        return undefined;
      }

      const eyes = FaceDetect.getEyes(image, closest);
      if (!eyes) {
        return undefined;
      }
      if (options.minEyeDistance && eyes.right.x - eyes.left.x < options.minEyeDistance) {
        return undefined;
      }

      return FaceDetect.getICAORect(eyes);
    } catch {
      return undefined;
    }
  }

  static getEyes(image: any, face: number[]) {
    let r, c, s, r1, c1, r2, c2;

    // First eye
    r = face[0] - 0.075 * face[2];
    c = face[1] - 0.175 * face[2];
    s = 0.35 * face[2];

    [r1, c1] = FaceDetect.doPuploc(r, c, s, 63, image);

    // second eye
    r = face[0] - 0.075 * face[2];
    c = face[1] + 0.175 * face[2];
    s = 0.35 * face[2];
    [r2, c2] = FaceDetect.doPuploc(r, c, s, 63, image);

    if (r1 >= 0 && c1 >= 0 && r2 >= 0 && c2 >= 0) {
      return {
        left: { x: c1, y: r1 },
        right: { x: c2, y: r2 }
      };
    }
    return undefined;
  }
}
