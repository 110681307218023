<div class="container-fluid p-4">
    <div class="row mb-3">
        <div class="col-sm-12 ">
            <button class="btn btn-outline-info mr-2" type="button"> Leer código de barras</button> 
            <button class="btn btn-outline-secondary " type="reset"> Limpiar datos leídos</button> 
        </div>
    </div>
    <form class="needs-validation" novalidate>
    <div class="row">
        <div class="col-sm-4">
            <div class="form-group required">
                <label for="issuer" class="control-label">Expedidor de documento</label>
                <input type="text" class="form-control" id="issuer" placeholder="País de expedición del documento" required>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group required">
                <label for="tipodoc" class="control-label">Tipo de documento</label>
                <input type="text" class="form-control" id="tipodoc" placeholder="Tipo de documento" required>
            </div>
        </div>
        <div class="col-sm-4 ">
            <div class="form-group required">
                <label for="numero" class="control-label">Número de documento</label>
                <input type="text" class="form-control" id="numero" placeholder="Número de documento" required>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-8">
            <div class="form-group required">
                <label for="nombre" class="control-label">Nombre completo</label>
                <input type="text" class="form-control" id="nombre" placeholder="Nombre completo de la persona" required>
            </div>
        </div>
        <div class="col-sm-4">
            <div class="form-group required">
                <label for="nac" class="control-label">Nacionalidad</label>
                <input type="text" class="form-control" id="nac" placeholder="Nacionalidad de la persona" required>
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-sm-4">
            <div class="form-group required">
                <label for="fecnac" class="control-label">Fecha de nacimiento</label>
                <input type="text" class="form-control" id="fecnac" placeholder="Fecha de nacimiento (dd/MM/aaaa)" required>
            </div>
            <div class="form-group required">
                <label for="fecexp" class="control-label">Fecha de expedición</label>
                <input type="text" class="form-control" id="fecexp" placeholder="Fecha de expedición (dd/MM/aaaa)" required>
            </div>
        </div>
        <div class="col-sm-4">
           
            <div class="form-group required">
                <label for="sexo" class="control-label">Sexo</label>
                <input type="text" class="form-control" id="sexo" placeholder="Sexo" required>
            </div>
            <div class="form-group required">
                <label for="autoridad" class="control-label">Autoridad</label>
                <input type="text" class="form-control" id="autoridad" placeholder="Autoridad u organización expedidora" required>
            </div>
           
        </div>

        <div class="col-sm-4">
            <div class="form-group">
                <label for="lugnac" class="control-label">Lugar de nacimiento</label>
                <input type="text" class="form-control" id="lugnac" placeholder="Lugar de nacimiento de la persona">
            </div>
            <div class="form-group required">
                <label for="feccad" class="control-label">Fecha de caducidad</label>
                <input type="text" class="form-control" id="feccad" placeholder="Fecha de caducidad del documento (dd/MM/aaaa)" required>
            </div>
        </div>
    </div>
    </form>

    </div>

