import { AfterViewInit, Component, ViewChild } from '@angular/core';
import {
  ConfiguracioService,
  Excepcio,
  EscanerEmpremtesService,

  ConsultaPolicial,
  UsuarioConsultaPolicial,

} from 'projects/siepbio-comu/src/public-api';
import { FacialService } from 'projects/siepbio-comu/src/lib/serveis/facial.service';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { ApartatService } from '../../serveis/apartat.service';
import { ContexteService } from '../../serveis/contexte.service';
import { TranslateService } from '@ngx-translate/core';
import { IntegrationService } from 'projects/siepbio-comu/src/lib/serveis/integration.service';
import { UmbralCalidadBiometrica } from 'projects/siepbio-comu/src/lib/model/traveler';
import { AutenticacionService } from 'projects/siepbio-comu/src/lib/serveis/autenticacion.service';

@Component({
  selector: 'app-inicialitzacio',
  templateUrl: './inicialitzacio.component.html',
})
export class InicialitzacioComponent implements AfterViewInit {
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;
  missatge: string = '';

  constructor(
    private contexteServei: ContexteService,
    private escanerServei: EscanerEmpremtesService,
    private facialServei: FacialService,
    private apartatServei: ApartatService,
    private translate: TranslateService,
    private configuracio: ConfiguracioService,
    private integrationService: IntegrationService,
    private autenticacionService: AutenticacionService
  ) {}

  async ngAfterViewInit() {
    this.inicialitzar();
  }

  version(): string {
    return this.contexteServei.version;
  }

  software(): string {
    return this.contexteServei.software;
  }
  
  async inicialitzar() {
    try {
      //this.inicialitzarConfiguracion();
      console.log("Se habilita el lector de huellas y sus servicios",this.configuracio.parametrosConfiguracion.habilitarLectorHuellas);
      if (this.configuracio.parametrosConfiguracion.habilitarLectorHuellas){
        
        await this.inicialitzarEscanerServeis();
      }
    
      //await this.inicialitzarFacialServeis(localitzador);
      await this.inicializarServiciosIntegracion();
      await this.inicializarServiciosConsultaPolicial();
      
      this.apartatServei.loginEvent.emit();

    } catch (ex) {
      if (ex instanceof Excepcio) this.apartatServei.finalitzacioEvent.emit(ex);
      else {
        const err = new Excepcio(
          await this.translate
            .get('inicialitzacio.error-programari')
            .toPromise(),
          await this.translate
            .get('inicialitzacio.error-programari-indicacions')
            .toPromise(),
          ex,
          () => {
            this.reintentarInicialitzar();
          }
        );
        this.apartatServei.finalitzacioEvent.emit(err);
      }
    }
  }

  private reintentarInicialitzar() {
    this.apartatServei.inicialitzacioEvent.emit();
    this.inicialitzar();
  }

  private excepcioClientServices(ex): Excepcio {
    return new Excepcio(
      this.translate.instant('inicialitzacio.error-client-services'),
      this.translate.instant('inicialitzacio.error-cs-indicacions'),
      ex,
      () => {
        this.reintentarInicialitzar();
      }
    );
  }

  private async inicialitzarEscanerServeis(): Promise<void> {
    this.missatge = await this.translate
      .get('inicialitzacio.missatge-escaner')
      .toPromise();
    try {
      await this.escanerServei.connectar();
      console.log("es connecta lector huellas");
    } catch (ex) {
      throw new Excepcio(
        this.translate.instant(
          'inicialitzacio.error-escanner-empremtes-missatge'
        ),
        this.translate.instant(
          'inicialitzacio.error-escanner-empremtes-indicacions'
        ),
        ex,
        () => {
          this.inicialitzar();
        }
      );
    }
  }

  private async inicialitzarFacialServeis(): Promise<void> {
    this.missatge = await this.translate
      .get('inicialitzacio.missatge-facial')
      .toPromise();
    try {

      console.log("es connecta facial");
    } catch (ex) {
      throw this.excepcioClientServices( ex);
    }
  }

  //TODO Revisar método
  private comprobarParametrosUndefined(parametrosConfig:object | undefined):boolean
  { 
     if(parametrosConfig === undefined)
         return true;
     let propiedades = Object.values(parametrosConfig);
     for(let i = 0;i<propiedades.length;i++)
     {
         
        if(propiedades[i]  == undefined )
         {
            return true;
         }
     }
     return false;
 }

  private inicialitzarConfiguracion(): void {
    let excepcion = new Excepcio("Alguna de las variables de configuración no tiene valor.","Acceda a la configuración y cumplimente el valor o pulse Restaurar valores para que tome los valores por defecto")
    try{
      let algunaVariableUndefined = this.comprobarParametrosUndefined(this.configuracio.parametrosConfiguracion);
      if (algunaVariableUndefined){
        throw this.excepcioClientServices( excepcion);
      }
    }
    catch(ex){
      excepcion.intern = ex.message;
      throw excepcion;
    }
  }

  private async inicializarServiciosIntegracion(): Promise<void> {
    this.missatge =  await this.translate
    .get('inicialitzacio.missatge-umbrales')
    .toPromise();
    try {
      this.integrationService.host = this.configuracio.parametrosConfiguracion.hostServiciosIntegracion;
      console.log("integracio host", this.configuracio.parametrosConfiguracion);
      let umbralHuellas = new UmbralCalidadBiometrica(this.configuracio.parametrosConfiguracion.biometricQualityMetricThresholdFP);
      let resultado = await this.integrationService.inicializaCalidad(umbralHuellas,"fingerQA");
      console.log("resultado inicializacion umbral", resultado);
      if (resultado.resultado != 'Finger QA OK'){
        throw new Excepcio(
          await this.translate
            .get('inicialitzacio.error-inicializacion-umbral-huellas')
            .toPromise(),
          await this.translate
            .get('inicialitzacio.error-umbral-indicaciones')
            .toPromise()
          
         
        );
      }
      let umbralFacial = new UmbralCalidadBiometrica(this.configuracio.parametrosConfiguracion.biometricQualityMetricThresholdFace);
      resultado = await this.integrationService.inicializaCalidad(umbralFacial,"faceQA");
      if (resultado.resultado != 'Face QA OK'){
        throw new Excepcio(
          await this.translate
            .get('inicialitzacio.error-inicializacion-umbral-facial')
            .toPromise(),
          await this.translate
            .get('inicialitzacio.error-umbral-indicaciones')
            .toPromise()
        
          
        );
      }
      console.log("inicializa umbral de calidad");
    } catch (ex) {
      console.log("error inicializacion umbral");
      throw this.excepcioClientServices(ex);
    }
  }

  


  private async inicializarServiciosConsultaPolicial(): Promise<void> {
    this.missatge =  await this.translate
    .get('inicialitzacio.missatge-umbrales')
    .toPromise();
    try {
      this.integrationService.host = this.configuracio.parametrosConfiguracion.hostServiciosIntegracion;
      /*
      console.log("integracio host", this.configuracio.parametrosConfiguracion);

      let datos =  new ConsultaPolicial();
      //TODO datos que vendrán por configuración
      datos.ip_dispositivo ="192.168.1.1";
      datos.modo_funcionamiento = "";
      datos.tipo_algoritmo_dactilar ="";
      datos.tipo_algoritmo_facial ="";
      datos.usuario = new  UsuarioConsultaPolicial();
      let resultado = await this.autenticacionService.consultasPoliciales(datos);
      console.log("resultado inicializacion consultas policiales", resultado);
      /* TODO se debería poder almacenar este resultado porque puede determinar el comportamiento de otras pantallas      
      if (resultado.codigoError != undefined){
        throw new Excepcio(
          await this.translate
            .get('inicialitzacio.error-inicializacion-umbral-huellas')
            .toPromise(),
          await this.translate
            .get('inicialitzacio.error-umbral-indicaciones')
            .toPromise()
        );
      }
      else if (resultado.literalError != undefined){
        throw new Excepcio("Error: "+ resultado.literalError);
      }
      */
    } catch (ex) {
      console.log("error inicialización consultas policiales");
      throw this.excepcioClientServices(ex);
    }
  }


  getValorParametre(parametres: string, variable: string): string {
    let json = JSON.parse(parametres);
    return json[variable];
  }
}
