import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import pako from 'pako';
import { Subscription } from 'rxjs';
import { FingerprintImage, RawImage } from '../../../model/raw-image';
import {
  EscanerEmpremtesService,
  TipusPitu,
} from '../../../serveis/escaner-empremtes.service';

@Component({
  selector: 'lib-captura-dactilar-en-viu',
  templateUrl: './captura-dactilar-en-viu.component.html',
})
export class CapturaDactilarEnViuComponent implements OnInit, OnDestroy {
  @ViewChild('preview') canvasRef: ElementRef;

  canvasWidth: number = 400;
  canvasHeight: number = 375;

  private subscriptions = new Array<Subscription>();

  constructor(private escanerServei: EscanerEmpremtesService) {
    this.subscriptions.push(
      this.escanerServei.imatgeEnViuEvent.subscribe((raw: RawImage) => {
        this.mostraImatge(raw, true);
      })
    );

    this.subscriptions.push(
      this.escanerServei.imatgeCapturadaEvent.subscribe((fpi: FingerprintImage) => {
        if (fpi) {
          this.mostraImatge(fpi.rawImage, false);
          this.escanerServei.pita(TipusPitu.Ok);
        } else this.escanerServei.pita(TipusPitu.Malament);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.subscriptions.length = 0;
  }

  ngOnInit(): void {}

  private data: any;

  neteja() {
    const ctx = this.canvasRef.nativeElement.getContext('2d');

    ctx.fillStyle = 'white'; // bg-light de bootstrap
    ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);
  }

  private async mostraImatge(raw: RawImage, compressed: boolean) {
    var decoded = atob(raw.data);

    const ctx = this.canvasRef.nativeElement.getContext('2d');
    if (!this.data)
      this.data = ctx.createImageData(this.canvasWidth, this.canvasHeight);

    if (compressed) {
      const array = Uint8Array.from(decoded, (c) => c.charCodeAt(0));

      const bytes = pako.inflate(array);

      var ptr = 0;

      bytes.forEach((byte) => {
        this.data.data[ptr++] = byte;
        this.data.data[ptr++] = byte;
        this.data.data[ptr++] = byte;
        this.data.data[ptr++] = 255;
      });
    } else {
      var p = 0;
      let scale = raw.width / this.canvasRef.nativeElement.width;

      for (var y = 0; y < this.canvasHeight; y++)
        for (var x = 0; x < this.canvasWidth; x++) {
          let index = Math.round(y * scale) * raw.width + Math.round(x * scale);
          const color = decoded.charCodeAt(index);
          this.data.data[p++] = color;
          this.data.data[p++] = color;
          this.data.data[p++] = color;
          this.data.data[p++] = 255;
        }
    }

    ctx.putImageData(this.data, 0, 0);
  }
}
