import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutenticacionSP, ConsultaPolicial, DatosAbrirPuerta, InformeMovilidad, PasoFrontera, RespuestaAbrirPuerta, RespuestaConsultaPolicial, RespuestaParametrosAbc, RespuestaPasoFrontera, UsuarioAutenticar } from '../model/autoritzacio';
import { Excepcio } from '../model/excepcio';
import { ConfiguracioService } from './configuracio.service';
import { DatosService } from './datos.service';
//import { AutenticacionSP, ConfiguracioService, ConsultaPolicial, DatosAbrirPuerta, Excepcio, InformeMovilidad, 
//  PasoFrontera, RespuestaAbrirPuerta, RespuestaConsultaPolicial, RespuestaParametrosAbc, RespuestaPasoFrontera } from 'projects/siepbio-comu/src/public-api';

@Injectable({
  providedIn: 'root'
})
export class AutenticacionService {

  //esta variable serà global i per configuració
  public host: string = "https://localhost:3007";
  private usuario: string;
  private literal: string;
  private keyAcceso: string;

  constructor(private http: HttpClient, private configuracionService: ConfiguracioService, private datosService: DatosService) {
    if (configuracionService.parametrosConfiguracion.hostServiciosPoliciales != undefined)
      this.host = configuracionService.parametrosConfiguracion.hostServiciosPoliciales;
    if (configuracionService.parametrosConfiguracion.autenticacionLiteralSistema != undefined)
      this.literal = configuracionService.parametrosConfiguracion.autenticacionLiteralSistema;
  }

  private async doCall(
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
      return await this.http
        .post(
          this.host + url,
          json ? json : undefined,
          {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'json',
          }
        )
        .toPromise();
    
  }

  private async manageCall(
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(url, params, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      obj = await this.doCall(url, params, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  /** AUTENTICACION */
  public async login(
    autenticacion: AutenticacionSP,
  ): Promise<UsuarioAutenticar> {
    
    let response =  await this.manageCall(
      '/api/autenticacion/autenticar/', null, JSON.stringify(autenticacion)
    );
    console.log("Login reesponse", response);
    let json = JSON.parse(JSON.stringify(response));
    if (json?.return?.codigoError != undefined && json?.return?.codigoError?.length > 0){
      //TODO se debería pasar un objeto con el código y el literal error
      throw new Excepcio(
        "Error en el servicio Autenticación [Login]. " , "Pruebe a reintentar", json?.return?.literalError
      );
    }
    else{

      let resultado = json.return;

      if (!resultado.usuario) {
        throw new Excepcio(
          "Error en el servicio Autenticación [Login]. " , "Pruebe a reintentar", json?.return?.literalError
        );
      }
      else {
        const result = resultado.usuario as UsuarioAutenticar;        
        console.log('Result', result);
        return result;
      }
    }
}

  public async logOut(): Promise<string> {
    try{
      console.log('Logout', this.datosService.usuarioAutenticado);

    let response = await this.manageCall(
      '/api/autenticacion/logout', null, JSON.stringify(this.datosService.usuarioAutenticado)
    );
    console.log("logout:", response);
    return JSON.stringify(response);
  }
  catch(ex){
    throw new Excepcio(
      "Error en el servicio Autenticación [logout]. ", "Pruebe a reintentar", ex.message
    );
  }
  }


/** CONTROL PERSONAS OBJETOS */

public async abrirPuerta(
  datos: DatosAbrirPuerta,
): Promise<RespuestaAbrirPuerta> {
  try{
  let response =  await this.manageCall(
    '/api/controlPersonasObjetos/abrirPuerta', null, JSON.stringify(datos)
  );
  console.log("logout:", response);
  return <RespuestaAbrirPuerta> JSON.parse(JSON.stringify(response));
}
catch(ex){
  throw new Excepcio(
    "Error en el servicio controlPersonasObjetos [AbrirPuerta]. " , "Pruebe a reintentar", ex.message
  );
}
}

public async informeMovilidad(
  datos: InformeMovilidad,
): Promise<RespuestaAbrirPuerta> {
  try{
  let response =  await this.manageCall(
    '/api/controlPersonasObjetos/abrirPuerta', null, JSON.stringify(datos)
  );
  console.log("logout:", response);
  return <RespuestaAbrirPuerta> JSON.parse(JSON.stringify(response?.informeMovilidadResponse?.return));
}
catch(ex){
  throw new Excepcio(
    "Error en el servicio controlPersonasObjetos [Informe Movilidad]. " , "Pruebe a reintentar", ex.message
  );
}
}

//TODO, si cal, fer el movilidad

public async pasoFrontera(
  datos: PasoFrontera,
): Promise<RespuestaPasoFrontera> {
  try{
  var timeStart = new Date().getTime();
  let response =  await this.manageCall(
    '/api/controlPersonasObjetos/pasoFrontera', null, JSON.stringify(datos)
  );
  var timeEnd = new Date().getTime();
  this.datosService.datosVerificacion.tiempos.tiempoConsumidoPasoFrontera = timeEnd - timeStart;
  console.log("Paso frontera Response:", response);
  return <RespuestaPasoFrontera> JSON.parse(JSON.stringify(response?.pasoFronteraResponse?.return));
}
catch(ex){
  throw new Excepcio(
    "Error en el servicio controlPersonasObjetos [Paso Frontera]. " , "Pruebe a reintentar", ex.message
  );
}
}

/** REGLAS FRONTERA */
public async consultasPoliciales(
  datos: ConsultaPolicial,
): Promise<RespuestaConsultaPolicial> {
  try{
  let response =  await this.manageCall(
    '/api/reglasFrontera/obtenerConsultasPoliciales/', null, JSON.stringify(datos)
  );
  console.log("ObtenerConsultas policiales response:", response);
  return <RespuestaConsultaPolicial> JSON.parse(JSON.stringify(response?.return));
}
catch(ex){
  throw new Excepcio(
    "Error en el servicio reglasFrontera [obtenerConsultasPoliciales]. " , "Pruebe a reintentar", ex.message
  );
}
}

public async parametrosABC(
  datos: ConsultaPolicial,
): Promise<RespuestaParametrosAbc> {
  try{
  let response =  await this.manageCall(
    '/api/reglasFrontera/obtenerParametrosAbc/', null, JSON.stringify(datos)
  );
  console.log("logout:", response);
  return <RespuestaParametrosAbc> JSON.parse(JSON.stringify(response?.return));
}
catch(ex){
  throw new Excepcio(
    "Error en el servicio reglasFrontera [obtenerParametrosAbc]. ", "Pruebe a reintentar", ex.message
  );
}
}

}
