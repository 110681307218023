import { SafeResourceUrl } from '@angular/platform-browser';
import { FingerprintImage, RawImage } from 'projects/siepbio-comu/src/public-api';
import { Disponibilitat } from './disponibilitat';
import { GrupEmpremtes } from './grup-empremtes';
import { ResultatCaptura } from './resultat-captura';

export class EmpremtaCaptura {
  constructor(
    public numeroDit?: number,
    public imatge?: SafeResourceUrl,
    public rawImatge?: RawImage,
    public puntuacio?: number,
    public disponibilitat: Disponibilitat = Disponibilitat.NORMAL,
    public esMillor: boolean = false,
    public resultatCaptura?: ResultatCaptura,
    public noEsMatched?: boolean,
    public intents: number = 0
  ) {}

 
}

export class EmpremtaAmbTipus {
  constructor(
    public empremtaCapturada: EmpremtaCaptura,
    public esRodada: boolean,
    public individual: boolean = false
  ) {}
}

export class EmpremtesAnalitzades {
  constructor(
    public empremtesCapturades: Array<EmpremtaCaptura>,
    public esRodada: boolean,
    public fpi: FingerprintImage
  ) {}
}

export class IntentCapturaGrup {
  constructor(public numeroIntent: number, public grup442: GrupEmpremtes) {}
}
