<div class="passport-scanner">
    <webcam
            class="webcam"
            [class.d-none]="capturedImage"
            [width]="cameraSize.width"
            [height]="cameraSize.height"
            [allowCameraSwitch] = "false"
            [switchCamera]="nextWebcamObservable"
            mirrorImage="never"
            [captureImageData]="true"
            [videoOptions]="videoOptions"
            [trigger]="triggerObservable"
            [imageQuality]="1"
            (imageCapture)="processCapturedImage($event)"
            (initError)="handleInitError($event)"
    ></webcam>

    <div *ngIf="!capturedImage" class="mask-frame"></div>

    <div *ngIf="capturedImage" class="position-absolute" [style.width.px]="maskSize.width" [style.height.px]="maskSize.height">
        <canvas id="canvasOutput" class="w-100 h-100" [class.d-none]="!showCanvas" width="640" height="480"></canvas>
        <img [src]="capturedImage | imageUrl" alt="">
        <div class="d-flex align-items-center justify-content-center mt-4">
            <button class="btn btn-info px-4" (click)="onRepeat()">
                {{ 'comu.accio.repetir' | translate }}
            </button>
            <button class="btn btn-info px-4 ml-4" (click)="onConfirm()">
                {{ 'comu.accio.confirmar' | translate }}
            </button>
        </div>
    </div>

    <div class="helper-text">
        {{ helperText | translate }}
    </div>

    <div *ngIf="capturing" class="time-counter">{{ remainSeconds }}</div>

    <ng-container *ngIf="!capturedImage">
        <button class="btn btn-info btn-icon btn-capture" [disabled]="capturing" (click)="startAutoCapture()">
            <fa-icon [icon]="icons.camera"></fa-icon>
        </button>
        <button class="btn btn-info btn-icon btn-switch-camera" [disabled]="capturing" (click)="showNextWebcam(true)">
            <fa-icon [icon]="icons.switchCamera"></fa-icon>
        </button>
        <button class="btn btn-icon btn-toggle-light" [class.btn-info]="!lightStatus" [class.btn-danger]="lightStatus" [disabled]="capturing" (click)="toggleLight(!lightStatus)">
            <fa-icon [icon]="icons.lightBulb"></fa-icon>
        </button>
    </ng-container>

    <button class="btn btn-close" (click)="onClose()">
        <fa-icon [icon]="icons.close"></fa-icon>
    </button>
</div>

<div [innerHTML]="maskPath | safeHtml"></div>

<lib-missatge-modal #messageModal></lib-missatge-modal>
