import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { Modal, ModalIcon } from 'projects/siepbio-comu/src/lib/model/modal';
import { Autoritzacio } from '../../model/autoritzacio';

export enum TipusAutoritzacio {
  Sortida,
  Retorn,
  NoAutoritzar,
  Test,
  Erronia,
}

@Component({
  selector: 'lib-autoritzacio-identificacio',
  templateUrl: './autoritzacio-identificacio.component.html',
})
export class AutoritzacioIdentificacioComponent implements OnInit {
  @Output() onResultatAutoritzacio = new EventEmitter<Autoritzacio>();
  @ViewChild('missatgeModal') missatgeModal: MissatgeModalComponent;

  autoritzacio: TipusAutoritzacio | null = null;
  observacions: string;

  TipusAutoritzacio = TipusAutoritzacio;

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {}

  onAutoritzacioCanvi(valor: TipusAutoritzacio) {
    this.autoritzacio = valor;
  }

  async autoritzar() {
    if (this.autoritzacio === null) {
      this.missatgeModal.obre(
        new Modal({
          titol: await this.translate
            .get('comu.autoritzacio.validacio.titol')
            .toPromise(),
          icona: ModalIcon.Atencio,
          missatge: await this.translate
            .get('comu.autoritzacio.validacio.falten-dades')
            .toPromise(),
          indicacions: await this.translate
            .get('comu.autoritzacio.validacio.indicacio-omplir')
            .toPromise(),
          esTancable: true,
        })
      );

      return;
    }

    if (
      this.autoritzacio === TipusAutoritzacio.Erronia &&
      (!this.observacions || this.observacions.trim() === '')
    ) {
      this.missatgeModal.obre(
        new Modal({
          titol: await this.translate
            .get('comu.autoritzacio.validacio.titol')
            .toPromise(),
          icona: ModalIcon.Atencio,
          missatge: await this.translate
            .get('comu.autoritzacio.validacio.falten-dades')
            .toPromise(),
          indicacions: await this.translate
            .get('comu.autoritzacio.validacio.indicacio-erroni')
            .toPromise(),
          esTancable: true,
        })
      );

      return;
    }

    const resultat = new Autoritzacio({
      observacions: this.observacions,
      autoritzat: this.autoritzacio !== TipusAutoritzacio.NoAutoritzar,
      erronia: this.autoritzacio === TipusAutoritzacio.Erronia,
      sortida:
        this.autoritzacio === TipusAutoritzacio.Sortida
          ? true
          : this.autoritzacio === TipusAutoritzacio.Retorn
          ? false
          : null,
      test: this.autoritzacio === TipusAutoritzacio.Test,
    });

    this.onResultatAutoritzacio.emit(resultat);
  }
}
