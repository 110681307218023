import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SistemaService {

  constructor() {}

  async connectar() {
  }

  async getNomMaquina(): Promise<string> {
    return "";
  }

  async getNomUsuari(): Promise<string> {
    return "";
  }
}
