import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EstatEnrolament } from '../model/estat-enrolament';

@Injectable({
  providedIn: 'root',
})
export class AplicacioService {
  constructor(private translate: TranslateService) {}

  obtenirNomDit(dit: number, nomSencer: boolean) {
    var nom = '';
    switch (dit) {
      case 1:
      case 6:
        if (nomSencer) nom = 'polze';
        break;
      case 2:
      case 7:
        nom = 'index';
        break;
      case 3:
      case 8:
        nom = 'mig';
        break;
      case 4:
      case 9:
        nom = 'anular';
        break;
      case 5:
      case 10:
        nom = 'auricular';
        break;
    }
    if (nom.length) nom = this.translate.instant('comu.nom-dit.' + nom);
    if (nomSencer) {
      var ma = 'dret';
      if (dit > 5) ma = 'esquerre';
      ma = this.translate.instant('comu.' + ma);
      nom += ma;
    }
    return nom;
  }

  obtenirEstatEnrolament() {
    return EstatEnrolament.EMPREMTES_BAIXA_QUALITAT;
  }
}
