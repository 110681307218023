export class RawImage {
  data?: any;
  width?: number;
  height?: number;
  pixelFormat?: PixelFormat;
}

export class FingerprintImage{
  public rawImage?: RawImage;
  public liveness?: number;
}

//
// Resumen:
//     Especifica el formato de los datos de color de cada uno de los píxeles de la
//     imagen.
export enum PixelFormat {
  //
  // Resumen:
  //     No se ha definido ningún formato de píxel.
  Undefined = 0,
  //
  // Resumen:
  //     No se ha especificado ningún formato de píxel.
  DontCare = 0,
  //
  // Resumen:
  //     Valor máximo de esta enumeración.
  Max = 15,
  //
  // Resumen:
  //     Los datos de píxel contienen valores de color indizado, lo que significa que
  //     los valores son índices referidos a los colores de la tabla de colores del sistema,
  //     a diferencia de los valores de color individuales.
  Indexed = 65536,
  //
  // Resumen:
  //     Los datos de píxeles contienen colores GDI.
  Gdi = 131072,
  //
  // Resumen:
  //     Especifica que el formato es de 16 bits por píxel, de los cuales se utilizan
  //     5 bits para cada uno de los componentes rojo, verde y azul. El bit restante no
  //     se utiliza.
  Format16bppRgb555 = 135173,
  //
  // Resumen:
  //     Especifica que el formato es de 16 bits por píxel; 5 bits se utilizan para el
  //     componente rojo, 6 bits para el componente verde y 5 bits para el componente
  //     azul.
  Format16bppRgb565 = 135174,
  //
  // Resumen:
  //     Especifica que el formato es de 24 bits por píxel, de los cuales se usan 8 bits
  //     para cada uno de los componentes rojo, verde y azul.
  Format24bppRgb = 137224,
  //
  // Resumen:
  //     Especifica que el formato es de 32 bits por píxel, de los cuales se usan 8 bits
  //     para cada uno de los componentes rojo, verde y azul. Los 8 bits restantes no
  //     se utilizan.
  Format32bppRgb = 139273,
  //
  // Resumen:
  //     Especifica que el formato de píxel es de 1 bit por píxel, y que utiliza color
  //     indizado. Por tanto, la tabla de colores contiene dos colores.
  Format1bppIndexed = 196865,
  //
  // Resumen:
  //     Especifica que el formato es de 4 bits por píxel, indizado.
  Format4bppIndexed = 197634,
  //
  // Resumen:
  //     Especifica que el formato es de 8 bits por píxel, indexado. Por tanto, la tabla
  //     de colores contiene 256 colores.
  Format8bppIndexed = 198659,
  //
  // Resumen:
  //     Los datos del píxel contienen valores alfa que no están previamente multiplicados.
  Alpha = 262144,
  //
  // Resumen:
  //     El formato de píxel es de 16 bits por píxel. La información de color especifica
  //     32.768 tonos de color, de los que 5 bits corresponden al rojo, 5 bits al verde,
  //     5 bits al azul, y 1 bit al alfa.
  Format16bppArgb1555 = 397319,
  //
  // Resumen:
  //     El formato de píxel contiene valores alfa previamente multiplicados.
  PAlpha = 524288,
  //
  // Resumen:
  //     Especifica que el formato es de 32 bits por píxel, de los cuales se utilizan
  //     8 bits para los componentes alfa, rojo, verde y azul. Los componentes rojo, verde
  //     y azul se multiplican previamente según el componente alfa.
  Format32bppPArgb = 925707,
  //
  // Resumen:
  //     Reservado.
  Extended = 1048576,
  //
  // Resumen:
  //     El formato de píxel es de 16 bits por píxel. La información de color especifica
  //     65.536 tonos de gris.
  Format16bppGrayScale = 1052676,
  //
  // Resumen:
  //     Especifica que el formato es de 48 bits por píxel, de los cuales se usan 16 bits
  //     para cada uno de los componentes rojo, verde y azul.
  Format48bppRgb = 1060876,
  //
  // Resumen:
  //     Especifica que el formato es de 64 bits por píxel, de los cuales se usan 16 bits
  //     para los componentes alfa, rojo, verde y azul. Los componentes rojo, verde y
  //     azul se multiplican previamente según el componente alfa.
  Format64bppPArgb = 1851406,
  //
  // Resumen:
  //     El formato de píxel predeterminado es de 32 bits por píxel. El formato especifica
  //     profundidad de color de 24 bits y un canal alfa de 8 bits.
  Canonical = 2097152,
  //
  // Resumen:
  //     Especifica que el formato es de 32 bits por píxel, de los cuales se utilizan
  //     8 bits para los componentes alfa, rojo, verde y azul.
  Format32bppArgb = 2498570,
  //
  // Resumen:
  //     Especifica que el formato es de 64 bits por píxel, de los cuales se usan 16 bits
  //     para los componentes alfa, rojo, verde y azul.
  Format64bppArgb = 3424269,
}
