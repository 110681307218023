<div class="bg-light">
  <div class="container pt-3 pb-2">
    <h2>{{ "inicialitzacio.titol" | translate }} </h2>
  </div>
</div>

<div class="container mt-4 mb-5">
  <div class="h3">{{software()}} Homologación</div>
  <div class="h5 text-secondary">{{ version() }}</div>

  <div class="h4 text-secondary mt-3">{{ missatge }}</div>

  <div class="h4">{{ "inicialitzacio.espereu" | translate }}</div>

  <div class="mt-4">
    <div class="spinner-border" style="width: 3rem; height: 3rem" role="status">
      <span class="sr-only"
        >{{ "inicialitzacio.inicialitzant" | translate }}...</span
      >
    </div>
  </div>
</div>
<lib-missatge-modal #missatgeModal></lib-missatge-modal>
