import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "imageUrl",
})
@Injectable()
export class ImageUrlPipe implements PipeTransform {
  transform(url?: string): string {
    if (!url) {
      return '';
    }
    if (url.startsWith('http') || url.startsWith('data:')) {
      return url;
    }

    return `data:image/png;base64,${url}`;
  }
}
