import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Modal, ModalAccio, ModalButton, ModalIcon } from '../../model/modal';

@Component({
  selector: 'lib-missatge-modal',
  templateUrl: './missatge-modal.component.html',
  styleUrls: ['./missatge-modal.component.css'],
})
export class MissatgeModalComponent implements OnInit {
  @ViewChild('modal') modal?: ElementRef;

  model: Modal = <Modal>{};
  ModalIcon = ModalIcon;

  private current?: NgbModalRef;
  private currentExecutor?: (
    value: ModalAccio | PromiseLike<ModalAccio>
  ) => void;

  constructor(
    private modalService: NgbModal,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  obre(model: Modal) {
    this.model = model;

    this.current = this.modalService.open(this.modal, { backdrop: 'static', centered: true });
  }

  async obreAsync(model: Modal): Promise<ModalAccio> {
    return new Promise<ModalAccio>((accept) => {
      this.currentExecutor = accept;

      this.model = model;
      this.current = this.modalService.open(this.modal, { backdrop: false, centered: true });
    });
  }

  async onClick(accio: ModalAccio) {
    if (accio.accio) accio.accio();

    if (accio.tancaModal) this.tanca();

    this.currentExecutor?.(accio);

    this.currentExecutor = undefined;
  }

  tanca() {
    
    this.current?.dismiss();

    this.current = undefined;
  }

  texteBoto(accio: ModalAccio): string {
    if (accio.text) {
      return accio.text;
    } else {
      switch (accio.boto) {
        case ModalButton.Cancelar:
          return this.translate.instant('comu.accio.cancelar');
        case ModalButton.Repetir:
          return this.translate.instant('comu.accio.repetir');
        default:
          return this.translate.instant('comu.accio.acceptar');
      }
    }
  }

  autofocus(accio: ModalAccio): string {
    if (accio.esAutofocus) {
      return 'ngbAutofocus';
    }
    return '';
  }
}
