import { CodiExcepcioCara, IsoQuality } from "projects/siepbio-comu/src/lib/model/facial-info";

export enum ResultatCaptura {
  CORRECTA = 0,
  BAIXA_QUALITAT = 1,
  MALA_QUALITAT = 2,
  REPETIDA = 3,
  POQUES_EMPREMTES = 4,
  ERRONI = 5,
  MA_ERRONIA = 6,
  HUELLA_FALSA = 7,
  SEGMENTACION_ERRONEA = 8
}

export class ResultatCapturaFoto {
  foto?: string; //base64
  fotoCorrecta?: boolean;
  fotoMillorable?: boolean;
  repetirFoto?: CodiExcepcioCara;
  warningInfo?: any;
}

