export class Formulario {
  tipo?: TipoFormulario;
  formularioPreguntas?: Array<FormularioPregunta>;
}

export class FormularioPregunta {
  identificador: string;
  pregunta: string;
  tipoPregunta: string;
  obligatorio: boolean;
  formularioPreguntaSeleccionGroup?: FormularioPreguntaSeleccion;
  formularioPreguntaFechaGroup?: FormularioPreguntaFecha;
  formularioPreguntaNumero?: FormularioPreguntaNumero;
}

export enum TipoFormulario {
  ELEGIBILIDAD,
  SCHENGEN,
}

/* TODO: parse string (REST API) a enum
export enum TipoPregunta {
  SI_NO,
  SELECCION_UNICA,
  SELECCION_MULTIPLE,
  FECHA,
  NUMERO,
  TEXTO_LIBRE,
}
*/
export class FormularioPreguntaSeleccion {
  listaOpciones: Array<FormularioPreguntaOpcion>;
}

export class FormularioPreguntaOpcion {
  nombre: string;
  texto: string;
}

export class FormularioPreguntaFecha {
  fechaMinima: Date;
  fechaMaxima: Date;
  esIntervalo: boolean;
  diasIntervaloMaximo?: number;
}

export class FormularioPreguntaNumero {
  numeroMinimo?: number;
  numeroMaximo?: number;
}
