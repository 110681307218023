import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfiguracioService, Excepcio } from 'projects/siepbio-comu/src/public-api';
import { Documento } from '../model/documento';
import { DocumentoUtilizado, ValidationData } from '../model/EntryExit';

@Injectable({
  providedIn: 'root'
})
export class DocumentReaderService {

  public host: string = "https://localhost:3001";
  constructor(private http: HttpClient,private configuracionService: ConfiguracioService) {
    if (configuracionService.parametrosConfiguracion.hostServiciosDocumentReader != undefined)
      this.host = configuracionService.parametrosConfiguracion.hostServiciosDocumentReader;
  }

  private async doCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    console.log("docall host documentreader", this.host);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (isGet)
      return await this.http
        .get(this.host + url, {
          headers: headers,
          params: params,
          observe: 'body',
          responseType: 'json',
        })
        .toPromise();
    else {
      return await this.http
        .post(
          this.host + url,
          json ? json : undefined,
          {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'json',
          }
        )
        .toPromise();
    }
  }

  private async manageCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    formParams?: FormData,
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(isGet, url, params, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      obj = await this.doCall(isGet, url, params, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  public async leer(
    chip: boolean,
    aa?: boolean, //aa = Autenticació Activa
    pa?: boolean, //pa = Autenticació Pasiva
    chipaa?: boolean, // chipaa = Autenticació de Chip
    ta?: boolean // ta= Autenticació de terminal
  ): Promise<DocumentoUtilizado> {
    try{
    let response = await this.manageCall(
      true,
      '/api/documentReader/read',{chip: String(chip), aa: String(aa), pa: String(pa),chipaa: String(chipaa), ta: String(ta), }
      );
      console.log("llamada a Leer MRZ", response);
      let result = new DocumentoUtilizado();
      let json = JSON.parse(JSON.stringify(response));
      if (json.message != undefined){
        //Se ha producido un error
        result.mensajeError = json.message?.Mensaje;
      }
      else{
        result.mrz = Boolean(json.mrz);
        result.rfid = Boolean(json.rfid);
        result.sod = Boolean(json.sod);
        let datosMrz = new Documento();
        datosMrz.apellidos = json.mrzData?.surname;
        datosMrz.nombre = json.mrzData?.forename;
        datosMrz.caducado = Boolean(json.mrzData?.expiredDocumentFlag);
        datosMrz.fechaCaducidad =  json.mrzData?.expiryDate;
        datosMrz.checksum = Boolean(json.mrzData?.checksum);
        datosMrz.fechaExpedicion = json.mrzData?.expeditionDate;
        datosMrz.fechaNacimiento = json.mrzData?.dateOfBirth;
        datosMrz.idPersonal = json.mrzData?.personalId;
        datosMrz.numeroDocumento = json.mrzData?.docNumber;
        datosMrz.tipoDocumento = json.mrzData?.docType;
        datosMrz.paisExpedidor = json.mrzData?.issuingState;
        datosMrz.nacionalidad = json.mrzData?.nationality;
        datosMrz.sexo = json.mrzData?.sex;
        datosMrz.line1 = json.mrzData?.line1;
        datosMrz.line2 = json.mrzData?.line2;
        datosMrz.line3 = json.mrzData?.line3;
        datosMrz.mrzCode = json.mrzData?.line1+json.mrzData?.line2+json.mrzData?.line3;
        result.MRZData =  datosMrz;
        result.validacions = <ValidationData> json.validacions;

        if (chip){
          let datosChip = new Documento();
          datosChip.apellidos = json.rfiddata?.surname;
          datosChip.nombre = json.rfiddata?.forename;
          datosChip.caducado = Boolean(json.rfiddata?.expiredDocumentFlag);
          datosChip.fechaCaducidad = json.rfiddata?.expiryDate;
          datosChip.checksum = Boolean(json.rfiddata?.checksum);
          datosChip.fechaExpedicion = json.rfiddata?.expeditionDate;
          datosChip.fechaNacimiento = json.rfiddata?.dateOfBirth;
          datosChip.idPersonal = json.rfiddata?.personalId;
          datosChip.numeroDocumento = json.rfiddata?.docNumber;
          datosChip.tipoDocumento = json.rfiddata?.docType;
          datosChip.paisExpedidor = json.rfiddata?.issuingState;
          datosChip.nacionalidad = json.rfiddata?.nationality;
          datosChip.sexo = json.rfiddata?.sex;
          datosChip.mrzCode = json.rfiddata?.mrz;
          datosChip.foto = json.rfiddata?.photo;
          datosChip.huella1 = json.rfiddata?.finger1;
          datosChip.huella2 = json.rfiddata?.finger2;
          datosChip.mrzCheck = Boolean(json.rfiddata?.mrzCheck);
          result.RFIDData =   datosChip;
        }
      }
      return result;
    }
    catch(ex){
      console.log("Excepcion reader", ex);
      let mensaje = "Compruebe que el servicio DocumentReader esté iniciado. Si es así, pruebe a reintentar la acción de lectura.";
      if (ex.status == 500){
        mensaje =  ex.error?.message;
      }
      throw new Excepcio(
        "No ha sido posible leer el documento. ", mensaje, ex.message
      );
    }
  }



}

