import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MissatgeModalComponent } from 'projects/siepbio-comu/src/lib/components/missatge-modal/missatge-modal.component';
import { Foto } from 'projects/siepbio-comu/src/lib/model/siepbio-model';

import { ComparacionFotos, ConfiguracioService, FacialService, ResultadoComparacionFotos, DatosService } from 'projects/siepbio-comu/src/public-api';
import { CategoriaMensaje, MensajeProceso, TipoMensajeProceso } from '../../model/EntryExit';
import { LiveViewFotoComponent } from '../../pagines/live-view-foto/live-view-foto.component';
import { ApartatService } from '../../serveis/apartat.service';
import { EnrolamentService } from '../../serveis/enrolament.service';
import { IdvService } from '../../serveis/idv.service';

@Component({
  selector: 'app-doc-foto-impresa',
  templateUrl: './doc-foto-impresa.component.html',
  styleUrls: ['./doc-foto-impresa.component.css']
})

//CLASSE OBSOLETA
export class DocFotoImpresaComponent implements OnInit {
  @ViewChild('liveView') liveView?: LiveViewFotoComponent;
  @ViewChild('missatgeModal') missatgeModal?: MissatgeModalComponent;
 
  foto?: Foto;
  closeResult: string = '';
  accio?: string;
  
  fotoZoom: string | undefined;
  fotoDocumento: string = 'assets/img/sin-fotografia.png';
  fotoImpresa: string = 'assets/img/sin-fotografia.png';
  score: number | undefined;
  coincidente: boolean | undefined;
  calculandoScore: boolean = false;

  constructor(
    private modalService: NgbModal,
    private apartatServei: ApartatService,
    private translate: TranslateService,
    private facialService: FacialService,
    private configurationService: ConfiguracioService,
    private enrolamentService: EnrolamentService,
    public datosService: DatosService
    ) {
    
   }

   ngOnInit(): void {
    

    this.accio = "Tomar foto";// this.translate.instant('comu.accio.fer-foto');
    if (this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.foto != undefined){
      this.fotoDocumento = "data:image/Jpeg;base64," + this.datosService.datosVerificacion?.documentoLeido?.RFIDData?.foto;
      
    }
    if (this.datosService.datosVerificacion?.fotoImpresa != undefined){
      this.fotoImpresa =  this.datosService.datosVerificacion?.fotoImpresa;
      this.accio = this.translate.instant('comu.accio.repetir');
    }
    /*
    this.foto = this.enrolamentServei.getFotoPersona();
    console.log("init registre. Foto", this.foto);
    console.log("init registre. imatge", this.foto?.imatge);
    if (this.foto != undefined && this.foto.imatge != undefined) {
      this.accio = this.translate.instant('comu.accio.repetir');
      
    } else {
      this.foto = new Foto('assets/img/sin-fotografia.png');
      
    }
    */
    this.score = this.datosService.datosVerificacion?.scoreImpresaVsChip;
    
  }



 

  
  ampliarImatge(content: any, imatge: string): void {
    if (imatge != undefined && imatge != 'assets/img/sin-fotografia.png'){
      this.fotoZoom = imatge;
      this.modalService.open(content, {
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        scrollable: true,
      });
    }
  }

  private añadirError(texto: string, error: boolean){
    let mensaje = new MensajeProceso();
    mensaje.tipo = error ? TipoMensajeProceso.Error : TipoMensajeProceso.Validacion;
    mensaje.categoria = CategoriaMensaje.Documento;
    mensaje.texto = texto;
    this.datosService.datosVerificacion = this.enrolamentService.añadirError(this.datosService.datosVerificacion, mensaje);
  }

  async validarScore(fotoCapturada: string, fotoChip: string): Promise<void>{
    if (fotoCapturada != undefined && fotoChip != undefined 
      && fotoCapturada != 'assets/img/sin-fotografia.png'
      && fotoChip != 'assets/img/sin-fotografia.png'){
      let comparacion = new ComparacionFotos();
      comparacion.galleryImage = this.facialService.netejarBase64(fotoCapturada);
      comparacion.probeImage = this.facialService.netejarBase64(fotoChip);
      let resultado = await this.facialService.match(comparacion);
      
      if (resultado.errorGalleryImage != undefined){
        this.añadirError(resultado.errorGalleryImage,true);
      }
      if (resultado.errorProbeImage != undefined){
        this.añadirError(resultado.errorProbeImage, true);
      }
      if (resultado.score != undefined && resultado.score > 0){
        this.score = resultado.score;
        this.coincidente = resultado.score >= this.configurationService.parametrosConfiguracion.umbralCoincidenciaFotos;
      }
      else {
        this.coincidente = undefined;
        this.score = undefined;
      }
    }
  }
  
  normalizaScore(score?: number): string {
    return FacialService.normalizaScore(score);
  }

  afegirFoto(): void {
    this.liveView?.obre();
  }
  
  onFotoCapturada(fotoCapturada: string): void {
    console.log("on foto impresa capturada ");
    if (fotoCapturada != undefined){
      this.calculandoScore = true;
      this.validarScore(fotoCapturada, this.fotoDocumento);
      this.calculandoScore = false;
      this.accio = this.translate.instant('comu.accio.repetir');
      this.datosService.datosVerificacion.fotoImpresa = fotoCapturada;
      this.fotoImpresa = fotoCapturada;
      
    }
  }

  borrarFoto(): void{
    this.fotoImpresa = 'assets/img/sin-fotografia.png';
    this.datosService.datosVerificacion.fotoImpresa = undefined;
  }
  
}
