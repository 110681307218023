<div class="container-fluid">
  <div class="row my-1">
    <div class="col-sm-12 ">
        
        <app-barra-acciones [accion]="Etapa.Facial">
            <button class="btn btn-primary btn-lg mr-2" type="button" (click)="afegirFoto()"><fa-icon [icon]="iconCamera"></fa-icon> Tomar foto</button> 
            <button  *ngIf="datosService.datosVerificacion.fotoCapturada" class="btn btn-secondary btn-lg " type="button" (click)="borrarFoto()"><fa-icon [icon]="iconDelete"></fa-icon> Borrar foto</button> 
        </app-barra-acciones>
        <!--span class="text-info" *ngIf="procesandoMRZ"> Leyendo MRZ y chip ...  </span><span *ngIf="procesandoMRZ" class="spinner-border spinner-border-sm text-info" role="status"></span> 
        <span class="text-info" *ngIf="procesandoEES"> Obteniendo información EES... </span><span *ngIf="procesandoEES" class="spinner-border spinner-border-sm text-info" role="status"></span>
        <span *ngIf="datosService.datosVerificacion?.documentoLeido" class="ml-3 badge badge-pill badge-{{colorResultadoMRZ}}">{{resultadoMRZ}}</span-->
      
    </div>
  </div>

      <div class="row justify-content-center">
        <div class="col-sm-4">
          <div class="card">
           
            <div class="card-body text-center">
              <img
                class="img-fluid shadow border border-secondary rounded"
                [src]="getFoto(datosService.datosVerificacion?.fotoCapturada)"
                alt="{{ 'comu.accio.ampliar' | translate }}"
                (click)="ampliarImatge(fotoAmpliada,datosService.datosVerificacion?.fotoCapturada)"
                title="{{ 'comu.accio.ampliar' | translate }}"
                aria-label="Ampliar imatge"
              />
            </div>
          </div>
        
          <div *ngIf="datosService.datosVerificacion?.resultadoFaceUSK" class="alert alert-{{mensajeUSK().color}} mt-1">{{mensajeUSK().texto}}</div>

          <div *ngIf="datosService.datosVerificacion?.resultadoCapturaFoto?.warningInfo != undefined" class="alert alert-warning">
            <a data-toggle="collapse" href="#warnings" role="button" aria-expanded="false" aria-controls="warnings" class="btn btn-link h5">Detalles por mejorar</a>
            <div class="collapse" id="warnings">
              <ul type="square">
                <li *ngFor="let item of datosService.datosVerificacion?.resultadoCapturaFoto?.warningInfo | keyvalue">{{ item.key }}</li>
              </ul>
            </div>
          </div>

        
        
        </div>
        <div class="col-sm-8">
          <div class="card-deck">
            
              <div class="card">
               
                <div class="card-body">
                  <img
                    class="img-fluid shadow border border-secondary rounded"
                    [src]="getFoto(datosService.datosVerificacion?.documentoLeido?.RFIDData?.foto)"
                    alt="{{ 'comu.accio.ampliar' | translate }}"
                    (click)="ampliarImatge(fotoAmpliada,datosService.datosVerificacion?.documentoLeido?.RFIDData?.foto)"
                    title="{{ 'comu.accio.ampliar' | translate }}"
                    aria-label="Ampliar imatge"
                  />
                  <div class="ribbon-wrapper ribbon-lg" *ngIf="datosService.datosVerificacion?.coincideFotoVsChip != undefined">
                    <div class="ribbon bg-{{datosService.datosVerificacion?.coincideFotoVsChip ? 'success': 'danger'}}">
                      {{normalizaScore(datosService.datosVerificacion?.scoreFotoVsChip)}}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center h5">Foto Chip</div>
              </div>
            
            
              <div class="card">
               
                <div class="card-body">
                  <img
                    class="img-fluid shadow border border-secondary rounded"
                    [src]="getFoto(datosService.datosVerificacion?.resultadoSearch?.registros[0]?.foto)"
                    alt="{{ 'comu.accio.ampliar' | translate }}"
                    (click)="ampliarImatge(fotoAmpliada,datosService.datosVerificacion?.resultadoSearch?.registros[0]?.foto)"
                    title="{{ 'comu.accio.ampliar' | translate }}"
                    aria-label="Ampliar imatge"
                  />
                  <div class="ribbon-wrapper ribbon-lg" *ngIf="datosService.datosVerificacion?.coincideFotoVsEES != undefined">
                    <div class="ribbon bg-{{datosService.datosVerificacion?.coincideFotoVsEES ? 'success': 'danger'}}">
                      {{normalizaScore(datosService.datosVerificacion?.scoreFotoVsEES)}}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center h5">Foto EES</div>
              </div>
            
            
              <div class="card">
                
                <div class="card-body">
                  <img
                    class="img-fluid shadow border border-secondary rounded"
                    [src]="getFoto(datosService.datosVerificacion?.fotoImpresa)"
                    alt="{{ 'comu.accio.ampliar' | translate }}"
                    (click)="ampliarImatge(fotoAmpliada,datosService.datosVerificacion?.fotoImpresa)"
                    title="{{ 'comu.accio.ampliar' | translate }}"
                    aria-label="Ampliar imatge"
                  />
                  <div class="ribbon-wrapper ribbon-lg" *ngIf="datosService.datosVerificacion?.coincideFotoVsImpresa != undefined">
                    <div class="ribbon bg-{{datosService.datosVerificacion?.coincideFotoVsImpresa ? 'success': 'danger'}}">
                      {{normalizaScore(datosService.datosVerificacion?.scoreFotoVsImpresa)}}
                    </div>
                  </div>
                </div>
                <div class="card-footer text-center h5">Foto VIZ</div>
              </div>
            
          </div>
        </div>
      </div>
    
 
</div>


<app-live-view-foto
  #liveView
  (onFoto)="onFotoCapturada($event)"
 
  [activarControlesCalidadFoto]="true"
></app-live-view-foto>

<ng-template #fotoAmpliada let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "comu.foto-ampliada" | translate }}
    </h4>
    <button
      type="button"
      class="btn  btn-danger"
      aria-label="Cerrar"
      (click)="modal.dismiss('tanca')"
      title="{{ 'comu.accio.tancar' | translate }}"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="text-center">
      <img
        class="img-fluid"
        alt=" {{ 'comu.foto-ampliada' | translate }}"
        src="{{ fotoZoom }}"
      />
    </div>
  </div>
</ng-template>
<lib-missatge-modal #missatgeModal></lib-missatge-modal>