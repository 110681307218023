<div class="row my-2">
    <div class="col-sm-12">
        <ng-content></ng-content>    
        <button class="btn btn-secondary btn-lg float-right ml-1" type="button" (click)="siguiente()"> <fa-icon [icon]="iconNext"  ></fa-icon> </button> 
        <div class="btn-group float-right">
            <button *ngIf="datosService.datosVerificacion?.decisionesTomadas != undefined && datosService.datosVerificacion?.decisionesTomadas[accion]?.tipo == TipoDecision.PENDIENTE" type="button" class="btn btn-secondary btn-lg "><fa-icon [icon]="iconPendiente"  ></fa-icon> Pendiente</button>
            <button *ngIf="datosService.datosVerificacion?.decisionesTomadas != undefined && datosService.datosVerificacion?.decisionesTomadas[accion]?.tipo == TipoDecision.ACEPTADO" type="button" class="btn btn-success btn-lg "><fa-icon [icon]="iconValido"  ></fa-icon> Válido</button>
            <button *ngIf="datosService.datosVerificacion?.decisionesTomadas != undefined && datosService.datosVerificacion?.decisionesTomadas[accion]?.tipo == TipoDecision.RECHAZADO" type="button" class="btn btn-danger btn-lg "><fa-icon [icon]="iconNoValido"  ></fa-icon> Rechazado</button>
            <button type="button" class="btn btn-{{datosService.datosVerificacion?.decisionesTomadas != undefined && datosService.datosVerificacion?.decisionesTomadas[accion]?.tipo == TipoDecision.PENDIENTE ? 'secondary' : datosService.datosVerificacion?.decisionesTomadas != undefined && datosService.datosVerificacion?.decisionesTomadas[accion]?.tipo == TipoDecision.ACEPTADO ? 'success':'danger' }} dropdown-toggle dropdown-icon" data-toggle="dropdown">
            <span class="sr-only">Toggle Dropdown</span>
            </button>
            <div class="dropdown-menu" role="menu">
                <a class="dropdown-item h4" href="javascript:void(0)" *ngIf="datosService.datosVerificacion?.decisionesTomadas != undefined && datosService.datosVerificacion?.decisionesTomadas[accion]?.tipo != TipoDecision.ACEPTADO"  (click)="cambiarResolucion(TipoDecision.ACEPTADO)"><fa-icon [icon]="iconValido"  ></fa-icon> Aceptar</a>
                <a class="dropdown-item h4" href="javascript:void(0)" *ngIf="datosService.datosVerificacion?.decisionesTomadas != undefined && datosService.datosVerificacion?.decisionesTomadas[accion]?.tipo != TipoDecision.RECHAZADO"  (click)="cambiarResolucion(TipoDecision.RECHAZADO)"><fa-icon [icon]="iconNoValido"  ></fa-icon> Rechazar</a>
            </div>
        </div>
    </div>
</div>

<ng-template #contentMotivo let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        Motivo de la resolución
      </h4>
      <button
        type="button"
        class="btn btn-danger"
        aria-label="Cerrar"
        (click)="modal.dismiss('')"
        title="{{ 'comu.accio.tancar' | translate }}"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body bg-light">
        <form [formGroup]="motivoForm" id="formMotivo" (ngSubmit)="onSubmit()">
            <div class="mb-3">
                <label for="motivoManual">Introduzca el motivo por el cual está {{TipoDecision[resolucionProvisional]}}</label>
                <div class="form-group">
                <input type="text" class="form-control" id="motivoManual" size="1"  name="motivoManual" formControlName="motivoManual"
                [ngClass]="{ 'is-invalid': submitted && f.motivoManual.errors }"
                >
                <div *ngIf="submitted && f.motivoManual.errors" class="invalid-feedback">
                    <div *ngIf="f.motivoManual.errors.required">Campo obligatorio</div>
                </div>

                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer inline-footer">
        <button
        ngbAutofocus
        type="submit"
        form="formMotivo"
        class="btn btn-primary btn-lg mr-1 my-1 float-right"
      >
        {{ "comu.accio.guardar" | translate }}
      </button>
      <button
        type="button"
        (click)="modal.dismiss('')"
        class="btn btn-secondary btn-lg mx-1 my-1 float-right"
      >
        {{ "comu.accio.cancelar" | translate }}
      </button>
    </div>
  </ng-template>

<lib-missatge-modal #missatgeModal></lib-missatge-modal>

