import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Excepcio } from '../model/excepcio';
import {
  ComparacionFotos,
  FacialConfiguration,
  FacialInfo,
  IdentificationResult,
  IsoQuality,
  ResultadoComparacionFotos,
  Error,
  ResultadoIcao,
  QualityResponse,
  CodiExcepcioCara,
  IcaoRequest
} from '../model/facial-info';

import { ConfiguracioService } from './configuracio.service';

@Injectable({
  providedIn: 'root',
})
export class FacialService {
  public host: string = "https://localhost:3002";
  constructor(private http: HttpClient, private configuratioService: ConfiguracioService) {
    if (configuratioService.parametrosConfiguracion.hostServiciosFacialRecognition != undefined)
      this.host = configuratioService.parametrosConfiguracion.hostServiciosFacialRecognition;
  }

/*
  //OBSOLETO . substituir por quality y icao
  async getImatgeICAO(fotoBase64: string | undefined): Promise<FacialInfo> {
    console.log('getImatgeICAO', fotoBase64);
    console.log('connection', this._connection);
    var result = <FacialInfo>await this._connection?.invoke('Qualitat', fotoBase64);
    console.log('Result getImatgeICAO', result);
    return result;

  }

  //OBSOLETO 
  async inicialitza(config: FacialConfiguration) {
    await this._connection?.invoke('Inicialitza', config);
  }

  async buscaCara(probe: string): Promise<IdentificationResult> {
    return await this._connection?.invoke('BuscaCara', probe);
  }


  async setGaleria(templates: Array<string>) {
    await this._connection?.invoke('SetGaleria', templates);
  }
  */
  private async doCall(
    isGet: boolean,
    url: string,
    json?: string
  ): Promise<any> {
    console.log("docall host facial", this.host);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (isGet)
      return await this.http
        .get(this.host + url, {
          headers: headers,
          observe: 'body',
          responseType: 'json',
        })
        .toPromise();
    else {
      return await this.http
        .post(
          this.host + url,
          json ? json : undefined,
          {
            headers: headers,
            observe: 'body',
            responseType: 'json',
          }
        )
        .toPromise();
    }
  }

  private async manageCall(
    isGet: boolean,
    url: string,
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(isGet, url, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      obj = await this.doCall(isGet, url, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  public netejarBase64(b64: string | undefined): string | undefined {
    let result: string;
    if (b64 == undefined || b64.length == 0) return undefined;
    const i = b64.indexOf(',');
    if (i > -1) {
      return b64.substring(i + 1);
    }
    return undefined;
  }

  public async quality(
    foto: string
  ): Promise<QualityResponse> {
    try{
    let response = await this.manageCall(
      false,
      '/api/facialRecognition/quality', JSON.stringify(foto)
    );
    console.log("Quality. Response:", response);
    let resultQa = new QualityResponse();
    let json = JSON.parse(JSON.stringify(response));
    if (Number(json["code"]) > 0){

      let error = new Error();
      error.Code = Number(json["code"]);
      error.Message = json["message"];
      resultQa.error = error;
      console.log("Error en quality", error);
    }
    else{
      let result = new IsoQuality();
      result.background = Boolean(json.isoQuality?.bBackground);
      result.exposure = Boolean(json.isoQuality?.bExposure);
      result.eyeOpen = Boolean(json.isoQuality?.bEyeOpen);
      result.frontal = Boolean(json.isoQuality?.bFrontal);
      result.gaze = Number(json.isoQuality?.bGaze) == -1 ? undefined : Boolean(json.isoQuality?.bGaze);
      result.glasses = Boolean(json.isoQuality?.bGlasses);
      result.grayScaleProf = Boolean(json.isoQuality?.bGrayScaleProf);
      result.hCentred = Boolean(json.isoQuality?.bhCentred);
      result.hat = Boolean(json.isoQuality?.bHat);
      result.heavyFrames = Boolean(json.isoQuality?.bHeavyFrames);
      result.isoErrorType = Number(json.isoQuality?.isoErrorType);
      result.lengthHead = Boolean(json.isoQuality?.bLengthHead);
      result.light = Boolean(json.isoQuality?.bLight);
      result.mouthClose = Boolean(json.isoQuality?.bMouthClose);
      result.naturalSkinColor = Boolean(json.isoQuality?.bNaturalSkinColor);
      result.noEyeShadow = Boolean(json.isoQuality?.bNoEyeShadow);
      result.noHotSpot = Boolean(json.isoQuality?.bNoHotSpot);
      result.noTintedGlasses = Boolean(json.isoQuality?.bNoTintedGlasses);
      result.notRedEye = Boolean(json.isoQuality?.bNotRedEye);
      result.onlyOneFace = Boolean(json.isoQuality?.bOnlyOneFace);
      result.resolution = Boolean(json.isoQuality?.bResolution);
      result.sharp = Boolean(json.isoQuality?.bSharp);
      result.score = Number(json.isoQuality?.score);
      result.vPos = Boolean(json.isoQuality?.bvPos);
      result.veil = Boolean(json.isoQuality?.bVeil);
      result.whRatio = Boolean(json.isoQuality?.bwhRatio);
      result.widthHead = Boolean(json.isoQuality?.bWidthHead);
      resultQa.isoQuality = result;
    }
    resultQa.warnings = Number(json["warnings"]);
    console.log("Resultado Quality", resultQa);
    return resultQa;
  }
  catch(ex){
    throw new Excepcio(
      "Error en el servicio Facial [Quality]. ", "Pruebe a reintentar", ex.message
    );
  }
  }

  public async icao(
    foto: IcaoRequest
  ): Promise<ResultadoIcao> {
    let result = new ResultadoIcao();
    try{
    let response = await this.manageCall(
      false,
      '/api/facialRecognition/icao',  JSON.stringify(foto)
    );
    console.log("ICAO. Response:", response);
    result.image = response;
    console.log("Resultado ICAO", result);
  }
  catch(ex){
    if(ex.error?.data?.faceExceptionCode) {
      result.error = new Error();
      result.error.Code = ex.error.data.faceExceptionCode;
      result.error.Message = ex.error.message;
    } else {
      throw new Excepcio(
        "Error en el servicio Facial [ICAO]. " , "Pruebe a reintentar", ex.message
      );
    }
  }
  return result;
  }


  public async qualityIcao(
    foto: string
  ): Promise<QualityResponse> {
    let resultQa = new QualityResponse();
    try{
      let response = await this.manageCall(
        false,
        '/api/facialRecognition/qualityAndIcao', JSON.stringify(foto)
      );
      console.log("Quality and ICAO. Response:", response);
      let json = JSON.parse(JSON.stringify(response));
      
      let result = new IsoQuality();

      result.background = Boolean(json.quality?.isoQuality?.bBackground);
      result.exposure = Boolean(json.quality?.isoQuality?.bExposure);
      result.eyeOpen = Boolean(json.quality?.isoQuality?.bEyeOpen);
      result.frontal = Boolean(json.quality?.isoQuality?.bFrontal);
      result.gaze = Number(json.quality?.isoQuality?.bGaze) == -1 ? undefined : Boolean(json.quality?.isoQuality?.bGaze);
      result.glasses = Boolean(json.quality?.isoQuality?.bGlasses);
      result.grayScaleProf = Boolean(json.quality?.isoQuality?.bGrayScaleProf);
      result.hCentred = Boolean(json.quality?.isoQuality?.bhCentred);
      result.hat = Boolean(json.quality?.isoQuality?.bHat);
      result.heavyFrames = Boolean(json.quality?.isoQuality?.bHeavyFrames);
      result.isoErrorType = Number(json.quality?.isoQuality?.isoErrorType);
      result.lengthHead = Boolean(json.quality?.isoQuality?.bLengthHead);
      result.light = Boolean(json.quality?.isoQuality?.bLight);
      result.mouthClose = Boolean(json.quality?.isoQuality?.bMouthClose);
      result.naturalSkinColor = Boolean(json.quality?.isoQuality?.bNaturalSkinColor);
      result.noEyeShadow = Boolean(json.quality?.isoQuality?.bNoEyeShadow);
      result.noHotSpot = Boolean(json.quality?.isoQuality?.bNoHotSpot);
      result.noTintedGlasses = Boolean(json.quality?.isoQuality?.bNoTintedGlasses);
      result.notRedEye = Boolean(json.quality?.isoQuality?.bNotRedEye);
      result.onlyOneFace = Boolean(json.quality?.isoQuality?.bOnlyOneFace);
      result.resolution = Boolean(json.quality?.isoQuality?.bResolution);
      result.sharp = Boolean(json.quality?.isoQuality?.bSharp);
      result.score = Number(json.quality?.isoQuality?.score);
      result.vPos = Boolean(json.quality?.isoQuality?.bvPos);
      result.veil = Boolean(json.quality?.isoQuality?.bVeil);
      result.whRatio = Boolean(json.quality?.isoQuality?.bwhRatio);
      result.widthHead = Boolean(json.quality?.isoQuality?.bWidthHead);
      resultQa.isoQuality = result;
      resultQa.imagenIcao = json.icao;
      resultQa.warnings = Number(json.quality?.warnings);
      console.log("Resultado Quality", resultQa);
    }
    catch(ex){
      if(ex.error?.data?.faceExceptionCode) {
        resultQa.error = new Error();
        resultQa.error.Code = ex.error.data.faceExceptionCode;
        resultQa.error.Message = ex.error.message;
      } else {
        throw new Excepcio(
          "Error en el servicio Facial [Quality & ICAO]. " , "Pruebe a reintentar", ex.message
        );
      }
    }
  return resultQa;
  }

  public async qualityMatch(
    fotos: ComparacionFotos
  ): Promise<ResultadoComparacionFotos> {
    console.log(" host facial", this.host);
    console.log("fotos per comparar", fotos);
    try{
    let response = await this.manageCall(
      false,
      '/api/facialRecognition/qualityAndmatch', JSON.stringify(fotos)
    );
    console.log("quality abd match. Response:", response);
    let result = new ResultadoComparacionFotos();
    let json = JSON.parse(JSON.stringify(response));
    result.errorGalleryImage =  json.galleryInfo?.error?.message;
    result.errorProbeImage = json.probeInfo?.error?.message;
    result.score =  Number(json['score']);
    console.log("match. result:", result);
    return result;
  }
  catch(ex){
    throw new Excepcio(
      "Error en el servicio Facial [Quality & Match]. ", "Pruebe a reintentar", ex.message
    );
  }
  }

  public async match(
    fotos: ComparacionFotos
  ): Promise<ResultadoComparacionFotos> {
    console.log(" host facial", this.host);
    console.log("fotos per comparar", fotos);
    try{
    let response = await this.manageCall(
      false,
      '/api/facialRecognition/match', JSON.stringify(fotos)
    );
    console.log("match. Response:", response);
    let result = new ResultadoComparacionFotos();
    let json = JSON.parse(JSON.stringify(response));
    result.errorGalleryImage =  json.galleryInfo?.error?.message;
    result.errorProbeImage = json.probeInfo?.error?.message;
    result.score =  Number(json['score']);
    console.log("match. result:", result);
    return result;
  }
  catch(ex){
    throw new Excepcio(
      "Error en el servicio Facial [Match]. ", "Pruebe a reintentar", ex.message
    );
  }
  }

  public async template(
    foto: string
  ): Promise<string> {
    console.log("fotos per fer el Template", foto);
    let response = await this.manageCall(
      false,
      '/api/facialRecognition/template', JSON.stringify(foto)
    );
    return response;
  }

  public async version(): Promise<string> {
    console.log("version FRecognition");
    let response = await this.manageCall(
      true,
      '/api/facialRecognition/version'
    );
    return response;
  }


  public static normalizaScore(nonNormalizedScore?: number): string {
    if (nonNormalizedScore == null) return null;

    var maxRange = [100, Number.MAX_VALUE];

    for(var scoreRange of this.faceScoreFrp_5_2_0_Ranges) {
        if (nonNormalizedScore >= scoreRange[1]) {
            const result = this.calculateNormalizedScore(maxRange, scoreRange, nonNormalizedScore);

            console.log("Normalizado de " + nonNormalizedScore + " = " + result);

            return result + '%';
        }

        maxRange = scoreRange;
    }

    return null;
  }

  private static calculateNormalizedScore(maxRange: Array<number>, minRange: Array<number>, nonNormalizedScore: number): number
  {
      try
      {
          var scoreValueDifference = maxRange[1] - minRange[1];
          var scoreKeyDifference = maxRange[0] - minRange[0];
          var unitValue = scoreValueDifference / scoreKeyDifference;

          return minRange[0] + Math.floor((nonNormalizedScore - minRange[1]) / unitValue);
      }
      catch (ex) {
          return minRange[0];
      }
  }

  private static faceScoreFrp_5_2_0_Ranges: Array<Array<number>> = [
    [100,3600],
    [99,3550],
    [98,3500],
    [97,3450],
    [96,3400],
    [95,3350],
    [94,3300],
    [93,3250],
    [92,3200],
    [91,3150],

    [90,3108],//FAR 0.0001

    [89,3102],
    [88,3096],
    [87,3088],
    [86,3080],
    [85,3072],
    [84,3064],
    [83,3056],
    [82,3048],
    [81,3040],
    [80,3031],
    [79,3020],
    [78,3012],

    [77,3002],//FAR 0.0005 -> HIGH
    
    [76,3001],
    [75,3000],
    [74,2993],
    [73,2986],
    [72,2980],
    [71,2973],
    [70,2966],
    [69,2960],
    [68,2953],
    [67,2946],
    [66,2940],
    [65,2933],
    [64,2926],
    [63,2920],
    [62,2913],

    [61,2907],//FAR 0.001 -> MEDIUM
    
    [60,2890],
    [59,2885],
    [58,2875],
    [57,2870],
    [56,2860],
    [55,2855],
    [54,2845],
    [53,2840],
    [52,2830],

    [51,2826],//FAR 0.005

    [50,2820],
    [49,2815],
    [48,2810],
    [47,2805],
    [46,2800],

    [45,2796],//FAR 0.01 -> LOW

    [44,2787],
    [43,2786],
    [42,2784],
    [41,2782],
    [40,2780],
    [39,2778],
    [38,2776],
    [37,2774],
    [36,2772],
    [35,2770],
    [34,2768],
    [33,2766],
    [32,2764],
    [31,2762],
    [30,2760],
    [29,2758],
    [28,2756],
    [27,2754],
    [26,2752],
    [25,2750],
    [24,2748],
    [23,2746],
    [22,2744],
    [21,2742],
    [20,2740],
    [19,2738],
    [18,2736],
    [17,2734],
    [16,2732],
    [15,2730],
    [14,2728],
    [13,2726],
    [12,2724],
    [11,2722],
    [10,2720],
    [9,2718],
    [8,2716],
    [7,2714],
    [6,2712],
    [5,2710],
    [4,2718],
    [3,2715],
    [2,2710],
    [1,2700],
    [0,0]
  ];
}
