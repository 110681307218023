<div class="card caixaEmpremta mx-1">
  <div class="mb-1">
    <div ngbDropdown class="d-inline-block float-right">
      <a class="btn btn-link fontGran p-0" id="dropdownBasic1" ngbDropdownToggle></a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button
          ngbDropdownItem class="h4"
          (click)="seleccionarDisponibilitat(Disponibilitat.NORMAL)"
        >
          {{ "comu.disponibilitat.normal" | translate }}
        </button>
        <button
          ngbDropdownItem class="h4"
          (click)="seleccionarDisponibilitat(Disponibilitat.AMPUTAT)"
        >
          {{ "comu.disponibilitat.amputat" | translate }}
        </button>
        <button
          ngbDropdownItem class="h4"
          (click)="seleccionarDisponibilitat(Disponibilitat.NO_DISPONIBLE)"
        >
          {{ "comu.disponibilitat.no-disponible" | translate }}
        </button>
      </div>
    </div>

    <span class="h6">{{ getNomDit(false) }}</span>
  </div>
  <div class="text-center">
    <a
      href="javascript:void(0)"
      (click)="ampliarImatge(contentEmpremta)"
      title="ampliar imatge"
    >
      <div
        class="card text-center mx-auto quadre quadre-{{ getBadge(true) }}"
        title="{{ getNomDit(true) }}"
      >
        <img
          class="h-100 w-100"
          style="object-fit: contain"
          *ngIf="getImatge()"
          [src]="getImatge()"
          alt="{{ getNomDit(true, true) }}"
          title="{{ getNomDit(true, true) }}"
        />

        <span class="badge-top badge badge-{{ getBadge(false) }} ml-1">{{
          getPuntuacio()
        }}</span>

        <span
          *ngIf="dit.imatge != null"
          ngbTooltip="{{ getPuntuacio() }}"
          placement="right"
          class="badge badge-{{ getBadge(true) }} badgeBottom"
          >{{ getResultatText() }}</span
        >

        <span *ngIf="esMillorCaptura()" class="millor-captura">▲</span>
      </div>
    </a>

    <button
      type="button"
      (click)="capturar()"
      class="btn btn-lg btn-outline-primary  my-1 py-3 "
      [hidden]="dit.disponibilitat !== Disponibilitat.NORMAL"
    >
      {{ "comu.accio.capturar" | translate }}
    </button>
    <button
      type="button"
      (click)="netejarEmpremta()"
      title="{{ 'comu.accio.netejar' | translate }}"
      class="btn btn-outline-secondary btn-lg t my-1 ml-1 py-3"
      aria-label="Netejar"
      [hidden]="dit.disponibilitat !== Disponibilitat.NORMAL"
    >
      <svg
        width="1em"
        height="1em"
        viewBox="0 0 16 16"
        class="bi bi-trash"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
        />
        <path
          fill-rule="evenodd"
          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
        />
      </svg>
    </button>
  </div>
</div>

<ng-template #capturaTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "comu.accio.capturar" | translate }} {{ getNomDit(true) }}
      {{ getTexteRodada() }}
    </h4>
    <button
      type="button"
      class="btn  btn-danger"
      aria-label="Cerrar"
      (click)="cancelar()"
      title=" {{ 'comu.accio.tancar' | translate }} "
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row text-center">
      <div class="h3 mx-auto px-5">
        {{ getTitol() }}
      </div>
      <div class="caixaCaptura mx-auto">
        <lib-captura-dactilar-en-viu></lib-captura-dactilar-en-viu>
      </div>
    </div>
  </div>

  <div class="modal-footer" style="display: inline-block">
    <!--button
      type="button"
      (click)="calibrarEscaner()"
      class="float-left btn btn-outline-secondary btn-lg"
    >
      {{ "comu.accio.calibrar" | translate }}
    </button-->
    <button
      type="button"
      (click)="cancelar()"
      class="btn btn-secondary btn-lg float-right"
    >
      {{ "comu.accio.cancelar" | translate }}
    </button>
    <button
      ngbAutofocus
      type="button"
      [hidden]="!esRodada"
      (click)="acceptar()"
      class="btn btn-primary btn-lg float-right"
    >
      {{ "comu.accio.acceptar" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #contentEmpremta let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ getNomDit(true) }} {{ getTexteRodada() }}
    </h4>
    <button
      type="button"
      class="btn  btn-danger"
      aria-label="Cerrar"
      (click)="modal.dismiss('')"
      title="{{ 'comu.accio.tancar' | translate }}"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body bg-light">
    <div class="row text-center">
      <div class="caixaCaptura mx-auto h-100">
        <img
          class="h-100 w-100"
          style="object-fit: contain"
          *ngIf="dit.imatge != null"
          [src]="getImatge()"
          alt="{{ getNomDit(true) }}"
        />
      </div>
    </div>
  </div>
</ng-template>

<lib-calibrar-escaner-empremtes #calibrar></lib-calibrar-escaner-empremtes>
<lib-missatge-modal #missatgeModal></lib-missatge-modal>
