<div class="bg-light">
  <div class="container pt-3 pb-2">
    <h2>
      <button
        type="button"
        class="btn btn-link"
        (click)="tornar()"
        aria-label="Tornar"
        title="{{ 'comu.accio.tornar' | translate }}"
      >
        <svg
          width="2em"
          height="2em"
          viewBox="0 0 16 16"
          class="bi bi-arrow-left-circle-fill"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-4.5.5a.5.5 0 0 0 0-1H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5z"
          />
        </svg>
      </button>
      {{ "comu.seleccio-dits.seleccio-dits-captura" | translate }}
    </h2>
  </div>
</div>

<div class="container mt-4">
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <h5 class="card-header">
          {{ "comu.seleccio-dits.ma-esquerra" | translate }}
        </h5>
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col text-center">
              <div
                (click)="seleccionarDit(10)"
                class="finger rounded littleF {{
                  esSeleccionatDit(10) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.auricular" | translate }}
              </div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(9)"
                class="finger rounded ringF {{
                  esSeleccionatDit(9) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.anular" | translate }}
              </div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(8)"
                class="finger rounded middleF {{
                  esSeleccionatDit(8) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">{{ "comu.nom-dit.mig" | translate }}</div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(7)"
                class="finger rounded indexF {{
                  esSeleccionatDit(7) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.index" | translate }}
              </div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(6)"
                class="finger rounded thumbF {{
                  esSeleccionatDit(6) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.polze" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="card">
        <h5 class="card-header">
          {{ "comu.seleccio-dits.ma-dreta" | translate }}
        </h5>
        <div class="card-body">
          <div class="row no-gutters">
            <div class="col text-center">
              <div
                (click)="seleccionarDit(1)"
                class="finger rounded thumbF {{
                  esSeleccionatDit(1) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.polze" | translate }}
              </div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(2)"
                class="finger rounded indexF {{
                  esSeleccionatDit(2) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.index" | translate }}
              </div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(3)"
                class="finger rounded middleF {{
                  esSeleccionatDit(3) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">{{ "comu.nom-dit.mig" | translate }}</div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(4)"
                class="finger rounded ringF {{
                  esSeleccionatDit(4) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.anular" | translate }}
              </div>
            </div>
            <div class="col text-center">
              <div
                (click)="seleccionarDit(5)"
                class="finger rounded littleF {{
                  esSeleccionatDit(5) ? 'fingerSelected' : ''
                }}"
              ></div>
              <div class="fingerName">
                {{ "comu.nom-dit.auricular" | translate }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
