export const getBase64FromDataUrl = (dataUrl: string) => {
  return dataUrl.split(',')[1];
};

export const getCroppedImage = (image: ImageData, x: number, y: number, width: number, height: number) => {
  if (!image) {
    return null;
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return null;
  }

  ctx.putImageData(image, -x, -y);

  const dataURL = canvas.toDataURL('image/png');
  const imageData = ctx.getImageData(0, 0, width, height);

  canvas.remove();

  return { dataURL, imageData };
};

export const detectBlurryImage = (image: ImageData, split = 1) => {
  if (!image) {
    return false;
  }

  const data = image.data;

  // Convert to grayscale
  for (let i = 0; i < data.length; i += 4) {
    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
    data[i]     = avg; // red
    data[i + 1] = avg; // green
    data[i + 2] = avg; // blue
  }

  // Calculate second derivative
  const derivatives: number[] = [];
  const dx = Math.floor((image.width - 2) / split);
  const dy = Math.floor((image.height - 2) / split);
  for (let i = 0; i < split; i++) {
    for (let j = 0; j < split; j++) {
      let maxDerivative = 0;
      for (let y = 1 + i * dy; y < 1 + i * dy + dy; y++) {
        for (let x = 1 + j * dx; x < 1 + j * dx + dx; x++) {
          const pos = (y * image.width + x) * 4;
          const dx = Math.abs(2 * data[pos] - data[pos - 4] - data[pos + 4]);
          const dy = Math.abs(2 * data[pos] - data[pos - 4 * image.width] - data[pos + 4 * image.width]);
          maxDerivative = Math.max(maxDerivative, dx, dy);
        }
      }
      if (maxDerivative > 0) {
        derivatives.push(maxDerivative);
      }
    }
  }

  return !derivatives.length || Math.min(...derivatives) <= 50;
};

export function isImageGoodQuality(variances: number[], lastVariance: number): boolean{
  // First, sort the variances in ascending order
  const sortedVariances = [...variances].sort((a, b) => a - b);

  // Find the 80th percentile position
  const index = Math.ceil((80 / 100) * sortedVariances.length) - 1;

  // Get the 80th percentile value
  const percentile80th = sortedVariances[index];

  console.log(`Variance: ${lastVariance}, percentile80th: ${percentile80th}`);
  // Check if the lastVariance is greater than or equal to the 90th percentile
  return lastVariance >= percentile80th;
}

export function isOutlier(variances: number[], lastVariance: number): boolean {
  // First, sort the variances in ascending order
  const sortedVariances = [...variances].sort((a, b) => a - b);

  // Calculate the quartile indices
  const q1Index = Math.ceil((25 / 100) * (sortedVariances.length + 1)) - 1;
  const q3Index = Math.ceil((75 / 100) * (sortedVariances.length + 1)) - 1;

  // Get the quartile values
  const q1 = sortedVariances[q1Index];
  const q3 = sortedVariances[q3Index];

  // Check if the lastVariance is between Q1 and Q3
  return lastVariance < q1; // || lastVariance > q3;
}

export function removeLowestVariance(variances: number[], lastVariance: number) {
  // Find the index of the lowest variance
  const minIndex = variances.indexOf(Math.min(...variances));

  // Remove the lowest variance
  variances.splice(minIndex, 1);
}

export function getBlurryVariance(imageData: ImageData): number {
  // Convert to grayscale
  const gray = new Float32Array(imageData.width * imageData.height);
  for (let i = 0; i < imageData.data.length; i += 4) {
      gray[i / 4] = imageData.data[i] * 0.299 + imageData.data[i + 1] * 0.587 + imageData.data[i + 2] * 0.114;
  }

  // Laplacian kernel
  const kernel = [0, 1, 0, 1, -4, 1, 0, 1, 0];
  const laplacian = applyKernel(imageData.width, imageData.height, gray, kernel);

  // Calculate variance
  const mean = laplacian.reduce((a, b) => a + b) / laplacian.length;
  const variance = laplacian.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / laplacian.length;

  // Threshold to determine if the image is blurry
  // const threshold = 600; // Adjust this threshold based on your requirements
  console.log(variance);
  return variance; // < threshold;
}

function applyKernel(width: number, height: number, pixels: Float32Array, kernel: number[]): Float32Array {
  const output = new Float32Array(pixels.length);
  const kernelSize = Math.sqrt(kernel.length);
  const edge = Math.floor(kernelSize / 2);

  for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
          let sum = 0;
          for (let ky = 0; ky < kernelSize; ky++) {
              for (let kx = 0; kx < kernelSize; kx++) {
                  const px = x + kx - edge;
                  const py = y + ky - edge;

                  if (px >= 0 && px < width && py >= 0 && py < height) {
                      sum += pixels[py * width + px] * kernel[ky * kernelSize + kx];
                  }
              }
          }
          output[y * width + x] = sum;
      }
  }

  return output;
}


