import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-seleccio-dits',
  templateUrl: './seleccio-dits.component.html',
  styleUrls: ['./seleccio-dits.component.css'],
})
export class SeleccioDitsComponent implements OnInit {
  @Output() onSortir = new EventEmitter<number[]>();

  @Input() dits: number[];
  @Input() maximNumeroSeleccionats: number;

  constructor() {}

  ngOnInit(): void {}

  async tornar() {
    this.onSortir.emit(this.dits);
  }

  seleccionarDit(dit: number) {
    if (this.esSeleccionatDit(dit)) {
      if (this.dits.length > 1)
        this.dits = [...this.dits.filter((x) => x !== dit)];
    } else {
      if (this.maximNumeroSeleccionats == 1) this.dits = [dit];
      else {
        switch (dit) {
          case 1:
            this.selecciona(dit, [6]);
            break;
          case 2:
            this.selecciona(dit, [7, 3]);
            break;
          case 3:
            this.selecciona(dit, [2, 8]);
            break;
          case 6:
            this.selecciona(dit, [1]);
            break;
          case 7:
            this.selecciona(dit, [2, 8]);
            break;
          case 8:
            this.selecciona(dit, [7, 3]);
            break;
          case 4:
          case 5:
          case 9:
          case 10:
            this.selecciona(dit, []);
            break;
        }
      }
    }
  }

  esSeleccionatDit(dit: number): boolean {
    if (!this.dits) return false;

    return this.dits.includes(dit);
  }

  selecciona(dit: number, altres: Array<number>) {
    this.dits = [...this.dits.filter((x) => altres.includes(x)), dit];

    this.dits = this.dits
      .slice(this.dits.length - this.maximNumeroSeleccionats)
      .sort(this.ditsOrdenacio);
  }

  private ditsOrdenacio(a: number, b: number): number {
    const ordre = [5, 6, 7, 8, 9, 4, 3, 2, 1, 0];

    return ordre[a - 1] - ordre[b - 1];
  }
}
