<div class="card mt-2">
  <h5 class="card-header">{{ "comu.autoritzacio.titol" | translate }}</h5>

  <div class="card-body">
    <form>
      <div class="row">
        <div class="col-lg-4">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              [checked]="autoritzacio === TipusAutoritzacio.Sortida"
              (change)="onAutoritzacioCanvi(TipusAutoritzacio.Sortida)"
              id="sortida"
              name="autoritzacio"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="sortida">{{
              "comu.autoritzacio.sortida" | translate
            }}</label>
          </div>

          <div class="custom-control custom-radio">
            <input
              type="radio"
              [checked]="autoritzacio === TipusAutoritzacio.Retorn"
              (change)="onAutoritzacioCanvi(TipusAutoritzacio.Retorn)"
              id="retorn"
              name="autoritzacio"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="retorn">{{
              "comu.autoritzacio.entrada" | translate
            }}</label>
          </div>

          <div class="custom-control custom-radio">
            <input
              type="radio"
              [checked]="autoritzacio === TipusAutoritzacio.NoAutoritzar"
              (change)="onAutoritzacioCanvi(TipusAutoritzacio.NoAutoritzar)"
              id="no-autoritzar"
              name="autoritzacio"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="no-autoritzar">{{
              "comu.autoritzacio.no-autoritzar" | translate
            }}</label>
          </div>
        </div>

        <div class="col-lg-8">
          <div class="custom-control custom-radio">
            <input
              type="radio"
              [checked]="autoritzacio === TipusAutoritzacio.Test"
              (change)="onAutoritzacioCanvi(TipusAutoritzacio.Test)"
              id="test"
              name="autoritzacio"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="test">{{
              "comu.autoritzacio.comprovacio-identitat" | translate
            }}</label>
          </div>

          <div class="custom-control custom-radio">
            <input
              type="radio"
              [checked]="autoritzacio === TipusAutoritzacio.Erronia"
              (change)="onAutoritzacioCanvi(TipusAutoritzacio.Erronia)"
              id="erronia"
              name="autoritzacio"
              class="custom-control-input"
            />
            <label class="custom-control-label" for="erronia">{{
              "comu.autoritzacio.identificacio-erronia" | translate
            }}</label>
          </div>

          <div
            *ngIf="autoritzacio === TipusAutoritzacio.Erronia"
            class="form-group"
          >
            <label for="observacions"
              >{{ "comu.autoritzacio.observacions" | translate }}:</label
            >
            <textarea
              [(ngModel)]="observacions"
              class="form-control"
              id="observacions"
              name="observacions"
              rows="3"
            ></textarea>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-info mt-3 pl-5 pr-5"
        (click)="autoritzar()"
      >
        {{ "comu.accio.fet" | translate }}
      </button>
    </form>
  </div>
</div>

<lib-missatge-modal #missatgeModal></lib-missatge-modal>
