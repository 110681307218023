import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DisponibilitatMa } from '../../model/disponibilitat-ma';
import {
  EmpremtaAmbTipus,
  EmpremtaCaptura,
  EmpremtesAnalitzades,
} from '../../model/empremta-captura';
import { ApartatService } from '../../serveis/apartat.service';
import { GrupEmpremtes } from '../../model/grup-empremtes';
import { Etapa, TipoDecision } from '../../model/documento';

import { ConfiguracioService, DatosService } from 'projects/siepbio-comu/src/public-api';

import { faCheck, faExclamationTriangle, faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-enrolament-empremtes',
  templateUrl: './enrolament-empremtes.component.html',
})
export class EnrolamentEmpremtesComponent implements OnInit {
  @Input() esRodada: boolean;
  @Input() intentsDreta: number;
  @Input() intentsEsquerra: number;
  @Input() intentsPolzes: number;


  @Output('onSeleccionatDisponibilitat')
  onSeleccionatDisponibilitatPare = new EventEmitter<DisponibilitatMa>();

  @Output('onSeleccionatDisponibilitatDit')
  onSeleccionatDisponibilitatDitPare = new EventEmitter<EmpremtaAmbTipus>();

  @Output('onNetejarEmpremtes')
  onNetejarEmpremtesPare = new EventEmitter<Array<EmpremtaCaptura>>();

  @Output('onNetejarEmpremta')
  onNetejarEmpremtaPare = new EventEmitter<EmpremtaAmbTipus>();

  @Output('onIncrementarIntents')
  onIncrementarIntentsPare = new EventEmitter<GrupEmpremtes>();

  @Output('onAnalitzaEmpremtes')
  onAnalitzaEmpremtesPare = new EventEmitter<EmpremtesAnalitzades>();

  Etapa: typeof Etapa = Etapa;
  GrupEmpremtes: typeof GrupEmpremtes = GrupEmpremtes;
  iconValido = faCheck;
  iconNoValido = faExclamationTriangle;

  constructor(
    private apartatServei: ApartatService,
    private configuracionService: ConfiguracioService,
    public datosService: DatosService
    ) {}

  ngOnInit(): void {
    let fecha = this.datosService.datosVerificacion?.documentoLeido?.MRZData?.fechaNacimiento;
    //let fecha = "12/12/2012";
    let edadCapturaMin = this.configuracionService.parametrosConfiguracion.edadMinimaCapturaHuellas;
    console.log("Huellas capturadas", this.datosService.datosVerificacion?.huellasCapturadas);
    
    if(this.tieneHuellasEES()){
      this.datosService.datosVerificacion.mensajeNoEsNecesarioTomarHuellas = "Se encuentran huellas en el EES. No es necesario tomarlas de nuevo.";
    }
    else if ( fecha != undefined && !this.esNecesarioTomarHuellas(fecha, edadCapturaMin)){ 
      this.datosService.datosVerificacion.mensajeNoEsNecesarioTomarHuellas = "Edad inferior a "+ edadCapturaMin +"años. No es necesario tomar las huellas.";
    }
    else{
      this.datosService.datosVerificacion.mensajeNoEsNecesarioTomarHuellas = undefined;
    }
  }

  tieneHuellasEES(): boolean{
    this.datosService.datosVerificacion?.resultadoSearch?.registros?.forEach(element => {
      if (element?.huellas != null && element?.huellas?.length > 0 && element?.huellas?.some((e) => e.rawImatge !=undefined)) 
       return true;
    });
    return false;
  }

  hayHuellasCapturadas(): boolean{
    if (this.datosService.datosVerificacion?.huellasCapturadas == undefined || this.datosService.datosVerificacion?.huellasCapturadas?.length == 0) return false;
    return this.datosService.datosVerificacion?.huellasCapturadas?.some(element => 
      element?.rawImatge != undefined
    );
  }

  esNecesarioTomarHuellas(fecha: string, edad?: number): boolean{
    if (edad != undefined && edad > 0){
      let [day, month, year] = fecha.split('/');
      const fechaNacimiento = new Date(+year, +month - 1, +day);
      const fechaActual = new Date();
      const fechaMinima = new Date(fechaActual.getFullYear() - edad, fechaActual.getMonth(), fechaActual.getDate());
      return  fechaNacimiento < fechaMinima;
    }
    return true;
  }

  ditsEsquerra() {
    const dits = this.datosService.datosVerificacion.huellasCapturadas;

    return [
      dits[10 - 1],
      dits[9 - 1],
      dits[8 - 1],
      dits[7 - 1],
    ];
  }

  ditsDreta() {
    const dits = this.datosService.datosVerificacion.huellasCapturadas;

    return [
      dits[2 - 1],
      dits[3 - 1],
      dits[4 - 1],
      dits[5 - 1],
    ];
  }

  ditsPolzes() {
    // 6-1 i 1-1 ja que és base 0
    const dits = this.datosService.datosVerificacion.huellasCapturadas;

    return [dits[5], dits[0]];
  }

  onSeleccionatDisponibilitat(disponibilitatMa: DisponibilitatMa) {
    this.onSeleccionatDisponibilitatPare.emit(disponibilitatMa);
  }

  onSeleccionatDisponibilitatDit(empremta: EmpremtaAmbTipus) {
    this.onSeleccionatDisponibilitatDitPare.emit(empremta);
  }

  onNetejarEmpremtes(empremtes: Array<EmpremtaCaptura>) {
    this.onNetejarEmpremtesPare.emit(empremtes);
  }

  onNetejarEmpremta(empremta: EmpremtaAmbTipus) {
    this.onNetejarEmpremtaPare.emit(empremta);
  }

  onIncrementarIntents(grup442: GrupEmpremtes) {
    this.onIncrementarIntentsPare.emit(grup442);
  }

  async onAnalitzaEmpremtes(empremtes: EmpremtesAnalitzades) {
  
    this.onAnalitzaEmpremtesPare.emit(empremtes);
  }

  

  
  
}
