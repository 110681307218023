import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfiguracioService, Excepcio } from 'projects/siepbio-comu/src/public-api';
import { DecisionResultadoFaceUSK, WarningResultadoFaceUSK, ResultadoFaceUSK, SlapQualityResult, FingerQualityRequest, SlapQuality, FingerScore, FingerPosition } from '../model/EntryExit';

@Injectable({
  providedIn: 'root'
})
export class UskService {

  public host: string = "https://localhost:3008";
  constructor(private http: HttpClient, private configuracionService: ConfiguracioService) {
    if (configuracionService.parametrosConfiguracion.hostServiciosUSK != undefined)
      this.host = configuracionService.parametrosConfiguracion.hostServiciosUSK;
  }

  private async doCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    console.log("docall host documentreader", this.host);
    var headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (isGet)
      return await this.http
        .get(this.host + url, {
          headers: headers,
          params: params,
          observe: 'body',
          responseType: 'json',
        })
        .toPromise();
    else {
      return await this.http
        .post(
          this.host + url,
          json ? json : undefined,
          {
            headers: headers,
            observe: 'body',
            params: params,
            responseType: 'json',
          }
        )
        .toPromise();
    }
  }

  private async manageCall(
    isGet: boolean,
    url: string,
    params?: { [param: string]: string | string[] },
    json?: string
  ): Promise<any> {
    var obj = await this.doCall(isGet, url, params, json);

    if (obj && obj.rc && obj.rc === 'ERR00-004') {
      obj = await this.doCall(isGet, url, params, json);
      if (obj && obj.rc) throw 'session-expired';
    }

    return obj;
  }

  public async face(
    foto: string
  ): Promise<ResultadoFaceUSK> {
    try{
    let response = await this.manageCall(
      false,
      '/api/usk/face',null, JSON.stringify(foto)
      );
      console.log("llamada a usk de foto", response);
      
      let json = JSON.parse(JSON.stringify(response));
      
      let resultado = new ResultadoFaceUSK();
      let decisionString : string = json["decision"];
      resultado.decision = DecisionResultadoFaceUSK[decisionString];
      let warningString : string = json["warning"];
      resultado.warning =  WarningResultadoFaceUSK[warningString];
      
      return resultado;
    }
    catch(ex){
      throw new Excepcio(
        "Error en el servicio USK [face]. " , "Pruebe a reintentar", ex.message
      );
    }
  }

  public async slap(
    request: Array<FingerQualityRequest>
  ): Promise<SlapQualityResult> {
    try{
      let response = await this.manageCall(
        false,
        '/api/usk/slap',null, JSON.stringify(request)
        );
        console.log("llamada a usk de huellas", response);
        
        return response as SlapQualityResult;
/*
        let json = JSON.parse(JSON.stringify(response));
        let resultado = new SlapQualityResult();
        resultado.slapQuality = Number(json["slapQuality"] as SlapQuality);
        resultado.fingersScore = new Array<FingerScore>();
        resultado.slapScore = json["slapScore"];
        json["fingersScore"].forEach(fpScore => {              
          let element = new FingerScore();
          element.position = <FingerPosition> fpScore["position"];
          element.score = fpScore["score"];
          resultado.fingersScore.push(element);
        });*/
        //return resultado;
      }
      catch(ex){
        throw new Excepcio(
          "Error en el servicio USK [slap fingerprints]. " , "Pruebe a reintentar", ex.message
        );
      }
  }
}
